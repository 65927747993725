import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./pagination.css";

const navListStyle = {
  display: "block",
  float: "left",
  margin: "10px",
};

const itemListStyle = {
  display: "block",
  float: "left",
  margin: "10px",
};

export default class Page extends Component {
  state = {
    isOpen: false,
  };

  static propTypes = {
    pageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    pageNumber: PropTypes.number.isRequired,
    DateRef: PropTypes.func,
    setopen: PropTypes.func,
    open: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isNavigationPage: PropTypes.bool,
    date: PropTypes.string,
  };

  static defaultProps = {
    activeClass: "active",
    itemClass: undefined,
    linkClass: undefined,
    activeLinkCLass: undefined,
    isActive: false,
    isDisabled: false,
    href: "#",
    isNavigationPage: false,
  };

  handleClick = (e) => {
    const { isDisabled, pageNumber } = this.props;
    e.preventDefault();

    if (isDisabled) {
      return;
    }
    this.props.onClick(pageNumber);
  };

  // showCal = () => {

  //   render() {
  //     return (
  //     <Calendar value={new Date()} />
  //   )}
  // }

  render() {
    let {
      pageText,
      isActive,
      isDisabled,
      date,
      setSelectedDate,
      setopen,
      open,
      DateRef,
      budget_type,


    } = this.props;


    let elem =
      this.props.isNavigationPage === true ? (
        <li
          className={isDisabled ? "disabled" : ""}
          onClick={(e) => this.handleClick(e)}
        >
          <a href={"#"}>
            <strong>{pageText}</strong>
          </a>
        </li>
      ) : (budget_type === 1) ? (
        <li
          className={isActive ? "active" : ""}
          onClick={(e) => this.handleClick(e)}
        >
          {isActive ? (
            <a href={"#"}>Day {date.date.getDate()}</a>
          ) : (
            <a href={"#"}>Day {date.date.getDate()}</a>
          )}
        </li>
      ) : (
        <li
          className={isActive ? "active" : ""}
          onClick={(e) => this.handleClick(e)}
        >
          {isActive ? (
            <a href={"#"}>
              <button
                className="calender-btn"
                onClick={() => {
                  setopen(true);
                }}
              >
                <i class="bi bi-calendar3"></i>
              </button>
              {open && (
                <DatePicker
                  selected={moment(moment(date.date).format('YYYY-MM-DD').toString()).toDate()}
                  maxDate={moment('2024-12-31').toDate()}
                  minDate={moment('2024-01-01').toDate()}
                  ref={DateRef}
                  inline
                  onChange={(date) => {
                    setSelectedDate(date);
                    setopen(false);
                  }}
                />
              )}
              {moment(date.date, "YYYY-MM-DD").format("ll")}
            </a>
          ) : (
            <a href={"#"}>
              {moment(date.date, "YYYY-MM-DD").format("ll").split(",")[0]}
            </a>
          )}
        </li>
      );

    return elem;
  }
}
