import "bootstrap/dist/css/bootstrap.min.css";
import ListGroup from "react-bootstrap/ListGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { DateRange, DateRangePicker } from "react-date-range";
import React, { useState } from "react";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import { useRef, useEffect } from "react";
import { ConstructOutline } from "react-ionicons";

function DateRangeList({
  setedate,
  setsdate,
  setdatepickershow,
  state,
  setSelectedRange,
  selectedRange,
  tot,
  budget,
  Budgetlength,
  selectYear

}) {
  //const ref = useRef(null)
  useEffect(() => {}, [selectedRange]);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const earlierDate = new Date(today);
const earlierlastseven = new Date(today);
const earlierlastthirty= new Date(today);
const earlierlastmonth= new Date(today);
  const prevyear=today.getFullYear()- 1

  earlierDate.setDate((earlierDate.getDate()-1))
  earlierlastseven.setDate((earlierlastseven.getDate()-6))
  earlierlastthirty.setDate((earlierlastthirty.getDate()-29))
  earlierlastmonth.setDate((earlierlastmonth.getMonth()-1))

  const yesterday = earlierDate.getFullYear()
  const lastseven = earlierlastseven.getFullYear()
  const lastthirty = earlierlastthirty.getFullYear()
  const lastmonth=earlierlastmonth.getFullYear()
console.log(yesterday,'yesterday')
  const datee =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const [selectedDepartment_active, setselectedDepartment_active] =
    useState(null);
  const setPeriod = (id) => {
    setselectedDepartment_active(id);
    setdatepickershow(false);

    let sdate;
    let edate;
    const today = new Date();
    if (id === 1) {
      sdate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      edate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      setSelectedRange(
        Moment(new Date()).format("LL") +
          " - " +
          Moment(new Date()).format("LL")
      );
    }
    if (id === 2) {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      sdate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      edate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      setSelectedRange(
        Moment(sdate).format("LL") + " - " + Moment(edate).format("LL")
      );
    } else if (id === 3) {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      sdate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      edate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      setSelectedRange(
        Moment(sdate).format("LL") + " - " + Moment(edate).format("LL")
      );
    } else if (id === 4) {
      var date = new Date();
      date.setDate(date.getDate() - 30);
      sdate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      edate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      setSelectedRange(
        Moment(sdate).format("LL") + " - " + Moment(edate).format("LL")
      );
    } else if (id === 5) {
      var date = new Date();
      sdate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + 1;
      edate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      setSelectedRange(
        Moment(sdate).format("LL") + " - " + Moment(edate).format("LL")
      );
    } else if (id === 6) {
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      sdate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + 1;
      edate =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      setSelectedRange(
        Moment(sdate).format("LL") + " - " + Moment(edate).format("LL")
      );
    }

    setedate(edate);
    setsdate(sdate);
  };
  useEffect(() => {
    console.log(tot.YearToDay, "tot");
  }, []);

  return (
    <div
      className="container-fluid R-list-container"
      style={{
        paddingRight: "0px",
        paddingLeft: "0px",
        marginLeft: "-55px",
        marginRight: "-55px",
      }}
    >
      {budget!=undefined && Budgetlength != 0 &&(
      <div className="col-md-12">
        <div className="row  R-list">
          <div className="col-md-5 col-sm-5 col-lg-4">
            <div className="p&l-Dropdown pandl-drop">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-autoclose-outside"
                  className="drop-ml datepicker-dropml fordroppandl pandl-dropdn"
                  variant="secondary"
                >
                  <i class="bi bi-calendar3 calendor-icon"></i>{" "}
                  <span className="cal-text" style={{fontSize:'15px'}}>{selectedRange}</span>
                   <i style={{color:'#24D153',fontWeight:'600',fontSize:'15px'}} class="fa fa-angle-down icon-drop-pl"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="pandldropyear" variant="dark" style={{ width: "95%" }}>
                  {/* <Dropdown.Item  active>
              Today
              </Dropdown.Item> */}
               { 
                new Date().getFullYear()==selectYear &&   <>
                  <Dropdown.Item
                    onClick={() => setPeriod(1)}
                    className={selectedDepartment_active === 1 ? "active" : ""}
                  >
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setPeriod(2)}
                    className={selectedDepartment_active === 2 ? "active" : ""}
                    disabled={yesterday==prevyear}
                  >
                    Yesterday
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => setPeriod(3)}
                    className={selectedDepartment_active === 3 ? "active" : ""}
                    disabled={lastseven==prevyear}
                  >
                    Last 7 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setPeriod(4)}
                    className={selectedDepartment_active === 4 ? "active" : ""}
                    disabled={lastthirty==prevyear}
                  >
                    Last 30 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setPeriod(5)}
                    className={selectedDepartment_active === 5 ? "active" : ""}
                  >
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setPeriod(6)}
                    className={selectedDepartment_active === 6 ? "active" : ""}
                    disabled={lastmonth==prevyear}
                  >
                    Last Month
                  </Dropdown.Item>
                  </>}
                  <Dropdown.Item onClick={() => setdatepickershow(true)}>
                    Custom Range
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="col-md-7 col-sm-7 col-lg-8">
            <div
              className="forpandltodate"
              style={{ float: "right", display: "flex", gap: "50px" }}
            >
              <div style={{ display: "flex", gap: "20px" }}>
                <p
                  className="pandl-text"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}
                >
                  P&L
                </p>
                <CurrencyFormat
                
                  style={{
                    background: "#FF0C00 0% 0% no-repeat padding-box",
                    color: "white",
                    borderRadius: "3px",
                    width: "125px",
                    height: "35px",
                    marginTop:'15px'
                  }}
                  thousandSeparator={true}
                  disabled={true}
                  className="pandl-input non-edit-cs admin-budget-td"
                  prefix={tot.YearToDay < 0 ? "$(" : "$"}
                  suffix={tot.YearToDay < 0 ? ")" : ""}
                  value={Math.abs(tot && tot.YearToDay ? tot.YearToDay.toFixed(2) : "")}

                />
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                <p
                  className="pandl-text"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}
                >
                  P&L to Date
                </p>
                <CurrencyFormat
                 
                  style={{
                    color: "White",
                    background: "#FF0C00 0% 0% no-repeat padding-box",
                    width: "125px",
                    height: "35px",
                    borderRadius: "3px",
                    marginTop:'15px'
                  }}
                  thousandSeparator={true}
                  prefix={tot.MonthMTD < 0 ? "$(" : "$"}
                  suffix={tot.MonthMTD < 0 ? ")" : ""}
                  value={Math.abs(tot && tot.MonthMTD ? tot.MonthMTD.toFixed(2) : "")}
                  disabled={true}
                  className="pandl-input non-edit-cs admin-budget-td"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
)}
    </div>
  );
}
export default DateRangeList;
