import 'bootstrap/dist/css/bootstrap.min.css'
import './SnapshotTable.css';
import Table from 'react-bootstrap/Table';
import { useEffect ,useState} from 'react';
import { Spinner } from '../../../Component/Spinner'

function SnapshotTable1({ Rounds_Daily_mtd,Rounds_mtd,Revenue_mtd }) {
    const[show,setshow] = useState(false)
    const[dailyRound,setDailyRounds] = useState()


    useEffect(() => {

        // const sum = Rounds_Daily_mtd?.reduce((accumulator, object) => {
        //     return accumulator + object.Actual_Rounds;
        //   }, 0);
        //   setDailyRounds(sum);

        // const timer = setTimeout(() => {
  
        //   console.log('This will run after 1 second!')
  
        //     setshow(true);
  
        // }, 1000);
  
        // return () => clearTimeout(timer);
  
      }, []);


    return (
        <>
          {show ? <Spinner /> : (
        <div class="table-scroll  table-responsive table2 table-revenue" style={{marginBottom:'-14px'}}>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th className='dashboard-th'> </th>

                        <th className='dashboard-th' ><div className='tab-card-new admin-budget-th'> <p style={{ textAlign: "-webkit-center", margin: "0 auto", color:"white" }} className='' >MTD</p> </div> </th>
                        <th className='dashboard-th'> <div className='tab-card-new admin-budget-th'><p style={{ textAlign: "-webkit-center", margin: "0 auto", color:"white" }}>MTD Budget</p></div> </th>
                        <th className='dashboard-th'> <div className='tab-card-new admin-budget-th '><p style={{ textAlign: "-webkit-center",  margin: "0 auto", color:"white" }}>Variance</p> </div> </th>
                        <th className='dashboard-th'> <div className='tab-card-new admin-budget-th'><p style={{ textAlign: "-webkit-center",  margin: "0 auto", color:"white" }}>Variance % </p></div></th>

                    </tr>
                </thead>
                <tbody >
                    <tr>
                        <td className='sideheader' style={{borderRight:"0px"}}><p style={{padding:'5px 0px'}} className='admin-budget-td sideheader-td'> Rounds</p></td>
                        {Rounds_mtd?.map(round => <>
                            <td class="table-data2" style={{borderRight:"0px"}}>
                            <div style={{background:'#f1f1f1',padding:'5px 10px',borderRadius:'3px'}} className={'table-data textalign'}>{round.Roundsmtd < 0 ? Math.abs(round.Roundsmtd) : round.Roundsmtd}</div></td>   
                            <td class="table-data2" style={{borderRight:"0px"}}>
                            <div style={{background:'#f1f1f1',padding:'5px 10px',borderRadius:'3px'}} class='table-data textalign'>{round.Roundsbudget < 0 ? Math.abs(round.Roundsbudget) : round.Roundsbudget}</div></td> 
                            <td class="table-data2" style={{borderRight:"0px"}}> 
                            <div style={{background:'#f1f1f1',padding:'5px 10px',borderRadius:'3px'}} class={round.Roundsbudget > round.Roundsmtd?" table-data negative textalign":"table-data positive textalign"}>{round.Roundsvarience < 0 ? Math.abs(round.Roundsvarience): round.Roundsvarience}</div></td> 
                            <td class="table-data2" style={{borderRight:"0px"}}> <div style={{background:'#f1f1f1',padding:'5px 10px',borderRadius:'3px'}} class={round.Roundsbudget > round.Roundsmtd?"table-data negative textalign":"table-data positive textalign"}>{round.Roundsvarienceper < 0 ? Math.abs(round.Roundsvarienceper).toLocaleString()+ " %" : round.Roundsvarience.toLocaleString()+ " %"}</div> </td>
                        </>)}
                    </tr>
                    {console.log(Revenue_mtd,"GHG")}
                    {Revenue_mtd?.map(rev => <>
                    <tr >                  
                        <td className='sideheader' style={{borderRight:"0px"}}><p style={{padding:'5px 0px'}} className='admin-budget-td sideheader-td'>{rev.Name}</p></td>                       
                        <td class="table-data2" style={{borderRight:"0px"}}>
                             <div style={{background:'#f1f1f1',padding:'5px 10px',borderRadius:'3px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} class={(rev.Name=="Income" && rev.mtd < rev.budget) ?"table-data table-data-2 negative textalign gray-bac": (rev.Name=="Income" && rev.mtd> rev.budget )? "table-data table-data-2 positive textalign gray-bac" : (rev.Name!="Income" && rev.mtd > rev.budget) ? "table-data negative textalign gray-bac" : " table-data positive textalign white-bac gray-bac"  }>
                             <span style={{textAlign: 'left', marginRight: '5px'}}>$</span>
                            <span style={{textAlign: 'right'}}>  {rev.mtd < 0 ? Math.abs(rev.mtd).toLocaleString() : rev.mtd.toLocaleString()}</span>

                          
                             </div>
                              </td>
                        <td class="table-data2" style={{borderRight:"0px"}}>
                            <div style={{background:'#f1f1f1',padding:'5px 10px',borderRadius:'3px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}  class={rev.Name=="Income" ? "table-data table-data-2 textalign gray-bac " : 'table-data textalign gray-bac'}>
                            <span style={{textAlign: 'left', marginRight: '5px'}}>$</span>
                            <span style={{textAlign: 'right'}}> {rev.budget < 0 ? Math.abs(rev.budget).toLocaleString() : rev.budget.toLocaleString()}</span>
                           
                            </div> 
                            </td>
                        <td class="table-data2" style={{borderRight:"0px"}}>
                            <div style={{background:'#f1f1f1',padding:'5px 10px',borderRadius:'3px',borderRadius:'3px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} class={(rev.Name=="Income" && rev.budget > rev.mtd) ?"table-data table-data-2 negative textalign gray-bac": (rev.Name=="Income" && rev.budget < rev.mtd )? "table-data table-data-2 positive textalign gray-bac" : (rev.Name!="Income" && rev.budget < rev.mtd) ? "table-data negative textalign gray-bac" : "table-data positive textalign gray-bac"  }>
                            <span style={{textAlign: 'left', marginRight: '5px'}}>$</span>
                            <span style={{textAlign: 'right'}}>{rev.variance < 0 ? Math.abs(rev.variance).toLocaleString() : rev.variance.toLocaleString()}</span>
                          
                        </div> 
                         </td>
                        <td class="table-data2" style={{borderRight:"0px"}}>
                            <div style={{background:'#f1f1f1',padding:'5px 10px',borderRadius:'3px'}} class={(rev.Name=="Income" && rev.budget > rev.mtd) ?"table-data table-data-2 negative textalign gray-bac": (rev.Name=="Income" && rev.budget < rev.mtd )? "table-data table-data-2 positive textalign gray-bac" : (rev.Name!="Income" && rev.budget < rev.mtd) ? "table-data negative textalign gray-bac" : "table-data positive textalign gray-bac"  }> {rev.variance_per < 0 ? Math.abs(rev.variance_per).toLocaleString() + " %" : rev.variance_per.toLocaleString() + " %"}   
                            </div> 
                            </td>                        
                    </tr>
                    </>)}
                   
                </tbody>
            </Table>
        </div>)
    }
    </>
    );
}
export default SnapshotTable1;