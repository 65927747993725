import "bootstrap/dist/css/bootstrap.min.css";

import "../Tab_manageuser/Tab_user.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Pagination from "react-bootstrap/Pagination";
import TableComponent1 from "../Component/Tables/TableComponent1";
import { useState, useEffect } from "react";
import axios from "axios";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import { Spinner } from "../Component/Spinner";
import { ToastContainer, toast } from "react-toastify";

const sample = [
  {
    firstName: "Nagaraj",
    lastName: "Vanaraj",
    email: "nagaraj@jetzerp.com",
    password: "river",
    course: "cse",
    department: "it",
    Role: "Team Member",
    Status: "1",
    FobesoftStatus: "Not Applicable",
  },
];
const column = [
  { column: "First Name" },
  { column: "Last Name" },
  { column: "Email" },
  { column: "Password" },
  { column: "Course" },
  { column: "Department" },
  { column: "Role" },
  { column: "Status" },

];

function InvoiceDashboard({ tab, setTab, setEditUser, setDeleteUser }) {
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const [bossid, setbossid] = useState(items.Restaurant_id);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const[userupdated,setUserUpdated]=useState(false);
  const [duplicate, setduplicate] = useState([]);
  const [serch,setsearchs]=useState([])
  //HANDLE DROPDOWN CHANGE
  //const [selectDepartment, SetselectDepartment] = useState("All");
  const [dropdown, setdropdown] = useState("");

  const [fromdate, setfromdate] = useState(() => {
    return new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0];
  });
  const [todate, settodate] = useState(() => {
    return new Date().toISOString().split("T")[0];
  });
  function handleDateChange(event) {
    if (event.target.name === "fromdate") {
      setfromdate(event.target.value);
    } else if (event.target.name === "todate") {
      settodate(event.target.value);
    }
  }
  const [selectDepartment, SetselectDepartment] = useState("All");

  const [bossid1, setbossid1] = useState('');




  useEffect(()=>{
const hit=async()=>{
if(items.Account_Type==0 && items.Account_id==5){

  setIsLoading(true);
  let result = [];
  const { data } = await mainAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id='+items.Restaurant_id);
  if(data){
    setIsLoading(false);
  }
 // const { data } = await fobeAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id=3580');
  console.log("UserList", data.Invoice_Details);
  if (data.Invoice_Details) {
    if(data.Invoice_Details.length > 0){
   result = data.Invoice_Details.map((item) => ({
    Amount: item.rest_details.Amount,
    CreatedDate: item.rest_details.CreatedDate,
    Inv_num: item.rest_details.Inv_num,
    Purveyor_name: item.rest_details.Purveyor_name,
    Rest_id: item.rest_details.Rest_id,
    UpdatedDate: item.rest_details.UpdatedDate,
    Upload_id: item.rest_details.Upload_id,
    
    file_status: item.rest_details.file_status,
    filename: item.rest_details.filename,
    invoice_from: item.rest_details.invoice_from,
    invoice_status: item.rest_details.invoice_status,
    rawdata : item.rest_details.rawdata,
  })).sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)); // Sort by CreatedDate in descending order



  setData(result);
  setSearch(result);
  setduplicate(result)
  setsearchs(result);
  }
  else{

    setData([]);
    setSearch([]);
    setduplicate([]);
    setsearchs([]);
    toast.error("No Data Found")
  }
  } else {
 

    setData([]);
    setSearch([]);
    setduplicate([]);
    setsearchs([]);
    toast.error("No Data Found")
  }


}

}
hit();
  },[])
  const [selectedDepartment, setselectedDepartment] = useState(null);
  let course;

  const[searches,setsearches]=useState();


  const[eventykey,seteventkey]=useState('All')

const handleclick = async(eventKey, event)=>{

  SetselectDepartment(eventKey.split("`")[1]);


  setselectedDepartment(eventKey);
  setperiod1("All");
  setperiodvalue("All");



  //console.log(eventKey.split("`")[0],"EventKey")
  if (eventKey.split("`")[1] === "All ") {
    let course = eventKey.split("`")[0];

    let countss=eventKey.split("`")[1]
    seteventkey("All")

    setIsLoading(true);
    let result = [];
    const { data } = await mainAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id='+course);
    if(data){
      setIsLoading(false);
    }
   // const { data } = await fobeAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id=3580');
    console.log("UserList", data.Invoice_Details);
    if (data.Invoice_Details) {
      if(data.Invoice_Details.length > 0){
     result = data.Invoice_Details.map((item) => ({
      Amount: item.rest_details.Amount,
      CreatedDate: item.rest_details.CreatedDate,
      Inv_num: item.rest_details.Inv_num,
      Purveyor_name: item.rest_details.Purveyor_name,
      Rest_id: item.rest_details.Rest_id,
      UpdatedDate: item.rest_details.UpdatedDate,
      Upload_id: item.rest_details.Upload_id,
      
      file_status: item.rest_details.file_status,
      filename: item.rest_details.filename,
      invoice_from: item.rest_details.invoice_from,
      invoice_status: item.rest_details.invoice_status,
      rawdata : item.rest_details.rawdata,
    })).sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)); // Sort by CreatedDate in descending order
   
    setData(result);
    setSearch(result);
    setduplicate(result)
    setsearchs(result);
    }
    else{
  
      setData([]);
      setSearch([]);
      setduplicate([]);
      setsearchs([]);
      toast.error("No Data Found")
    }
    } else {
   

      setData([]);
      setSearch([]);
      setduplicate([]);
      setsearchs([]);
      toast.error("No Data Found")
    }



  }
  
else {
  

  course = eventKey.split("`")[0];
  console.log(course,"EventKey1")
  let countss=eventKey.split("`")[1]
  seteventkey(countss)

  setsearches(course)

    console.log(bossid1,"EventKey")
    setIsLoading(true);
    let result = [];
    const { data } = await mainAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id='+course);
    if(data){
      setIsLoading(false);
    }
   // const { data } = await fobeAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id=3580');
    console.log("UserList", data.Invoice_Details);
    if (data.Invoice_Details) {
      if(data.Invoice_Details.length > 0){
     result = data.Invoice_Details.map((item) => ({
      Amount: item.rest_details.Amount,
      CreatedDate: item.rest_details.CreatedDate,
      Inv_num: item.rest_details.Inv_num,
      Purveyor_name: item.rest_details.Purveyor_name,
      Rest_id: item.rest_details.Rest_id,
      UpdatedDate: item.rest_details.UpdatedDate,
      Upload_id: item.rest_details.Upload_id,
      
      file_status: item.rest_details.file_status,
      filename: item.rest_details.filename,
      invoice_from: item.rest_details.invoice_from,
      invoice_status: item.rest_details.invoice_status,
      rawdata : item.rest_details.rawdata,
    })).sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)); // Sort by CreatedDate in descending order
   
    setData(result);
    setSearch(result);
    setduplicate(result)
    setsearchs(result);
    }
    else{
  
      setData([]);
      setSearch([]);
      setduplicate([]);
      setsearchs([]);
      toast.error("No Data Found")
    }
    } else {
   

      setData([]);
      setSearch([]);
      setduplicate([]);
      setsearchs([]);
      toast.error("No Data Found")
    }
    // console.log(data.getResultList);
  }

}


const getDatas = async () => {

if(items.Account_Type==0 && eventykey!=="All")
{



setIsLoading(true);
  let result = [];
  const { data } = await mainAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id='+searches);
  if(data){
    setIsLoading(false);
  }
 // const { data } = await fobeAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id=3580');
  console.log("UserList", data.Invoice_Details);
  if (data.Invoice_Details) {
    if(data.Invoice_Details.length > 0){
   result = data.Invoice_Details.map((item) => ({
    Amount: item.rest_details.Amount,
    CreatedDate: item.rest_details.CreatedDate,
    Inv_num: item.rest_details.Inv_num,
    Purveyor_name: item.rest_details.Purveyor_name,
    Rest_id: item.rest_details.Rest_id,
    UpdatedDate: item.rest_details.UpdatedDate,
    Upload_id: item.rest_details.Upload_id,
    
    file_status: item.rest_details.file_status,
    filename: item.rest_details.filename,
    invoice_from: item.rest_details.invoice_from,
    invoice_status: item.rest_details.invoice_status,
    rawdata : item.rest_details.rawdata,
  })).sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)); // Sort by CreatedDate in descending order
 
  setData(result);
  setSearch(result);
  setduplicate(result)
  setsearchs(result);
  }
  else{

    setData([]);
    setSearch([]);
    setduplicate([]);
    setsearchs([]);
    toast.error("No Data Found")
  }
  } else {
 

    setData([]);
    setSearch([]);
    setduplicate([]);
    setsearchs([]);
    toast.error("No Data Found")
  }

}
else{
  setIsLoading(true);
  let result = [];
  const { data } = await mainAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id='+bossid);
  if(data){
    setIsLoading(false);
  }
 // const { data } = await fobeAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id=3580');
  console.log("UserList", data.Invoice_Details);
  if (data.Invoice_Details) {
    if(data.Invoice_Details.length > 0){
   result = data.Invoice_Details.map((item) => ({
    Amount: item.rest_details.Amount,
    CreatedDate: item.rest_details.CreatedDate,
    Inv_num: item.rest_details.Inv_num,
    Purveyor_name: item.rest_details.Purveyor_name,
    Rest_id: item.rest_details.Rest_id,
    UpdatedDate: item.rest_details.UpdatedDate,
    Upload_id: item.rest_details.Upload_id,
    
    file_status: item.rest_details.file_status,
    filename: item.rest_details.filename,
    invoice_from: item.rest_details.invoice_from,
    invoice_status: item.rest_details.invoice_status,
    rawdata : item.rest_details.rawdata,
  })).sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)); // Sort by CreatedDate in descending order
 
 
  setData(result);
  setSearch(result);
  setduplicate(result)
  setsearchs(result);
  }
  else{

    setData([]);
    setSearch([]);
    setduplicate([]);
    setsearchs([]);
    toast.error("No Data Found")
  }
  } else {
 

    setData([]);
    setSearch([]);
    setduplicate([]);
    setsearchs([]);
    toast.error("No Data Found")
  }
}
  // console.log(data.getResultList);
};
  const getData = async () => {

    console.log(bossid,"Boosid")



    setIsLoading(true);
    let result = [];
    const { data } = await mainAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id='+bossid);
    if(data){
      setIsLoading(false);
    }
   // const { data } = await fobeAxios.get("api/Budget/Getinvoice_updated_details?data="+fromdate+','+todate+'&Rest_id=3580');
    console.log("UserList", data.Invoice_Details);

     result = data.Invoice_Details.map((item) => ({
      Amount: item.rest_details.Amount,
      CreatedDate: item.rest_details.CreatedDate,
      Inv_num: item.rest_details.Inv_num,
      Purveyor_name: item.rest_details.Purveyor_name,
      Rest_id: item.rest_details.Rest_id,
      UpdatedDate: item.rest_details.UpdatedDate,
      Upload_id: item.rest_details.Upload_id,
      
      file_status: item.rest_details.file_status,
      filename: item.rest_details.filename,
      invoice_from: item.rest_details.invoice_from,
      invoice_status: item.rest_details.invoice_status,
      rawdata : item.rest_details.rawdata,
    })).sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)); // Sort by CreatedDate in descending order
   
    setData(result);
    setSearch(result);
    setduplicate(result)
    setsearchs(result);
    // console.log(data.getResultList);
  };
  const [periodvalue, setperiodvalue] = useState("All");

  const [period1, setperiod1] = useState("All");

  const handleDropdownChange = (event) => {
    setperiodvalue(event)
    setperiod1(event)
    let filter=[];
    //setdropdown(event.target.value) 
    if(event=="Processing"){

     console.log(period1,"Console")

      filter = duplicate.filter( (item) => item.invoice_status == "5" || item.invoice_status == "1" || item.invoice_status == "6"); 
console.log(filter,"filter");
    }
  
  else  if(event=="Completed"){


       filter = duplicate.filter(
        (item) => item.invoice_status == "2"
      );
     
       }
 else   if(event=="Re-Scan"){
      filter = duplicate.filter(
        (item) => item.invoice_status == "3"
      );

    }
    else if(event=="All"){

      filter = duplicate.filter((item) => item.invoice_status != "0"
      );
   
    }
    setData(filter);
    setSearch(filter);
    console.log(filter,"filter");
  };
  useEffect(() => {
    setSearchText("");
    getData();
   
  }, []);
  
  useEffect(()=>{
    getData();
    if(searchText!==""){
      searchFunction(searchText)
    }
  },[userupdated])
useEffect(()=>{
  updateData();
console.log(data,"swathi")
},[data])



const [updatedData, setUpdatedData] = useState([]);


const updateData = () => {
  const newData = serch.map(item => {

    console.log(item,"item")

    if (item.invoice_status === 1 || item.invoice_status === 5  || item.invoice_status === 6 ) {
    return { ...item, file: 'processing' };
    }
    if (item.invoice_status === 3) {
      return { ...item, file: 'rescan' };
      }
      if (item.invoice_status === 2) {
        return { ...item, file: 'completed' };
        }

  });
  setUpdatedData(newData);
};
console.log(updatedData,"Item");

  const searchFunction = (searchText) => {

    
   setSearchText(searchText);
    console.log(updatedData,"Item");
 if (searchText != "" && searchText != null) {
      const user = updatedData.filter((record) => {

       
        return (
          record.CreatedDate.toLowerCase().includes(searchText.toLowerCase()) ||
          record.file.toLowerCase().includes(searchText.toLowerCase()) ||
          record.file_status.toString().toLowerCase().includes(searchText.toLowerCase()) ||
          record.Inv_num.toLowerCase().includes(searchText.toLowerCase()) ||
          record.Purveyor_name.toLowerCase().includes(searchText.toLowerCase()) ||
          record.Amount.toString().toLowerCase().includes(searchText.toLowerCase()) ||
          (record.invoice_status === "5" || record.invoice_status === "1" || record.invoice_status === "6" ? "Processing" :
            record.invoice_status === "2" ? "completed" :
            record.invoice_status === "7" ? "Duplicate" : "Re-scan").toLowerCase().startsWith(searchText.toLowerCase())
          );
      });
      console.log(updatedData,"record")
        if (user.length > 0) {
      setSearch(user);
      console.log(user, "Users");
    } 
    else {
      setSearch([]);
    }
    if(searchText==="")
    {
 

    }
  }
  else{
    console.log(updatedData,"empty")
    setSearch(updatedData);
  }
  }




  const getPage = () => {};

  const editUser = (user) => {
    console.log("user:", user);
    // setEditUser(user);

  };

  return (
    <div class="table-sections">
      <div>
      <ToastContainer />
    </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableComponent1
          
          data={{ list: search }}
          nestedColumn={false} 
          Search={true}
          searchFunction={searchFunction}
          searchstate={searchText}
          pagination={true}
          fromdate={fromdate}
          todate={todate}
          handleDateChange={handleDateChange}
          loader={setIsLoading}
          getData={getData}
          getDatas={getDatas}
          setdropdown={setdropdown}
          dropdown={dropdown}
          handleDropdownChange={handleDropdownChange}
          period1={period1}
          setperiod1={setperiod1}       
          periodvalue={periodvalue}
          setbossid={setbossid}
          bossid={bossid}
          handleclick={handleclick}
          selectDepartment={selectDepartment}
          selectedDepartment={selectedDepartment}

        />
      )}
    </div>
  );
}
export default InvoiceDashboard;
