import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import "../Home/Home.css";
import "./Budget.css";
import Button from "@mui/material/Button";
import YearTable from "../Component/Tables/YearTable";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
// import Button from 'react-bootstrap/Button';
import MonthlyViewTable from "../Component/Tables/MonthlyViewTable";
import axios from "axios";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import { Spinner } from "../Component/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrencyFormat from "react-currency-format";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { MdLockOutline,MdOutlineLockOpen  } from "react-icons/md";

import Tooltip from "@material-ui/core/Tooltip";

import {
  getStepButtonUtilityClass,
  Hidden,
  TextField,
  Zoom,
} from "@mui/material";
import AddCategoryPagination from "./TableComponent_AddCategory";
import { BiMenu } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import zIndex from "@mui/material/styles/zIndex";
import { ZoomIn } from "@material-ui/icons";
import { useRef } from "react";

import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import $, { event } from "jquery";
import "jquery-ui/ui/widgets/sortable";

const itemschangeorder = [
  { id: 1, name: "Catering" },
  { id: 2, name: "Food" },
  { id: 3, name: "Wine" },
  { id: 4, name: "Beer" },
  { id: 5, name: "Liquor" },
  { id: 6, name: "Liquor" },
  { id: 7, name: "Liquor" },
  { id: 8, name: "Liquor" },
];

const Revenue = [
  { column: "REVENUE" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const AdminExpenses = [
  { column: "Admin Expenses" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];
const TurfMaintenance = [
  { column: "Turf Expenses" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];
const FitnessExpenses = [
  { column: "Bldg Mnt Expenses" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];
const TennisExpenses = [
  { column: "Tennis Expenses" },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];
const TurfCareExpenses = [
  { column: " REVENUE   " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const CostOfGoods = [
  { column: " Cost Of Goods " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const Labor = [
  { column: " Labor " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const Controllable = [
  { column: " Controllable " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const NonControllable = [
  { column: " Non Controllable " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

const ProfitLoss = [
  { column: " Profit/Loss " },
  { column: "BUDGETED AMOUNT" },
  { column: "PER(%)" },
];

let type_budget;
const yearlist = [];

const cr = [
  {
    Cat_id: 0,
    Cat_name: "LABOR",
    Cat_amt: "",
    Cat_per: "",
    Editable_Status: "",
    Master_id: "",
    cat_type: "Labor",
    Default_addon: "",
  },
  {
    Cat_id: 67,
    Cat_name: "Workers Comp % Of Total Payroll",
    Cat_amt: "",
    Cat_per: 1.85,
    Editable_Status: 0,
    Master_id: "3",
    cat_type: "Labor",
    Default_addon: 1,
  },
  {
    Cat_id: 68,
    Cat_name: "Workers Comp % Of Total Sales",
    Cat_amt: "",
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "3",
    cat_type: "Labor",
    Default_addon: 1,
  },
  {
    Cat_id: 66,
    Cat_name: "Payroll Tax",
    Cat_amt: "",
    Cat_per: 3.63,
    Editable_Status: 0,
    Master_id: "3",
    cat_type: "Labor",
    Default_addon: 1,
  },
  {
    Cat_id: 0,
    Cat_name: "CONTROLLABLE",
    Cat_amt: "",
    Cat_per: "",
    Editable_Status: "",
    Master_id: "",
    cat_type: "Controllable",
    Default_addon: "",
  },
  {
    Cat_id: 97,
    Cat_name: "Management / Franchise Fees",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 111,
    Cat_name: "Telephone",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 113,
    Cat_name: "Utilities - Water",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 114,
    Cat_name: "Utilities - Disposal",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 115,
    Cat_name: "Utilities - Electric",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 116,
    Cat_name: "Utilities - Gas",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "4",
    cat_type: "Controllable",
    Default_addon: 1,
  },
  {
    Cat_id: 0,
    Cat_name: "NON CONTROLLABLE",
    Cat_amt: "",
    Cat_per: "",
    Editable_Status: "",
    Master_id: "",
    cat_type: "NonControllable",
    Default_addon: "",
  },
  {
    Cat_id: 125,
    Cat_name: "Bank Charges",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "5",
    cat_type: "NonControllable",
    Default_addon: 1,
  },
  {
    Cat_id: 127,
    Cat_name: "Credit Card Discount",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "5",
    cat_type: "NonControllable",
    Default_addon: 1,
  },
  {
    Cat_id: 130,
    Cat_name: "Insurance - General Liab",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "5",
    cat_type: "NonControllable",
    Default_addon: 1,
  },
  {
    Cat_id: 131,
    Cat_name: "Payroll Fees",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "5",
    cat_type: "NonControllable",
    Default_addon: 1,
  },
  {
    Cat_id: 151,
    Cat_name: "Rent",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "6",
    Default_addon: 1,
  },
  {
    Cat_id: 152,
    Cat_name: "CAM Charges",
    Cat_amt: 0,
    Cat_per: 0,
    Editable_Status: 0,
    Master_id: "6",
    Default_addon: 1,
  },
];

// --------------------MONTHLY VIEW TABLE--------------------------//
function Tab1({
  butab,
  setbutab,
  setshowCourses,
  course_id,
  Courseflage,
  refreshgetdata,
  bossid,
  setbossid,
  selectDepartment,
  SetselectDepartment,
  settriggervariable,
  triggervariable,
  setBudgetlength,
  Budgetlength,
  setcheckingForNoBudget,
  checkingForNoBudget,
  tabss,
  alldepartments,
  setbudget_type,
  setSelectYear,
  selectYear,
  sety,
  ye,
  budget_type,
  selectedCourse,
  setbudget,
}) {
  let items = JSON.parse(localStorage.getItem("UserClaims"));
  const myRef = useRef(null);
  const [bos, setbos] = useState(bossid);
  const [showCourse, setShowCourse] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(5);
  const [deletecat, setCatDelete] = useState(false);
  const [revdel,setrevdel]= useState(false);
  const [lockpopshow, setlockpopshow] = useState(false);
  const [lockbudchange, setlockbudchange] = useState();
  const [delete_cat, setDeleteCAt] = useState("");
  let newDate = new Date();
  // const [selectYear, setSelectYear] = useState(newDate.getFullYear());
  const [selectedOption, setSelectedOption] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showtotal, setshowtotal] = useState(true);
  const [ lockyear , setlockyear] = useState(0);
  const[tooltiplock, settooltiplock]=useState("Yearly Budget is Unlocked")
  const [inputValue, setInputValue] = useState('');

  const [buttrue1, setbuttrue1] = useState();
  const [labeltitle1, setlabeltitle1] = useState();

  const [buttrue2, setbuttrue2] = useState();
  const [id1, setid1] = useState();
  const [label1, setLabel1] = useState();
  const [indexe1, setindexe1] = useState();

  const [show, setShow] = useState(false);
  const [showtable, setshowtable] = useState(false);
  const [addcategory, setaddcategory] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [revMonth, setRevMonth] = useState([]);
  const [text, setText] = useState("");
  const [BudgetRevenuelength, setBudgetRevenuelength] = useState();
  const [showRevenueBudget, setshowRevenueBudget] = useState(true);
  const userDetail = JSON.parse(localStorage.getItem("UserClaims"));
  const [Monthtotal, setMonthtotal] = useState(0);
  const [LabelList, setLabelList] = useState([]);
  const [Total, setTotal] = useState(0);
  const [prev, setprev] = useState();
  const [next, setnext] = useState();
  const [chname, setchname] = useState();
  const [editRowId, setEditRowId] = useState(null);
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const [d, setd] = useState(date);
  const [m, setm] = useState(month);
  //const [ye, sety] = useState(year);
  const [Edit, setEdit] = useState(false);
  const [showCat, setShowCat] = useState(true);
  const [laborindex, setindex] = useState("");
  let splUser = items.Special_user;
  let Acc_type = items.Account_Type;
  const [checkes, setchecks] = useState(false);
  const [save, setsave] = useState(true);
  const [Hide, setHide] = useState(true);
  const [hidetax, sethidetax] = useState(true);
  const [payroll,setpayroll]=useState();
  
  console.log(budget_type, "budbud");

 

  const [startDate, setStartDate] = useState("");
  let month_new;
  let date_new;

  let date_picker;
  if (startDate === "") {
    date_picker = 2022 + "-" + 12 + "-" + 27;
  } else {
    if (startDate.getMonth() + 1 < 10) {
      month_new = "0" + (startDate.getMonth() + 1);
    } else {
      month_new = startDate.getMonth() + 1;
    }

    if (startDate.getDate() < 10) {
      date_new = "0" + startDate.getDate();
    } else {
      date_new = startDate.getDate();
    }
    date_picker = startDate.getFullYear() + "-" + month_new + "-" + date_new;
  }

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setAddCategoryflag(false);
    setShowModal(false);
    setText("");
  };
  const handleShowModal = () => setShowModal(true);

  const [Year_List, setYear_List] = useState([]);
  const [Plan_List, setPlan_List] = useState([]);
  const [Revenue_List, setRevenue_List] = useState([]);
  const [hideCategoriesandconstant, sethideCategoriesandConstant] =
    useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [budloading, setbudloading]=useState(true);
  const [loader, setloader] = useState(true);
  const [dept, setdept] = useState();
  const [department, setdepartment] = useState();
  const [y, setY] = useState(yearlist);
  const [zero, setzero] = useState(false);

  const [data, setData] = useState({ list: [], total: 0, percentage: 0 });
  // const[Rev,SetRev]=useState({ list: sample, total: 432423, percentage: 98 });
  const [isMonthView, setIsMonthView] = useState(false);
  const [monthNavi, setMonthNavi] = useState(false);
  const [error, seterror] = useState(false);
  const [monthData, setMonthData] = useState({
    data: revMonth,
    label: "Revenue Monthly View",
    title: "Revenue",
  });

  const getData = async () => {
    let GetLabelURL;
    setIsLoading(true);
    setloader(true);
    settooltiplock(items.Edit_Year_Status==1 ? "Yearly Budget is Locked":"Yearly Budget is Unlocked")
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);
    setbudget_type(
      data.LabelAndYear[1].Year_List.find((item) => item.Year == selectYear)
        .budget_type
    );
    let GetBudgetURL, GetRevenueMonthurl;

    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        GetBudgetURL = "Api/BudgetPeriod/GetBudget/" + selectYear;
        GetRevenueMonthurl = "Api/BudgetPeriod/GetRevenueMonth/" + selectYear;
      } else {
        GetBudgetURL = `Api/Budget/GetBudget/` + selectYear;
        GetRevenueMonthurl = `Api/Budget/GetRevenueMonth/` + selectYear;
      }
    } else {
      GetBudgetURL = `/api/Boss/GetBossBudget/` + bossid + "/" + ye;
      GetRevenueMonthurl = `api/Boss/GetBossRevenueMonth/` + bossid + "/" + ye;
    }

    //console.log(labelName, "labelName");

    await fobeAxios.get(GetBudgetURL).then((res) => {
      setBudgetRevenuelength(res.data.BudgetRevenue.length);
      setIsLoading(false);
      setbudloading(false);
      setloader(false);
      if (res.data.BudgetRevenue.length > 0) {
        setIsLoading(false);
        setYear_List(res.data.BudgetRevenue[0].Year_List);
        setPlan_List(res.data.BudgetRevenue[1].Plan_List);
        setPlan_List(res.data.BudgetRevenue[2].Revenue_List);
        /////////////////For Recurring/////////////
        console.log(res.data.BudgetRevenue[2].Total, "Recurring");
        settotalbudgetamount(res.data.BudgetRevenue[2].Total);
        //////////////////////////////////////////////////
        setaddcategory(res.data.BudgetRevenue[2].Revenue_List);
        setRevenue(res.data.BudgetRevenue[2]);

        res.data.BudgetRevenue[2].Revenue_List.map((g) => {
          g.abs = true;
        });
        setData({
          ...data,
          list: res.data.BudgetRevenue[2].Revenue_List,
          total: res.data.BudgetRevenue[2].Total,
          percentage: res.data.BudgetRevenue[2].Per,
        });

        console.log("inputtt", res.data.BudgetRevenue[2].Total);
        setInputValue(res.data.BudgetRevenue[2].Total);
        setbudget(res.data.BudgetRevenue[2].Total);

        if (res.data.BudgetRevenue) {
          if (res.data.BudgetRevenue[1]) {
            if (
              res.data.BudgetRevenue[1].Plan_List
                ? res.data.BudgetRevenue[1].Plan_List[0]
                : false
            ) {
              setTotal(res.data.BudgetRevenue[1].Plan_List[0].Total);
            }
          }
        }
      } else {
        setYear_List([""]);
        setPlan_List([""]);
        setPlan_List([""]);
        setbudget(undefined);

        setRevenue([""]);
        setData({ ...data, list: [], total: 0, percentage: 0 });
        setTotal("");
      }
    });

    await fobeAxios.get(GetRevenueMonthurl).then((res) => {
      console.log("Revmonth", res.data.Revenue_Thingies);
      setRevMonth(res.data.Revenue_Thingies);
      seterror(res.data.Revenue_Thingies.length > 0 ? false : true);
      res.data.Revenue_Thingies.map((g) => {
        g.List.map((y) => {
          y.abs = true;
        });
      });
      setMonthData({
        data: res.data.Revenue_Thingies,
        label: data.LabelAndYear[0].Labelfirst[0].Label_Name + " Monthly View",
        title: data.LabelAndYear[0].Labelfirst[0].Label_Name,
      });

      if (res.data.Revenue_Thingies[0]) {
        setMonthtotal(res.data.Revenue_Thingies[0].Total);
      }
    });
    //}
    // else if(labelName=="Turf Revenue"){
  };
  const val = String(inputValue).replace(/\,/g,'')
  useEffect(() => {
    const bool = val<=0 ? true : false
    
    setzero(bool)
    console.log(val,"val")
  
    console.log(zero,"zero")
  },[inputValue])
  
  // console.log(zero,"zero")
  const getdeptdata = async () => {
    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
    if (userDetail.Member_Type != "4") {
      course_id = userDetail.Restaurant_id;
    }
    setSelectedDept(localItems?.Account_Name, "localItems?.Account_id");
    mainAxios.get(`Api/User/GetAllUserTypes?id=5`).then((res) => {
      setdept(res.data.AllUserTypes);
    });
    mainAxios
      .get("api/Common/getRestaurantList/" + course_id + "/" + ye)
      .then((res) => {
        console.log(res.data.Restaurants, "Restaurants");
        setdepartment(res.data.Restaurants);
      });
  };

  const getLabel = async () => {
    let GetLabelURL;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);

    // setbudget_type(data.LabelAndYear[1].budget_type)
    console.log(data.LabelAndYear[0].Labelfirst, "LabelAndYear");
    setbutab(data.LabelAndYear[0].Labelfirst[0].Label_Name);

    setLabelList(data.LabelAndYear[0].Labelfirst);
    setnext(data.LabelAndYear[0].Labelfirst[1].Label_Name);
    setchname(data.LabelAndYear[0].Labelfirst[0].Label_Name);
    setprev("");

    // getData(data.LabelAndYear[0].Labelfirst[0].Label_Name);
    console.log(data.LabelAndYear[1].Year_List, "datafor Label");
    setY(data.LabelAndYear[1].Year_List);
  };
  const [selectedDept, setSelectedDept] = useState();

  useEffect(() => {
    if (
      items.Account_Type == 4 ||
      items.Account_Type == 5 ||
      items.Account_Type == 9 ||
      items.Account_Type == 10
    ) {
      sethideCategoriesandConstant(false);
    }
    setIsLoading(true);
    getData();
    getdeptdata();
    getLabel();

    if (items) {
      if (items.Member_Type == "4") {
        setshowtable(false);
      } else {
        setshowtable(true);
      }
      if (
        items.Member_Type == 4 ||
        ((items.Special_user == 0 || items.Special_user == 2) &&
          items.Account_Type == 0)
      ) {
        setShowCourse(true);
      } else {
        setShowCourse(false);
      }
      if (
        (items.Special_user == 0 || items.Special_user == 1) &&
        items.Account_Type != 0
      ) {
        setshowcheckbox(true);
      } else {
        setshowcheckbox(false);
      }

      if (budget_type == "1") {
        setSelectedOption("option1");
      } else {
        setSelectedOption("option2");
      }
    }
  }, [
    selectYear,
    ye,
    bossid,
    course_id,
    refreshgetdata,
    triggervariable,
    tabss,
    budget_type,
  ]);
  const changeData = async (id, label_title, index) => {

    console.log(id, label_title, index,"how")
 
    if (
      label_title === "Turf Maintenance" ||
      label_title === "Admin Expenses" ||
      label_title === "Tennis Expenses" ||
      label_title === "Turf Revenue" ||
      label_title === "Bldg Mnt Expenses"
    ) {
      console.log('ManiKandan',label_title,  items.Account_Type)
      if (
        items.Account_Type == 4 ||
        items.Account_Type == 5 ||
        items.Account_Type == 9 ||
        items.Account_Type == 10
      ) {
      
        sethideCategoriesandConstant(false); 
      }else {
        console.log('ManiKandan bypass',label_title,  items.Account_Type)
        sethideCategoriesandConstant(true);
      }
    } 
    sethideCategoriesandConstant(true);
    setbuttrue1(true);
    setbuttrue2(false);
    setid1(id);
    setLabel1(label_title);
    setindexe1(index);
    setindex(index);
    setshowCourses(false);
    setIsLoading(true);
    let GetLabelURL;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);

    // console.log(items, "item")
    let geturl,
      GetRevenueMnthurl,
      GetGetcogurl,
      GetcogMonthurl,
      GetLaboururl,
      GetLaborMonthurl,
      GetControurl,
      GetControlMonthurl,
      GetNonControurl,
      GetNonControlMonthurl,
      GetProfitLossurl,
      GetProfitLossMonthurl;

    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        geturl = "Api/BudgetPeriod/GetBudget/" + selectYear;
        GetRevenueMnthurl = "Api/BudgetPeriod/GetRevenueMonth/" + selectYear;
        GetGetcogurl = "Api/BudgetPeriod/GetCog/" + selectYear;
        GetcogMonthurl = "Api/BudgetPeriod/GetcogMonth/" + selectYear;
        GetLaboururl = "Api/BudgetPeriod/GetLabour/" + selectYear;
        GetLaborMonthurl = "Api/BudgetPeriod/GetlaborMonth/" + selectYear;
        GetControurl = "Api/BudgetPeriod/GetContro/" + selectYear;
        GetControlMonthurl = "Api/BudgetPeriod/GetControlMonth/" + selectYear;
        GetNonControurl = "Api/BudgetPeriod/GetNoncontro/" + selectYear;
        GetNonControlMonthurl =
          "Api/BudgetPeriod/GetnonControlMonth/" + selectYear;
        GetProfitLossurl = "Api/BudgetPeriod/GetProfitLoss/" + selectYear;
        GetProfitLossMonthurl =
          "Api/BudgetPeriod/GetProfitLossMonth/" + selectYear;
      } else {
        geturl = `Api/Budget/GetBudget/` + selectYear;
        GetRevenueMnthurl = `Api/Budget/GetRevenueMonth/` + selectYear;
        GetGetcogurl = `Api/Budget/GetCog/` + selectYear;
        GetcogMonthurl = `Api/Budget/GetCogMonth/` + selectYear;
        GetLaboururl = `Api/Budget/GetLabour/` + selectYear;
        GetLaborMonthurl = `Api/Budget/GetlaborMonth/` + selectYear;
        GetControurl = `Api/Budget/GetContro/` + selectYear;
        GetControlMonthurl = `Api/Budget/GetControlMonth/` + selectYear;
        GetNonControurl = `Api/Budget/GetNoncontro/` + selectYear;
        GetNonControlMonthurl = `Api/Budget/GetnonControlMonth/` + selectYear;
        GetProfitLossurl = `Api/Budget/GetProfitLoss/` + selectYear;
        GetProfitLossMonthurl = `Api/Budget/GetProfitLossMonth/` + selectYear;
      }
    } else {
      geturl = `api/Boss/GetBossBudget/` + bossid + "/" + ye;
      GetRevenueMnthurl = `api/Boss/GetBossRevenueMonth/` + bossid + "/" + ye;
      GetGetcogurl = `api/Boss/GetBossCog/` + bossid + "/" + ye;
      GetcogMonthurl = `api/Boss/GetBossCogMonth/` + bossid + "/" + ye;
      GetLaboururl = `api/Boss/GetBossLabor/` + bossid + "/" + ye;
      GetLaborMonthurl = `api/Boss/GetBossLaborMonth/` + bossid + "/" + ye;
      GetControurl = `api/Boss/GetBossControllable/` + bossid + "/" + ye;
      GetControlMonthurl =
        `api/Boss/GetBossControllableMonth/` + bossid + "/" + ye;
      GetNonControurl = `api/Boss/GetBossNoncontrollable/` + bossid + "/" + ye;
      GetNonControlMonthurl =
        `api/Boss/GetBossNoncontrollableMonth/` + bossid + "/" + ye;
      GetProfitLossurl = `api/Boss/GetBossProfitLoss/` + bossid + "/" + ye;
      GetProfitLossMonthurl =
        `api/Boss/GetBossProftLossMonth/` + bossid + "/" + ye;
    }
    if (items.Member_Type == 4) {
      setshowCourses(true);
    } else {
      setshowCourses(false);
    }

    if (id === 1) {
      setHide(true);
      setshowRevenueBudget(true);
      setShowCat(true);
      setIsLoading(true);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);

      await fobeAxios.get(geturl).then((res) => {
        setIsLoading(false);
        res.data.BudgetRevenue[2].Revenue_List.map((c) => {
          c.abs = true;
        });
        setData({
          ...data,
          list: res.data.BudgetRevenue[2].Revenue_List,
          total: res.data.BudgetRevenue[2].Total,
          percentage: res.data.BudgetRevenue[2].Per,
        });
        setaddcategory(res.data.BudgetRevenue[2].Revenue_List);
        if (typeof res.data.BudgetRevenue[1].Plan_List !== "undefined") {
          setTotal(res.data.BudgetRevenue[1].Plan_List[0].Total);
        }
      });

      await fobeAxios.get(GetRevenueMnthurl).then((res) => {
        // console.log("Revmonth", res.data.Revenue_Thingies);
        res.data.Revenue_Thingies.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });
        setMonthData({
          data: res.data.Revenue_Thingies,
          label: label_title + " Monthly View",

          title: label_title,
        });
        setshowtotal(true);
      });
      setIsLoading(false);
    } else if (id === 2) {
      setHide(true);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      // setshowCourses(false);
      setIsMonthView(false);
      setshowRevenueBudget(true);
      setShowCat(false);
      // setMonthData({data:turfMonth,label:'Turfcare Monthly View',title:'Turf Care'})
      //setData({ list: tableTurfCare, total: 432423, percentage: 98 })
    } else if (id === 3) {
      setHide(true);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      setIsLoading(true);
      setshowRevenueBudget(true);
      setShowCat(false);
      //setshowCourses(false);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetGetcogurl).then((res) => {
        console.log(res.data.BudgetCOG[2].Cog_List, "COG");
        res.data.BudgetCOG[2].Cog_List.map((g) => {
          g.abs = true;
        });
        setData({
          list: res.data.BudgetCOG[2].Cog_List,
          total: res.data.BudgetCOG[2].Total,
          percentage: res.data.BudgetCOG[2].Per,
        });
        setaddcategory(res.data.BudgetCOG[2].Cog_List);
      });

      await fobeAxios.get(GetcogMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Cog_Things);
        console.log(res.data + "COG");
        res.data.Cog_Things.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });

        setMonthData({
          data: res.data.Cog_Things,
          label: label_title + " Monthly View",
          title: label_title,
        });
        setshowtotal(true);
      });
      //setMonthData({data:cogMonth,label:'Cost of Goods Monthly View',title:'Cost of Goods'})
      setIsLoading(false);
    } else if (id === 4) {
      setHide(true);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      //sethideCategoriesandConstant(false)
      // setshowCourses(false);
      setIsLoading(true);
      setshowRevenueBudget(true);
      setShowCat(false);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetLaboururl).then((res) => {
        res.data.BudgetLabour[2].Labour_List.map((g) => {
          g.abs = true;
        });

        setData({
          list: res.data.BudgetLabour[2].Labour_List,
          total: res.data.BudgetLabour[2].Total,
          percentage: res.data.BudgetLabour[2].Per,
        });
        setaddcategory(res.data.BudgetLabour[2].Labour_List);
      });

      await fobeAxios.get(GetLaborMonthurl).then((res) => {
        console.log("labmonth", res.data.Labor_Thingies);
        res.data.Labor_Thingies.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });

        setMonthData({
          data: res.data.Labor_Thingies,
          label: label_title + " Monthly View",
          title: label_title,
        });
        setshowtotal(true);
      });



      await fobeAxios.get(`api/Budget/Labourpayrollname/`).then((res) => {

      
        setpayroll(res.data)
      })
      // setMonthData({data:LabourMonth,label:'Labour Monthly View',title:'Labour'})
      setIsLoading(false);
    } else if (id === 5) {
      setHide(true);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      //setshowCourses(false);
      //sethideCategoriesandConstant(false)
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetControurl).then((res) => {
        res.data.BudgetControllable[2].Controllable_List.map((g) => {
          g.abs = true;
        });
        setData({
          list: res.data.BudgetControllable[2].Controllable_List,
          total: res.data.BudgetControllable[2].Total,
          percentage: res.data.BudgetControllable[2].Per,
        });
        setaddcategory(res.data.BudgetControllable[2].Controllable_List);
      });

      await fobeAxios.get(GetControlMonthurl).then((res) => {
        console.log("MANI", res.data.Controllable_Thingies);
        res.data.Controllable_Thingies.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });
        setMonthData({
          data: res.data.Controllable_Thingies,
          label: label_title + " Monthly View",
          title: label_title,
        });
        setshowtotal(true);
      });
      //setMonthData({data:ControllableMonth,label:'Controllable Monthly View',title:'Controllable'})
      setIsLoading(false);
    } else if (id === 6) {
      setHide(true);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      //setshowCourses(false);
      //sethideCategoriesandConstant(false)
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetNonControurl).then((res) => {
        res.data.BudgetNControllable[2].NonControllable_List.map((g) => {
          g.abs = true;
        });

        setData({
          list: res.data.BudgetNControllable[2].NonControllable_List,
          total: res.data.BudgetNControllable[2].Total,
          percentage: res.data.BudgetNControllable[2].Per,
        });
        setaddcategory(res.data.BudgetNControllable[2].NonControllable_List);
      });

      await fobeAxios.get(GetNonControlMonthurl).then((res) => {
        // console.log("Revmonth", res.data.NonControllable_Thingies);
        res.data.NonControllable_Thingies.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });

        setMonthData({
          data: res.data.NonControllable_Thingies,
          label: label_title + " Monthly View",
          title: label_title,
        });
        setshowtotal(true);
      });
      //setMonthData({data:NonControllableMonth,label:'Non Controllable Monthly View',title:'Non Controllable'})
      setIsLoading(false);
    } else if (id === 7) {
      setHide(false);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      //setshowCourses(false);
      sethideCategoriesandConstant(false);
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetProfitLossurl).then((res) => {
        res.data.Profit_Loss.map((g) => {
          g.abs = true;
        });
        setData({ list: res.data.Profit_Loss });
        // setTotal(res.data.Profit_Loss[1].Plan_List[0].Total)
      });

      await fobeAxios.get(GetProfitLossMonthurl).then((res) => {
        console.log("Revmonth", res.data.Profit_Loss);
        res.data.Profit_Loss.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });
        setshowtotal(false);
        setMonthData({
          data: res.data.Profit_Loss,
          label: label_title + " Monthly View",
          title: label_title,
        });
      });
      //setMonthData({data:profitMonth,label:'Profit/Lose Monthly View',title:'Profit/Lose'})
      setIsLoading(false);
    }

    setnext(data.LabelAndYear[0].Labelfirst[index + 1].Label_Name);
    setprev(data.LabelAndYear[0].Labelfirst[index - 1].Label_Name);
  };

  const editYearDatapercentage = (id, value, menuId) => {
    console.log("id:", id, "value:", value, "menuId:", menuId);
    let temp = data.list.map((x) => {
      if (x.Cat_id == id) {
        x.Cat_per = value.value;

        return x;
      }
      return x;
    });
    data.total = 0;

    data.total = temp.reduce(
      (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
      0
    );
    data.percentage = (data.total / Total) * 100;
    if (menuId == 1) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          console.log("x.Total:", Total);
          if (!isNaN(x.Cat_per)) {
            x.Cat_amt = (x.Cat_per / 100) * Total;
          } else {
            x.Cat_amt = 0;
          }
          data.total = temp.reduce(
            (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
            0
          );
          data.percentage = (data.total / Total) * 100;
          console.log("x.catper:", data.total);
          return x;
        }
        return x;
      });
      setData({ ...data, list: temp });
    } else if (menuId == 2) {
      temp = temp.map((x) => {
        
        if (x.Cat_id == id && x.Cat_id !== 31) {
          console.log("revenue11", revenue, "temp", temp);
          let Budget = revenue.Revenue_List.find(
            (rev) => rev.Cat_name == x.Cat_name
          ).Cat_amt;
          if (!isNaN(x.Cat_per)) {
            x.Cat_amt = (Budget * x.Cat_per) / 100;
          } else {
            x.Cat_amt = 0;
          }
          // console.log("x.catper:", x.Cat_per)
          data.total = temp.reduce(
            (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
            0
          );
          data.percentage = (data.total / Total) * 100;
          return x;
        } else if (x.Cat_id == id && x.Cat_id == 31) {
          console.log(revenue,"ghh")
          let Budget ;
          if(items.Account_Type==2 || items.Account_Type==11){
             Budget = revenue.Revenue_List.reduce((acc, curr) => {
              let value = curr.Cat_id == 3 || curr.Act_catering == 1 ? curr.Cat_amt : 0;
              
              return acc + value;
            }, 0);

          }
          else{
             Budget = revenue.Revenue_List.reduce((acc, curr) => {
              let value = curr.Cat_id == 1 || curr.Act_catering == 1 ? curr.Cat_amt : 0;
              
              return acc + value;
            }, 0);

          }
       
           console.log(Budget,'newcondition')

          if (!isNaN(x.Cat_per)) {
            x.Cat_amt = (Budget * x.Cat_per) / 100;
          } else {
            x.Cat_amt = 0;
          }
          // console.log("x.catper:", x.Cat_per)
          data.total = temp.reduce(
            (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
            0
          );
          data.percentage = (data.total / Total) * 100;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
      });
    } else if (
      menuId == 3 ||
      menuId == 4 ||
      menuId == 5 ||
      menuId == 6 ||
      menuId == 7
    ) {
      temp = temp.map((x) => {
       
        if (x.Cat_id == id) {
          if (!isNaN(x.Cat_per)) {
            x.Cat_amt = (x.Cat_per / 100) * Total;
          } else {
            x.Cat_amt = 0;
          }
          data.total = temp.reduce(
            (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
            0
          );
          data.percentage = (data.total / Total) * 100;
          // console.log("x.catper:", x.Cat_per)
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
      });
    }
  };

  const editabsper = (id, value, menuId) => {
    console.log("id:", id, "value:", value, "menuId:", menuId);
    let temp = data.list.map((x) => {
      if (x.Cat_id == id) {
        // x.Cat_per = value.value;
        x.abs = false;
        return x;
      } else {
        x.abs = true;
        return x;
      }

      return x;
    });

    //data.total = 0;

    // data.total = temp.reduce(
    //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
    //   0
    // );
    //data.percentage = (data.total / Total) * 100;
    if (menuId == 1) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          console.log("x.Total:", data.total);
          // x.Cat_amt = (x.Cat_per / 100) * Total;
          // data.total = temp.reduce(
          //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
          //   0
          // );
          // data.percentage = (data.total / Total) * 100;
          // console.log("x.catper:", x.Cat_per)
          x.abs = false;
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({ ...data, list: temp });
    } else if (menuId == 2) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          // let Budget = revenue.Revenue_List.find(
          //   (rev) => rev.Cat_name == x.Cat_name
          // ).Cat_amt;
          //  // x.Cat_amt = (Budget * x.Cat_per) / 100;

          //   // console.log("x.catper:", x.Cat_per)
          // data.total = temp.reduce(
          //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
          //   0
          // );
          //   data.percentage = (data.total / Total) * 100;
          x.abs = false;
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
      });
    } else if (
      menuId == 3 ||
      menuId == 4 ||
      menuId == 5 ||
      menuId == 6 ||
      menuId == 7
    ) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          // x.Cat_amt = (x.Cat_per / 100) * Total;
          // data.total = temp.reduce(
          //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
          //   0
          // );
          // data.percentage = (data.total / Total) * 100;
          // console.log("x.catper:", x.Cat_per)
          x.abs = false;
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
      });
    }
  };

  const editabsperblur = (id, value, menuId) => {
    let temp = data.list.map((x) => {
      if (x.Cat_id == id) {
        // x.Cat_per = value.value;
        x.abs = true;
        return x;
      } else {
        x.abs = true;
        return x;
      }

      return x;
    });

    //data.total = 0;

    // data.total = temp.reduce(
    //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
    //   0
    // );
    //data.percentage = (data.total / Total) * 100;
    if (menuId == 1) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          console.log("x.Total:", data.total);
          // x.Cat_amt = (x.Cat_per / 100) * Total;
          // data.total = temp.reduce(
          //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
          //   0
          // );
          // data.percentage = (data.total / Total) * 100;
          // console.log("x.catper:", x.Cat_per)
          x.abs = true;
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({ ...data, list: temp });
    } else if (menuId == 2) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          // let Budget = revenue.Revenue_List.find(
          //   (rev) => rev.Cat_name == x.Cat_name
          // ).Cat_amt;
          //  // x.Cat_amt = (Budget * x.Cat_per) / 100;

          //   // console.log("x.catper:", x.Cat_per)
          // data.total = temp.reduce(
          //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
          //   0
          // );
          //   data.percentage = (data.total / Total) * 100;
          x.abs = true;
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
      });
    } else if (
      menuId == 3 ||
      menuId == 4 ||
      menuId == 5 ||
      menuId == 6 ||
      menuId == 7
    ) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          // x.Cat_amt = (x.Cat_per / 100) * Total;
          // data.total = temp.reduce(
          //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
          //   0
          // );
          // data.percentage = (data.total / Total) * 100;
          // console.log("x.catper:", x.Cat_per)
          x.abs = true;
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
      });
    }
  };

  const editabs = (id, value, menuId) => {
    //alert(value.value+" : value")
    let temp = data.list.map((x) => {
      if (x.Cat_id == id) {
        x.abs = false;

        return x;
      } else {
        x.abs = true;

        return x;
      }
      return x;
    });

    // data.total = 0;

    // data.total = temp.reduce(
    //   (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
    //   0
    // );

    if (menuId == 1) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          //  x.Cat_per = (x.Cat_amt / data.total) * 100;
          x.abs = false;

          // console.log("x.catper:", x.Cat_per)
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({ ...data, list: temp });
    } else if (menuId == 2) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          // let revCogBudget = revenue.Revenue_List.find(
          //   (rev) => rev.Cat_name == x.Cat_name
          // ).Cat_amt;
          //  x.Cat_per = (x.Cat_amt / revCogBudget) * 100;
          x.abs = false;
          // console.log("x.catper:", x.Cat_per)
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
        percentage: (data.total / revenue.Total) * 100,
      });
    } else if (menuId == 3 || menuId == 4 || menuId == 5) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          //x.Cat_per = (x.Cat_amt / revenue.Total) * 100;
          x.abs = false;

          // console.log("x.catper:", x.Cat_per)
          return x;
        } else {
          x.abs = true;

          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
        percentage: (data.total / revenue.Total) * 100,
      });
    }
  };

  const editabsblur = (id, menuId) => {
    //alert(value.value+" : value")
    let temp = data.list.map((x) => {
      if (x.Cat_id == id) {
        x.abs = true;
        return x;
      } else {
        x.abs = true;
        return x;
      }
      return x;
    });

    //  data.total = 0;

    //  data.total = temp.reduce(
    //    (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
    //    0
    //  );

    if (menuId == 1) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          //  x.Cat_per = (x.Cat_amt / data.total) * 100;
          x.abs = true;

          // console.log("x.catper:", x.Cat_per)
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({ ...data, list: temp });
    } else if (menuId == 2) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          // let revCogBudget = revenue.Revenue_List.find(
          //   (rev) => rev.Cat_name == x.Cat_name
          // ).Cat_amt;
          //  x.Cat_per = (x.Cat_amt / revCogBudget) * 100;
          x.abs = true;
          // console.log("x.catper:", x.Cat_per)
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
        percentage: (data.total / revenue.Total) * 100,
      });
    } else if (menuId == 3 || menuId == 4 || menuId == 5) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          //x.Cat_per = (x.Cat_amt / revenue.Total) * 100;
          x.abs = true;
          // console.log("x.catper:", x.Cat_per)
          return x;
        } else {
          x.abs = true;
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
        percentage: (data.total / revenue.Total) * 100,
      });
    }
  };

  const editYearData = (id, value, menuId) => {
    let temp = data.list.map((x) => {
      if (x.Cat_id == id) {
        x.Cat_amt = value.value;
        return x;
      }
      return x;
    });

    data.total = 0;

    data.total = temp.reduce(
      (total, obj) => (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + total,
      0
    );
    data.percentage = (data.total / Total) * 100;
    if (menuId == 1) {
      console.log(Total, "ww");
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          if (!isNaN(x.Cat_amt)) {
            x.Cat_per = (x.Cat_amt / Total) * 100;
          } else {
            x.Cat_per = 0;
          }

          // console.log("x.catper:", x.Cat_per)
          return x;
        }
        return x;
      });
      setData({ ...data, list: temp });
    } else if (menuId == 2) {
      let revCogBudget;
      temp = temp.map((x) => {
        if (x.Cat_id == id && x.Cat_id !== 31) {
        
          try {
            revCogBudget = revenue.Revenue_List.find(
              (rev) => rev.Cat_name == x.Cat_name)?.Cat_amt ?? 0;
          
            console.log("hhhh : ", revCogBudget,revenue.Revenue_List);

          } catch (e) {
            console.log(e.toString());
          }
          if (x.Cat_amt !== "" && revCogBudget !== 0) {
            console.log(revCogBudget, "wed");
            x.Cat_per = (x.Cat_amt / revCogBudget) * 100;
          } else {
            x.Cat_per = 0;
          }
          console.log("x.catper:", x.Cat_amt);
          return x;
        }
        else if  (x.Cat_id == id && x.Cat_id == 31){

          if (items.Account_Type==2 || items.Account_Type==11){

            revCogBudget = revenue.Revenue_List.reduce((acc, curr) => {
              let value = curr.Cat_id == 3 || curr.Act_catering == 1 ? curr.Cat_amt : 0;
              console.log(acc,value,"newcondition")
 
              return acc + value;
            }, 0);
 
            x.Cat_per = (x.Cat_amt / revCogBudget) * 100;
         }
         else{
          revCogBudget = revenue.Revenue_List.reduce((acc, curr) => {
            let value = curr.Cat_id == 1 || curr.Act_catering == 1 ? curr.Cat_amt : 0;
            console.log(acc,value,"newcondition")

            return acc + value;
          }, 0);

          x.Cat_per = (x.Cat_amt / revCogBudget) * 100;
         }


         
         //console.log(revCogBudget,"newcondition")

        }
        return x;
      });
      setData({
        ...data,
        list: temp,
        percentage: (data.total / revenue.Total) * 100,
      });
    } else if (menuId == 3 || menuId == 4 || menuId == 5) {
      temp = temp.map((x) => {
        if (x.Cat_id == id) {
          if (!isNaN(x.Cat_amt)) {
            x.Cat_per = (x.Cat_amt / revenue.Total) * 100;
          } else {
            x.Cat_per = 0;
          }
          console.log("x.catper:", x.Cat_amt);
          return x;
        }
        return x;
      });
      setData({
        ...data,
        list: temp,
        percentage: (data.total / revenue.Total) * 100,
      });
    }
  };

  const editMonthData = (id, value, Month_id, menuId) => {
    console.log("Month_id:", Month_id, menuId);
    if (menuId == "Revenue") {
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
                
              if(item.Cat_amt != value.value){
                   setlockyear(1);
              }
              console.log(item.Cat_amt, value.value,"onchange")
              item.Cat_amt = value.value;
              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );

              if (!isNaN(item.Cat_amt) && x.Total > 0) {
                item.Cat_Per = (item.Cat_amt / x.Total) * 100;
              } else {
                item.Cat_Per = 0;
              }
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (menuId == "Cost Of Goods") {
      let temp = monthData.data.map((x) => {
        if (x.Month_Id == Month_id) {
          let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
          let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_amt = value.value;
              let revItemTotal = revList.find(
                (revItem) => revItem.Cat_Name == item.Cat_Name
              ).Cat_amt;

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              if (!isNaN(item.Cat_amt)) {
                item.Cat_Per = (item.Cat_amt / revItemTotal) * 100;
              } else {
                item.Cat_Per = 0;
              }
              if (!isNaN(x.Total)) {
                x.Month_totper = (x.Total / revTotal) * 100;
              } else {
                x.Month_totper = 0;
              }
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (
      menuId == "Labor" ||
      menuId == "Controllable" ||
      menuId == "Non Controllable"
    ) {
      console.log(menuId, "IDDDD");
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_amt = value.value;

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              if (!isNaN(item.Cat_amt) && revTotal !== 0) {
                item.Cat_Per = (item.Cat_amt / revTotal) * 100;
              } else {
                item.Cat_Per = 0;
              }
              if (!isNaN(x.Total) && revTotal !== 0) {
                x.Month_totper = (x.Total / revTotal) * 100;
              } else {
                x.Month_totper = 0;
              }
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    }
  };

  const editMonthabs = (id, value, Month_id, menuId) => {
    console.log("Month_id:", Month_id, menuId);
    if (menuId == "Revenue") {
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              // item.Cat_amt = value.value;
              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // item.Cat_Per = (item.Cat_amt / x.Total) * 100;
              item.abs = false;
              return item;
            } else {
              item.abs = true;
              return item;
            }
          });
        }

        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (menuId == "Cost Of Goods") {
      let temp = monthData.data.map((x) => {
        if (x.Month_Id == Month_id) {
          // let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
          // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

          x.List.map((item) => {
            if (item.Cat_id == id) {
              // item.Cat_amt = value.value;
              // let revItemTotal = revList.find(
              //   (revItem) => revItem.Cat_Name == item.Cat_Name
              // ).Cat_amt;

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // item.Cat_Per = (item.Cat_amt / revItemTotal) * 100;
              // x.Month_totper = (x.Total / revTotal) * 100;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (
      menuId == "Labor" ||
      menuId == "Controllable" ||
      menuId == "Non Controllable"
    ) {
      console.log(menuId, "IDDDD");
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

          x.List.map((item) => {
            if (item.Cat_id == id) {
              // item.Cat_amt = value.value;

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // item.Cat_Per = (item.Cat_amt / revTotal) * 100;
              // x.Month_totper = (x.Total / revTotal) * 100;
              item.abs = false;
              return item;
            } else {
              item.abs = true;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    }
  };

  const editMonthabsblur = (id, value, Month_id, menuId) => {
    console.log("Month_id:", Month_id, menuId);
    if (menuId == "Revenue") {
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              // item.Cat_amt = value.value;
              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // item.Cat_Per = (item.Cat_amt / x.Total) * 100;
              item.abs = true;
              return item;
            } else {
              item.abs = true;
              return item;
            }
          });
        }

        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (menuId == "Cost Of Goods") {
      let temp = monthData.data.map((x) => {
        if (x.Month_Id == Month_id) {
          // let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
          // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

          x.List.map((item) => {
            if (item.Cat_id == id) {
              // item.Cat_amt = value.value;
              // let revItemTotal = revList.find(
              //   (revItem) => revItem.Cat_Name == item.Cat_Name
              // ).Cat_amt;

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // item.Cat_Per = (item.Cat_amt / revItemTotal) * 100;
              // x.Month_totper = (x.Total / revTotal) * 100;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (
      menuId == "Labor" ||
      menuId == "Controllable" ||
      menuId == "Non Controllable"
    ) {
      console.log(menuId, "IDDDD");
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

          x.List.map((item) => {
            if (item.Cat_id == id) {
              // item.Cat_amt = value.value;

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // item.Cat_Per = (item.Cat_amt / revTotal) * 100;
              // x.Month_totper = (x.Total / revTotal) * 100;
              item.abs = true;
              return item;
            } else {
              item.abs = true;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    }
  };

  const editMonthDatapercentage = (id, value, Month_id, menuId) => {
    console.log("value", value.formattedValue);
    if (menuId == "Revenue") {
      let temp = { ...monthData };
      temp = temp.data.map((x) => {
        let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
        let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_Per = value.value;

              if (!isNaN(item.Cat_Per)) {
                item.Cat_amt = (value.value / 100) * Monthtotal;
              } else {
                item.Cat_amt = 0;
              }

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              x.Month_totper = (x.Total / Monthtotal) * 100;
              return item;
            }
            return item;
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (menuId == "Cost Of Goods") {
      let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
      let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
      let temp = monthData.data.map((x) => {
        if (x.Month_Id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              let revItemTotal = revList.find(
                (revItem) => revItem.Cat_Name == item.Cat_Name
              ).Cat_amt;

              item.Cat_Per = value.value;

              if (!isNaN(item.Cat_Per)) {
                item.Cat_amt = (revItemTotal * item.Cat_Per) / 100;
              } else {
                item.Cat_amt = 0;
              }
              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              x.Month_totper = (x.Total / Monthtotal) * 100;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (
      menuId == "Labor" ||
      menuId == "Controllable" ||
      menuId == "Non Controllable"
    ) {
      console.log(id, value, Month_id, menuId, "IDDDD");
      let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
      let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              item.Cat_Per = value.value;
              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );

              const numericValue = parseFloat(
                value.value.replace(/[^0-9.-]+/g, "")
              );

              if (!isNaN(numericValue) && revTotal !==0) {
                item.Cat_amt = (numericValue / 100) * revTotal;
              } else {
                item.Cat_amt = 0;
              }

              console.log(item.Cat_amt, revTotal, "IDDDD");

              x.Total = x.List.reduce(
                (Total, obj) =>
                  (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
                0
              );
              console.log(x.Total,revTotal,"uuuu")
              if (!isNaN(x.Total) && revTotal !==0) {
                
              x.Month_totper = (x.Total / revTotal) * 100;
              }
              else{
                x.Month_totper=0;
              }
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    }
  };

  const editMonthperabs = (id, value, Month_id, menuId) => {
    console.log("checking", value.value);
    if (menuId == "Revenue") {
      let temp = { ...monthData };
      temp = temp.data.map((x) => {
        // let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
        // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              console.log(item.Cat_id, "ko");
              // item.Cat_Per = value.formattedValue;

              // item.Cat_amt = (value.formattedValue / 100) * Monthtotal;

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // x.Month_totper = (x.Total / Monthtotal) * 100;
              item.abs = false;
              return item;
            } else {
              item.abs = true;

              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (menuId == "Cost Of Goods") {
      // let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
      // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
      let temp = monthData.data.map((x) => {
        if (x.Month_Id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              // let revItemTotal = revList.find(
              //   (revItem) => revItem.Cat_Name == item.Cat_Name
              // ).Cat_amt;

              // item.Cat_Per = value.formattedValue;

              // item.Cat_amt = (revItemTotal * item.Cat_Per) / 100;

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // x.Month_totper = (x.Total / Monthtotal) * 100;
              item.abs = false;
              return item;
            } else {
              item.abs = true;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (
      menuId == "Labor" ||
      menuId == "Controllable" ||
      menuId == "Non Controllable"
    ) {
      console.log(id, value, Month_id, menuId, "IDDDD");

      // let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
      // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              // item.Cat_Per = value.formattedValue;
              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );

              // const numericValue = parseFloat(value.formattedValue.replace(/[^0-9.-]+/g, ''));

              // item.Cat_amt = (numericValue / 100) * revTotal;

              // console.log(item.Cat_amt, revTotal, 'IDDDD')

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // x.Month_totper = (x.Total / revTotal) * 100;
              item.abs = false;
              return item;
            } else {
              item.abs = true;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    }
  };

  const editMonthperabsblur = (id, value, Month_id, menuId) => {
    console.log("checking", value.value);
    if (menuId == "Revenue") {
      let temp = { ...monthData };
      temp = temp.data.map((x) => {
        // let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
        // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;

        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              console.log(item.Cat_id, "ko");
              // item.Cat_Per = value.formattedValue;

              // item.Cat_amt = (value.formattedValue / 100) * Monthtotal;

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // x.Month_totper = (x.Total / Monthtotal) * 100;
              item.abs = true;
              return item;
            } else {
              item.abs = true;

              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (menuId == "Cost Of Goods") {
      // let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
      // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
      let temp = monthData.data.map((x) => {
        if (x.Month_Id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              // let revItemTotal = revList.find(
              //   (revItem) => revItem.Cat_Name == item.Cat_Name
              // ).Cat_amt;

              // item.Cat_Per = value.formattedValue;

              // item.Cat_amt = (revItemTotal * item.Cat_Per) / 100;

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // x.Month_totper = (x.Total / Monthtotal) * 100;
              item.abs = true;
              return item;
            } else {
              item.abs = true;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    } else if (
      menuId == "Labor" ||
      menuId == "Controllable" ||
      menuId == "Non Controllable"
    ) {
      console.log(id, value, Month_id, menuId, "IDDDD");

      // let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
      // let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
      let temp = monthData.data.map((x) => {
        if (x.Month_id == Month_id) {
          x.List.map((item) => {
            if (item.Cat_id == id) {
              // item.Cat_Per = value.formattedValue;
              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );

              // const numericValue = parseFloat(value.formattedValue.replace(/[^0-9.-]+/g, ''));

              // item.Cat_amt = (numericValue / 100) * revTotal;

              // console.log(item.Cat_amt, revTotal, 'IDDDD')

              // x.Total = x.List.reduce(
              //   (Total, obj) =>
              //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
              //   0
              // );
              // x.Month_totper = (x.Total / revTotal) * 100;
              item.abs = true;
              return item;
            } else {
              item.abs = true;
              return item;
            }
          });
        }
        return x;
      });
      setMonthData({ ...monthData, data: temp });
    }
  };

  const editMonthTotal = (value, Month_id, menuId) => {
    console.log("valueee", value);
    console.log("Month_id", Month_id.value);
    console.log("menuId", menuId);
    console.log(monthData.data, " monthData");
    setsave(false);
    let temp = monthData.data.map((x) => {
      if (x.Month_id == menuId) {
        x.Total = Month_id.value;
      }
      return x;
    });
    setMonthData({ ...monthData, data: temp });

    // else if (menuId == "Cost Of Goods") {
    //   let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
    //   let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
    //   let temp = monthData.data.map((x) => {
    //     if (x.Month_Id == Month_id) {
    //       x.List.map((item) => {
    //         if (item.Cat_id == id) {
    //           let revItemTotal = revList.find(
    //             (revItem) => revItem.Cat_Name == item.Cat_Name
    //           ).Cat_amt;

    //           item.Cat_Per = value.formattedValue;

    //           item.Cat_amt = (revItemTotal * item.Cat_Per) / 100;

    //           x.Total = x.List.reduce(
    //             (Total, obj) =>
    //               (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
    //             0
    //           );
    //           x.Month_totper = (x.Total / Monthtotal) * 100;
    //           return item;
    //         }
    //       });
    //     }
    //     return x;
    //   });
    //   setMonthData({ ...monthData, data: temp });
    // } else if (
    //   menuId == "Labor" ||
    //   menuId == "Controllable" ||
    //   menuId == "Non Controllable"
    // ) {
    //   let revList = revMonth.find((rev) => rev.Month_id == Month_id).List;
    //   let revTotal = revMonth.find((rev) => rev.Month_id == Month_id).Total;
    //   let temp = monthData.data.map((x) => {
    //     if (x.Month_id == Month_id) {
    //       x.List.map((item) => {
    //         if (item.Cat_id == id) {
    //           item.Cat_Per = value.formattedValue;
    //           // x.Total = x.List.reduce(
    //           //   (Total, obj) =>
    //           //     (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
    //           //   0
    //           // );

    //           item.Cat_amt = (value.formattedValue / 100) * revTotal;

    //           x.Total = x.List.reduce(
    //             (Total, obj) =>
    //               (obj.Cat_amt ? parseFloat(obj.Cat_amt) : 0) + Total,
    //             0
    //           );
    //           x.Month_totper = (x.Total / revTotal) * 100;
    //           return item;
    //         }
    //       });
    //     }
    //     return x;
    //   });
    //   setMonthData({ ...monthData, data: temp });
    // }
  };

  const editMonthTotalabs = (value, Month_id, menuId) => {
    console.log("valueee", value);
    console.log("Month_id", Month_id.value);
    console.log("menuId", menuId);
    console.log(monthData.data, " monthData");

    let temp = monthData.data.map((x) => {
      if (x.Month_id == menuId) {
        // x.Total = Month_id.value;
        x.abs = true;
        return x;
      } else {
        x.abs = false;
        return x;
      }
      return x;
    });
    setMonthData({ ...monthData, data: temp });
  };

  const editMonthTotalabsblur = (menuId) => {
    let temp = monthData.data.map((x) => {
      if (x.Month_id == menuId) {
        // x.Total = Month_id.value;
        x.abs = false;
        return x;
      } else {
        x.abs = false;
        return x;
      }
    });
    setMonthData({ ...monthData, data: temp });
  };

  const saveYearData = async (menuId) => {
    const index = LabelList.findIndex((user) => user.Label_Name === menuId);

    menuId = LabelList[index - 1].Label_Name;

    if (menuId == "Controllable") {
    }
    setIsLoading(true);
    let url, msg;
    if (
      menuId == "Revenue" ||
      menuId == "Admin Expenses" ||
      menuId == "Turf Maintenance" ||
      menuId == "Bldg Mnt Expenses" ||
      menuId == "Tennis Expenses" ||
      menuId == "Turf Revenue"
    ) {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addRevenueYearly";
        changeData(1, "Revenue", laborindex);
        getData();
        //sear
      } else {
        url = "Api/Budget/addRevenueYearly";
       // changeData(1, "Revenue", laborindex);
       // getData();
      }
 

      if(items.Account_Type==1 ||items.Account_Type==2 ||items.Account_Type==3 ||items.Account_Type==6 ||items.Account_Type==11)
      {
        msg = "Revenue Added Successfully";
      }
      else{
        msg = "Expenses Added Successfully";
      }
      
    } else if (menuId.trim().toLowerCase() == "cost of goods") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addCogYearly";
        changeData(3, "Cost Of Goods", laborindex);
      } else {
        url = "Api/Budget/addCogYearly";
      //  changeData(3, "Cost Of Goods", laborindex);
      }
      msg = "Cost of Goods Added Successfully";
    } else if (menuId.trim().toLowerCase() == "labor") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addLaberYearly";
        changeData(4, "Labor", laborindex);
      } else {
        url = "Api/Budget/addLaberYearly";
       // changeData(4, "Labor", laborindex);
      }
      msg = "Labor Added Successfully";
    } else if (menuId.trim().toLowerCase() == "controllable") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addControllableYearly";
        changeData(5, "Controllable", laborindex);
      } else {
        url = "Api/Budget/addControllableYearly";
       // changeData(5, "Controllable", laborindex);
      }
      msg = "Controllable Added Successfully";
    } else if (menuId.trim().toLowerCase() == "non controllable") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addNonControllableYearly";
        changeData(6, "Non Controllable", laborindex);
      } else {
        url = "Api/Budget/addNonControllableYearly";
       // changeData(6, "Non Controllable", laborindex);
      }
      msg = "Non Controllable Added Successfully";
    }

    let budget = {
      Budget:data.list?.map(u => {
        if (u?.Cat_amt == '' || u?.Cat_amt == null || u?.Cat_amt == undefined) {
          return { ...u, Cat_amt: 0.0 }
        }
        return { ...u }
      }),
      Budget_details: [
        { budget_year: selectYear },
        { budget_type: budget_type },
        { Restaurant_id: userDetail.Restaurant_id },
      ],
    };
    await fobeAxios
      .post(url, budget)
      .then((resp) => {
        console.log(budget, "FGFGFGF");
       
        if(msg == "Revenue Added Successfully" || msg == "Expenses Added Successfully"){
     
          changeData(1, "Revenue", laborindex);
          getData();

        }else if(msg == "Cost of Goods Added Successfully"){
          changeData(3, "Cost Of Goods", laborindex)
        }
        else if(msg == "Labor Added Successfully"){
          changeData(4, "Labor", laborindex);
        }
        else if(msg == "Controllable Added Successfully"){
          changeData(5, "Controllable", laborindex);
        }
        else if(msg == "Non Controllable Added Successfully"){
          changeData(6, "Non Controllable", laborindex);
        }
        toast.success(msg);
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });

   // setIsLoading(false);
  };

  const [showCheckbox, setshowcheckbox] = useState(false);
  const saveMonthData = async (menuId) => {
    setsave(true);
    setIsLoading(true);
    const index = LabelList.findIndex((user) => user.Label_Name === menuId);

    menuId = LabelList[index - 1].Label_Name.trim();

    console.log(menuId, "menuId");

    let url, msg;
    if (
      menuId == "Revenue" ||
      menuId == "Admin Expenses" ||
      menuId == "Turf Maintenance" ||
      menuId == "Bldg Mnt Expenses" ||
      menuId == "Tennis Expenses" ||
      menuId == "Turf Revenue"
    ) {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addRevenueMonthly";
      } else {
        url = "Api/Budget/addRevenueMonthly";
      }
      if(items.Account_Type==1 ||items.Account_Type==2 ||items.Account_Type==3 ||items.Account_Type==6 ||items.Account_Type==11){
      msg =  "Revenue Monthly Added Successfully";
      }
      else{
        msg =  "Expenses Monthly Added Successfully";
      }

    } else if (menuId == "Cost Of Goods") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addCogMonthly";
      } else {
        url = "Api/Budget/addCogMonthly";
      }
      msg = "Cost of Goods Monthly Added Successfully";
    } else if (menuId == "Labor") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addLaborMonthly";
      } else {
        url = "Api/Budget/addLaborMonthly";
      }
      msg = "Labor Monthly Added Successfully";
    } else if (menuId == "Controllable") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addControllablerMonthly";
      } else {
        url = "Api/Budget/addControllablerMonthly";
      }
      msg = "Controllable Monthly Added Successfully";
    } else if (menuId == "Non Controllable") {
      if (budget_type == "1") {
        url = "Api/BudgetPeriod/addNonControllableMonthly";
      } else {
        url = "Api/Budget/addNonControllableMonthly";
      }
      msg = "Non Controllable Monthly Added Successfully";
    }

    let budget = {
      Budget: monthData.data,
      Budget_details: [
        { budget_year: selectYear },
        { budget_type: budget_type },
        { Restaurant_id: userDetail.Restaurant_id }
    
      ],
    };
    await fobeAxios
      .post(url, budget)
      .then((resp) => {
        toast.success(msg);
        if (
          menuId == "Revenue" ||
          menuId == "Admin Expenses" ||
          menuId == "Turf Maintenance" ||
          menuId == "Bldg Mnt Expenses" ||
          menuId == "Tennis Expenses" ||
          menuId == "Turf Revenue"
        ) {
          getData();
         
        }
        if (menuId == "Controllable") {
          changeDatadup("Controllable");
        }
        revenuechage();
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
     
     console.log(label1,id1,"lablecontro")
    changeData(id1, label1, laborindex);
    setIsLoading(false);
  };
  const changeDatadup = async (label_title) => {
    setbuttrue2(true);
    setbuttrue1(false);
    setlabeltitle1(label_title);
    // alert(label_title)

    sethideCategoriesandConstant(true);
    setIsLoading(true);
    let GetLabelURL;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);

    const index = data.LabelAndYear[0].Labelfirst.findIndex((user) => user.Label_Name.trim() === label_title.trim());

    // console.log(items, "item")
    let geturl,
      GetRevenueMnthurl,
      GetGetcogurl,
      GetcogMonthurl,
      GetLaboururl,
      GetLaborMonthurl,
      GetControurl,
      GetControlMonthurl,
      GetNonControurl,
      GetNonControlMonthurl,
      GetProfitLossurl,
      GetProfitLossMonthurl;

    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        geturl = "Api/BudgetPeriod/GetBudget/" + selectYear;
        GetRevenueMnthurl = "Api/BudgetPeriod/GetRevenueMonth/" + selectYear;
        GetGetcogurl = "Api/BudgetPeriod/GetCog/" + selectYear;
        GetcogMonthurl = "Api/BudgetPeriod/GetcogMonth/" + selectYear;
        GetLaboururl = "Api/BudgetPeriod/GetLabour/" + selectYear;
        GetLaborMonthurl = "Api/BudgetPeriod/GetlaborMonth/" + selectYear;
        GetControurl = "Api/BudgetPeriod/GetContro/" + selectYear;
        GetControlMonthurl = "Api/BudgetPeriod/GetControlMonth/" + selectYear;
        GetNonControurl = "Api/BudgetPeriod/GetNoncontro/" + selectYear;
        GetNonControlMonthurl =
          "Api/BudgetPeriod/GetnonControlMonth/" + selectYear;
        GetProfitLossurl = "Api/BudgetPeriod/GetProfitLoss/" + selectYear;
        GetProfitLossMonthurl =
          "Api/BudgetPeriod/GetProfitLossMonth/" + selectYear;
      } else {
        geturl = `Api/Budget/GetBudget/` + selectYear;
        GetRevenueMnthurl = `Api/Budget/GetRevenueMonth/` + selectYear;
        GetGetcogurl = `Api/Budget/GetCog/` + selectYear;
        GetcogMonthurl = `Api/Budget/GetCogMonth/` + selectYear;
        GetLaboururl = `Api/Budget/GetLabour/` + selectYear;
        GetLaborMonthurl = `Api/Budget/GetlaborMonth/` + selectYear;
        GetControurl = `Api/Budget/GetContro/` + selectYear;
        GetControlMonthurl = `Api/Budget/GetControlMonth/` + selectYear;
        GetNonControurl = `Api/Budget/GetNoncontro/` + selectYear;
        GetNonControlMonthurl = `Api/Budget/GetnonControlMonth/` + selectYear;
        GetProfitLossurl = `Api/Budget/GetProfitLoss/` + selectYear;
        GetProfitLossMonthurl = `Api/Budget/GetProfitLossMonth/` + selectYear;
      }
    } else {
      geturl = `api/Boss/GetBossBudget/` + bossid + "/" + ye;
      GetRevenueMnthurl = `api/Boss/GetBossRevenueMonth/` + bossid + "/" + ye;
      GetGetcogurl = `api/Boss/GetBossCog/` + bossid + "/" + ye;
      GetcogMonthurl = `api/Boss/GetBossCogMonth/` + bossid + "/" + ye;
      GetLaboururl = `api/Boss/GetBossLabor/` + bossid + "/" + ye;
      GetLaborMonthurl = `api/Boss/GetBossLaborMonth/` + bossid + "/" + ye;
      GetControurl = `api/Boss/GetBossControllable/` + bossid + "/" + ye;
      GetControlMonthurl =
        `api/Boss/GetBossControllableMonth/` + bossid + "/" + ye;
      GetNonControurl = `api/Boss/GetBossNoncontrollable/` + bossid + "/" + ye;
      GetNonControlMonthurl =
        `api/Boss/GetBossNoncontrollableMonth/` + bossid + "/" + ye;
      GetProfitLossurl = `api/Boss/GetBossProfitLoss/` + bossid + "/" + ye;
      GetProfitLossMonthurl =
        `api/Boss/GetBossProftLossMonth/` + bossid + "/" + ye;
    }

    if (items.Member_Type == 4) {
      setshowCourses(true);
    } else {
      setshowCourses(false);
    }

    console.log("TITLE", label_title);

    if (
      label_title == "Revenue" ||
      label_title == "Admin Expenses" ||
      label_title == "Turf Maintenance" ||
      label_title == "Tennis Expenses" ||
      label_title == "Turf Revenue" ||
      label_title == "Bldg Mnt Expenses"
    ) {
      // setshowCourses(false);
      // if (label_title != "Turf Revenue") {
      //   sethideCategoriesandConstant(false);
      // }

      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      setshowRevenueBudget(true);
      if(label_title == "Turf Revenue"  ||  label_title == "Revenue"){
        sethideCategoriesandConstant(true)
      }
      
      setShowCat(true);
      setIsLoading(true);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);

      await fobeAxios.get(geturl).then((res) => {
        setIsLoading(false);
        res.data.BudgetRevenue[2].Revenue_List.map((g) => {
          g.abs = true;
        });
        setData({
          ...data,
          list: res.data.BudgetRevenue[2].Revenue_List,
          total: res.data.BudgetRevenue[2].Total,
          percentage: res.data.BudgetRevenue[2].Per,
        });
        setaddcategory(res.data.BudgetRevenue[2].Revenue_List);
        if (typeof res.data.BudgetRevenue[1].Plan_List !== "undefined") {
          setTotal(res.data.BudgetRevenue[1].Plan_List[0].Total);
        }
      });

      await fobeAxios.get(GetRevenueMnthurl).then((res) => {
        // console.log("Revmonth", res.data.Revenue_Thingies);
        res.data.Revenue_Thingies.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });

        setMonthData({
          data: res.data.Revenue_Thingies,
          label: label_title + " Monthly View",

          title: "Revenue",
        });
        setshowtotal(true);
      });
      setIsLoading(false);
    } 
    // else if (label_title == "Turf Care") {
    //   //setshowCourses(false);
    //   if (items.Member_Type == 4) {
    //     setshowCourses(true);
    //   } else {
    //     setshowCourses(false);
    //   }
    //   setIsMonthView(false);
    //   setshowRevenueBudget(true);
    //   setShowCat(true);
    //   // setMonthData({data:turfMonth,label:'Turfcare Monthly View',title:'Turf Care'})
    //   //setData({ list: tableTurfCare, total: 432423, percentage: 98 })
    // } 
    else if (label_title == "Cost Of Goods") {
      setIsLoading(true);
      setshowRevenueBudget(true);
      setShowCat(false);

      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }

      //setshowCourses(false);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetGetcogurl).then((res) => {
        res.data.BudgetCOG[2].Cog_List.map((g) => {
          g.abs = true;
        });
        setData({
          list: res.data.BudgetCOG[2].Cog_List,
          total: res.data.BudgetCOG[2].Total,
          percentage: res.data.BudgetCOG[2].Per,
        });
        setaddcategory(res.data.BudgetCOG[2].Cog_List);
      });

      await fobeAxios.get(GetcogMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Cog_Things);
        res.data.Cog_Things.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });

        setMonthData({
          data: res.data.Cog_Things,
          label: label_title + " Monthly View",
          title: "Cost of Goods",
        });
        setshowtotal(true);
      });
      //setMonthData({data:cogMonth,label:'Cost of Goods Monthly View',title:'Cost of Goods'})
      setIsLoading(false);
    } else if (label_title == "Labor") {
      //setshowCourses(false);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      setIsLoading(true);
      setshowRevenueBudget(true);
      setShowCat(false);
      // setData({list:"",total:"",percentage:""});
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetLaboururl).then((res) => {
        res.data.BudgetLabour[2].Labour_List.map((g) => {
          g.abs = true;
        });
        setData({
          list: res.data.BudgetLabour[2].Labour_List,
          total: res.data.BudgetLabour[2].Total,
          percentage: res.data.BudgetLabour[2].Per,
        });
        setaddcategory(res.data.BudgetLabour[2].Labour_List);
      });

      await fobeAxios.get(GetLaborMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Labor_Thingies);
        res.data.Labor_Thingies.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });

        setMonthData({
          data: res.data.Labor_Thingies,
          label: label_title + " Monthly View",
          title: "Labour",
        });
        setshowtotal(true);
      });

      // setMonthData({data:LabourMonth,label:'Labour Monthly View',title:'Labour'})
      setIsLoading(false);
    } else if (label_title == "Controllable") {
      //setshowCourses(false);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetControurl).then((res) => {
        res.data.BudgetControllable[2].Controllable_List.map((g) => {
          g.abs = true;
        });
        setData({
          list: res.data.BudgetControllable[2].Controllable_List,
          total: res.data.BudgetControllable[2].Total,
          percentage: res.data.BudgetControllable[2].Per,
        });
        setaddcategory(res.data.BudgetControllable[2].Controllable_List);
      });

      await fobeAxios.get(GetControlMonthurl).then((res) => {
        // console.log("Revmonth", res.data.Controllable_Thingies);
        res.data.Controllable_Thingies.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });
        setMonthData({
          data: res.data.Controllable_Thingies,
          label: label_title + " Monthly View",
          title: "Controllable",
        });
        setshowtotal(true);
      });
      //setMonthData({data:ControllableMonth,label:'Controllable Monthly View',title:'Controllable'})
      setIsLoading(false);
    } else if (label_title == "Non Controllable") {
      //setshowCourses(false);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetNonControurl).then((res) => {
        res.data.BudgetNControllable[2].NonControllable_List.map((g) => {
          g.abs = true;
        });
        setData({
          list: res.data.BudgetNControllable[2].NonControllable_List,
          total: res.data.BudgetNControllable[2].Total,
          percentage: res.data.BudgetNControllable[2].Per,
        });
        setaddcategory(res.data.BudgetNControllable[2].NonControllable_List);
      });

      await fobeAxios.get(GetNonControlMonthurl).then((res) => {
        // console.log("Revmonth", res.data.NonControllable_Thingies);
        res.data.NonControllable_Thingies.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });

        setMonthData({
          data: res.data.NonControllable_Thingies,
          label: label_title + " Monthly View",
          title: "Non Controllable",
        });
        setshowtotal(true);
      });
      //setMonthData({data:NonControllableMonth,label:'Non Controllable Monthly View',title:'Non Controllable'})
      setIsLoading(false);
    } else if (label_title == "Profit/Loss") {
      sethideCategoriesandConstant(false);
      //setshowCourses(false);
      if (items.Member_Type == 4) {
        setshowCourses(true);
      } else {
        setshowCourses(false);
      }
      setshowRevenueBudget(true);
      setShowCat(false);
      setIsLoading(true);
      setIsMonthView(monthNavi ? true : false);
      await fobeAxios.get(GetProfitLossurl).then((res) => {
        res.data.Profit_Loss.map((g) => {
          g.abs = true;
        });
        setData({ list: res.data.Profit_Loss });
        // setTotal(res.data.Profit_Loss[1].Plan_List[0].Total)
      });

      await fobeAxios.get(GetProfitLossMonthurl).then((res) => {
        console.log("Revmonth", res.data.Profit_Loss);
        res.data.Profit_Loss.map((g) => {
          g.List.map((y) => {
            y.abs = true;
          });
        });
        setshowtotal(false);
        setMonthData({
          data: res.data.Profit_Loss,
          label: label_title + " Monthly View",
          title: "Profit/Loss",
        });
      });
      //setMonthData({data:profitMonth,label:'Profit/Lose Monthly View',title:'Profit/Lose'})
      setIsLoading(false);
    }

    setnext(data.LabelAndYear[0].Labelfirst[index + 1].Label_Name);
    setprev(data.LabelAndYear[0].Labelfirst[index - 1].Label_Name);

    console.log(data.LabelAndYear[0], "Label");
  };

  const rl =
    y.length > 0
      ? y.map((x) => (
          <Dropdown.Item
            value={x.Year}
            active={selectYear == x.Year}
            eventKey={x.Year}
          >
            {x.Year}
          </Dropdown.Item>
        ))
      : "";
  const [selectedDepartment, setselectedDepartment] = useState(null);
  const handleSelectdrp = (eventKey, event) => {
    if (eventKey.split("`")[1] === "All Department") {
      setbossalldepart(1);
    } else {
      setbossalldepart(0);
    }
    setcheckingForNoBudget(false);
    setselectedDepartment(eventKey);
    SetselectDepartment(eventKey.split("`")[1]);
    setbossid(eventKey.split("`")[0]);
    setbutab("Revenue");
    setEdit(false);
    setshowtable(true);
  };

  ///////////////////////////Ad Category////////////////////////////////

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setbudget(inputValue);
  };
  const onSubmit = async () => {
    setIsLoading(true);
    //e.preventDefault(); // prevent page refresh
    let data;
    let adbudgeturl;
    if (selectedOption == "option1") {
      adbudgeturl = "/Api/BudgetPeriod/addBudgetPlan";
      data = {
        Start_date: date_picker,
        budget_amount: inputValue,
        budget_type: 1,
        budget_year: ye,
        template_type: 2,
        calender_type: 0,
      };
    } else {
      adbudgeturl = "/Api/Budget/addBudgetPlan";
      data = {
        Start_date: "2022-12-31",
        budget_amount: inputValue,
        budget_type: 2,
        budget_year: ye,
        template_type: 2,
        calender_type: 0,
      };
    }

    await fobeAxios.post(adbudgeturl, data).then((resp) => {
        toast.success("Budget Added Successfully");
        if (resp) {
          settriggervariable(true);


       fobeAxios.get(`api/Budget/GetlaborMonth/${ye}`).then((res) => {

       })
       fobeAxios.get(`api/Budget/GetControlMonth/${ye}`).then((res) => {

       })
       fobeAxios.get(`api/Budget/GetnonControlMonth/${ye}`).then((res) => {

       })





          mainAxios.get("/Api/User/GetUserClaims").then((respp) => {


              localStorage.setItem("UserClaims", JSON.stringify(respp.data));
              items = respp.data;
              budget_type = items.budget_type;
              setbudget_type(items.budget_type);

              splUser = items.Special_user;
              Acc_type = items.Account_Type;
              console.log(splUser, Acc_type, budget_type, "items");
              if (budget_type == 1) {
                setSelectedOption("option1");
              } else {
                setSelectedOption("option2");
              }
              if (respp) {
                getData();
              }
              // window.location.reload();
              setShow(false);
              
            })
            .catch((error) => {
              setIsLoading(false);
              return Promise.reject("invalid");
            });
        }
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    // if(BudgetRevenuelength!=0 && data.total!=inputValue){
    // setShow(true)}
    // else{
    //   setShow(false)
    // }
  };

  console.log(butab, "TRTTRTR");

const[yearlock,setyearlock]=useState(items.Edit_Year_Status)




const revenuechage=()=>{
  mainAxios.get("/Api/User/GetUserClaims").then((resp) => {
    localStorage.setItem("UserClaims", JSON.stringify(resp.data))
    setyearlock(resp.data.Edit_Year_Status)
    settooltiplock(resp.data.Edit_Year_Status==1? "Yearly Budget is Locked":"Yearly Budget is Unlocked")
    setIsLoading(false);
  })
}

 




 const yearlockonandoff=()=>{

  setlockpopshow(false);
     console.log(items.Edit_Year_Status,"change")

     var usrNew = {
      edit_status: lockbudchange ? 1 : 0,
      
    };
    setIsLoading(true);

   fobeAxios.post(`api/Budget/updateyeareditstatus/`, usrNew).then((res) => {
      

      if (res.data== 1 ) {

        settooltiplock("Yearly Budget is Locked");

        mainAxios.get("/Api/User/GetUserClaims").then((resp) => {
        localStorage.setItem("UserClaims", JSON.stringify(resp.data))
          setyearlock(resp.data.Edit_Year_Status)
          setIsLoading(false);

        })


      toast.success("Yearly Budget is Locked");
      
      }
      else{
        mainAxios.get("/Api/User/GetUserClaims").then((resp) => {
          localStorage.setItem("UserClaims", JSON.stringify(resp.data))
          setyearlock(resp.data.Edit_Year_Status)
          setIsLoading(false);
          toast.success("Yearly Budget is Unlocked");

          settooltiplock("Yearly Budget is Unlocked");


        })
  

      }
    });


  }




console.log(yearlock,"yearlock")

  const addNewCategory = async () => {
    if (text != undefined && text != null && text != "") {
      submitcategory();
      setTimeout(() => {
        setAddCategoryflag(false);
        setText("");
      }, 500);
    }
  };
  const handleInputChangeforcategory = (event) => {
    setText(event.target.value);

    const inputValue = event.target.value.trim().toLowerCase();

    // Check for payroll Final
    if (payroll?.Final && payroll.Final.length > 0) {
      const finalPayrolls = payroll.Final.map(item => item['payroll'].toLowerCase());
      if (finalPayrolls.includes(inputValue)) {
        sethidetax(false);
        return; // Exit early if condition is met
      }
    }
    
    // Check for payroll Mgnt
    if (payroll?.Mgnt && payroll.Mgnt.length > 0) {
      const mgntNames = payroll.Mgnt.map(item => item['Mgntname'].toLowerCase());
      if (mgntNames.includes(inputValue)) {
        sethidetax(false);
        setIsChecked(true);
        return; // Exit early if condition is met
      }
    }

    sethidetax(true);

     
  };
  const submitcategory = async () => {
    setIsLoading(true);
    let controllername;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        controllername = "BudgetPeriod";
      } else {
        controllername = "Budget";
      }
    }
    console.log(checkes, "aaa");
    let budget = {
      Budget: {
        category_Act_as_catering: checkes,
        category_Act_as_revenue:"0",
        category_name: text,
        category_tax: false,
      },
      Budget_details: [
        { budget_year: selectYear },
        { budget_type: budget_type },
      ],
    };

    let addtoastdata = text + " " + "Category Added Successfully!";
    if (
      butab == "Revenue" ||
      butab == "Admin Expenses" ||
      butab == "Turf Maintenance" ||
      butab == "Tennis Expenses" ||
      butab == "Turf Revenue" ||
      butab == "Bldg Mnt Expenses"
    ) {
      await fobeAxios
        .post("Api/" + controllername + "/addRevenueCategory", budget)
        .then((resp) => {
          getData();
          if (resp) {
            changeDatadup(butab);
            setText("");
            setchecks(false);
            if (resp.data == 1) {
              toast.success(addtoastdata);
            } else if (resp.data == 2) {
              toast.error(
                <div>
                  {budget["Budget"].category_name} Already Exists
                  <br />
                  {butab} Category
                </div>
              );
            } else {
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          getData();
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Cost Of Goods") {
      // if(checkes==false && budget.Budget.category_name!="Catering")
      // {

      await fobeAxios
        .post("Api/" + controllername + "/addCogCategory", budget)
        .then((resp) => {
           getData()
          if (resp) {
            changeDatadup(butab);
            changeData(id1, label1, indexe1);
            setText("");
            if (resp.data == 1) {
              toast.success(addtoastdata);
            } else if (resp.data == 2) {
              toast.error(
                <div>
                  {budget["Budget"].category_name} Already Exists
                  <br />
                  {butab} Category
                </div>
              );
            } else {
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          getData();
          toast.error("Failed");
          setIsLoading(false);
        });
    }
    //}
    else if (butab == "Labor") {
     
      let taxvalue;
      if (isChecked == true && text.trim().toLowerCase() !== "payroll tax" && text.trim().toLowerCase() !== "workers comp.") {
        taxvalue = 1;
      } else {
        taxvalue = 0;
      }
      budget = {
        Budget: {
          category_name: text,
          category_tax: taxvalue==0 ? false :isChecked,
        },
        Budget_details: [
          { budget_year: selectYear },
          { budget_type: budget_type },
          { tax: taxvalue },
        ],
      };
      await fobeAxios
        .post("Api/" + controllername + "/addLaborCategory", budget)
        .then((resp) => {
        //   getData()
          if (resp) {
            changeDatadup(butab);
            changeData(id1, label1, indexe1);

            setText("");
            setIsChecked(false);
            console.log(resp, "resp");
            if (resp.data == 1) {
              toast.success(addtoastdata);
            } else if (resp.data == 2) {
              toast.error(
                <div>
                  {budget["Budget"].category_name} Already Exists
                  <br />
                  {butab} Category
                </div>
              );
            } else {
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          getData();
          toast.error("Failed");
          setIsLoading(false);
        });
      
     
    } else if (butab == "Controllable") {
      await fobeAxios
        .post("Api/" + controllername + "/addCtrlblCategory", budget)
        .then((resp) => {
           //getData()
          if (resp) {
            changeDatadup(butab);
            changeData(id1, label1, indexe1);
            setText("");
            if (resp.data == 1) {
              toast.success(addtoastdata);
            } else if (resp.data == 2) {
              toast.error(
                <div>
                  {budget["Budget"].category_name} Already Exists
                  <br />
                  {butab} Category
                </div>
              );
            } else {
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          getData();
          toast.error("Failed");

          setIsLoading(false);
        });
    } else if (butab == "Non Controllable") {
      await fobeAxios
        .post("Api/" + controllername + "/addNonCtrlblCategory", budget)
        .then((resp) => {
       //    getData()
          if (resp) {
            changeDatadup(butab);
            changeData(id1, label1, indexe1);

            setText("");
            if (resp.data == 1) {
              toast.success(addtoastdata);
            } else if (resp.data == 2) {
              toast.error(
                <div>
                  {budget["Budget"].category_name} Already Exists
                  <br />
                  {butab} Category
                </div>
              );
            } else {
              toast.error("Failed");
            }
          }
        })
        .catch((err) => {
          getData();
          toast.error("Failed");
          setIsLoading(false);
        });
    }
    setCurrentPage(1);
  };

  const CustomToast = ({ info1, info2 }) => (
    <div>
      <strong>{info1}</strong>
      <br />
      <span>{info2}</span>
    </div>
  );
  const handleDelete = async (data) => {
    setIsLoading(true);
    console.log(data, "data");
    setEditRowId(null);
    let Dltcontrollername;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        Dltcontrollername = "BudgetPeriod";
      } else {
        Dltcontrollername = "Budget";
      }
    }
    let budget = {
      Budget: {
        Cat_id: data.Cat_id,
        Cat_name: data.Cat_name,
        Cat_amt: data.Cat_amt,
        Cat_per: data.Cat_per,
        Editable_Status: data.Editable_Status,
        Default_addon: data.Default_addon,
      },
      Budget_details: [
        { budget_year: selectYear },
        { budget_type: budget_type },
      ],
    };
    let deltoastdata = data.Cat_name + " " + "Category Deleted Successfully!";
    if (
      butab == "Revenue" ||
      butab == "Admin Expenses" ||
      butab == "Turf Maintenance" ||
      butab == "Tennis Expenses" ||
      butab == "Turf Revenue" ||
      butab == "Bldg Mnt Expenses"
    ) {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteRevenueCategory", budget)
        .then((resp) => {
          if (resp.data === 0) {
            toast.warning("Minimum one category should be available!");
            getData();
            changeDatadup(butab);
            setText("");
          } else if (resp) {
            getData();
            changeDatadup(butab);

            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Cost Of Goods") {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteCogCategory", budget)
        .then((resp) => {
          if (resp.data === 0) {
            toast.warning("Minimum one category should be available!");
            changeDatadup(butab);
            setText("");
          } else if (resp) {
            changeDatadup(butab);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Labor") {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteLaborCategory", budget)
        .then((resp) => {
          if (resp.data === 0) {
            toast.warning("Minimum one category should be available!");
            changeDatadup(butab);
            setText("");
          } else if (resp) {
            changeDatadup(butab);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Controllable") {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteCtrlblCategory", budget)
        .then((resp) => {
          if (resp.data === 0) {
            toast.warning("Minimum one category should be available!");
            changeDatadup(butab);
            setText("");
          } else if (resp) {
            changeDatadup(butab);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Non Controllable") {
      await fobeAxios
        .post("Api/" + Dltcontrollername + "/deleteNonCtrlblCategory", budget)
        .then((resp) => {
          if (resp.data === 0) {
            toast.warning("Minimum one category should be available!");
            changeDatadup(butab);
            setText("");
          } else if (resp) {
            changeDatadup(butab);
            toast.success(deltoastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    }
    setCurrentPage(1);
  };
  const handleEdit = async (data) => {
    console.log(data, "data");
    setEditRowId(data.Cat_id);
  };
  const updateTableData = (id, key, value) => {
    const newData = addcategory.map((item) => {
      if (item.Cat_id == id) {
        item.Cat_name = value;
        return item;
      }
      return item;
    });
    setaddcategory(newData);
  };
  const updatecategory = async (data) => {
    setIsLoading(true);
    console.log(data, "data");
    setEditRowId(null);
    let updatecontollername;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        updatecontollername = "BudgetPeriod";
      } else {
        updatecontollername = "Budget";
      }
    }
    let budget = {
      Budget: {
        Cat_id: data.Cat_id,
        Cat_name: data.Cat_name,
        Cat_amt: data.Cat_amt,
        Cat_per: data.Cat_per,
        Editable_Status: data.Editable_Status,
        Default_addon: data.Default_addon,
      },
      Budget_details: [
        { budget_year: selectYear },
        { budget_type: budget_type },
      ],
    };
    let toastdata = data.Cat_name + " " + "Category Updated Successfully!";
    if (
      butab == "Revenue" ||
      butab == "Admin Expenses" ||
      butab == "Turf Maintenance" ||
      butab == "Tennis Expenses" ||
      butab == "Turf Revenue" ||
      butab == "Bldg Mnt Expenses"
    ) {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateRevenueCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(butab);
            toast.success(toastdata);

            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Cost Of Goods") {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateCogCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(butab);
            toast.success(toastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Labor") {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateLaborCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(butab);
            toast.success(toastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Controllable") {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateCtrlblCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(butab);
            toast.success(toastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    } else if (butab == "Non Controllable") {
      await fobeAxios
        .post("Api/" + updatecontollername + "/updateNonCtrlblCategory", budget)
        .then((resp) => {
          if (resp) {
            changeDatadup(butab);
            toast.success(toastdata);
            setText("");
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    }
    setCurrentPage(1);
  };
  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////

  // constant recurring expenses

  const [showpopup, setShowpopup] = useState(false);
  const [showdeletepopup, setShowdeletepopup] = useState(false);
  const [constantRecurring, setConstantRecurring] = useState([]);
  const [adCategory, setadCategory] = useState("");
  const [totalbudgetamount, settotalbudgetamount] = useState();
  const [checbox1, setCheckbox1] = useState(false);
  const [checbox2, setCheckbox2] = useState(false);
  const [bossalldepart, setbossalldepart] = useState(1);
  const [callRecurring, setCallRecurring] = useState(false);
  const [deleteItemvalue, setdeleteItemvalue] = useState();

  const handleTaxCheckbox = (e, i, value) => {
    let temp = [...constantRecurring];
    let index = temp.findIndex((b) => b.Cat_id == value.Cat_id);

    if (index == 1) {
      temp[index].Cat_per = 0.07;
      temp[index + 1].Cat_per = 0;
    } else if (index == 2) {
      temp[index].Cat_per = 0.07;
      temp[index - 1].Cat_per = 0;
    }
    setConstantRecurring(temp);
  };
  const handleChangeValue = (e, value, index) => {
    let temp = [...constantRecurring];
    if (e !== undefined && e !== "") {
      temp[index].Cat_amt = e;
      temp[index].Cat_per = (e / totalbudgetamount) * 100;
    } else {
      temp[index].Cat_amt = 0;
      temp[index].Cat_per = 0;
    }
    setConstantRecurring(temp);
  };

  const handletaxper = (e, value, index) => {
    let temp = [...constantRecurring];
    if (e !== undefined && e !== "") {
      temp[index].Cat_per = e;
    } else {
      temp[index].Cat_per = 0;
    }
    setConstantRecurring(temp);
  };
  const handleChangePer = (e, value, index) => {
    console.log(e);
    let temp = [...constantRecurring];
    if (e !== undefined && e !== "") {
      temp[index].Cat_per = e;
      temp[index].Cat_amt = (e / 100) * totalbudgetamount;
    } else {
      temp[index].Cat_per = 0;
      temp[index].Cat_amt = 0;
    }

    setConstantRecurring(temp);
  };

  const handleSaveRecurring = async (e, user) => {
    e.preventDefault();
    try {
      const payload = {
        Budget: constantRecurring,
        Budget_details: [
          {
            budget_year: selectYear,
          },
          {
            budget_type: budget_type,
          },
        ],
      };
      let constantRecurringbudget = "";

      if (user === 1) {
        if (budget_type == "1") {
          constantRecurringbudget =
            "BudgetPeriod/UpdateBossRecurringPeriod/" +
            selectYear +
            "/" +
            bossid +
            "/";
        } else {
          constantRecurringbudget =
            "Boss/UpdateBossRecurring/" + selectYear + "/" + bossid + "/";
        }
      } else {
        if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
          if (budget_type == "1") {
            constantRecurringbudget = "Budgetperiod/updateRecuring/";
          } else {
            constantRecurringbudget = "Budget/updateRecuring/";
            if (buttrue1) {
              changeData(id1, label1, indexe1);
            } else if (buttrue2) {
              changeDatadup(labeltitle1);
            }
          }
        }
      }

      await fobeAxios
        .post("api/" + constantRecurringbudget, payload)
        .then((e) => {
          console.log(e, "kkdi");
          if (e.data === 1) {
            toast.success("Saved Successfully");
            setCallRecurring(!callRecurring);
          } else {
            toast.error("Failed");
          }
        })
        .catch(() => {
          toast.error("Failed");
        });
    } catch (err) {}
  };

  const handleClosepopup = () => {
    setadCategory("");
    setCheckbox1(false);
    setCheckbox2(false);
    setShowpopup(false);
  };
  const handleClosedeletepopup = () => {
    setShowdeletepopup(false);
    setdeleteItemvalue();
  };
  const handleClosedeletepopupConfirm = async () => {
    try {
      if (deleteItemvalue !== undefined) {
        const payloadelete = {
          Recurring: deleteItemvalue,
          Budget_details: [
            {
              budget_year: selectYear,
            },
            {
              budget_type: budget_type,
            },
          ],
        };
        let constantRecurringbudget = "";
        if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
          if (budget_type == "1") {
            constantRecurringbudget =
              "Api/Budgetperiod/deleteRecurringCategory";
          } else {
            constantRecurringbudget = "Api/Budget/deleteRecurringCategory";
          }
        } else {
          if (budget_type == "1") {
            constantRecurringbudget =
              "api/Boss/deleteBossPeriodRecurringCategory/" + ye + "/" + bossid;
          } else {
            constantRecurringbudget =
              "api/Boss/deleteBossRecurringCategory/" + ye + "/" + bossid;
          }
        }
        await fobeAxios
          .post(constantRecurringbudget, payloadelete)
          .then((x) => {
            setdeleteItemvalue();
            if (x.data === 1) {
              toast.success("Deleted Successfully");
              setCallRecurring(!callRecurring);
            } else {
              toast.success("Deleted Failed");
            }
          })
          .catch(() => {
            setdeleteItemvalue();
            toast.success("Deleted Failed");
          });
      }
    } catch (err) {}
    setShowdeletepopup(false);
    console.log(label1,id1,"labledelete")
    changeData(id1, label1, laborindex);
    changeDatadup(butab);


  };

  const handlecloserevamtzero = () => {
    setrevdel(false);
  };

  const handlelockbudget = () => {
    setlockpopshow(false);
  };

  const yearunlockok = () => {
    setlockpopshow(true);
    setlockbudchange(true);
  }

  const yearlockok = () => {
    setlockpopshow(true);
    setlockbudchange(false);
  }


  const handleclosedeletecat = () => {
    setCatDelete(false);
  };

  const handleDeletecat = () => {
    setCatDelete(false);
    handleDelete(delete_cat);
  };

  const handle2delete = (x) => {
   
   if(items.Account_Type==4 ||  items.Account_Type ==5 ||  items.Account_Type == 9 ||  items.Account_Type == 10)
   {
    setDeleteCAt("");
    setCatDelete(true);

    setDeleteCAt(x);
    {
      console.log(x, "x value");
    }
   }

   else{

    if(x.Cat_amt != 0 && butab=="Revenue")
    
    {

      
      setrevdel(true);

    }

    else{

      setrevdel(false);
      setDeleteCAt("");
      setCatDelete(true);
  
      setDeleteCAt(x);
      {
        console.log(x, "x value");
      }



    }


   }

  };
  const getConstantRecurring = async () => {
    setIsLoading(true);
    let Url = "";
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      if (budget_type == "1") {
        Url = "Api/Budgetperiod/GetRecurring/" + ye;
      } else {
        Url = "Api/Budget/GetRecurring/" + ye;
      }
    } else {
      if (bossalldepart === 1) {
        let c = "";
        if (selectedCourse) {
          c = selectedCourse;
        } else {
          c = items?.Company;
        }
        Url =
          "api/Boss/GetBossRecurringForAllDepartMents/" + ye + "/" + c + "/";
      } else {
        Url = "Api/Boss/GetBossRecurring/" + ye + "/" + bossid + "/";
      }
    }
    try {
      await fobeAxios
        .get(Url)
        .then((response) => {
          console.log(response.data.Recurring, "forthedatafromconstants");
          setConstantRecurring(response.data.Recurring);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleCancelAdCat = (e) => {
    e.preventDefault();
    setadCategory("");
    setCheckbox1(false);
    setCheckbox2(false);
  };
  const handleAdCategory = (e) => {
    setadCategory(e);
  };
  const handleCat_Click = async (e) => {

    console.log(checbox1, checbox2 ,"trtrt")
    e.preventDefault();
    if (
      adCategory !== undefined &&
      adCategory !== "" &&
      (checbox1 || checbox2)
    ) {
      const payload = {
        Recurring: {
          cat_name: adCategory,
          cat_type: checbox1
            ? "Controllable"
            : checbox2
            ? "NonControllable"
            : "",
        },
        Budget_details: [
          {
            budget_year: selectYear,
          },
          {
            budget_type: budget_type,
          },
        ],
      };
      let constantRecurringbudget = "";
      if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
        if (budget_type == "1") {
          constantRecurringbudget = "Api/Budgetperiod/addRecurringCategory";
        } else {
          constantRecurringbudget = "Api/Budget/addRecurringCategory";
        }
      } else {
        if (budget_type == "1") {
          constantRecurringbudget =
            "api/Boss/addBossPeriodRecurringCategory/" + ye + "/" + bossid;
        } else {
          constantRecurringbudget =
            "api/Boss/addBossRecurringCategory/" + ye + "/" + bossid;
        }
      }

      await fobeAxios
        .post(constantRecurringbudget, payload)
        .then((x) => {
          if (x.data === 1) {
            setCheckbox1(false);
            setCheckbox2(false);
            setadCategory("");
            toast.success("Added Successfully");
            setCallRecurring(!callRecurring);
          } else {
            toast.error("failed");
          }
        })
        .catch(() => {
          toast.error("failed");
        });
    } else {
    }
  console.log(label1,id1,"labletitle")
    changeData(id1, label1, laborindex);
    changeDatadup(butab);
  };

  useEffect(() => {
    getConstantRecurring();
    //getData()
  }, [callRecurring]);
  useEffect(() => {
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      setbossalldepart(0);
    } else {
      setbossalldepart(1);
    }
  }, [selectedCourse]);
  ////////////////////////////////////////////////////////////////

  //////////////////changeOrder/////////////////////
  const [list, setList] = useState([]);

  //   useEffect(() => {

  //   $("#sortable").sortable({
  //     containment: "#popupContainer" // Replace "popupContainer" with the ID or class of your popup container

  //   });

  // }, []);

  const onDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
    // e.target.style.backgroundColor ='red'
    console.log(id, "ccc");
  };

  const onDrop = (e, newIndex) => {
    console.log(newIndex, "ondrags");

    const id = e.dataTransfer.getData("text/plain");
    const item = list.find((item) => item.Cat_id.toString() === id);
    const oldIndex = list.findIndex((item) => item.Cat_id.toString() === id);

    if (oldIndex !== -1) {
      const newItems = [...list];
      newItems.splice(oldIndex, 1); // remove old item
      newItems.splice(newIndex, 0, item); // insert item at new index
      const reorderedList = newItems.map((item, index) => {
        return { ...item }; //, orderid: index + 1
      });
      setList(reorderedList);
    }
  };

  const onDragOver = (e) => {
    console.log(e, "ondragss");

    e.preventDefault();
  };

  const handleSaveOrders = async (e) => {
    e.preventDefault();
    const payload = {
      type: butab,
      year: selectYear,
      Details: list,
    };
    await fobeAxios
      .post("api/Budget/SaveOrderdetails", payload)
      .then((y) => {
        if (y.data === "OrderId Inserted successfully") {
          toast.success("Data Submitted Successfully");
         // getLabel();
         console.log(butab,"ghhg")
         if(butab =="Revenue"){
          getData();
         }
         else{
          changeData(id1, label1, laborindex);
         }
        } else {
          toast.error("Failed");
        }
      })
      .catch((z) => {
        toast.error("Failed");
      });
  };
  const getorderDetails = async () => {
 
    await fobeAxios
      .get("api/Budget/GetOrderdetails/" + selectYear + "/" + butab)
      .then((x) => {
        console.log(x, "forGettingApi");
        setList(x.data.Orderdetails);
      })
      .catch(() => {});
    
  };

  useEffect(() => {
    getorderDetails();
    if (
      butab === "Turf Revenue" ||
      butab === "Admin Expenses" ||
      butab === "Tennis Expenses" ||
      butab === "Bldg Mnt Expenses" ||
      butab ===  "Turf Maintenance"
    ) {
      if (
        items.Account_Type == 4 ||
        items.Account_Type == 5 ||
        items.Account_Type == 9 ||
        items.Account_Type == 10
      ) {
        sethideCategoriesandConstant(false);
      }
      getData();
    }
  }, [butab]);

  ///////////////////////////////////////////////////

  // const indexOfLastPost = currentPage * postPerPage;
  // const indexOfFirstPost = indexOfLastPost - postPerPage;
  // const currentPosts = addcategory.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageChange = (event, value) => {
    {
      console.log(value, "page number");
    }
    setCurrentPage(value);
  };
  const [AddCategoryflag, setAddCategoryflag] = useState(false);
  const openAddCategoryBar = () => {
    setAddCategoryflag(true);
    setTimeout(() => {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 50);
  };
  /////////////////////////////////////////////

  const handlecheck = (Event) => {
    console.log(Event.target.checked, "hii");

    setchecks(Event.target.checked);
  };
  const deptyearhandle = (year) => {
    console.log(year, "year");
    setSelectYear(year);
    sety(year);
  };
  const handleKeyPress = (e) => {
   
    if (e.key === "Enter") {
      
      console.log(BudgetRevenuelength,"yyyy")
      if (BudgetRevenuelength == 0) {
        
        onSubmit();
       
      } 
    }
  }
  

  return (
    <>
      {showRevenueBudget ? (
        <div className="container-fluid">
          {(splUser == 0 || splUser == 1) && Acc_type != 0 ? (
            <div className="row ">
              <div
                className="col-md-12 header-panel newres"
                style={{ marginTop: "8px", marginBottom: "14px", padding: "0" }}
              >
                <div
                  className="col-xs-12 sm-0"
                  style={{
                    paddingTop: "36px",
                    paddingLeft: "0",
                    paddingBottom: "15px",
                    borderTop: "2px solid rgb(197 196 196 / 39%)",
                  }}
                >
                  <div className="row">
                    {showCat && budget_type != 0 ? (
                      <div className="col-md-5 col-sm-4 col-lg-5 col-xs-12">
                        <h2
                          className="Daily-font"
                          style={{
                            fontSize: "26px",
                            marginTop: "-1px",
                            color: "#7b7b7b",
                          }}
                        >
                          Customer Budget{" "}
                          <span className="h2-title Daily-font">
                            {budget_type == 1
                              ? "for 13 - 4 Week Periods"
                              : "for 12 Months"}
                            {/* {
                             JSON.parse(localStorage.getItem("UserClaims"))
                              budget_type == "2"
                              ? "for 12 Months"
                              : "for 13 -4 Week Periods"} */}
                          </span>
                        </h2>
                      </div>
                    ) : (
                      <div className="col-sm-4 col-md-5 col-lg-5 col-xs-12">
                        {" "}
                        <h2
                          style={{
                            fontSize: "30px",
                            marginTop: "0px",
                            color: "#7b7b7b",
                          }}
                        >
                          <span></span>
                        </h2>
                      </div>
                    )}
                    <div className="col-md-7 col-sm-8 col-lg-7 col-xs-12">
                      <div
                        className="row fandb-row"
                        style={{
                          float: "right",
                          display: "flex",
                          gap: "15px",
                          marginRight: "-30px",
                        }}
                      >
                        {console.log(hideCategoriesandconstant,"hide")}
                        {hideCategoriesandconstant && BudgetRevenuelength > 0 && (
                          <>
                            <div
                              className="col cat-col"
                              style={{ marginTop: "5px" }}
                            >
                              <a
                                className="categori budcatger"
                                onClick={handleShowModal}
                                data-toggle="modal"
                                data-target="#modalone"
                                style={{
                                  fontSize: "16px",
                                  color: "rgb(23 216 75)",
                                  marginTop: "0.5em",
                                  textDecoration: "none",
                                  fontWeight: "600",
                                }}
                              >
                                Categories{" "}
                                <i
                                  class="bi bi-plus-circle-fill"
                                  style={{
                                    color: "#00d95c",
                                    fontWeight: "600",
                                    fontSize: "17px",
                                  }}
                                ></i>
                              </a>
                            </div>
                            <div className="col">
                              <a
                                className="recurbutbudg"
                                style={{
                                  fontSize: "12px",
                                  float: "center",
                                  color: "rgb(243, 244, 255)",
                                  textDecoration: "none",
                                }}
                              >
                                <button
                                  className="btn recurringbtn mtbtn"
                                  type="button"
                                  onClick={() => {
                                    setCallRecurring(!callRecurring);
                                    setShowpopup(true);
                                  }}
                                  style={{ padding: "5px 14px" }}
                                >
                                  Constant Recurring Expenses
                                </button>
                              </a>
                            </div>
                          </>
                        )}

                        {showCat && BudgetRevenuelength > 0 && (
                          <div className="col">
                            <Form>
                              <Form.Group
                                as={Row}
                                className="mb-3 ml-3 budgetyeardrop"
                                controlId="formPlaintextPassword"
                              >
                                <Col sm="12">
                                  <Dropdown
                                    className="budjdropdown"
                                    onSelect={deptyearhandle}
                                  >
                                    <Dropdown.Toggle
                                      id="dropdown-button-dark-example1"
                                      className="drop fb-drop daily-sales-dropml"
                                      variant="secondary"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {selectYear}&nbsp;{" "}
                                      <i
                                        style={{
                                          color: "#24D153",
                                          fontWeight: "600",
                                          fontSize: "15px",
                                        }}
                                        class="fa fa-angle-down icon-drop"
                                      ></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                      variant="dark"
                                      className="responsedropyear"
                                    >
                                      {rl}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Col>
                              </Form.Group>
                            </Form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {showCheckbox && showCat && (
                  <div
                    className="col-xs-12 sm-0"
                    style={{
                      paddingTop: "25px",
                      paddingLeft: "0",
                      paddingBottom: "10px",
                      borderTop: "2px solid rgb(197 196 196 / 39%)",
                      borderBottom: "2px solid rgb(197 196 196 / 39%)",
                    }}
                  >
                    <form>
                      <div className="row">
                        <div
                          className="col-md-4 col-sm-6"
                          style={{
                            padding: "0",
                            borderRight: "2px solid rgb(197 196 196 / 39%)",
                            marginTop: "-15px",
                          }}
                        >
                          <div
                            className="col-xs-12 col-sm-12 col-md-12"
                            style={{ paddingRight: "0", paddingTop: "15px" }}
                          >
                            <h5
                              className="format-bud"
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                                color: "rgb(0, 0, 0)",
                                padding: "0",
                                marginBottom: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              Please select the format of the budget{" "}
                            </h5>
                            <div
                              className="col-xs-12 col-sm-12 col-md-12"
                              style={{
                                fontSize: "14px",
                                padding: "0px",
                                marginBottom: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              <div
                                className="col-xs-7 col-sm-7 col-md-7"
                                style={{ padding: "0" }}
                              >
                                <div class="radio-item-budget">
                                  <input
                                    type="radio"
                                    id="ritemb1"
                                    name="ritem"
                                    value="option1"
                                    checked={selectedOption === "option1"}
                                    onChange={handleOptionChange}
                                    // checked={
                                    //   JSON.parse(localStorage.getItem("UserClaims"))
                                    //     .budget_type == "1"
                                    // }
                                    disabled={
                                      BudgetRevenuelength > 0 &&
                                      selectedOption != "option1"
                                        ? true
                                        : false
                                    }
                                  />
                                  <label for="ritemb1">
                                    {" "}
                                    13 - 4 Week Periods
                                  </label>
                                </div>
                              </div>
                              <div
                                className="col-xs-5 col-sm-5 col-md-5"
                                style={{ padding: "0" }}
                              >
                                <div class="radio-item-budget">
                                  <input
                                    type="radio"
                                    id="ritemb2"
                                    name="ritem"
                                    value="option2"
                                    checked={selectedOption === "option2"}
                                    onChange={handleOptionChange}
                                    // checked={
                                    //   JSON.parse(localStorage.getItem("UserClaims"))
                                    //     .budget_type == "2"
                                    // }
                                    disabled={
                                      BudgetRevenuelength > 0 &&
                                      selectedOption != "option2"
                                        ? true
                                        : false
                                    }
                                  />
                                  <label for="ritemb2"> 12 Months</label>
                                </div>
                              </div>

                              {/*    {selectedOption === "option1" && (
                                <div
                                  className="col-xs-12 col-sm-6 col-md-6"
                                  style={{ padding: "0" }}
                                >
                                  <div className="date-picker">
                                    <DatePicker
                                      
                                      placeholder="27/12/2022"
                                      onChange={(date) => setStartDate(date)}
                                    />
                                  </div>
                                </div>
                        )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <h5
                              className="estimate-text"
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                                color: "rgb(0, 0, 0)",
                                padding: "0",
                                marginBottom: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              {items.Account_Type == 1 ||
                              items.Account_Type == 2 ||
                              items.Account_Type == 3 ||
                              items.Account_Type == 6 ||
                              items.Account_Type == 11
                                ? "Your Estimated Revenue"
                                : "Your Estimated Expenses"}
                            </h5>
                          </div>
                          <div
                            className="col-md-6 col-sm-8 col-xs-8"
                            style={{ color: "rgb(174 167 167)" }}
                          >
                            <div className="row">
                              <div
                                className="col-md-1 col-sm-1 col-xs-1"
                                style={{ padding: "0em" }}
                              >
                                {/*<CurrencyFormat className="input_field fl-r form-control" id="Cat_amt" name="Cat_amt" />*/}
                                <div
                                  className="daily-dollar"
                                  style={{
                                    textAlign: "right",
                                    marginTop: "0.5em",
                                  }}
                                >
                                  $
                                </div>
                              </div>
                              <div
                                className="col-md-9 col-sm-9 col-xs-9"
                                style={{
                                  padding: "0em",
                                  marginLeft: "-0.5em",
                                  width: "90%",
                                }}
                              >
                                {console.log(inputValue, "inputValue")}
                                <CurrencyFormat
                                  style={{
                                    color: "rgb(174 167 167)",
                                    fontweight: "bold",
                                    border: "2px solid rgb(0 0 0 / 28%)",
                                    fontSize: "13px",
                                    borderRadius: "3px",
                                  }}
                                  fixedDecimalScale={2}
                                  className="input_field fl-r form-control daily-input"
                                  decimalScale={2}
                                  value={inputValue}
                                  thousandSeparator={true}
                                  onChange={handleInputChange}

                                  onKeyDown={handleKeyPress}

                                  disabled={(BudgetRevenuelength > 0 ? true : false)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-4 col-sm-4 col-md-6 text-left p-0">
                            <button
                              className="btn recurringbtn daily-submit"
                              style={{ backgroundColor: "rgb(23 216 75)" }}
                              type="button"
                              onClick={() => {
                                if (BudgetRevenuelength > 0) {
                                  handleShow();
                                } else {
                                  onSubmit();
                                }
                              }}
                           disabled={zero || (BudgetRevenuelength > 0 ? true : false)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <div>
                        
                        {/* <BootstrapSwitchButton
                              checked={yearlock==0? false : true}
                               size="sm"
                             onChange={(e) => {
                             yearlockonandoff(e);
                             
                              }}
                           /> */}
                 
                 
                    
                        {BudgetRevenuelength != 0 && (
                         
                         <div className="col-md-2 col-sm-2">
                        <div  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    }}>

<Tooltip style={{ position: 'absolute' }} title={<span className="my-tooltip-title">{tooltiplock}</span>}>
 


                        <Button
                style={{
                    backgroundColor: 'rgb(23, 216, 75)', // Set the button background color
                    color: 'white', // Set the button text color (optional)
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', // Align items to the center
                    padding: '8px',
                    minWidth: '50px',
                    borderRadius: '4px'
                }}
            >

              {(
(yearlock==0 || yearlock ==null) ? (
  
      <MdOutlineLockOpen style={{ fontSize: '23px', color: 'white' }} onClick={() =>yearunlockok(true)} />
  

):(

  <MdLockOutline style={{ fontSize: '23px', color: 'white' }} onClick={()=>yearlockok(false)} />
  
)

              )}
            </Button>
            </Tooltip>
            </div>
                        </div>
                        )}
                        
                  

                      </div>
                      </div>
                      <Modal show={show} size="sm" onHide={handleClose}>
                        <Modal.Header style={{ border: "none" }}>
                          <Modal.Title
                            style={{
                              textAlign: "center",
                              background: "rgb(236 173 27)",
                              padding: "0.5em",
                            }}
                          >
                            <BsExclamationTriangle
                              style={{ marginRight: "0.2em", top: "2px" }}
                            />
                            Warning
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                          style={{
                            textAlign: "center",
                            fontSize: "1.01em",
                            padding: "0.3em 1em",
                          }}
                        >
                          <p>
                            Resetting your estimated revenue figure will clear
                            all budgeted information.
                          </p>
                          <p>
                            To update your estimated revenue for the year please
                            update your monthly revenue expectations located at
                            the bottom of this page.
                          </p>
                          <p>Are you sure you want to reset your account?</p>
                        </Modal.Body>
                        <Modal.Footer
                          style={{
                            textAlign: "center",
                            marginRight: "0em",
                            border: "none",
                          }}
                        >
                          <button
                            className="btn btn-danger"
                            onClick={handleClose}
                            style={{ marginRight: "0.5em" }}
                          >
                            CANCEL
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={onSubmit}
                          >
                            CONFIRM
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </form>
                  </div>
                )}
              </div>
              <div className="col-xs-12 text-center">
                <h3
                  style={{
                    margin: "18px 0px",
                    color: "#666",
                    fontSize: "25px",
                    display: "none",
                  }}
                >
                  Estimated Revenue for 2023
                </h3>
              </div>
              {/* <div className="col-md-12" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-md-8" style={{ paddingBottom: "20px" }}>
                    <button
                      className="btn recurringbtn"
                      style={{ backgroundColor: "#19a73f" }}
                      type="button"
                      data-toggle="modal"
                      data-target="#changemodal"
                      onClick={(e)=>{e.preventDefault();getorderDetails();}}
                    >
                      CHANGE ORDER
                    </button>
                  </div>
                </div>
              </div> */}{" "}
            </div>
          ) : null}

          {(splUser !== 0 || splUser !== 1) && Acc_type == 0 ? (
            <>
              <div
                className="row"
                style={{
                  borderBottom: "1px solid rgb(221, 221, 221)",
                  paddingBottom: "17px",
                }}
              >
                <div className="col-sm-4 col-md-5 col-lg-5">
                  {budget_type != 0 ? (
                    <h2
                      className="Daily-font"
                      style={{
                        fontSize: "26px",
                        marginTop: "0px",
                        color: "#7b7b7b",
                        marginLeft: "-20px",
                      }}
                    >
                      Customer Budget{" "}
                      <span
                        className="h2-title Daily-font"
                        style={{ color: "#7b7b7b" }}
                      >
                        {budget_type == 1
                          ? "for 13 - 4 Week Periods"
                          : "for 12 Months"}
                      </span>
                    </h2>
                  ) : (
                    <h2
                      style={{
                        fontSize: "30px",
                        marginTop: "0px",
                      }}
                    >
                      <br></br>
                      <span></span>
                    </h2>
                  )}
                </div>
                <div className="col-md-7 col-lg-7 col-sm-8">
                  <label
                    class="control-label"
                    style={{
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#000000",
                      fontWeight: "600",
                      float: "right",
                      marginRight: "-20px",
                    }}
                  >
                 {console.log(chname,"hh")}
                 {chname=="Turf Maintenance" || chname=="Bldg Mnt Expenses" || chname=="Tennis Expenses" || chname=="Admin Expenses"? "Total Estimated Expenses" : "Total Estimated Revenue"}&nbsp;&nbsp;&nbsp;{" "}
                 
                    <span
                      class="label estimated-label"
                      style={{
                        position: "relative",
                        top: "-2px",
                        fontSize: "16px",
                      }}
                    >
                      <span className="dollar">$</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <CurrencyFormat
                        style={{ padding: "4px 0px" }}
                        fixedDecimalScale={2}
                        className="non-edit-cs control-label-amt"
                        decimalScale={2}
                        value={Budgetlength > 0 ? inputValue : "0"}
                        thousandSeparator={true}
                        disabled={true}
                      />
                    </span>
                  </label>
                </div>

                {/* <div className="col-sm-12 col-md-6">
                  <div className="row">
                    <div className="col-xs-12">
                      <a
                        style={{
                          fontSize: "13px",
                          color: "rgb(243, 244, 255)",
                          textDecoration: "none",
                          float: "right",
                          marginLeft: "60px"
                        }}
                      >
                        <button
                          className="btn recurringbtn"
                          type="button"
                          onClick={() => {
                            setCallRecurring(!callRecurring);
                            setShowpopup(true);
                          }}
                        >
                          CONSTANT RECURRING EXPENSES
                        </button>
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row">
                {/* <div className="col-sm-12 col-md-2">
                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Col sm="12">
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            className="drop-ml "
                            variant="secondary"
                          >
                            {selectYear}
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="dark">{rl}</Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Form.Group>
                  </Form>
                </div> */}
                {showCourse && showCat && (
                  <div className="col-sm-12 col-md-10">
                    {/* <Form>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Col sm="8">
                          <Form.Label
                            style={{
                              float: "right",
                              marginTop: "5.5px",
                              color: "#666",
                              fontWeight: "500",
                            }}
                          >
                            Select Department
                          </Form.Label>
                        </Col>
                        <Col sm="4">
                          <Dropdown onSelect={handleSelectdrp} >
                            <Dropdown.Toggle
                              id="dropdown-button-dark-example1"
                              className="drop-ml "
                              variant="secondary"
                            >
                              {selectDepartment
                                ? selectDepartment.slice(0, 23)
                                : "All Department"}
                            </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark"  className='responsedrop'>

                              <Dropdown.Item
                                eventKey={
                                  alldepartments + "`" + "All Department"
                                }
                              >
                                All Department
                              </Dropdown.Item>
                              {department?.map((x) => (
                                <Dropdown.Item
                                  eventKey={x.Rest_id + "`" + x.Rest_Name}
                                  className={selectedDepartment === x.Rest_id + "`" + x.Rest_Name?'active':""}
                                >
                                  {x.Rest_Name}
                                </Dropdown.Item>
                              ))}

                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Form.Group>
                    </Form> */}
                  </div>
                )}
                <div
                  class="col-xs-12"
                  // style={{
                  //   borderTop: "1px solid #ddd",
                  //   paddingTop: "25px",
                  //   paddingBottom: "25px",
                  // }}
                >
                  {/* <div class="col-xs-12">
                    <label
                      class="control-label"
                      style={{
                        fontSize: "18px",
                        textAlign: "left",
                        color: "#666",
                        fontWeight: "500",
                      }}
                    >
                      Total Estimated Revenue:{" "}
                      <span
                        class="label label-success"
                        style={{
                          position: "relative",
                          top: "-2px",
                          fontSize: "16px",
                        }}
                      >
                     $ <CurrencyFormat fixedDecimalScale={2} className='non-edit-cs' decimalScale={2} value={Budgetlength > 0 ? inputValue :"0" } thousandSeparator={true} disabled={true} />
                      </span>
                    </label>
                  </div> */}
                </div>
              </div>
            </>
          ) : null}

          <Modal
            className="category-modal"
            show={showModal}
            size="lg"
            onHide={handleCloseModal}
          >
            <Modal.Header className="category-header">
              <Modal.Title>
                <h4
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontSize: "24px",
                  }}
                >
                  Categories
                </h4>
                <i
                  class="bi bi-x-circle-fill"
                  aria-hidden="true"
                  data-dismiss="modal"
                  style={{
                    cursor: "pointer",
                    color: "#24D153",
                    fontSize: "23px",
                    float: "right",
                    marginTop: "-35px",
                    marginRight: "0px",
                  }}
                  onClick={handleCloseModal}
                ></i>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0px", borderTop: "5px solid #fff" }}>
              <div
                class="col-xs-12 modal-body table-responsive"
                style={{
                  background: "#fff",
                  marginBottom: "18px",
                  padding: "0px 20px 20px 20px",
                  borderRadius: "0px 0px 6px 6px",
                }}
              >
                <p
                  className="cat-popup-text"
                  style={{
                    color: "#9D9D9D",
                    fontSize: "16px",
                    textAlign: "left",
                    borderTop: "2px solid #BCBCBC",
                    paddingTop: "15px",
                    paddingBottom: "5px",
                  }}
                >
                  You may add or delete the below list of categories as needed.
                </p>
                <div
                  className="table-responsive table-scroll-budget"
                  style={{ maxHeight: "300px", border: "none" }}
                >
                  <table
                    class="table tabble "
                    style={{ width: "100%", margin: "auto" }}
                  >
                    <thead>
                      <tr>
                        {/* <th style={{ width: "35%" }}>
                          <div class="tab-card-new">{butab} CATEGORY</div>
                        </th>
                        {butab == "Labor" && (
                          <th style={{ width: "35%" }}>
                            <div class="tab-card-new">TAXES</div>
                          </th>
                        )}
                        <th style={{ width: "35%" }}>
                          <div class="tab-card-new">DELETE</div>
                        </th>
                        <th>
                          <div class="tab-card-new">EDIT</div>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody ref={myRef}>
                      {addcategory.map((x, index) => (
                        <tr key={x.Cat_id}>
                          {editRowId === x.Cat_id ? (
                            <input
                              className="cat-edit-input"
                              style={{
                                padding: "0px 0px 0px 8px",
                                borderRadius: "3px",
                                margin: "15px 2px",
                                color: "#202020",
                                fontSize: "15px",
                                textTransform: "capitalize",
                                fontWeight: "600",
                                border: "none",
                                backgroundColor: "rgb(255, 222, 131)",
                                float: "left",
                              }}
                              type="text"
                              value={x.Cat_name}
                              onChange={(e) =>
                                updateTableData(
                                  x.Cat_id,
                                  "Cat_name",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <td
                              className="cat-table-data"
                              style={{ color: "#202020", fontSize: "16px" }}
                            >
                              <p
                                class="text-left cat-para"
                                style={{
                                  padding: "8px",
                                  margin: "0",
                                  color: "#202020",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  fontWeight: "600",
                                }}
                              >
                                {x.Cat_name}
                              </p>
                            </td>
                          )}
                          <td
                            className="cat-table-data"
                            style={{ textAlign: "right" }}
                          >
                            {butab == "Labor" && (
                              <i
                                className={
                                  x.Cat_tax == 1
                                    ? "bi bi-check-lg"
                                    : "bi bi-x-circle cat-x-circle"
                                }
                                style={{
                                  color: x.Cat_tax == 1 ? "green" : "orange",
                                  marginRight: x.Cat_tax == 1 ? "5px" : "5px",
                                }}
                              ></i>
                            )}

                            {editRowId === x.Cat_id ? (
                              <i
                                class="bi bi-x-circle cat-x-circle"
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  marginRight: "4px",
                                }}
                                onClick={() => setEditRowId(null)}
                              ></i>
                            ) : (
                              x.Default_addon === 0 && (
                                <i
                                  class="bi bi-pencil-square cat-sqaure"
                                  style={{
                                    color: "#7B7B7B",
                                    paddingRight: "0px",
                                    fontSize: "16px",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleEdit(x)}
                                ></i>
                              )
                            )}

                            {editRowId === x.Cat_id ? (
                              <i
                                class="bi bi-check-circle cat-check-circle"
                                style={{
                                  color:
                                    x.Cat_name != "" &&
                                    x.Cat_name != undefined &&
                                    x.Cat_name != null
                                      ? "green"
                                      : "#9B9B9B",
                                  cursor:
                                    x.Cat_name != "" &&
                                    x.Cat_name != undefined &&
                                    x.Cat_name != null
                                      ? "pointer"
                                      : "default",
                                }}
                                onClick={() => {
                                  if (
                                    x.Cat_name != "" &&
                                    x.Cat_name != undefined &&
                                    x.Cat_name != null
                                  ) {
                                    updatecategory(x);
                                  }
                                }}
                              ></i>
                            ) : (
                              <i
                                class="bi bi-trash cat-trash"
                                style={{
                                  color: "#7B7B7B",
                                  fontSize: "17px",
                                  cursor: "pointer",
                                }}
                                onClick={
                                  () => handle2delete(x)
                                  // handleDelete(x)
                                }
                              ></i>
                            )}
                          </td>
                        </tr>
                      ))}
                      {AddCategoryflag && (
                        <>
                          <tr>
                            <td className="cat-table-data">
                              <div className="col-md-6 col-sm-7">
                                <p
                                  class="text-left"
                                  style={{
                                    padding: "3px 0px 0px 3px",
                                    marginLeft: "-12px",
                                    color: "#202020",
                                    fontSize: "15px",
                                    textTransform: "capitalize",
                                    fontWeight: "600",
                                    marginTop: "5px",
                                  }}
                                >
                                  Enter New {butab} Category
                                </p>
                              </div>
                              <div className="col-md-6 col-sm-4">
                                <div className="row">
                                  <input
                                    className="cat-input"
                                    type="text"
                                    value={text}
                                    onChange={handleInputChangeforcategory}
                                    style={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontweight: "600",
                                      border: "none",
                                      background: "#ffde83",
                                      borderRadius: "3px",
                                      padding: "0px 0px 0px 8px",
                                      marginTop: "10px",
                                    }}
                                  />
                                  {butab.trim().toUpperCase() === "LABOR" && hidetax && (
                                    <>
                                      <input
                                        class="styled-checkbox"
                                        id="styled-checkbox-1"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        type="checkbox"
                                        value="value1"
                                      />
                                      <label
                                        for="styled-checkbox-1"
                                        style={{
                                          marginLeft: "40px",
                                          color: "#5c5b5b",
                                          fontweight: "500",
                                        }}
                                      >
                                        Include Taxes
                                      </label>
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td
                              className="cat-table-data"
                              style={{ textAlign: "right" }}
                            >
                              <i
                                class="bi bi-x-circle cat-x-circle"
                                style={{
                                  color: "red",
                                  paddingRight: "0px",
                                  fontSize: "20px",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setAddCategoryflag(false);
                                  setText("");
                                }}
                              ></i>
                              <i
                                class="bi bi-check-circle cat-check-circle"
                                style={{
                                  color:
                                    text != undefined &&
                                    text != null &&
                                    text != ""
                                      ? "#24D153"
                                      : "#9B9B9B",
                                  paddingRight: "0px",
                                  fontSize: "20px",
                                  marginRight: "4px",
                                  cursor:
                                    text != undefined &&
                                    text != null &&
                                    text != ""
                                      ? "pointer"
                                      : "default",
                                }}
                                onClick={() => {
                                  addNewCategory();
                                }}
                              ></i>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: "10px" }} className="col-md-12">
                  <div
                    className="col-md-6 col-sm-6"
                    style={{ textAlign: "left" }}
                  >
                    <a
                      className="add-cat-i"
                      onClick={() => {
                        if (!AddCategoryflag) {
                          openAddCategoryBar();
                          // setchecks(false)
                        }
                      }}
                      style={{
                        fontSize: "17px",
                        color: !AddCategoryflag ? "#24D153" : "#9B9B9B",
                        textDecoration: "none",
                        cursor: !AddCategoryflag ? "pointer" : "default",
                      }}
                    >
                      {" "}
                      <i
                        className="bi bi-plus-circle-fill"
                        style={{
                          color: !AddCategoryflag ? "#24D153" : "#9B9B9B",
                          position: "relative",
                          fontSize: "23px",
                          top: "2px",
                          cursor: !AddCategoryflag ? "pointer" : "default",
                        }}
                      ></i>
                      Add a Category
                    </a>
                  </div>

                  {items.Account_Type === 1 && butab === "Revenue" && (
                    <>
                      <div
                        className="col-md-6 col-sm-6"
                        style={{ textAlign: "right", justifyContent: "right" }}
                      >
                        <div>
                          <input
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            type="checkbox"
                            onClick={(e) => handlecheck(e)}
                            checked={checkes}
                          />
                          <span
                            style={{ marginRight: "-15px", fontSize: "16px" }}
                          >
                            Would it be a Food?
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : null}

      <div
        className="container-fluid"
        style={{ marginRight: "-20px", paddingRight: "0px" }}
      >
        <div className="table-sections">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={butab}
            activeKey={butab}
            onSelect={(k, e) => {
              if (
                e.key === "ArrowUp" ||
                e.key === "ArrowDown" ||
                e.key === "ArrowLeft" ||
                e.key === "ArrowRight"
              ) {
                e.preventDefault(); // Prevent the event from being triggered
                return; // Exit the event handler
              }

              setbutab(k);
            }}
          >
            <Row
              style={{
                padding: "0em",
                marginInlineEnd: "0em",
                marginTop: "30px",
              }}
            >
              {budloading ? (
                <Spinner />
              ) : (
                <>
              {(isLoading && Budgetlength >= 0) || Budgetlength > 0 ? (
                <>
                  <Col lg={2} md={2} sm={3} sx={3} style={{ padding: "0em" }}>
                    {(items.Special_user == 1 || items.Special_user == 0) &&
                      items.Account_Type != 0 &&
                      Hide === true && (
                        <>
                          {" "}
                          <button
                            className="change-order"
                            style={{
                              backgroundColor: "rgb(229 229 229)",
                              color: "#7b7b7",
                              fontSize: "13px",
                              width: "100%",
                              fontWeight: "bold",
                              border: "none",
                              padding: "6px",
                              borderRadius: "4px",
                              color: "#7b7b7b",
                              marginTop: "7px",
                              marginBottom: "25px",
                              textAlign: "left",
                            }}
                            type="button"
                            data-toggle="modal"
                            data-target="#changemodal"
                            onClick={(e) => {
                              e.preventDefault();
                              getorderDetails();
                            }}
                          >
                            <i
                              class="bi bi-list change-icon"
                              style={{
                                color: "#7b7b7",
                                fontSize: "13px",
                                marginLeft: "5px",
                              }}
                            ></i>
                            Change Order
                          </button>
                        </>
                      )}

                    <Nav
                      variant="pills"
                      className="flex-column"
                      style={{ paddingTop: "8px" }}
                    >
                      {LabelList?.map((x, index) => {
                       
                        if (x.Label_Name == "Revenue") {
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Revenue"
                                  onClick={() =>
                                    changeData(1, "Revenue", index)
                                  }
                                >
                                  Revenue
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }

                        if (x.Label_Name == "Admin Expenses") {
                          // eventKey_1="Revenue"
                          // eventKey_1="Admin Expenses"
                          // {console.log(eventKey_1,"Event key")}
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Admin Expenses"
                                  onClick={() =>
                                    changeData(1, "Admin Expenses", index)
                                  }
                                >
                                  Admin Expenses
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }
                        if (x.Label_Name == "Turf Maintenance") {
                          // eventKey_1="Revenue"
                          // eventKey_1="Turf & Maintenance"
                          // {console.log(eventKey_1,"Event key")}
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Turf Maintenance"
                                  onClick={() =>
                                    changeData(1, "Turf Maintenance", index)
                                  }
                                >
                                  Turf Maintenance
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }

                        if (x.Label_Name == "Bldg Mnt Expenses") {
                          // eventKey_1="Revenue"
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Bldg Mnt Expenses"
                                  onClick={() =>
                                    changeData(1, "Bldg Mnt Expenses", index)
                                  }
                                >
                                  Bldg Mnt Expenses
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }

                        if (x.Label_Name == "Tennis Expenses") {
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Tennis Expenses"
                                  onClick={() =>
                                    changeData(1, "Tennis Expenses", index)
                                  }
                                >
                                  Tennis Expenses
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }
                        if (x.Label_Name == "Turf Revenue") {
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Turf Revenue"
                                  onClick={() =>
                                    changeData(1, "Turf Revenue", index)
                                  }
                                >
                                  Turf Revenue
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }
                        if (x.Label_Name == "Cost Of Goods") {
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Cost Of Goods"
                                  onClick={() =>
                                    changeData(3, "Cost Of Goods", index)
                                  }
                                >
                                  Cost Of Goods
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }
                        if (x.Label_Name == "Labor") {
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Labor"
                                  onClick={() => changeData(4, "Labor", index)}
                                >
                                  Labor
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }
                        if (x.Label_Name.trim() == "Controllable") {
                          return (
                            <>
                           
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Controllable"
                                  onClick={() =>
                                    changeData(5, "Controllable", index)
                                    
                                  }
                                >
                                  Controllable
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }
                        if (x.Label_Name == "Non Controllable") {
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Non Controllable"
                                  onClick={() =>
                                    changeData(6, "Non Controllable", index)
                                  }
                                >
                                  Non Controllable
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }
                        if (x.Label_Name == "Profit/Loss") {
                          return (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Profit/Loss"
                                  onClick={() =>
                                    changeData(7, "Profit/Loss", index)
                                  }
                                >
                                  Profit / Loss
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        }
                      })}
                    </Nav>
                  </Col>
                  <Col lg={10} md={10} sm={9} xs={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="Revenue">
                        <div className="col-md-12 pd-0">
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabsname={butab}
                            tabs={true}
                            Edit={Edit}
                            selectDepartment={selectDepartment}
                            menuId={1}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            header={Revenue}
                            editabs={editabs}
                            editabsper={editabsper}
                            data={data}
                            nestedColumn={false}
                            isFooter={true}
                            Search={false}
                            pagination={false}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                            setyearlock={setyearlock}
                          />
                        </div>
                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              disabled={(butab === "Revenue" && Object.keys(revenue).length && Math.round(data?.total * 100) / 100 !== Math.round(revenue?.Total * 100) / 100 || yearlock==1) ? true : false }
                              variant="contained"
                              onClick={() => saveYearData(next)}
                              style={{
                                cursor: (yearlock == 0 || yearlock == null) ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>
                      {console.log(
                        Math.round(data.total * 100) / 100,
                        revenue,
                        "qq"
                      )}

                      {butab === "Revenue" &&
                        revenue?.length !== 0 &&
                        Math.round(data.total * 100) / 100 !==
                          Math.round(revenue?.Total * 100) / 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Amount Should not be less or More than $
                            {revenue?.Total}
                          </p>
                        )}
                      {console.log(
                        Math.round(data?.percentage * 100) / 100,
                        "eee"
                      )}
                      {butab === "Revenue" &&
                        revenue?.length !== 0 &&
                        Math.round(data?.percentage * 100) / 100 !== 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Percentage Should not be less or More than
                            100%
                          </p>
                        )}

                      <Tab.Pane eventKey="Admin Expenses">
                        <div className="table-sections">
                          {/* <div className="col-md-12 pd-0"> */}
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabsname={butab}
                            tabs={true}
                            header={AdminExpenses}
                            data={data}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            nestedColumn={false}
                            isFooter={true}
                            selectDepartment={selectDepartment}
                            Search={false}
                            pagination={false}
                            Edit={Edit}
                            menuId={1}
                            editabs={editabs}
                            editabsper={editabsper}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>

                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          {/* {prev ? (
                            <button
                              style={{ float: "left" }}
                              className="nextbuttn nxt-response"
                              variant="contained"
                              onClick={() => {
                                setbutab(prev);
                                changeDatadup(prev);
                              }}
                            >
                              {prev}
                            </button>
                          ) : null} */}
                          {/* {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              onClick={() => saveYearData(next)}
                              disabled={
                                butab === "Admin Expenses" &&
                                Object.keys(revenue).length &&
                                Math.round(data.total * 100) / 100 !=
                                  Math.round(revenue?.Total * 100) / 100 || yearlock==1
                                  ? true
                                  : false
                              }
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )} */}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>

                      {butab === "Admin Expenses" &&
                        Math.round(data.total * 100) / 100 !==
                          Math.round(revenue?.Total * 100) / 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Amount Should not be less or More than $
                            {revenue?.Total}
                          </p>
                        )}

                      {butab === "Admin Expenses" &&
                        Math.round(data.percentage * 100) / 100 !== 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Percentage Should not be less or More than
                            100%
                          </p>
                        )}

                      <Tab.Pane eventKey="Turf Maintenance">
                        <div className="table-sections">
                          {/* <div className="col-md-12 pd-0"> */}
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabsname={butab}
                            tabs={true}
                            header={TurfMaintenance}
                            data={data}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            nestedColumn={false}
                            isFooter={true}
                            selectDepartment={selectDepartment}
                            Search={false}
                            pagination={false}
                            Edit={Edit}
                            menuId={1}
                            editabs={editabs}
                            editabsper={editabsper}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>

                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          {/* {prev ? (
                            <Button
                              style={{ float: "left" }}
                              className="nextbuttn nxt-response"
                              variant="contained"
                              onClick={() => {
                                setbutab(prev);
                                changeDatadup(prev);
                              }}
                            >
                              {prev}
                            </Button>
                          ) : null} */}
                          {/* {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              disabled={
                                butab === "Turf Maintenance" &&
                                Object.keys(revenue).length &&
                                Math.round(data.total * 100) / 100 !=
                                  Math.round(revenue?.Total * 100) / 100 || yearlock==1
                                  ? true
                                  : false
                              }
                              onClick={() => saveYearData(next)}
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )} */}
                          <button
                            className="nextbuttn nxt-response" 
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>
                      {butab === "Turf Maintenance" &&
                        Math.round(data.total * 100) / 100 !==
                          Math.round(revenue?.Total * 100) / 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Amount Should not be less or More than $
                            {revenue?.Total}
                          </p>
                        )}

                      {butab === "Turf Maintenance" &&
                        Math.round(data.percentage * 100) / 100 !== 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Percentage Should not be less or More than
                            100%
                          </p>
                        )}

                      <Tab.Pane eventKey="Bldg Mnt Expenses">
                        <div className="table-sections">
                          {/* <div className="col-md-12 pd-0"> */}
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabsname={butab}
                            header={FitnessExpenses}
                            tabs={true}
                            data={data}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            nestedColumn={false}
                            isFooter={true}
                            selectDepartment={selectDepartment}
                            Search={false}
                            pagination={false}
                            Edit={Edit}
                            menuId={1}
                            editabs={editabs}
                            editabsper={editabsper}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>

                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          {/* {prev ? (
                            <button
                              style={{ float: "left" }}
                              className="nextbuttn nxt-response"
                              variant="contained"
                              onClick={() => {
                                setbutab(prev);
                                changeDatadup(prev);
                              }}
                            >
                              {prev}
                            </button>
                          ) : null} */}
                          {/* {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              onClick={() => saveYearData(next)}
                              disabled={
                                butab === "Bldg Mnt Expenses" &&
                                Object.keys(revenue).length &&
                                Math.round(data.total * 100) / 100 !=
                                  Math.round(revenue?.Total * 100) / 100 || yearlock==1
                                  ? true
                                  : false
                              }
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )} */}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>
                      {butab === "Bldg Mnt Expenses" &&
                        Math.round(data.total * 100) / 100 !==
                          Math.round(revenue?.Total * 100) / 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Amount Should not be less or More than $
                            {revenue?.Total}
                          </p>
                        )}

                      {butab === "Bldg Mnt Expenses" &&
                        Math.round(data.percentage * 100) / 100 !== 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Percentage Should not be less or More than
                            100%
                          </p>
                        )}

                      <Tab.Pane eventKey="Tennis Expenses">
                        <div className="table-sections">
                          {/* <div className="col-md-12 pd-0"> */}
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabsname={butab}
                            header={TennisExpenses}
                            tabs={true}
                            data={data}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            nestedColumn={false}
                            isFooter={true}
                            selectDepartment={selectDepartment}
                            Search={false}
                            pagination={false}
                            Edit={Edit}
                            menuId={1}
                            editabs={editabs}
                            editabsper={editabsper}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>

                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          {/* {prev ? (
                            <button
                              style={{ float: "left" }}
                              className="nextbuttn nxt-response"
                              variant="contained"
                              onClick={() => {
                                setbutab(prev);
                                changeDatadup(prev);
                              }}
                            >
                              {prev}
                            </button>
                          ) : null} */}
                          {/* {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              disabled={
                                butab === "Tennis Expenses" &&
                                Object.keys(revenue).length &&
                                Math.round(data.total * 100) / 100 !=
                                  Math.round(revenue?.Total * 100) / 100 || yearlock==1
                                  ? true
                                  : false
                              }
                              onClick={() => saveYearData(next)}
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )} */}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                             {next}
                          </button>
                        </div>
                      </Tab.Pane>
                      {butab === "Tennis Expenses" &&
                        Math.round(data.total * 100) / 100 !==
                          Math.round(revenue?.Total * 100) / 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Amount Should not be less or More than $
                            {revenue?.Total}
                          </p>
                        )}

                      {butab === "Tennis Expenses" &&
                        Math.round(data.percentage * 100) / 100 !== 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Percentage Should not be less or More than
                            100%
                          </p>
                        )}

                      <Tab.Pane eventKey="Turf Revenue">
                        <div className="table-sections">
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabsname={butab}
                            header={TurfCareExpenses}
                            data={data}
                            nestedColumn={false}
                            isFooter={true}
                            selectDepartment={selectDepartment}
                            Search={false}
                            pagination={false}
                            Edit={Edit}
                            menuId={1}
                            tabs={true}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            editabs={editabs}
                            editabsper={editabsper}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>
                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          {/* {prev ? (
                            <button
                              style={{ float: "left" }}
                              className="nextbuttn nxt-response"
                              variant="contained"
                              onClick={() => {
                                setbutab(prev);
                                changeDatadup(prev);
                              }}
                            >
                              {prev}
                            </button>
                          ) : null} */}
                          {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              onClick={() => saveYearData(next)}
                              disabled={
                                butab === "Turf Revenue" &&
                                Object.keys(revenue).length &&
                                Math.round(data.total * 100) / 100 !=
                                  Math.round(revenue?.Total * 100) / 100 || yearlock==1
                                  ? true
                                  : false
                              }
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>
                      {butab === "Turf Revenue" &&
                        Math.round(data.total * 100) / 100 !==
                          Math.round(revenue?.Total * 100) / 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Amount Should not be less or More than $
                            {revenue?.Total}
                          </p>
                        )}

                      {butab === "Turf Revenue" &&
                        Math.round(data.percentage * 100) / 100 !== 100 &&
                        isLoading == false && (
                          <p style={{ color: "#e46262" }}>
                            Total Percentage Should not be less or More than
                            100%
                          </p>
                        )}

                      <Tab.Pane eventKey="Cost Of Goods">
                        <div className="table-sections">
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            editabs={editabs}
                            editabsper={editabsper}
                            tabs={true}
                            tabsname={butab}
                            selectDepartment={selectDepartment}
                            Edit={Edit}
                            menuId={2}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            header={CostOfGoods}
                            data={data}
                            nestedColumn={false}
                            isFooter={true}
                            Search={false}
                            pagination={false}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>
                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          <button
                            style={{ float: "left" }}
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(prev);
                              changeDatadup(prev);
                            }}
                          >
                            {prev}
                          </button>
                          {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              onClick={() => saveYearData(next)}
                              disabled={yearlock==1?true:false}
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="Labor">
                        <div className="table-sections">
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabs={true}
                            tabsname={butab}
                            Edit={Edit}
                            selectDepartment={selectDepartment}
                            menuId={3}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            header={Labor}
                            data={data}
                            nestedColumn={false}
                            isFooter={true}
                            Search={false}
                            pagination={false}
                            changeData1={changeData}
                            index={laborindex}
                            editabs={editabs}
                            editabsper={editabsper}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>
                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          <button
                            style={{ float: "left" }}
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(prev);
                              changeDatadup(prev);
                            }}
                          >
                            {prev}
                          </button>
                          {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              onClick={() => saveYearData(next)}
                              disabled={yearlock==1?true:false}
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="Controllable">
                        <div className="table-sections">
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabs={true}
                            tabsname={butab}
                            menuId={4}
                            selectDepartment={selectDepartment}
                            Edit={Edit}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            header={Controllable}
                            data={data}
                            nestedColumn={false}
                            isFooter={true}
                            Search={false}
                            pagination={false}
                            editabs={editabs}
                            editabsper={editabsper}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>
                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          <button
                            style={{ float: "left" }}
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(prev);
                              changeDatadup(prev);
                            }}
                          >
                            {prev}
                          </button>
                          {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              onClick={() => saveYearData(next)}
                              disabled={yearlock==1?true:false}
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="Non Controllable">
                        <div className="table-sections">
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabs={true}
                            tabsname={butab}
                            Edit={Edit}
                            selectDepartment={selectDepartment}
                            menuId={5}
                            editYearData={editYearData}
                            editYearDatapercentage={editYearDatapercentage}
                            header={NonControllable}
                            data={data}
                            nestedColumn={false}
                            isFooter={true}
                            Search={false}
                            pagination={false}
                            editabs={editabs}
                            editabsper={editabsper}
                            editabsblur={editabsblur}
                            editabsperblur={editabsperblur}
                            yearlock={yearlock}
                          />
                        </div>
                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          <button
                            style={{ float: "left" }}
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(prev);
                              changeDatadup(prev);
                            }}
                          >
                            {prev}
                          </button>
                          {items.Account_Type != 0 && items.Account_id == 5 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              onClick={() => saveYearData(next)}
                              disabled={yearlock==1?true:false}
                              style={{
                                cursor: yearlock === 0 || yearlock == null ? 'pointer' : 'not-allowed' // Set cursor based on button state
                            }}
                            >
                              Save
                            </Button>
                          )}
                          <button
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab(next);
                              changeDatadup(next);
                              setHide(false);
                            }}
                          >
                            {next}
                          </button>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="Profit/Loss">
                        <div className="table-sections">
                          <YearTable
                            bossid={bossid}
                            year={ye}
                            tabsname={butab}
                            tabs={false}
                            Edit={Edit}
                            selectDepartment={selectDepartment}
                            header={ProfitLoss}
                            data={data}
                            nestedColumn={false}
                            isFooter={true}
                            Search={false}
                            pagination={false}
                            editabs={editabs}
                            editabsper={editabsper}
                          />
                        </div>
                        {console.log(prev, "WERE")}
                        <div
                          className="col-md-12 pd-0 rig-alg"
                          style={{ marginTop: "-13px", marginBottom: "6px" }}
                        >
                          <button
                            style={{ float: "left" }}
                            className="nextbuttn nxt-response"
                            variant="contained"
                            onClick={() => {
                              setbutab("Non Controllable");
                              changeDatadup("Non Controllable");
                            }}
                          >
                            {"Non Controllable"}
                          </button>
                          {/* {(items.Account_Type!=0 && items.Account_id==5 || items.Account_Type==0&&
                          <Button
                            className="mat-btn mat-save save-response save-end"
                            variant="contained"
                          >
                            Save
                          </Button>
                          )} */}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </>
              ) : (
                <>
                      {BudgetRevenuelength == 0 && (
                  <div className="col-xs-12 text-center">
                    <div className="col-xs-12 ">
                      <figure className="info-img">
                        <img
                          src="https://d2a7u30t6wbkim.cloudfront.net/assets/videos/golf-gif.gif"
                          // src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/budget-flat.png"
                          alt="Budget Image"
                          style={{ width: "5%" }}
                        />
                      </figure>
                    </div>
                    <div className="col-xs-12  info-title">
                      <h5 className="" style={{ fontWeight: "900" }}>
                        No Budget available..
                      </h5>
                      <div className="col-xs-12">
                        <p className="awesome">
                          <span
                            className="info-span1"
                            style={{
                              fontSize: "13px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                              color: "#24d153",
                            }}
                          >
                            "Create a budget for a successful golf course"
                          </span>
                        </p>
                      </div>
                    </div>

                    {items.Account_Type != 0 && items.Account_id == 5 && (
                      <p
                        className="res-tag"
                        style={{
                          background: "#d0d8d8",
                          padding: "7px 12px",
                          borderRadius: "7px",
                          color: "#000",
                          letterSpacing: "1px",
                          fontSize: "12px",
                          display: "inline-block",
                        }}
                      >
                        <a
                          href="#/services/budget"
                          onClick={() => {}}
                          style={{ color: "#2196f3" }}
                        >
                          Click
                        </a>{" "}
                        here to budget!
                      </p>
                    )}
                  </div>
                      )}
                    </>
                  )}
                </>
              )}
            </Row>
          </Tab.Container>
        </div>
        {console.log(Budgetlength, "DATATATA")}

        {Budgetlength > 0 && (
          <div className="row" style={{ marginInlineEnd: "1em" }}>
            <div className="col-md-12" style={{ padding: "0em" }}>
              <hr
                style={{
                  borderTop: "2px solid rgb(197 196 196 / 39%)",
                  marginTop: "14px",
                  marginBottom: "23px",
                }}
              />
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div
                className="col-md-12"
                style={{ padding: "0em", marginTop: "11px" }}
              >
                {console.log(monthData.label,"fgfgf")}
                <span className="h2-title Daily-font">Monthly {butab=="Turf Maintenance" ? "Turf Expenses" : butab }</span>
                <Button
                  className="mat-btn mat-save review-btn save-response"
                  variant="contained"
                  onClick={() => {
                    setIsMonthView(!isMonthView);
                    setMonthNavi(!isMonthView);
                  }}
                >
                  {monthData.label=="Turf Maintenance Monthly View" ? "Turf Expenses Monthly View" : monthData.label }
                </Button>
              </div>
            )}
            <div className="col-md-12" style={{ padding: "0em" }}>
              {isMonthView && (
                <>
                  <MonthlyViewTable
                    menuId={butab}
                    selectDepartment={selectDepartment}
                    editMonthData={editMonthData}
                    editMonthDatapercentage={editMonthDatapercentage}
                    monthIndex={0}
                    editMonthTotal={editMonthTotal}
                    data={monthData.data.slice(0, 6)}
                    title={monthData.title}
                    isBudget={true}
                    error={error}
                    showtotal={showtotal}
                    editMonthabs={editMonthabs}
                    save={save}
                    setsave={setsave}
                    editMonthperabs={editMonthperabs}
                    editMonthTotalabs={editMonthTotalabs}
                    editMonthTotalabsblur={editMonthTotalabsblur}
                    editMonthperabsblur={editMonthperabsblur}
                    editMonthabsblur={editMonthabsblur}
                  />
                  <MonthlyViewTable
                    menuId={butab}
                    selectDepartment={selectDepartment}
                    editMonthData={editMonthData}
                    editMonthDatapercentage={editMonthDatapercentage}
                    editMonthTotal={editMonthTotal}
                    monthIndex={6}
                    data={monthData.data.slice(6, 12)}
                    title={monthData.title}
                    isBudget={true}
                    error={error}
                    showtotal={showtotal}
                    editMonthabs={editMonthabs}
                    editMonthperabs={editMonthperabs}
                    editMonthTotalabs={editMonthTotalabs}
                    editMonthTotalabsblur={editMonthTotalabsblur}
                    editMonthperabsblur={editMonthperabsblur}
                    editMonthabsblur={editMonthabsblur}
                  />
                  <div
                    className="col-md-12 pd-0 rig-alg"
                    style={{ marginTop: "-13px", marginBottom: "6px" }}
                  >
                    {(butab === "Revenue" ||
                      butab === "Turf Revenue" ||
                      butab === "Admin Expenses" ||
                      butab === "Turf Maintenance" ||
                      butab === "Bldg Mnt Expenses" ||
                      butab === "Tennis Expenses") && (
                      <>
                        {/* {prev ? (
                        <button
                          className="nextbuttn nxt-response"
                          variant="contained"
                          onClick={() => {
                            setbutab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </button>
                      ) : null} */}
                        {items.Account_Type != 0 && items.Account_id == 5 && (
                          <Button
                            className="mat-btn mat-save save-response"
                            variant="contained"
                            onClick={() => saveMonthData(next)}
                          //  disabled={save}
                         
                          >
                            Save
                          </Button>
                        )}
                        <button
                          className="nextbuttn nxt-response"
                          variant="contained"
                          onClick={() => {
                            setbutab(next);
                            changeDatadup(next);
                            setIsMonthView(true);
                          }}
                        >
                          {next}
                        </button>
                      </>
                    )}

                    {butab === "Cost Of Goods" && (
                      <>
                        <button
                          className="nextbuttn nxt-response"
                          style={{ float: "left" }}
                          variant="contained"
                          onClick={() => {
                            setbutab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </button>
                        {/* {(items.Account_Type!=0 && items.Account_id==5 &&
                      <Button
                        className="mat-btn mat-save save-response"
                        variant="contained"
                        onClick={() => saveMonthData(next)}
                      >
                        Save
                      </Button>)} */}
                        <button
                          className="nextbuttn nxt-response"
                          variant="contained"
                          onClick={() => {
                            setbutab(next);
                            changeDatadup(next);
                          }}
                        >
                          {next}
                        </button>
                      </>
                    )}

                    {butab === "Labor" && (
                      <>
                        <button
                          className="nextbuttn nxt-response"
                          style={{ float: "left" }}
                          variant="contained"
                          onClick={() => {
                            setbutab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </button>
                        {items.Account_Type != 0 && items.Account_id == 5 && (
                          <Button
                            className="mat-btn mat-save save-response"
                            variant="contained"
                            onClick={() => saveMonthData(next)}
                          >
                            Save
                          </Button>
                        )}
                        <button
                          className="nextbuttn nxt-response"
                          variant="contained"
                          onClick={() => {
                            setbutab(next);
                            changeDatadup(next);
                          }}
                        >
                          {next}
                        </button>
                      </>
                    )}

                    {butab === "Controllable" && (
                      <>
                        <button
                          className="nextbuttn nxt-response"
                          style={{ float: "left" }}
                          variant="contained"
                          onClick={() => {
                            setbutab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </button>
                        {items.Account_Type != 0 && items.Account_id == 5 && (
                          <Button
                            className="mat-btn mat-save save-response"
                            variant="contained"
                            onClick={() => saveMonthData(next)}
                          >
                            Save
                          </Button>
                        )}
                        <button
                          className="nextbuttn nxt-response"
                          variant="contained"
                          onClick={() => {
                            setbutab(next);
                            changeDatadup(next);
                          }}
                        >
                          {next}
                        </button>
                      </>
                    )}

                    {butab === "Non Controllable" && (
                      <>
                        <button
                          className="nextbuttn nxt-response"
                          style={{ float: "left" }}
                          variant="contained"
                          onClick={() => {
                            setbutab(prev);
                            changeDatadup(prev);
                          }}
                        >
                          {prev}
                        </button>
                        {items.Account_Type != 0 && items.Account_id == 5 && (
                          <Button
                            className="mat-btn mat-save save-response"
                            variant="contained"
                            onClick={() => saveMonthData(next)}
                          >
                            Save
                          </Button>
                        )}
                        <button
                          className="nextbuttn nxt-response"
                          variant="contained"
                          onClick={() => {
                            setbutab(next);
                            changeDatadup(next);
                          }}
                        >
                          {next}
                        </button>
                      </>
                    )}

                    {butab === "Profit/Loss" && (
                      <>
                        {console.log(prev, "Prevaaa")}

                        <button
                          className="nextbuttn nxt-response"
                          style={{ float: "left" }}
                          variant="contained"
                          onClick={() => {
                            setbutab("Non Controllable");
                            sethideCategoriesandConstant(false);
                            changeDatadup("Non Controllable");
                          }}
                        >
                          {"Non Controllable"}
                        </button>
                        {/* {(items.Account_Type!=0 && items.Account_id==5 || items.Account_Type==0 &&
                      <Button
                        className="mat-btn mat-save save-response save-end"
                        variant="contained"
                      >
                        Save
                      </Button>)} */}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {/*  */}


      {showpopup && (
      <Modal
       className="Recurring-popup"
       show={showpopup}
       size="large"
       onHide={handleClosepopup}
       backdrop="static"
       keyboard={false}
       style={{ paddingTop: "10em" }}
       //onClick={handleClosepopup}
      >
        <Modal.Header
          style={{
            border: "none",
            padding: "45px 45px 20px 45px",
            background: "#fff",
            borderRadius: "6px",
          }}
        >
          <Modal.Title
            style={{
              fontSize: "24px",
              textAlign: "left",
              color: "#000",
              background: "#fff",
              padding: "0px 0px 25px",
              borderBottom: "2px solid #BCBCBC",
            }}
          >
            Recurring Constant Expenses{" "}
            <i
              class="bi bi-x-circle-fill"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#24D153",
                fontSize: "23px",
                float: "right",
                marginTop: "-3px",
              }}
              onClick={handleClosepopup}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "28px",
          }}
        >
          <div class="row">
            {/* <div class="col-md-4 col-xs-5" style={{ top: "5px" }}>
                  <p>Enter New Category </p>
                </div> */}
            <div
              className="col-lg-6 col-md-6 col-sm-5 col-xs-12"
              style={{ textAlign: "left" }}
            >
              <input
                type="text"
                name="Details"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  height: "50px",
                  border: "1px solid #707070",
                  borderRadius: "3px",
                }}
                className="form-control"
                placeholder="Enter a New Category"
                value={adCategory}
                onChange={(e) => {
                  handleAdCategory(e.target.value);
                }}
              />
            </div>
            <div
              className="col-lg-3 col-md-3 col-sm-4 col-xs-6 radiobtn-col"
              style={{ textAlign: "left", lineHeight: "25px" }}
            >
              <div>
                <label
                  class="new-label-class"
                  style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}
                >
                  <input
                    name="budget_type"
                    required=""
                    type="radio"
                    class="new-input-class"
                    checked={checbox1}
                    value={"1"}
                    onChange={(e) => {
                      console.log(e.target.checked,"jjj")
                      setCheckbox1(e.target.checked);
                      setCheckbox2(false);

                    }}
                  />
                  Controllable
                </label>
              </div>
              <div>
                <label
                  class="new-label-class"
                  style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}
                >
                  <input
                    name="budget_type"
                    required=""
                    type="radio"
                    class="new-input-class"
                    checked={checbox2}
                    value={"2"}
                    onChange={(e) => {
                      setCheckbox2(e.target.checked);
                      setCheckbox1(false);
                    }}
                  />
                  Non Controllable
                </label>
              </div>

              {/* <div class="radio-item1">
                <input
                  type="radio"
                  id="ritema"
                  name="ritem"
                  checked={checbox1}
                  value={"1"}
                  onChange={(e) => {
                    setCheckbox1(e.target.checked);
                  }}
                />
                <label
                  for="ritema"
                  style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}
                >
                  Controllable
                </label>
              </div>
              <div class="radio-item1">
                <input
                  type="radio"
                  id="ritemb"
                  name="ritem"
                  checked={checbox2}
                  value={"2"}
                  onChange={(e) => {
                    setCheckbox2(e.target.checked);
                  }}
                />
                <label
                  for="ritemb"
                  style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}
                >
                  Non Controllable
                </label>
              </div> */}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 addbtn-col">
              <button
                disabled={
                  bossalldepart === 1 ||
                  (checbox1 === false && checbox2 === false)
                    ? true
                    : false
                }
                className="btn recur-add"
                style={{
                  marginLeft: "-0px",
                  background: "#24D153",
                  borderColor: "#24D153",
                  color: "#fff",
                  padding: "10px 25px 10px 20px",
                  fontSize: "18px",
                }}
                onClick={handleCat_Click}
              >
                <i
                  class="bi bi-plus-circle-fill"
                  aria-hidden="true"
                  style={{
                    marginLeft: "0.3em",
                    fontSize: "17px",
                    color: "#fff",
                  }}
                ></i>{" "}
                Add{" "}
              </button>
            </div>

            {/* <div
                  class="col-md-8 col-xs-7"
                  style={{ textAlign: "left"}}
                >
                  <div class="row">
                    <div class="col-md-12 col-xs-12">
                   
                    </div>
                    <div
                      class="col-md-12 col-xs-12"
                      style={{ padding: "0em", marginTop: "1em" }}
                    >
                      <div
                        class="col-md-4 col-xs-6"
                        style={{ paddingLeft: "0.5em" }}
                      >
                   
                      </div>
                      <div
                        class="col-md-8 col-xs-6"
                        style={{ textAlign: "left", padding: "0em" }}
                      >
                      
                      </div>
                    </div>
                    <div
                      class="col-md-12 col-xs-12"
                      style={{ paddingLeft: "2em", marginTop: "1em" }}
                    >
                  
                      <button
                        className="btn btn-mute"
                        onClick={handleCancelAdCat}
                      >
                        Cancel{" "}
                      </button>
                    </div>
                  </div>
                </div> */}
          </div>

          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div
                className="table-responsive table-scroll-budget"
                style={{
                  maxHeight: "300px",
                  marginTop: "10px",
                  border: "none",
                }}
              >
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th
                        className="scrollview popup-th"
                        style={{ paddingLeft: "0px" }}
                      >
                        <div className="tab-card-new-budgetpopup  admin-budget-th">
                          Controllable
                        </div>
                      </th>
                      <th>
                        <div className="tab-card-new-budgetpopup  admin-budget-th">
                          Budget Amount
                        </div>
                      </th>
                      <th colSpan={2}>
                        <div className="tab-card-new-budgetpopup  admin-budget-th">
                          Per(%)
                        </div>
                      </th>
                      {/* <th><div className="tab-card-new-budgetpopup" style={{backgroundColor:"white"}}/></th> */}
                    </tr>
                  </thead>
                  <tbody class="tab-gf">
                    {constantRecurring?.map((x, index) => {
                      return (
                        <>
                          {x.Cat_id == 0 ? (
                            <tr>
                              <td
                                className="labor-text"
                                style={{
                                  fontSize: "18px",
                                  textTransform: "capitalize",
                                  fontweight: "600",
                                }}
                              >
                                {x.Cat_name}
                              </td>
                              <td></td>
                              <td style={{ borderRight: "" }}></td>
                              <td></td>
                            </tr>
                          ) : null}
                          {x.Cat_id !== 0 &&
                          x.Cat_amt === "" &&
                          (x.Cat_id == 67 || x.Cat_id == 68) ? (
                            <tr>
                              <td className="tab-card-new-budgetpopup-val-1 admin-budget-td">
                                {" "}
                                <div class="">
                                  <label
                                    for={x.Cat_id}
                                    class="new-label-classf"
                                  >
                                    <input
                                      required=""
                                      type="radio"
                                      class="new-input-classf"
                                      style={{
                                        marginRight: "0.5em",
                                        cursor: "pointer",
                                      }}
                                      id={x.Cat_id}
                                      checked={x.Cat_per !== 0}
                                      name={x.Cat_id}
                                      onClick={(e) => {
                                        handleTaxCheckbox(
                                          e.target.checked,
                                          index,
                                          x
                                        );
                                      }}
                                    />{" "}
                                    {x.Cat_name}
                                  </label>
                                </div>
                              </td>
                              <td></td>
                              <td>
                                <p class="fl-r">
                                  <input
                                    class={
                                      bossalldepart === 0
                                        ? "input-edit admin-budget-td iput-edit-popup"
                                        : "non-edit-css admin-budget-td"
                                    }
                                    name="1"
                                    id="1"
                                    type="text"
                                    value={x.Cat_per || 0}
                                    onChange={(e) => {
                                      // if (
                                      //   (splUser == 0 || splUser == 1) &&
                                      //   Acc_type != 0
                                      // ) {
                                      handletaxper(e.target.value, x, index);
                                      // }
                                    }}
                                    fdprocessedid="6pqots"
                                  />
                                  <span
                                    className="admin-budget-td popup-per"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    %
                                  </span>
                                </p>
                              </td>
                              <td>
                                {x.Cat_id != 0 &&
                                x.Cat_id != 152 &&
                                x.Cat_id != 151 &&
                                x.Cat_id != 68 &&
                                x.Cat_id != 67 &&
                                x.Cat_id != 66 ? (
                                  // (splUser == 0 || splUser == 1) &&
                                  // Acc_type != 0
                                  <i
                                    disabled={
                                      bossalldepart === 1 ? true : false
                                    }
                                    class="fa fa-trash"
                                    aria-hidden="true"
                                    style={{
                                      color: "red",
                                      fontSize: "1.2em",
                                      padding: "1em",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (bossalldepart !== 1) {
                                        setdeleteItemvalue(x);
                                        setShowdeletepopup(true);
                                      }
                                    }}
                                  ></i>
                                ) : null}
                              </td>
                            </tr>
                          ) : null}

                          {x.Cat_id !== 0 &&
                          x.Cat_amt === "" &&
                          x.Cat_id !== 67 &&
                          x.Cat_id !== 68 ? (
                            <tr>
                              <td className="tab-card-new-budgetpopup-val-1 admin-budget-td">
                                {" "}
                                {x.Cat_name}
                              </td>
                              <td></td>
                              <td>
                                <p class="fl-r">
                                  <input
                                    class={
                                      bossalldepart === 0
                                        ? "input-edit admin-budget-td iput-edit-popup"
                                        : "non-edit-css admin-budget-td"
                                    }
                                    name="1"
                                    id="1"
                                    type="text"
                                    value={x.Cat_per || 0}
                                    onChange={(e) => {
                                      // if (
                                      //   (splUser == 0 || splUser == 1) &&
                                      //   Acc_type != 0
                                      // ) {
                                      handletaxper(e.target.value, x, index);
                                      // }
                                    }}
                                    fdprocessedid="6pqots"
                                  />
                                  <span
                                    className="admin-budget-td"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    %
                                  </span>
                                </p>
                              </td>
                              <td>
                                {x.Cat_id != 0 &&
                                x.Cat_id != 152 &&
                                x.Cat_id != 151 &&
                                x.Cat_id != 68 &&
                                x.Cat_id != 67 &&
                                x.Cat_id != 66 &&
                                (items.Member_Type == "4" ||
                                  ((splUser == 0 || splUser == 2) &&
                                    Acc_type == 0)) ? (
                                  //&&
                                  // (splUser == 0 || splUser == 1) &&
                                  // Acc_type != 0
                                  <i
                                    disabled={
                                      bossalldepart === 1 ? true : false
                                    }
                                    class="fa fa-trash"
                                    aria-hidden="true"
                                    style={{
                                      color: "red",
                                      fontSize: "1.2em",
                                      padding: "1em",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (bossalldepart !== 1) {
                                        setdeleteItemvalue(x);
                                        setShowdeletepopup(true);
                                      }
                                    }}
                                  ></i>
                                ) : null}
                              </td>
                            </tr>
                          ) : null}
                          {console.log(Acc_type,"cool")}
                          {x.Cat_id !== 0 &&  (
        (x.Cat_id !== 151 || (Acc_type !== 4 && Acc_type !== 5 && Acc_type !== 6 && Acc_type !== 9 && Acc_type !== 10)) &&
        (x.Cat_id !== 152 || (Acc_type !== 4 && Acc_type !== 5 && Acc_type !== 6 && Acc_type !== 9 && Acc_type !== 10))
    )  && 
                          x.Cat_amt !== "" &&
                          x.Cat_per !== "" ? (
                            <tr>
                              <td className="tab-card-new-budgetpopup-val-1 admin-budget-td">
                                {" "}
                                {x.Cat_name}
                              </td>
                              <td>
                                {" "}
                                <span className="admin-budget-td">$</span>
                                <p class="fl-r">
                                  <input
                                    class={
                                      bossalldepart === 0
                                        ? "input-edit admin-budget-td iput-edit-popup"
                                        : "non-edit-css admin-budget-td"
                                    }
                                    name="1"
                                    id="1"
                                    type="text"
                                    value={x.Cat_amt || 0}
                                    onChange={(e) => {
                                      if (
                                        // (splUser == 0 || splUser == 1) &&
                                        // Acc_type != 0
                                        true
                                      ) {
                                        handleChangeValue(
                                          e.target.value,
                                          x,
                                          index
                                        );
                                      }
                                    }}
                                  />
                                </p>
                              </td>
                              <td>
                                <p class="fl-r">
                                  <input
                                    class={
                                      bossalldepart === 0
                                        ? "input-edit admin-budget-td iput-edit-popup"
                                        : "non-edit-css admin-budget-td"
                                    }
                                    name="1"
                                    id="1"
                                    type="text"
                                    value={x.Cat_per || 0}
                                    onChange={(e) => {
                                      if (
                                        // (splUser == 0 || splUser == 1) &&
                                        // Acc_type != 0
                                        true
                                      ) {
                                        handleChangePer(
                                          e.target.value,
                                          x,
                                          index
                                        );
                                      }
                                    }}
                                  />
                                  <span
                                    className="admin-budget-td popup-per"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    %
                                  </span>
                                </p>
                              </td>
                              <td>
                                {x.Cat_id != 0 &&
                                x.Cat_id != 152 &&
                                x.Cat_id != 151 &&
                                x.Cat_id != 68 &&
                                x.Cat_id != 67 &&
                                x.Cat_id != 66 ? (
                                  <i
                                    disabled={
                                      bossalldepart === 1 ? true : false
                                    }
                                    class="fa fa-trash trash-icon"
                                    aria-hidden="true"
                                    style={{
                                      color: "#7B7B7B",
                                      fontSize: "1.2em",
                                      padding: "1em",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (bossalldepart !== 1) {
                                        setdeleteItemvalue(x);
                                        setShowdeletepopup(true);
                                      }
                                    }}
                                  ></i>
                                ) : null}
                              </td>
                            </tr>
                          ) : null}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ textAlign: "center", marginRight: "0em", border: "none" }}
        >
          <button
            disabled={bossalldepart === 1 ? true : false}
            className="btn btn-success popup-save"
            onClick={(e) => {
              if (
                //   (splUser == 0 || splUser == 1) &&
                // Acc_type != 0
                true
              ) {
                if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
                  handleSaveRecurring(e, 0);
                } else {
                  handleSaveRecurring(e, 1);
                }
              }
            }}
            style={{
              marginRight: "0.5em",
              background: "#24D153",
              borderColor: "#24D153",
            }}
          >
            Save
            {/* <i class="fa fa-thumbs-o-up" aria-hidden="true"></i> */}
          </button>
          <button
            className="btn btn-mute popup-cancel"
            onClick={handleClosepopup}
          >
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>
      )}
      {/* Delete Popup */}
      {/* <Modal className="warning1" show={showdeletepopup} size="sm" onHide={handleClosedeletepopup}>
       <Modal.Header style={{ border: "none" ,padding:'45px 45px 20px 45px',background:'#fff',borderRadius:'6px'}}>
          <Modal.Title
            style={{
              fontSize:'22px',
              textAlign: "left",
              color: "#000",
              background: "#fff",
              padding: "0px 0px 25px",
              borderBottom:'2px solid #BCBCBC'
            }}
          >
            Warning{" "}
            <i
              class="bi bi-x-circle-fill"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#24D153",
                fontSize: "23px",
                float: "right",
                marginTop: "-3px",
              }}
              onClick={handleClosepopup}
            ></i>
          </Modal.Title>
        </Modal.Header>

         <Modal.Body
          style={{
            color:'#9d9d9d',
            textAlign: "left",
            fontSize: "18px",
            padding: "15px 30px",
          }}
        >
          {" "}
          Are you sure you want to delete?
        </Modal.Body>

        <Modal.Footer
           style={{ textAlign: "center", padding:'5px 30px 25px', border: "none" }}
        >
<div style={{display:'flex',gap:'5px',justifyContent:'center'}}>
        <button
            className="col btn btn-success confirm-btn"
            onClick={handleClosedeletepopupConfirm}
          >
            {" "}
            Confirm{" "}
          </button>
                    <button
            className="col btn cancel-btn"
            onClick={handleClosedeletepopup}
            style={{ marginRight: "0.5em" }}
          >
            {" "}
            Cancel{" "}
          </button>
</div>
  
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={showdeletepopup}
        size="sm"
        onHide={handleClosedeletepopup}
        className="delete-popup"
      >
        <Modal.Header
          style={{
            border: "none",
            borderRadius: "5px 5px 0px 0px",
            background: "#FFBC00",
            padding: "25px 10px 20px 10px",
          }}
        >
          <Modal.Title
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ color: "#fff", fontSize: "24px" }}>
              {" "}
              <i
                class="bi bi-exclamation-triangle"
                style={{ color: "#fff", fontSize: "25px" }}
              ></i>{" "}
              <span style={{ fontSize: "24px" }}>Warning</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "10px 30px",
          }}
        >
          {" "}
          <h1
            style={{
              fontSize: "16px",
              fontweight: "500",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            Are you sure you want to delete?
          </h1>
        </Modal.Body>
        <Modal.Footer
          style={{
            textAlign: "center",
            marginRight: "0em",
            border: "none",
            padding: "0px 15px 25px 15px",
          }}
        >
          <button
            className="btn btn-danger"
            onClick={handleClosedeletepopup}
            style={{
              marginRight: "0.5em",
              backgroundColor: "#FF0C00",
              borderColor: "#FF0C00",
            }}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            style={{ backgroundColor: "#24D153", borderColor: "#24D153" }}
            className="btn btn-success"
            onClick={handleClosedeletepopupConfirm}
          >
            {" "}
            Confirm{" "}
          </button>
        </Modal.Footer>
      </Modal>

      {/* <Modal className="warning1" show={deletecat} size="sm" onHide={handleclosedeletecat}>
      <Modal.Header style={{ border: "none" ,padding:'45px 45px 20px 45px',background:'#fff',borderRadius:'6px'}}>
          <Modal.Title
            style={{
              fontSize:'22px',
              textAlign: "left",
              color: "#000",
              background: "#fff",
              padding: "0px 0px 25px",
              borderBottom:'2px solid #BCBCBC'
            }}
          >
            Warning{" "}
            <i
              class="bi bi-x-circle-fill"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#24D153",
                fontSize: "23px",
                float: "right",
                marginTop: "-3px",
              }}
              onClick={handleclosedeletecat}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            color:'#9d9d9d',
            textAlign: "left",
            fontSize: "18px",
            padding: "15px 30px",
          }}
        >
          {" "}
          Are you sure you want to delete the category?
        </Modal.Body>
        <Modal.Footer
          style={{ textAlign: "center", padding:'5px 30px 25px', border: "none" }}
        >
 <div style={{display:'flex',gap:'5px',justifyContent:'center'}}>
         <button className="col btn btn-success confirm-btn" onClick={handleDeletecat}>
            {" "}
            Confirm{" "}
          </button>
                   <button
            className="col btn btn-danger cancel-btn"
            onClick={handleclosedeletecat}
            style={{ marginRight: "0.5em" }}
          >
            {" "}
            Cancel{" "}
          </button>
 </div>
 
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={deletecat}
        size="sm"
        onHide={handleclosedeletecat}
        className="delete-popup delete-popup1"
      >
        <Modal.Header
          style={{
            border: "none",
            borderRadius: "5px 5px 0px 0px",
            background: "#FFBC00",
            padding: "25px 10px 20px 10px",
          }}
        >
          <Modal.Title
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ color: "#fff", fontSize: "24px" }}>
              {" "}
              <i
                class="bi bi-exclamation-triangle"
                style={{ color: "#fff", fontSize: "25px" }}
              ></i>{" "}
              Warning
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "10px 30px",
          }}
        >
          {" "}
          <h1
            style={{
              fontSize: "16px",
              fontweight: "500",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            Are you sure you want to delete the category?
          </h1>
        </Modal.Body>
        <Modal.Footer
          style={{
            textAlign: "center",
            marginRight: "0em",
            border: "none",
            padding: "0px 15px 25px 15px",
          }}
        >
          <button
            className="btn btn-danger"
            onClick={handleclosedeletecat}
            style={{
              marginRight: "0.5em",
              backgroundColor: "#FF0C00",
              borderColor: "#FF0C00",
            }}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            className="btn btn-success"
            onClick={handleDeletecat}
            style={{ backgroundColor: "#24D153", borderColor: "#24D153" }}
          >
            {" "}
            Confirm{" "}
          </button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={revdel}
        size="sm"
        onHide={handlecloserevamtzero}
        className="delete-popup delete-popup1"
      >
        <Modal.Header
          style={{
            border: "none",
            borderRadius: "5px 5px 0px 0px",
            background: "#FFBC00",
            padding: "25px 10px 20px 10px",
          }}
        >
          <Modal.Title
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ color: "#fff", fontSize: "24px" }}>
              {" "}
              <i
                class="bi bi-exclamation-triangle"
                style={{ color: "#fff", fontSize: "25px" }}
              ></i>{" "}
              Warning
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "10px 30px",
          }}
        >
          {" "}
          <h1
            style={{
              fontSize: "16px",
              fontweight: "500",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            Please zero out this category before deleting it. 
          </h1>
        </Modal.Body>
        <Modal.Footer
          style={{
            textAlign: "center",
            marginRight: "0em",
            border: "none",
            padding: "0px 15px 25px 15px",
          }}
        >
          <button
            className="btn btn-success"
            onClick={handlecloserevamtzero}
            style={{
              marginRight: "0.5em",
              backgroundColor: "#24D153",
              borderColor: "#24D153",
            }}
          >
            {" "}
            OK{" "}
          </button>
        
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={lockpopshow}
        size="sm"
        onHide={handlelockbudget}
        className="delete-popup delete-popup1"
      >
        <Modal.Header
          style={{
            border: "none",
            borderRadius: "5px 5px 0px 0px",
            background: "#FFBC00",
            padding: "25px 10px 20px 10px",
          }}
        >
          <Modal.Title
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ color: "#fff", fontSize: "24px" }}>
              {" "}
              <i
                class="bi bi-exclamation-triangle"
                style={{ color: "#fff", fontSize: "25px" }}
              ></i>{" "}
              Warning
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "10px 30px",
          }}
        >
          {" "}
          <h1
            style={{
              fontSize: "16px",
              fontweight: "500",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
           {yearlock==0 || yearlock ==null ? "Are you sure you want to lock this budget" : "Are you sure you want to unlock this budget"} 
          </h1>
        </Modal.Body>
        <Modal.Footer
          style={{
            textAlign: "center",
            marginRight: "0em",
            border: "none",
            padding: "0px 15px 25px 15px",
          }}
        >
          <button
            className="btn btn-success"
            onClick={yearlockonandoff}
            style={{
              marginRight: "0.5em",
              backgroundColor: "#24D153",
              borderColor: "#24D153",
            }}
          >
            {" "}
            OK{" "}
          </button>

          <button
            className="btn btn-danger"
            onClick={handlelockbudget}
            style={{
              marginRight: "0.5em",
              backgroundColor: "#FF0C00",
              borderColor: "#FF0C00",
            }}
          >
            {" "}
            Cancel{" "}
          </button>
        
        </Modal.Footer>
      </Modal> */}

<Modal
    show={lockpopshow}
    size="sm"
    onHide={handlelockbudget}
    className="delete-popup1"
>
    <Modal.Header
        style={{
            border: "none",
            borderRadius: "5px 5px 0px 0px",
            background: "#FFBC00",
            padding: "25px 10px 20px 10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        <Modal.Title
            style={{
                textAlign: "center",
            }}
        >
            <div style={{ color: "#fff", fontSize: "24px" }}>
                <i
                    className="bi bi-exclamation-triangle"
                    style={{ color: "#fff", fontSize: "25px" }}
                ></i>
                {" "}Warning
            </div>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body
        style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "10px 30px",
        }}
    >
        <h1
            style={{
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "15px",
                marginBottom: "15px",
                lineHeight:"25px",
            }}
        >
            {yearlock === 0 || yearlock == null
                ? "Are you sure you want to lock this budget?"
                : "Please note that by making any edits to the yearly budget, your monthly budgets will be reset accordingly."}
        </h1>
    </Modal.Body>
    <Modal.Footer
        style={{
            textAlign: "center",
            border: "none",
            padding: "0px 15px 25px 15px",
        }}
    >
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <button
                className="btn btn-success"
                onClick={yearlockonandoff}
                style={{
                    width: "40%", // Adjust the width here
                    backgroundColor: "#24D153",
                    borderColor: "#24D153",
                    marginRight: "5px", // Space between buttons
                }}
            >
                {yearlock==0|| yearlock==null ? "Ok":"Yes, I understand."}
            </button>

            <button
                className="btn btn-danger"
                onClick={handlelockbudget}
                style={{
                    width: "40%", // Adjust the width here
                    backgroundColor: "#FF0C00",
                    borderColor: "#FF0C00",
                    marginLeft: "5px", // Space between buttons
                }}
            >
                Cancel
            </button>
        </div>
    </Modal.Footer>
</Modal>


      <div
        class="modal fade"
        id="changemodal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog revenue-popup" role="document">
          <div
            class="modal-content"
            className="revenue-modal"
            style={{
              borderRadius: "6px",
              backgroundColor: "#fefefe",
              margin: "20% auto",
              padding: "0px",
              border: "1px solid #888",
            }}
          >
            {/* <div class="modal-header" style={{background:'#fff'}}>
              <h4
                class="modal-title"
                id="exampleModalLabel"
                style={{ color: "#000" ,fontSize:'24px'}}
              >
                Revenue
              </h4>
              <button
                type="button"
                class="close modaloneclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </button>
            </div> */}

            {/* <div
                  class="modal-header"
                  style={{
                    border: "none",
                    backgroundColor: "#fff",
                    padding: "40px 45px 20px 45px",
                    borderRadius:'6px'
                  }}
                >
                  <button
                  style={{marginRight:'30px',marginTop:'25px'}}
                    type="button"
                    class="close modaloneclose revenue-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  
                  >
                    &times;
                  </button>
                  <h3
                    class="modal-title text-center"
                    id="exampleModalLabel"
                    style={{ color: "#000",textAlign:'left',fontSize:'24px'}}
                  >
                    Revenue
                  </h3>
                </div> */}
            <div
              class="col-xs-12 modal-header"
              style={{
                border: "none",
                backgroundColor: "#fff",
                padding: "40px 45px 40px 45px",
                borderRadius: "6px",
                zIndex: "1",
              }}
            >
              <i
                class="bi bi-x-circle-fill"
                aria-hidden="true"
                data-dismiss="modal"
                style={{
                  cursor: "pointer",
                  color: "#24D153",
                  fontSize: "23px",
                  float: "right",
                  marginTop: "-25px",
                  marginRight: "-25px",
                }}
                onClick={handleClosepopup}
              ></i>
              <h3
                class="modal-title text-center"
                id="exampleModalLabel"
                style={{
                  color: "#000",
                  textAlign: "left",
                  fontSize: "24px",
                  borderBottom: "2px solid #BCBCBC",
                  paddingBottom: "20px",
                }}
              >
                Revenue
              </h3>
            </div>
            <div class="modal-body" style={{ padding: "0px 28px" }}>
              <div>
                <div>
                  <h2
                    className="cat-popup-text"
                    style={{
                      textAlign: "left",
                      color: "#9D9D9D",
                      fontSize: "16px",
                    }}
                  >
                    Drag and Drop Categories to Change Order
                  </h2>
                  <div
                    id="popupContainer"
                    style={{ paddingTop: "1px", paddingBottom: "1px" }}
                  >
                    <ul
                      id="sortable"
                      style={{
                        marginTop: "25px",
                        overflowX: "hidden",
                        overflowY: "scroll",
                        height: "180px",
                        marginBottom: "25px",
                      }}
                    >
                      {list.map((item, index) => (
                        <li
                          style={{
                            marginTop: "10px",
                            cursor: "pointer",
                            marginBottom: "10px",
                          }}
                          key={item.Cat_id}
                          draggable={true}
                          onDragStart={(e) => onDragStart(e, item.Cat_id)}
                          onDrop={(e) => onDrop(e, index)}
                          onDragOver={(e) => onDragOver(e)}
                        >
                          <div className="row">
                            <div className="col-md-8 col-xs-6">
                              {item.Cat_name}
                            </div>
                            <div
                              className="col-md-4 col-xs-6"
                              style={{ textAlign: "right" }}
                            >
                              {/* <i class="bi bi-arrow-down-up" style={{ color: "#fff", fontSize:'15px'}}></i> */}
                              <img
                                src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/up-down-img.png"
                                style={{ width: "7%" }}
                                className="up-down-img"
                              ></img>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              style={{ border: "none", padding: "30px" }}
            >
              <div className="row">
                <div
                  className="col-md-6 col-xs-6"
                  style={{ textAlign: "left" }}
                >
                  {/* <a
                    className="add-cat-i"
                    style={{
                      fontSize: "17px",
                      color: "#24D153",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    <i
                      className="bi bi-plus-circle-fill"
                      style={{
                        color: "#24D153",
                        position: "relative",
                        fontSize: "23px",
                        top: "2px",
                      }}
                    ></i>
                    Add a Category
                  </a> */}
                </div>
                <div className="col-md-6 col-xs-6">
                  <button
                    type="submit"
                    class="btn btn-success"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#24d153",
                      fontSize: "15px",
                      borderColor: "#24d153",
                      padding: "10px 18px 10px 18px",
                      borderRadius: "2px",
                    }}
                    onClick={handleSaveOrders}
                  >
                    Save Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Tab1;
