import 'bootstrap/dist/css/bootstrap.min.css'
import './TableComponent.css'
import Table from 'react-bootstrap/Table'
import { useState, useEffect } from 'react'
import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import CurrencyFormat from 'react-currency-format'

const MonthlyViewTable = ({
  monthIndex,
  editMonthTotal,
  showtotal,
  selectDepartment,
  editMonthData,
  editMonthDatapercentage,
  error,
  menuId,
  data,
  title,
  isBudget,
  editMonthabs,
  editMonthabsblur,
  editMonthperabsblur,
  editMonthperabs,
  editMonthTotalabs,
  editMonthTotalabsblur
}) => {
  const [items, setitems] = useState(
    JSON.parse(localStorage.getItem('UserClaims'))
  )

  //console.log("Revmonthprofit", data.list[0].push(data.list[0].shift()))

  console.log(title, 'Title')
  //  if(items.Account_Type==4 ||items.Account_Type==5||items.Account_Type==6||items.Account_Type==9||items.Account_Type==10 )

  return (
    <>
      {!error ? (
        <div className='table-scroll table-responsive table2 table-revenue'>
          <Table className='monthlytabbud' striped bordered hover>
            <thead>
              <tr>
                <th
                  style={{ width: '30%' }}
                  className='tab-card-newbud monthly-border  sticky-title'
                >
                  <div className=' admin-budget-th'> {title} </div>
                </th>
                {/* {data?.map(item =>

                <th colSpan={2}><div className='tab-card-new admin-budget-th'>{item.Month}</div></th>
              )} */}
                {data?.map(item => (
                  <>
                    <th className='tab-card-newbud monthly-border'>
                      <div
                        className=' admin-budget-th'
                        style={{ width: '200px' }}
                      >
                        {item.Month}{' '}
                      </div>
                    </th>
                    <th className='tab-card-newbud monthly-border'>
                      <div className=' admin-budget-th'>Per(%)</div>
                    </th>
                  </>
                ))}
              </tr>

              {/* <tr>
             

            </tr> */}
            </thead>
            <tbody>
              {data[0]?.List.map((item, index) => {
                let temp = []
                data?.map(x => {
                  temp.push({ ...x.List[index] })
                })
                if (
                  (items.Account_Type === 4 || items.Account_Type === 5 || items.Account_Type === 9 || items.Account_Type === 10) &&
                  (item.Cat_Name === 'Rent' || item.Cat_Name === 'CAM Charges')
                ) {
                  return null;
                }

                return (
                  <tr>
                    <td className='admin-budget-td sticky-column'>{temp[0].Cat_Name}</td>{' '}
                    {temp.map((cat, index) => {
                      return (
                        <>
                          <td className='admin-budget-td'>
                            <span className='fl-lf admin-budget-td admin-budget-ipad admin-budget-medium'>
                              $
                            </span>{' '}
                            <p className='fl-r'>
                              <CurrencyFormat
                                className={
                                  cat.Editable_Status == 1 &&
                                  items?.Member_Type != 4 &&
                                  items.Account_Type !== 0
                                    ? 'input-edit input-edit-mlab admin-budget-td'
                                    : 'non-edit-cs admin-budget-td'
                                }
                                // className={cat.Editable_Status==1 && items?.Member_Type!=4 && items.Account_Type!==0?'input-edit admin-budget-td':'non-edit-cs admin-budget-td'}

                                //className='non-edit-cs admin-budget-td'
                                onClick={() => {
                                  editMonthabs(
                                    cat.Cat_id,
                                    false,
                                    monthIndex + index + 1,
                                    menuId
                                  )
                                }}
                                onBlur={() => {
                                  editMonthabsblur(
                                    cat.Cat_id,
                                    false,
                                    monthIndex + index + 1,
                                    menuId
                                  )
                                }}
                                decimalScale={cat.abs ? 2 : undefined}
                                name={cat.Cat_id}
                                id={cat.Cat_id}
                                fixedDecimalScale={cat.abs ? true : false}
                                style={{ color: cat.Cat_amt < 0 ? 'red' : '' }}
                                prefix={cat.Cat_amt < 0 && cat.abs ? '(' : ''}
                                suffix={cat.Cat_amt < 0 && cat.abs ? ')' : ''}
                                // disabled={selectDepartment!=="All Department"? false : true}
                                disabled={
                                  cat.Editable_Status == 1 &&
                                  items?.Member_Type != 4 &&
                                  items.Account_Type != 0
                                    ? false
                                    : true
                                }
                                readOnly={cat.abs ? true : false}
                                value={
                                  cat.abs ? Math.abs(cat.Cat_amt) : cat.Cat_amt
                                }
                                //  value = {cat.abs ? (cat.Cat_amt !== 0 ? Math.abs(cat.Cat_amt) : "0.00") : cat.Cat_amt}

                                //thousandSeparator={true}

                                onValueChange={(value, name) =>
                                  editMonthData(
                                    cat.Cat_id,
                                    value,
                                    monthIndex + index + 1,
                                    menuId
                                  )
                                }
                                thousandSeparator={true}
                              />
                              {/* <CurrencyInput
                      className='input-edit-monthly'
                                        id={cat.Cat_id}
                                        name={cat.Cat_id}
                                        defaultValue={cat.Cat_amt}
                                        decimalsLimit={2}
                                        disabled={ ((items?.Special_user==1||items?.Special_user==0) && items?.Account_Type!=0) ? false : true}
                                        onValueChange={(value, name) => changeData(cat.Cat_id, value)}
                                    /> */}
                            </p>
                          </td>
                          <td className='admin-budget-td'>
                            <p className='fl-r span-perc per-medium'>
                              <CurrencyFormat
                                className={
                                  cat.Editable_Status == 1 &&
                                  items?.Member_Type != 4 &&
                                  (items?.Account_Type == 1 ||
                                    items?.Account_Type == 2 ||
                                    items?.Account_Type == 3 ||  items?.Account_Type == 6 || items?.Account_Type == 11 )  && (menuId != "Revenue")
                                    ? 'input-edit admin-budget-td'
                                    : 'non-edit-cs admin-budget-td'
                                }
                                //className='non-edit-cs admin-budget-td'    //input-edit admin-budget-td

                                onClick={() => {
                                  editMonthperabs(
                                    cat.Cat_id,
                                    false,
                                    monthIndex + index + 1,
                                    menuId
                                  )
                                }}
                                onBlur={() => {
                                  editMonthperabsblur(
                                    cat.Cat_id,
                                    false,
                                    monthIndex + index + 1,
                                    menuId
                                  )
                                }}
                                decimalScale={cat.abs ? 2 : undefined}
                                name={cat.Cat_id}
                                id={cat.Cat_id}
                                fixedDecimalScale={cat.abs ? true : false}
                                style={{ color: cat.Cat_Per < 0 ? 'red' : '' }}
                                readOnly={cat.abs ? true : false}
                                prefix={cat.Cat_Per < 0 && cat.abs ? '(' : ''}
                                suffix={cat.Cat_Per < 0 && cat.abs ? ')' : ''}
                                //value={cat.Cat_per}
                                value={
                                  cat.abs
                                    ? cat.Cat_per != 0
                                      ? Math.abs(cat.Cat_Per)
                                      : '0.00'
                                    : cat.Cat_Per
                                }
                                // value={ cat.abs ?  Math.round(cat.Cat_per) : Math.round(cat.Cat_per)}

                                disabled={
                                  (items?.Special_user == 1 ||
                                    items?.Special_user == 0) &&
                                  (items?.Account_Type == 1 ||
                                    items?.Account_Type == 2 ||
                                    items?.Account_Type == 3 ||  items?.Account_Type == 6 || items?.Account_Type == 11 ) && (menuId != "Revenue") &&
                                  cat.Editable_Status == 1
                                    ? false
                                    : true
                                }
                                //  disabled={ ((items?.Special_user==1||items?.Special_user==0) && items?.Account_Type!=0 &&cat.Editable_Status==1)? false : true}
                                //disabled={cat.Editable_Status==1 ? false:true}

                                thousandSeparator={true}
                                onValueChange={(value, name) =>
                                  editMonthDatapercentage(
                                    cat.Cat_id,
                                    value,
                                    monthIndex + index + 1,
                                    menuId
                                  )
                                }
                              />
                              {/* <CurrencyInput
                      className='input-edit-monthly'
                                        id={cat.Cat_id}
                                        name={cat.Cat_id}
                                        defaultValue={cat.Cat_Per}
                                        decimalsLimit={2}
                                        // disabled={(cat.Editable_Status == 1 ) ? false : true}
                                        onValueChange={(value, name) => changeData(cat.Cat_id, value)}
                                    /> */}
                              <span className='fl-ri ad-per-budget-medium'>
                                %
                              </span>
                            </p>
                          </td>
                        </>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
            {showtotal && (
              <tfoot>
                {console.log(title, items?.Account_Type, 'Tittile')}

                <tr>
                  <td className='admin-budget-td ipad-total sticky-footer'>TOTAL</td>
                  {data.map((item, index) => (
                    <>
                      <td>
                        {' '}
                        <span className='fl-lf admin-budget-td admin-budget-ipad admin-budget-medium'>
                          $
                        </span>
                        <p className='fl-r'>
                          <CurrencyFormat
                            className={
                              // items?.Account_Type !== 0 &&
                              // items.Account_Type != 4 &&
                              // items.Account_Type != 5 &&
                              // items.Account_Type != 6 &&
                              // items.Account_Type != 9 &&
                              // items.Account_Type != 10
                              //? "input-edit input-edit-mlab admin-budget-td"
                              //:
                              'non-edit-cs admin-budget-td'
                            }
                            style={{ color: item.Total < 0 ? 'red' : '' }}
                            prefix={item.Total < 0 && !item.abs ? '(' : ''}
                            suffix={item.Total < 0 && !item.abs ? ')' : ''}
                            onClick={() => {
                              editMonthTotalabs(
                                item.Total,
                                false,
                                monthIndex + index + 1,
                                menuId
                              )
                            }}
                            onBlur={() => {
                              editMonthTotalabsblur(menuId)
                            }}
                            readOnly={!item.abs ? true : false}
                            //className={ ((items?.Special_user==1||items?.Special_user==0 ) && items?.Account_Type!=0 && title=='Revenue' || title=='Turf Care Expenses' )  ? 'input-edit':'non-edit-cs' }

                            value={
                              !item.abs ? Math.abs(item.Total) : item.Total
                            }
                            fixedDecimalScale={!item.abs ? true : false}
                            onValueChange={(value, name) =>
                              editMonthTotal(
                                item.Total,
                                value,
                                monthIndex + index + 1,
                                menuId
                              )
                            }
                            thousandSeparator={true}
                            decimalScale={item.abs ? undefined : 2}
                            disabled={true}
                            // items?.Member_Type == 4 ||
                            // items?.Account_Type == 0 ||
                            // menuId == "Controllable" ||
                            // menuId == "Cost Of Goods" ||
                            // menuId == "Non Controllable" ||
                            // menuId == "Labor" ||
                            // items.Account_Type == 4 ||
                            // items.Account_Type == 5 ||
                            // items.Account_Type == 6 ||
                            // items.Account_Type == 9 ||
                            // items.Account_Type == 10
                            //   ? true
                            //   : false
                            // prefix={"$"}
                          />
                        </p>
                      </td>
                      <td>
                        <p className='fl-r per-medium ipad-percc tfoot-perc'>
                          <CurrencyFormat
                            className='non-edit-per-cs admin-budget-td'
                            style={{
                              color: item.Month_totper < 0 ? 'red' : ''
                            }}
                            prefix={item.Month_totper < 0 ? '(' : ''}
                            suffix={item.Month_totper < 0 ? ')' : ''}
                            value={Math.abs(item.Month_totper)}
                            fixedDecimalScale={2}
                            //disabled={(items?.Member_Type==4 ||items?.Account_Type==0  || title=='Controllable'||title=='Cost Of Goods' || title=='Non Controllable' || title=='Labor')?true:false}
                            disabled={true}
                            decimalScale={2}
                          />{' '}
                          <span className='fl-ri admin-budget-td ad-per-budget-medium'>
                            %
                          </span>
                        </p>
                      </td>
                    </>
                  ))}
                </tr>
              </tfoot>
            )}
          </Table>
        </div>
      ) : null}
    </>
  )
}
export default MonthlyViewTable
