import "../snapshot/Dashboard.css";
import SnapshotTable1 from "../Component/Tables/SnapshotDashboardTable/SnapshotTable1";
import SnapshotTable2 from "../Component/Tables/SnapshotDashboardTable/SnapshotTable2";
import Weather from "../Component/WeatherReport/Weather";
import axios from "axios";
import { useEffect, useState } from 'react';
import { useRef } from "react";
import { mainAxios, FobeAxios } from '../middleware/interceptor';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { Spinner } from '../Component/Spinner';
import SnapshotTable2_NonBinded from "../Component/Tables/SnapshotDashboardTable/SnapshotTable2-Nonbinded";
import Dropdown from 'react-bootstrap/Dropdown';
import Moment from "moment";
import { DateRange } from "react-date-range";
const sample1 = {
  Rounds_mtd: [
    { Roundsmtd: 0, Roundsbudget: 0, Roundsvarience: 0, Roundsvarienceper: 0 },
  ],
  Revenue_mtd: {
    MTD: [
      {
        Name: "Income",
        mtd: 0.00,
        budget: 0.00,
        variance: 0.00,
        variance_per:0.00,
      },
      {
        Name: "Labor",
        mtd: 0.00,
        budget: 0.00,
        variance: 0.00,
        variance_per: 0.00,
      },
    ],
  },
  Opentobuy: [
    {
      id: 1,
      catname: "Inside Golf Ops Hourly",
      mtdbuget: 0.00,
      mtdactual: 0.00,
      balance: 0.00,
    },

    {
      id: 1,
      catname: "Outside Golf Ops Hourly",
      mtdbuget: 0.00,
      mtdactual: 0.00,
      balance: 0.00,
    },

    {
      id: 2,
      catname: "Golf Merchandise",
      mtdbuget: 0.00,
      mtdactual: 0.00,
      balance: 0.00,
    },

    {
      id: 3,
      catname: "Repairs & Maintenance",
      mtdbuget: 0.00,
      mtdactual: 0.00,
      balance: 0.00,
    },

    { id: 3, catname: "Supplies", mtdbuget: 0.00, mtdactual: 0.00, balance: 0.00 },

    { id: 3, catname: "Uniforms", mtdbuget: 0.00, mtdactual: 0.00, balance: 0.00 },

    {
      id: 3,
      catname: "Travel and Entertainment",
      mtdbuget: 0.00,
      mtdactual: 0.00,
      balance: 0.00,
    },

    { id: 3, catname: "Utilities", mtdbuget: 0.00, mtdactual: 0.00, balance: 0.00 },

    { id: 3, catname: "Pest Control", mtdbuget: 0.00, mtdactual: 0.00, balance: 0.00 },
  ],


  OpentobuyExpense: [
    {

      Department: "",
      mtdbuget: 0.00,
      mtdactual: 0.00,
      balance: 0.00
    },


  ],
};

const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


//let sample

const Dashboard = ({ dropdowndata, tabs }) => {

  const [selectedRange, setSelectedRange] = useState(Moment(new Date()).format("LL") + " - " + Moment(new Date()).format("LL"));

  const [sample, setsample] = useState(sample1);
  // const [loader, setloader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dropdown, setDropdown] = useState([]);
  const today = new Date();
  const date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const containerRef = useRef(null);
  const [datepickershow, setdatepickershow] = useState(false);
  const [s1date, setsdate] = useState(date);
  const [e1date, setedate] = useState(date);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),

      key: "selection",
    },
  ]);
  const handleClick = (e) => {
    //console.log("aamada",containerRef)

    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setdatepickershow(false);
    }
  };
  //get current date

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const datepicker = (e) => {
    console.log(e, "eeee");
    console.log(e[0].startDate, "k");
    console.log(e[0].endDate, "k");
    var date = new Date(e[0].startDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    console.log([date.getFullYear(), mnth, day].join("-"), "formatted date");
    setsdate([date.getFullYear(), mnth, day].join("-"));
    var datee = new Date(e[0].endDate),
      mnth1 = ("0" + (datee.getMonth() + 1)).slice(-2),
      day1 = ("0" + datee.getDate()).slice(-2);
    console.log(
      [datee.getFullYear(), mnth1, day1].join("-"),
      "formatted staetdate"
    );
    setedate([datee.getFullYear(), mnth1, day1].join("-"));

    setSelectedRange(
      Moment([date.getFullYear(), mnth, day].join("-")).format("LL") +
      " - " +
      Moment([datee.getFullYear(), mnth1, day1].join("-")).format("LL")
    );

  };

  let sdate;
  let edate;

  const encodedString = btoa(dropdowndata);

  useEffect(() => {


    setLoading(true);
    console.log('date', date);

    mainAxios
      .get("Api/Hampton/getSnapshotChartData_daterange/" + s1date + '/' + e1date + '/' + encodedString)
      .then((resp) => {
        //console.log(resp.data)
        // sample=resp.data
        if (resp) {
          setLoading(false);
          // setloader(true)
          console.log("test", resp.data);
          setsample(resp.data);
          { console.log(sample, "sample") }
        }
      });
  }, [dropdowndata, tabs, s1date, e1date]);


  const [selectedDepartment_active, setselectedDepartment_active] = useState(null)
  const setPeriod = (id) => {


    const today = new Date();
    if (id === 1) {
      console.log(id, "Today")
      sdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      edate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setSelectedRange(Moment(new Date()).format('LL') + ' - ' + Moment(new Date()).format('LL'));
      console.log(sdate + "-" + edate, "Today");
      setsdate(sdate)
      setedate(edate)
    }
    if (id === 2) {

      var date = new Date();
      date.setDate(date.getDate() - 1);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      edate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(selectedRange, "yesterday")

      console.log(sdate + "-" + edate, "yesterday")
      setsdate(sdate)
      setedate(edate)
    }
    else if (id === 3) {
      var date = new Date();
      date.setDate(date.getDate() - 7);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      edate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));

      console.log(sdate + "-" + edate, "7dyas")
      setsdate(sdate)
      setedate(edate)
    } else if (id === 4) {
      var date = new Date();
      date.setDate(date.getDate() - 30);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      edate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(selectedRange, "Today")

      setsdate(sdate)
      setedate(edate)

    }

    else if (id === 5) {

      var date = new Date();
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + 1;
      edate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(selectedRange, "Last month")
      setsdate(sdate)
      setedate(edate)
    } else if (id === 6) {
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      sdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + 1;
      edate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      setSelectedRange(Moment(sdate).format('LL') + ' - ' + Moment(edate).format('LL'));
      console.log(selectedRange, "Today")
      setsdate(sdate)
      setedate(edate)

    }

  }


  return (
    <>
      {/* <FerrisWheelSpinner loading={loading}　size={28} /> */}

      <div className="R-list-glance">
        <div className='col-md-12 R-list-g'>
          <div className='container-d'>
            <div className="row">
              <div className="col-md-5 col-sm-5 col-lg-4">
                {/* <h4 style={{ textAlign: 'left' }}>Select Time Period : </h4> */}
                <div className='p&l-Dropdown'>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-autoclose-outside" className='drop-ml fordropglance datepicker-dropml' variant="secondary">
                      <i class="bi bi-calendar3 calendor-icon"></i> <span className="cal-text">{selectedRange}</span>
                      <i style={{ color: '#24D153', fontWeight: '600', fontSize: '15px' }} class="fa fa-angle-down icon-drop-pl"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant="dark" style={{ width: '95%', position: 'absolute' }}>
                      {/* <Dropdown.Item  active>
    Today
    </Dropdown.Item> */}
                      <Dropdown.Item onClick={() => setPeriod(1)} className={selectedDepartment_active === 1 ? 'active' : ""}>Today</Dropdown.Item>
                      <Dropdown.Item onClick={() => setPeriod(2)} className={selectedDepartment_active === 2 ? 'active' : ""}>Yesterday</Dropdown.Item>
                      <Dropdown.Item onClick={() => setPeriod(3)} className={selectedDepartment_active === 3 ? 'active' : ""}>Last 7 Days</Dropdown.Item>
                      <Dropdown.Item onClick={() => setPeriod(4)} className={selectedDepartment_active === 4 ? 'active' : ""}>Last 30 Days</Dropdown.Item>
                      <Dropdown.Item onClick={() => setPeriod(5)} className={selectedDepartment_active === 5 ? 'active' : ""}>This Month</Dropdown.Item>
                      <Dropdown.Item onClick={() => setPeriod(6)} className={selectedDepartment_active === 6 ? 'active' : ""}>Last Month</Dropdown.Item>
                      <Dropdown.Item onClick={() => setdatepickershow(true)}>Custom Range</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-md-7 col-sm-7 col-lg-8">

              </div>
            </div>


          </div>
        </div>
      </div>

      {datepickershow && (
        <div
          ref={containerRef}
          className="calender"
          style={{
            marginLeft: "200px",
            marginBottom: "-2em"
          }}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setState([item.selection]);
              datepicker([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          ></DateRange>
        </div>
      )}

      <div className="container-fluid">

        <div className="row">
          {/* <Weather /> */}
          <div class="container-fluid" style={{ marginTop: "1rem", paddingLeft: "40px", paddingRight: "40px" }}>
            <div class="row first-division">
              <div className="col-md-8 col-sm-7 col-xs-12 hello" style={{ marginTop: "8px" }}>
                <h4 class="h2-main header1 dash-text" style={{ fontSize: "24px" }}>Total Club Performance</h4>
                {/* <h5 class="h3-sub-tit header2 dash-para" style={{
                  fontSize: "16px",
                  paddingBottom: "25px"
                }}>{month[new Date().getMonth()]} MTD</h5> */}
                {sample?.Rounds_mtd?.length == 0 &&
                  <p className="budgetreport-content">No budget available...</p>}
                {sample?.Rounds_mtd?.length != 0 &&
                  <SnapshotTable1
                    Rounds_Daily_mtd={sample?.Rounds}
                    Rounds_mtd={sample?.Rounds_mtd}
                    Revenue_mtd={sample?.Revenue_mtd ? sample.Revenue_mtd["MTD"] : undefined}
                  />}
              </div>

              {/* <div className="col-md-8 ">
  <div className="row">
                    <h2 class="h2-main ">MTD Performance</h2>
                <p style={{textAlign: 'center', color: 'grey'}}>December Month Report</p>
                </div>
                <div className="row">
                  <figure className="fignobud">
                <img className="nobud" src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/nobuget1.gif" />
                </figure>
                <p style={{textAlign: 'center'}}>No budget available...</p>
                </div>
              </div> */}

              <div className=" col-md-4 col-sm-5 col-xs-12 hello2" style={{ marginTop: "8px" }}>
                <div class='row '>
                  <h4 className="h2-main header3 dash-text" style={{ fontSize: "24px" }}>Rounds</h4></div>
                <div class='row second-division' style={{
                  paddingBottom: "26px"
                }}>
                  <div className="col-md-6 col-sm-6 weeklyreport1" >
                    <p className="weeklyreport dash-para" style={{ fontSize: "16px" }}>Weekly Report</p></div>
                  <div className=" col-md-6 col-sm-6 weeklyreport1">
                  {sample?.Rounds_mtd?.length == 0 &&
                    <p className="weeklyreport budget-content-2 " style={{ fontSize: "16px" }}>No Budget Available</p>
                  }
                    </div> 
                  
                    
                    </div>




                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">

                    </div>
                    
                    
                    <div className="col-md-6">
                     
                      <span class="circular-progress">
                        <span class="progress-value">{sample?.Rounds_mtd[0].Roundsmtd > 0 && sample?.Rounds_mtd[0].Roundsbudget > 0 ?Math.round((sample?.Rounds_mtd[0].Roundsmtd/sample?.Rounds_mtd[0].Roundsbudget)*100): 0 }%</span>
                      </span>
                     
                    </div>
                    
                    <div className="col-md-3">

                    </div>

                  </div>


                </div>




                {/* <div className="budgetreport-img">
                  <img
                    className="budget-img"
                    src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/nobuget2.gif"
                    style={{ width: "45%" }}
                  />
                </div> */}

              </div>
            </div>
          </div>

          <div className="container-fluid" style={{ paddingLeft: "40px", paddingRight: "40px" }}>
            <div className="row">
              <div className="sec2_heading first-division" style={{ marginTop: '15px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                <h3 style={{ fontSize: '24px' }} class="h2-main dash-text">Open to Spend</h3>
                {/* <h2 class="h3-sub-tit header2 dash-para" style={{
                  paddingBottom: "20px", fontSize: "16px"
                }}>{month[new Date().getMonth()]}</h2> */}
              </div>
              {sample?.Opentobuy?.length == 0 &&
                <p className="budgetreport-content">No budget available...</p>}

              {sample?.Opentobuy?.length != 0 && sample?.OpentobuyExpense?.length != 0 &&
                <SnapshotTable2_NonBinded Opentobuy={sample?.Opentobuy} OpentobuyExpense={sample?.OpentobuyExpense}
                />}
            </div>
          </div>
        </div>

        {loading && <Spinner />}
      </div>

    </>
  );
}

export default Dashboard;
