import 'bootstrap/dist/css/bootstrap.min.css'
import Linechart from '../Component/Linechart'




  

function Reports() {
    return (
      <div className="App">
    

        <Linechart />
    
      </div>

 
 

    );
  }
  export default Reports;