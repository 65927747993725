import { useEffect } from 'react';
import '../Footer/footer.css'





const  Pagefooter = () => {
    useEffect(()=>{



    },[])
    let items = JSON.parse(localStorage.getItem("UserClaims"));

    
    return ( 
        <div className='containerfluid footer-secone'>
        <div className='container-fluid'>
            <div className='col-md-12' style={{padding: "18px 25px 31px 41px"}}>
    <div className='row' style={{borderTop: "1px solid #8080805e",paddingTop: "27px"}}>
    <div className='col-md-5 col-sm-6 col-xs-12'>
    <ul class="nav navbar-navone footer-nav">
        <li><a href='/home' className='link footer-link'>Home</a></li>
            {(items.Member_Type!=2 &&

        <li><a href='/businessjournal' className='link footer-link'>Business Outlook</a></li>)}
        <li><a href='/snapshot' className='link footer-link'>At a Glance </a></li>
     </ul>
    </div>
    <div className='col-md-7 col-sm-6 col-xs-12'>
        <div style={{    display: "flex", float: "right"}}>
    <p className='rightsgolf'>©2023 – FobeGolf, Inc. - <span key="reserved"> All Rights Reserved</span></p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/page_footer.png" style={{width: "119px", height: "50px", marginTop: "-15px"}} alt="FobeGolf-Logo" title="FobeGolf-Logo"/>
    </div>
    </div>

</div>
</div>
</div>
</div>
    );  
};

export default Pagefooter;