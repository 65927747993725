import "bootstrap/dist/css/bootstrap.min.css";
import "./../TableComponent.css";
import Table from "react-bootstrap/Table";
import { NavItem } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { useState, useEffect } from "react";
import React, { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactToPrint from "react-to-print";
import CurrencyFormat from "react-currency-format";
import { Button } from "@mui/material";

const P_L_Table = ({ data, title, header, isBudget, showexcel,Acctype }) => {
  const tableRef = useRef([]);


  console.log(data,"Data")

  const changeData = (id, value) => {};

  return (
    <>
      <div
        className="pandl-table"
        style={{ marginLeft: "-20px", marginRight: "-20px" , pageBreakBefore:'always'}}
      >
        {data ? (
          data[0] ? (
            <div
              className="table-scroll table-responsive table1 "
              style={{ marginTop: "8px" }}
            >
              <Table
                className="forbusidailytab"
                striped
                bordered
                hover
               
              >
                <thead>
                  <tr>
                    <th style={{ width: "25%" }} rowSpan={2}>
                      <div className="title-tab" style={{ width: "auto" }}>
                        {title}
                      </div>
                    </th>
                    {header.map((head, index) => (
                      <th colSpan={2}>
                        {" "}
                        <div className="tab-card-new admin-budget-th">
                          {head.column}
                        </div>
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {header.slice(0, header.length).map((x) => (
                      <>
                        <th>
                          <div className="tab-card-news admin-budget-td">
                            {isBudget ? "BUDGETED" : "Amount"}
                          </div>
                        </th>
                        <th>
                          <div className="tab-card-news admin-budget-td">
                            Per(%)
                          </div>
                        </th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data
                    ? data[0]
                      ? data[0].List?.map((item, index) => {
                          let temp = [];
                          data.map((x) => {
                            temp.push({ ...x.List[index] });
                          });
                          console.log(Acctype ,"tempdata");
                          return (
                            (temp[0].Cat_name !=="CAM Charges" || (Acctype !== 4 && Acctype !== 5 && Acctype !== 6 && Acctype !== 9 && Acctype !== 10)) &&
                            (temp[0].Cat_name !=="Rent" || (Acctype !== 4 && Acctype !== 5 && Acctype !== 6 && Acctype !== 9 && Acctype !== 10))&& (
                            <tr>
                            <td className="admin-budget-td print-td ">
                                {temp[0].Cat_name}
                              </td>{" "}
                              {temp.map((cat, i) => {
                                const catAmtColorforrevenue =
                                  Math.round(temp[1].Cat_amt) >
                                  Math.round(temp[0].Cat_amt)
                                    ? "red"
                                    : "green";
                                const catAmtColorForothers = temp[0].Cat_name == "Total Revenue"|| temp[0].Cat_name == "Operating Profit/Loss" || temp[0].Cat_name == "Net Profit/Loss"? Math.round(temp[1].Cat_amt) <= Math.round(temp[0].Cat_amt)? "green": "red"  : Math.round(temp[0].Cat_amt) > Math.round(temp[1].Cat_amt) || Math.round(temp[0].Cat_amt)< 0 ? "red": "green"  ;


                                const catAmtColorforrevenueper =
                                  Math.round(temp[1].Cat_per) >
                                  Math.round(temp[0].Cat_per)
                                    ? "red"
                                    : "green";
                                const catAmtColorForothersper =
                                  temp[0].Cat_name == "Total Revenue"
                                    ? Math.round(temp[1].Cat_per) <=
                                      Math.round(temp[0].Cat_per)
                                      ? "green"
                                      : "red"
                                    : Math.round(temp[1].Cat_per) >=
                                      Math.round(temp[0].Cat_per)
                                    ? "green"
                                    : "red";

                                return (
                                  <>
                                    <td
                                      style={{
                                        color:
                                          i == 0 && title == "REVENUE"
                                            ? catAmtColorforrevenue
                                            : i == 0 && title != "REVENUE"
                                            ? catAmtColorForothers
                                            : "black",
                                        fontWeight: "bolder",
                                        fontSize: "14px !important",
                                      }}
                                    >
                                      <span class="fl-lf admin-budget-td pl-ipad-span">
                                        $
                                      </span>

                                      <CurrencyFormat
                                        className={
                                          cat.Editable_Status == 1
                                            ? "input-edit-monthly fl-r admin-budget-td"
                                            : "non-edit-cs fl-r admin-budget-td"
                                        }
                                        style={{ color: cat.Cat_amt < 0 ? "red" : "" }}

                                        prefix={cat.Cat_amt < 0 ? "(" : ""}
                                        suffix={cat.Cat_amt < 0 ? ")" : ""}

                                        id={cat.Cat_id}
                                        name={cat.Cat_id}
                                        value={Math.abs(parseFloat(cat.Cat_amt.toFixed(3)))} // Round off the value before displaying
                                        fixedDecimalScale={2}
                                        decimalScale={2}
                                        disabled={
                                          cat.Editable_Status == 1
                                            ? false
                                            : true
                                        }
                                        onValueChange={(value, name) =>
                                          changeData(cat.Cat_id, value)
                                        }
                                        thousandSeparator={true}
                                        
                                      />
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          i == 0 && title == "REVENUE"
                                            ? catAmtColorforrevenueper
                                            : i == 0 && title != "REVENUE"
                                            ? catAmtColorForothersper
                                            : "black",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      <p className="fl-r pl-ipad-per">
                                        <CurrencyFormat
                                          className={
                                            cat.Editable_Status == 1
                                              ? "input-edit-monthly admin-budget-td"
                                              : "non-edit-cs admin-budget-td"
                                          }
                                          style={{ color: cat.Cat_per < 0 ? "red" : "" }}
                                          prefix={cat.Cat_per < 0 ? "(" : ""}
                                          suffix={cat.Cat_per < 0 ? ")" : ""}

                                          id={cat.Cat_id}
                                          name={cat.Cat_id}
                                          fixedDecimalScale={2}
                                          value={Math.abs(parseFloat(cat.Cat_per.toFixed(3)))} // Round off the value before displaying
                                          decimalScale={2}
                                          disabled={
                                            cat.Editable_Status == 1
                                              ? false
                                              : true
                                          }
                                          onValueChange={(value, name) =>
                                            changeData(cat.Cat_id, value)
                                          }
                                        />
                                        <span   style={{ color: cat.Cat_per < 0 ? "red" : "" }}class="fl-ri">%</span>
                                      </p>
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                            )
                          );
                        })
                      : null
                    : null}
                </tbody>

                {title != "PROFIT/LOSS" && (
                 // <tfoot>
                    <tr className="tfoot">
                      <td className="admin-budget-td">TOTAL</td>

                      <>
                        {console.log(data.list, "3rd Element")}
                        <td
                          style={{
                            color:
                              title === "REVENUE" &&
                              Math.round(data[0].Total) >=
                                Math.round(data[1].Total)
                                ? "green"
                                : title !== "REVENUE" &&
                                  Math.round(data[0].Total) <=
                                    Math.round(data[1].Total)
                                ? "green"
                                : Math.round(data[0].Total) ===
                                  Math.round(data[1].Total)
                                ? "green"
                                : "red",
                          }}
                        >
                          <span className="admin-budget-td">$</span>{" "}
                          <p className="fl-r admin-budget-td">
                            <CurrencyFormat
                            style={{ color: data[0].Total < 0 ? "red" : "" }}
                            prefix={data[0].Total < 0 ? "(" : ""}
                            suffix={data[0].Total < 0 ? ")" : ""}
                              fixedDecimalScale={2}
                              className="non-edit-cs admin-budget-td ipad-span1 pl-ipad"
                              
                              value={
                                data[0].Total == undefined
                                  ? ""
                                  : Math.abs(data[0].Total)
                              }
                              thousandSeparator={true}
                              decimalScale={2}
                              disabled={true}
                            />
                          </p>
                        </td>

                        <td
                          style={{
                            color:
                              title === "REVENUE" &&
                              Math.round(data[0].per) >= Math.round(data[1].per)
                                ? "green"
                                : title !== "REVENUE" &&
                                  Math.round(data[0].per) <=
                                    Math.round(data[1].per)
                                ? "green"
                                : Math.round(data[0].per) ===
                                  Math.round(data[1].per)
                                ? "green"
                                : "red",
                          }}
                        >
                          <p className="fl-r admin-budget-td span-percc pl-ipad">
                            <CurrencyFormat
                            style={{ color: data[0].Total < 0 ? "red" : "" }}
                            prefix={data[0].Total < 0 ? "(" : ""}
                            suffix={data[0].Total < 0 ? ")" : ""}
                              fixedDecimalScale={2}
                              className="non-edit-cs admin-budget-td"
                              value={
                                data[0].per == undefined
                                  ? ""
                                  : Math.abs(data[0].per)
                              }
                              decimalScale={2}
                              disabled={true}
                            />
                            <span    style={{ color: data[0].Total < 0 ? "red" : "" }}>%</span>
                          </p>
                        </td>

                        <td>
                          <span className="admin-budget-td">$</span>{" "}
                          <p className="fl-r admin-budget-td">
                            <CurrencyFormat
                              style={{ color: data[1].Total < 0 ? "red" : "" }}
                              prefix={data[1].Total < 0 ? "(" : ""}
                              suffix={data[1].Total < 0 ? ")" : ""}
                              fixedDecimalScale={2}
                              className="non-edit-cs admin-budget-td ipad-span1 pl-ipad"
                              value={
                                data[1].Total == undefined
                                  ? ""
                                  : Math.abs(data[1].Total)
                              }
                              decimalScale={2}
                              disabled={true}
                              thousandSeparator={true}
                            />
                          </p>
                        </td>

                        <td>
                          <p className="fl-r admin-budget-td span-percc pl-ipad">
                            <CurrencyFormat
                             style={{ color: data[1].per < 0 ? "red" : "" }}
                             prefix={data[1].per < 0 ? "(" : ""}
                             suffix={data[1].per < 0 ? ")" : ""}
                              fixedDecimalScale={2}
                              className="non-edit-cs admin-budget-td"
                              value={
                                data[1].per == undefined
                                  ? ""
                                  : Math.abs(data[1].per)
                              }
                              decimalScale={2}
                              disabled={true}
                            />
                            <span style={{ color: data[1].per < 0 ? "red" : "" }}> %</span>
                          </p>
                        </td>
                      </>
                    </tr>
                 // </tfoot>
                )}
              </Table>
            </div>
          ) : null
        ) : null}
      </div>
    </>
  );
};
export default P_L_Table;
