import { Box, getImageListItemBarUtilityClass } from '@mui/material';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./Recovery.css";
import { useEffect, useState } from "react";
import { mainAxios } from './middleware/interceptor';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
function Recovery(){
const [recemail,setrecemail]=useState(localStorage.getItem("email"))
let navigate=useNavigate();


//const RecoverySub=()=> {
//  alert("Button clicked!");


  const[newpass,setnewpass] = useState("");
  const[repass,setrepass] = useState("");
  const[chknewpass,setchknewpass] = useState(false);
  const[chkrepass1,setchkrepass1] = useState(false);
  const[chkrepass,setchkrepass] = useState(false);

const submit=(event)=>{
  event.preventDefault()
if(newpass===""){
    setchknewpass(true)
 //   setchkrepass(false)
  }

if(newpass!==""){
  setchknewpass(false)
}
 if(repass===""){
    setchkrepass1(true)
     setchkrepass(false)
  }
  else if(!repass===""){
    setchkrepass1(false)
  }
else if(newpass!==repass){
  //setchknewpass(false)
    setchkrepass(true)
    setchkrepass1(false)

  }
  else if(newpass===repass){
    //setchknewpass(false)
      setchkrepass(false)
      setchkrepass1(false)
  
    }
// console.log(Email,'kkkkk')
let data={
  UserName : recemail,
  password :repass,  
}

 mainAxios.post('api/hampton/save_Recover_password',data).then((resp)=>{
if(resp.data==="1")
{
  toast.success('Password reseted successfully')
  localStorage.removeItem("email")
setrecemail("")

navigate("/");
}}) 

console.log(recemail,'[[[')
}
  return (
    
    <Box>
      {console.log(localStorage.getItem("email"),'qqqq')}
      {
       // localStorage.getItem("email")?
      <Box className='recovery' style={{}}>
      <Box style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
      <img src='https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/header-logo.png' alt='logo' width={'150px'}/> 
      </Box>
      <Box className='formBox'>
        <h1>Reset Your Password</h1>
        <p>This is intended for the user with the email address aa****@jetzerp.com</p>
        <p style={{marginTop: '15px'}}>Password must be atleast six characters.</p>
      <Form noValidate onSubmit={(event)=>submit(event)}>
        <Form.Group className="mb-3" controlId="formBasicEmail" style={{margin: '20px auto'}}>
          <Form.Label>New Password</Form.Label>
          <Form.Control required type="password" onChange={(e)=>  setnewpass(e.target.value)} placeholder="Password" style={{height: '40px', fontSize: '18px'}}/>
          {chknewpass &&
            <Form.Control.Feedback type="invalid" style={{marginTop: '5px'}} >Enter new password</Form.Control.Feedback>}
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="formBasicPassword" style={{margin: '20px auto'}}>
          <Form.Label>Re-type new Password</Form.Label>
          <Form.Control required type="password"  onChange={(e)=>setrepass(e.target.value)}  placeholder="Re-type Password" style={{height: '40px', fontSize:'18px'}}/>
         { chkrepass1 &&
         <Form.Control.Feedback type="invalid" style={{marginTop: '5px'}} >Re-enter new password</Form.Control.Feedback>}
          {chkrepass &&
          <Form.Control.Feedback type="invalid" style={{marginTop: '5px'}} >password does not match</Form.Control.Feedback>}
        </Form.Group>
        
        <Button className='set-btn' variant="primary" type="submit" style={{fontSize: '18px', marginTop: '10px'}}>
          Set Password
        </Button>
        
      </Form>
      </Box>
    </Box>
// :
     
//     <Box style={{textAlign:'center', padding: '10%',display: 'block'}}>
//       <div className='expiry-content'>
//       <img className='expiry_img' src='https://d2a7u30t6wbkim.cloudfront.net/assets/img/linkexpiredimg.png' alt='expired' style={{margin: '0 auto'}}/>
//       <h1 style={{
//         fontSize: '20px',
//         fontWeight: '700',
//         color: 'red',
//         whiteSpace: 'nowrap',
//         marginTop: '20px'
//       }}>The link has expired..</h1>
//       <p style={{whiteSpace: 'nowrap'}}>Please try with a new one. <a href='/' style={{color: '#2196f3'}}>Login</a></p>
//       </div>
//     </Box>
}
    </Box>
  )
}

// useEffect(() => {
//   // Expose the function to the global scope
//   window.RecoverySub = RecoverySub;

//   return () => {
//     // Cleanup: Remove the function from the global scope when the component unmounts
//     delete window.RecoverySub;
//   };
// }, []);
//}

export default Recovery;
