// import Table1 from '../snapshot/table1'
import Tab from "react-bootstrap/Tab";
import React from 'react';
import Tabs from "react-bootstrap/Tabs";
import "../Home/Home.css";
import Form from 'react-bootstrap/Form';
import SnapshotBudget from "./SnapshotBudget";
import "../snapshot/snapshot.css";
import Dashboard from "./Dashboard";
import Dropdown from 'react-bootstrap/Dropdown';
import BookedTenderedRounds from "./BookedTenderedRounds ";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { mainAxios, FobeAxios } from "../middleware/interceptor";
import { Spinner } from '../Component/Spinner'
import { selectUnstyledClasses } from "@mui/base";
import Col from "react-bootstrap/Col";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

const drop = [
  {
    Coursename: "Blue Sky",
    Budget_exit_status: 1,
    Courseid: 1381,
  },
  {
    Coursename: "Country Club of Ocala",
    Budget_exit_status: 1,
    Courseid: 897,
  },
  {
    Coursename: "DEMO",
    Budget_exit_status: 0,
    Courseid: 1453,
  },
  {
    Coursename: "Eagle Harbor",
    Budget_exit_status: 1,
    Courseid: 952,
  },
  {
    Coursename: "Elizabethton",
    Budget_exit_status: 0,
    Courseid: 1978,
  },
  {
    Coursename: "Esplanade at Azario Lakewood Ranch",
    Budget_exit_status: 1,
    Courseid: 1811,
  },
  {
    Coursename: "Everglades",
    Budget_exit_status: 1,
    Courseid: 878,
  },
  {
    Coursename: "Falcons Fire",
    Budget_exit_status: 1,
    Courseid: 888,
  },
  {
    Coursename: "Hampshire",
    Budget_exit_status: 0,
    Courseid: 1982,
  },
  {
    Coursename: "Hampshire",
    Budget_exit_status: 0,
    Courseid: 2341,
  },
  {
    Coursename: "Harbor Hills",
    Budget_exit_status: 0,
    Courseid: 1975,
  },
  {
    Coursename: "Jacksonville Golf and Country Club",
    Budget_exit_status: 0,
    Courseid: 1986,
  },
  {
    Coursename: "Laurel Oak",
    Budget_exit_status: 1,
    Courseid: 1534,
  },
  {
    Coursename: "Metro West",
    Budget_exit_status: 1,
    Courseid: 901,
  },
  {
    Coursename: "Northland",
    Budget_exit_status: 1,
    Courseid: 889,
  },
  {
    Coursename: "Palencia",
    Budget_exit_status: 1,
    Courseid: 855,
  },
  {
    Coursename: "Pipestone",
    Budget_exit_status: 0,
    Courseid: 1991,
  },
  {
    Coursename: "Piqua",
    Budget_exit_status: 1,
    Courseid: 893,
  },
  {
    Coursename: "Plantation",
    Budget_exit_status: 1,
    Courseid: 868,
  },
  {
    Coursename: "Region 01",
    Budget_exit_status: 0,
    Courseid: 1122,
  },
  {
    Coursename: "River Hall",
    Budget_exit_status: 1,
    Courseid: 916,
  },
  {
    Coursename: "Southern Hills Plantation",
    Budget_exit_status: 1,
    Courseid: 882,
  },
  {
    Coursename: "St. Cloud Country Club",
    Budget_exit_status: 0,
    Courseid: 2102,
  },
  {
    Coursename: "Stillwater",
    Budget_exit_status: 0,
    Courseid: 1880,
  },
  {
    Coursename: "Stone Creek",
    Budget_exit_status: 1,
    Courseid: 896,
  },
  {
    Coursename: "Stonegate Country Club",
    Budget_exit_status: 1,
    Courseid: 869,
  },
  {
    Coursename: "Summer Glen",
    Budget_exit_status: 0,
    Courseid: 860,
  },
  {
    Coursename: "Tennesse National",
    Budget_exit_status: 0,
    Courseid: 1874,
  },
  {
    Coursename: "The Club at Del Webb Naples",
    Budget_exit_status: 1,
    Courseid: 891,
  },
  {
    Coursename: "The Links at Spruce Creek",
    Budget_exit_status: 0,
    Courseid: 1980,
  },
  {
    Coursename: "Trophy Apalachee",
    Budget_exit_status: 0,
    Courseid: 1717,
  },
  {
    Coursename: "Verandah",
    Budget_exit_status: 1,
    Courseid: 861,
  },
  {
    Coursename: "Victoria Hills",
    Budget_exit_status: 0,
    Courseid: 904,
  },
  {
    Coursename: "ZBoss",
    Budget_exit_status: 0,
    Courseid: 957,
  },
];
function Snapshot() {
  const [course, setCourse] = useState([]);
  const [year, setYear] = useState();
  
  const userDetail = JSON.parse(localStorage.getItem('UserClaims'));


  const [selectedCourse, setSelectedCourse] = useState(userDetail.Company);
  
  const [roundsYear, setRoundsYear] = useState([]);

  const [loading, setLoading] = useState(true);
  const [tabs, setTab] = useState(3);


  useEffect(() => {   
    getData();
  }, []);

  const getCourse = (value) => {   
    console.log(value,"fdfdf")
    setSelectedCourse(value)
  };

  var selcourse;
  const getData = async () => {
    if(userDetail.Member_Type==4){

    
    await mainAxios.get("Api/Hampton/GetAllCouseTypes").then((resp) => {
      if (resp) {
        console.log("okkkbye", resp.data["AllUserTypes"][0].Coursename);
        selcourse= resp.data["AllUserTypes"][0].Coursename;
        console.log(selcourse,"SEL");
        setCourse(resp.data["AllUserTypes"]);
        setSelectedCourse(resp.data["AllUserTypes"][0].Coursename);
        
      }
    });
  }
  else{
       const vaee = userDetail.Company;
      console.log(vaee, "userDetail");
      setSelectedCourse(userDetail.Company);
      setCourse(null);

  }

    await mainAxios.get("Api/Hampton/getRoundsYear").then((resp) => {
      if (resp) {     
        setRoundsYear(resp.data["YearList"].map(x => x.Year));
        setYear(resp.data["YearList"][0].Year);
        setLoading(false);
      }
    });
  };

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );


  return (

    <div className="container-fluid" style={{marginTop:"97px"}}>
       <ToastContainer autoClose={3000}/>
      {loading ? <Spinner /> : (
        <div className="row">
          <div className="tab-sections">
          <div className="col-xs-12 sm-0"  style={{
                padding:'0px 32px 10px 40px',
                 marginTop: "30px",
                 marginBottom: "-5px",
               
                }}>
                  <div className="row">
                        <div className={tabs==2?"col-md-12 col-sm-12 col-lg-12 col-xs-12" :"col-md-12 col-sm-12 col-lg-12 col-xs-12"}>
                      <div class="col-md-12 row" style={{paddingLeft:'0px',paddingRight:'5px'}}>
                        
                       <div className="col-md-7 col-lg-7 col-sm-8 col-xs-12">
                        <div className="button-column" style={{display:'flex',gap:'18px'}}>
                        {/* {(userDetail.Member_Type==4||(((userDetail.Special_user == 0 || userDetail.Special_user == 2) && userDetail.Account_Type == 0) && userDetail.Member_Type!=2 )) ? ( <> */}
                        {/* {hideCategoriesandconstant &&<> */}
                        {(userDetail.Account_Type ==2) ? ( <>

                    <div className="col">
                      <a
                     
                        
                     
                        style={{
                          fontSize: "11px",
                          color: "rgb(243, 244, 255)",
                      
                          textDecoration: "none",
                         
                        }}
                      >
                            <button
                              className={tabs==1?" button-size":"newbut"}
                              type="button"
                              onClick={() => {
                                setTab(1)
                              }}
                            >
                             Budget
                            </button>
                   
                     
                      </a>
                    </div></>) :null }
                    <div className="col" >
                     
                          <a
                            style={{
                              fontSize: "11px",
                              color: "rgb(243, 244, 255)",
                              textDecoration: "none",
                            }}
                          >
                            <button 
                            // style={{float:"right"}}
                              className={tabs==2?"booked-btn":"booked"}
                              type="button"
                              onClick={() => {
                                setTab(2)
                              }}
                            >
                              Booked Tendered Rounds
                            </button>
                          </a>
                       
                    </div>
                    <div className="col">
                    
                          <a
                            style={{
                              fontSize: "11px",
                              color: "rgb(243, 244, 255)",
                              textDecoration: "none",
                            }}
                          >
                            <button
                            //  style={{float:"right"}}
                              className={tabs==3?"dashboard-btn":"dashboard"}
                              type="button"
                              onClick={() => {
                                setTab(3)
                              }}
                            >
                              Dashboard
                            </button>
                          </a>
                      
                    </div>
                    {/* </>} */}
                        </div>
              
                       </div>
                    <div className="col-md-5 col-lg-5 col-sm-12">
                      <div className="drop-column" style={{display:'flex',gap:'15px',float:'right'}}>
                {userDetail.Account_Type===0 && userDetail.Member_Type!=2 &&
                    <>
                   <div class="col"><p style={{color:'#7B7B7B',fontSize:'12px'}} className="course-text">Course</p></div>
                    <div class="col">
                <Dropdown>
               <Dropdown.Toggle
                 id="dropdown-button-dark-example1"
                 className="course-drop course-drop2 drop-course"
                 variant="secondary"
               >    
              {selectedCourse.slice(0,10)}&nbsp; <i style={{color:'#24D153',fontWeight:'600',fontSize:'15px'}} class="fa fa-angle-down icon-drop"></i>
               </Dropdown.Toggle>
               <Dropdown.Menu variant="dark" className="responsedropcourse-snap" style={{ height: course ? "164px" : "50px",padding: course ? "3px 3px 3px 8px" : "0px 8px 0px 8px"  }}>
              
              {course ? (
                                    course.map((x) => (
                                      <Dropdown.Item
                                        eventKey={x.Coursename}
                                        active={
                                          x.Coursename === selectedCourse
                                            ? true
                                            : false
                                        }
                                        onClick={() =>
                                          getCourse(
                                            x.Coursename === undefined
                                              ? selectedCourse
                                              : x.Coursename
                                          )
                                        }
                                      >
                                        {x.Coursename === undefined
                                          ? selectedCourse
                                          : x.Coursename}
                                      </Dropdown.Item>
                                    ))
                                  ) : (
                                    <Dropdown.Item
                                      eventKey={selectedCourse}
                                     
                                    >
                                      {" "}
                                      {selectedCourse}
                                    </Dropdown.Item>
                                  )}                          
              
               </Dropdown.Menu>
             </Dropdown>
                    </div></>}
                    <div class="col"><p style={{color:'#7B7B7B',fontSize:'12px'}} className="year-text">Year</p></div>
                    <div class="col">
                          <Dropdown>
               <Dropdown.Toggle
                 id="dropdown-button-dark-example1"
                 className="course-dropml year-drop"
                 variant="secondary"
               >    
               2024
               &nbsp; <i style={{color:'#24D153',fontWeight:'600',fontSize:'15px'}} class="fa fa-angle-down icon-drop"></i>           
               </Dropdown.Toggle>
               <Dropdown.Menu variant="dark" className="responsedropyear-snap" style={{padding:'0px 8px 0px 8px'}}>
              <Dropdown.Item>2024</Dropdown.Item> 
               </Dropdown.Menu>
             </Dropdown>
                    </div>
                      </div>
                  </div>
                </div>
                </div>
                </div>
                
                  <div className="row" style={{marginRight:'8px',marginLeft:'0px',borderBottom:'1px solid #ccc'}}>
                       <div className={tabs==2? "row col-md-5 col-sm-12 col-lg-5 col-xs-12" :"row col-md-5 col-sm-12 col-lg-5 col-xs-12"}>
                        <h4
                        className="Daily-font"
                          style={{
                            fontSize: "30px",
                            marginTop: "30px",
                            color: "#7b7b7b",
                            marginBottom:'27px'
                          }}
                        >
                         {tabs==1? "Budget At A Glance" : tabs==2? "Booked / Tendered Rounds" : "Dashboard" }
                          <span className="h2-title">
                         
                          </span>
                        </h4>
                      </div>
                  </div>
                </div>
          </div>{" "}
        </div>
      )}
{console.log(selcourse,userDetail.Company,selectedCourse,"yyyy")}
 {tabs==1 ? 
 
 <SnapshotBudget 
 tabs={tabs} 
 dropdowndata={userDetail.Member_Type==4?selectedCourse:userDetail.Company} /> : tabs==2 ?
 <BookedTenderedRounds
  tabs={tabs}
  dropdowndata={userDetail.Member_Type==4?selectedCourse:userDetail.Company}  /> :
 <Dashboard
  dropdowndata={userDetail.Member_Type==4?selectedCourse:userDetail.Company} 
  tabs={tabs} />}

    </div>
  );
}

export default Snapshot;
