import { useRef, useState } from "react";
import "../Importteam/importteam.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
import TableComponent from "../Component/Tables/TableComponent";
import Uploadexceltable from "../Component/Tables/Uploadexceltable";
import * as XLSX from "xlsx";
import { mainAxios } from "../middleware/interceptor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sample = [];

const column = [
  { column: "First_Name" },
  { column: "Last_Name" },
  { column: "Email" },
  { column: "Password" },
  { column: "Role" },
  { column: "Course" },
  { column: "Department" },
  { column: "Phone" },
];

function Importteam() {
  let [data, setData] = useState(sample);
  const [search, setSearch] = useState(sample);
  const [col, setColumn] = useState(column);
  const [savedata, setSavedata] = useState([]);
  const [searchText, setSearchText] = useState("");
  const onChange = (e) => {
    const [file] = e.target.files;
    
    // Check if the selected file is of XLSX format
    if (file && file.name.endsWith('.xlsx')) {
      const reader = new FileReader();
  
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        console.log(data, 'swathi');
        setSavedata(data);
        if (data.length !== 0) {
          ValidateUser(data);
        }
      };
  
      reader.readAsBinaryString(file);
    } else {
      // Display an error message or perform any necessary actions for unsupported file type
      toast.error("Please Upload Excel File Only")
    }
  };
  const searchFunction = (searchText) => {
    setSearchText(searchText);
    console.log(searchText);
    const query = searchText;
    let user = data.filter((item) => {

      console.log(item,"Item")
      return(
        item.Email.toLowerCase().startsWith(searchText.toLowerCase())|| item.Course.toLowerCase().startsWith(searchText.toLowerCase()) || item.Role.toLowerCase().startsWith(searchText.toLowerCase()))
      });
  console.log(user,"SearchingDetails")
        if (user.length > 0) {
      setSearch(user);
      console.log(user, "Users");
    } else {
      setSearch([]);
    }
  };

  const getPage = () => {};

  const toastId = useRef(null);

  const ValidateUser = async (e) => {
    toastId.current = toast.loading("Please Wait");
    try {
      console.log(data, "EXel");

      await mainAxios.post("Api/Hampton/griddisplay", e).then((res) => {
        console.log(res.data.Final_result, "res1");

        setData(res.data.Final_result);
        setSearch(res.data.Final_result);
        if (res) {
          toast.update(toastId.current, {
            render: "Excel Upoaded Successfully",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId.current, {
            render: "Error in Uploading Exel",
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        }
      });
    } catch (err) {
      toast.update(toastId.current, {
        render: "something went wrong",
        isLoading: false,
        type: "error",
        autoClose: 2000,
      });
      console.log(err);
    }
    console.log(data, "resex");
  };
  const Saveusers = async () => {
    toastId.current = toast.loading("Please Wait");
    try {
      console.log(data, "EXel");

      await mainAxios
        .post("Api/Hampton/UploadValidation", savedata)
        .then((res) => {
          console.log(res.data.Final_result, "res1");

          // setData(res.data.Final_result);
          // setSearch(res.data.Final_result);
          if (res.data.Pass == savedata.length) {
            toast.update(toastId.current, {
              render: "All Users are added",
              type: "success",
              isLoading: false,
              autoClose: 2000,
            });
          } else {
            toast.update(toastId.current, {
              render: "All Users are not added",
              isLoading: false,
              type: "error",
              autoClose: 2000,
            });
          }
        });
    } catch (err) {
      toast.update(toastId.current, {
        render: "something went wrong",
        isLoading: false,
        type: "error",
        autoClose: 2000,
      });
      console.log(err);
    }
    console.log(data, "resex");
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div
        class="container-fluid"
        style={{ paddingRight: "40px", paddingLeft: "40px", marginTop:"97px"}}
      >
        <div
          className=""
          style={{
            paddingTop: "25px",
            paddingBottom: "25px",
            borderBottom: "1px solid rgb(197, 196, 196)",
          }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <p class="tit-le Daily-font" style={{marginBottom:'0px'}}>Import Team Member</p>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <a style={{display:'flex'}}
                class="download"
                href="https://s3.amazonaws.com/fobesoft/assets/hampton_connect/assets/Import_User_List_Fobegolf.xlsx"
              >
                <i
                  class="bi bi-cloud-download"
                  style={{ color: "#24D153",fontSize:'21px' }}
                ></i>{" "}
                <span style={{marginTop:'3px'}}>Download Team Member Roster Template{" "}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row text-center">
             <div className="twobutons">
              <div className="col" style={{cursor:'pointer'}}>
                <div class="upload-btn-wrapper" style={{ float: "right",cursor:'pointer'  }}>
                  <button
                    class="btn btn-default uplsucebut"
                    style={{ cursor: "pointer",display:'flex' }}
                  >
                    <i
                      class="bi bi-cloud-arrow-up"
                      style={{ color: "#fff", fontSize: "35px",cursor:'pointer'  }}
                    ></i>{" "}
                    <span className="manage-upload" style={{marginTop:'8px',cursor:'pointer' }}>Upload</span> 
                    <div className="input-upload">
                    <input
                      style={{ cursor: "pointer" }}
                      type="file"
                      name="myfile"
                      onChange={(e) => onChange(e)}
                    />
                    </div>
                 
                  </button>
                </div>
              </div>
              <div className="col">
                <div>  <button
                  className="uplsucebut"
                  style={{
                    float: "left",
                    display: "flex",
                    border: "none",
                    borderRadius: "3px",
                  }}
                >
                  <i
                    class="bi bi-list-ul"
                    style={{
                      color: "#fff",
                      fontSize: "35px",
                      paddingRight: "10px",
                      paddingTop: "0.5px",
                    }}
                  ></i>
                  <span style={{marginTop:'6px'}} className="manage-allteam" onClick={Saveusers}>Create All Team Members</span>
                </button></div>
              
              </div>
            </div>
            <div class="table-sections mx-auto">
              <Uploadexceltable
                header={col}
                data={{ list: search }}
                nestedColumn={false}
                Search={true}
                searchFunction={searchFunction}
                pagination={true}
                getPage={getPage}
                searchText={searchText}
              />
            </div>
          </div>
        </div>

        {/* <div class="upload-btn-wrapper">
          <button class="b-tn">
            <i class="bi bi-cloud-arrow-up-fill"></i> Upload
          </button>
          <input type="file" name="myfile" onChange={(e) => onChange(e)} />
        </div> */}

        {/* <div class="table-sections">
          <Uploadexceltable
            header={col}
            data={{ list: search }}
            nestedColumn={false}
            Search={true}
            searchFunction={searchFunction}
            pagination={true}
            getPage={getPage}
          />
        </div> */}
        {/* <div class="col-sm-12">
          <button className="btn btn-success s-txt-ctr">
            <img
              class="igm"
              src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/import-icon.png"
            ></img>
            <span onClick={Saveusers}>Create All Team Member</span>
          </button>
        </div> */}
      </div>
    </>
  );
}

export default Importteam;
