import '../Header/header.css'
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink ,useNavigate} from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { LocalStorageService } from '../Services/LocalStorageService';
import { useEffect } from 'react';
import { useState } from 'react';
// import { history } from './Services/history';


const  Header = () => {

  
  const localStorageService = LocalStorageService.getService();
  const token = localStorageService.getAccessToken("auth-token");
const headertoggle = () => { var name = document.getElementById('get'); if (name.classList.contains('open')) { name.classList.remove('open'); } else { name.classList.add('open'); } };

    return (
      <>

<div className=" navbar-headmobile"
style={{    
    position: 'fixed',
    zIndex: '1',
    width: '100%'}}>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-3">
                        <a style={{cursor: "pointer"}} href="/" className="navbar-brand"><img src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/logo.png" className="img100 forihead" alt="FobeGolf-Logo" title="FobeGolf-Logo" /></a>
                    </div>
                    <div className="col-md-7">
                        <ul className="navbar-nav ml-auto" style={{display: "grid", marginTop: "35px", marginRight: "95px"}}>
                                                        <li><span className="numberspan" style={{float: "right"}}><a id="" href="tel:904-676-6130" className="header-number">904-538-0780</a></span></li>
                            <li style={{lineHeight: "4.2rem"}}><span className="supporte" style={{color: "#24D153", fontSize: "16px"}}>SUPPORT@FOBEGOLF.COM</span></li>
                            {/* <li style={{lineHeight: "2.2rem", marginLeft: "5px", display: "none"}}>
                                <span className="header-icon" style={{marginLeft: "85px"}}>
                                    <a href="/" className="icoFacebook" title="Facebook" style={{color: "#24d153"}}>
                                        <i className="fa fa-facebook yt-p" style={{fontSize: "19.52px"}}></i>
                                    </a>

                                    <a href="/" className="icoYt" title="Youtube" style={{color: "#24d153"}}>
                                        <i className="fa fa-youtube-play yt-p" style={{fontSize: "20px", marginLeft: "3px"}}></i>
                                    </a>
                                    <a href="/" className="icoGoogle" title="Linkedin" style={{color: "#24d153"}}>
                                        <i className="fa fa-linkedin yt-p" aria-hidden="true" style={{fontSize: "21px", marginLeft: "4px"}}></i>
                                    </a>
                                    <a href="/" title="INSTA" style={{color: "#24d153"}}>
                                        <i className="fa fa-instagram yt-p" style={{cursor: "pointer", fontSize: "19.52px", marginLeft: "3px"}}></i>
                                    </a>
                                </span>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2 cotol">
                        <button type="button" onClick={headertoggle} id="get" className="navbar-toggle mbl-toggle" data-toggle="collapse" data-target="#myNavbar" style={{backgroundColor: "#24D153"}}>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>

                </div>
            </div>
            <div className="collapse navbars-collapse" id="myNavbar" style={{borderTop: "2px solid lightgrey", paddingBottom: "20px", marginTop: "20px"}}>
                <div className="freds">
                    <ul className="nav navbar-navs" style={{display: "grid", textAlign: "center", float: "none"}}>
                        <li style={{paddingTop: "5px", color: "#24D153"}}><a className="mobfont" target="blank" href="https://dev.fobegolf.com/services.html" style={{color: "#24d153 !important", fontSize: "20px"}}>Services</a></li>
                        <li style={{paddingTop: "5px", color: "#24D153"}}><a className="mobfont" target="blank" href="https://dev.fobegolf.com/Blog" style={{color: "#24d153 !important", fontSize: "20px"}}>Blog</a></li>
                        <li style={{paddingTop: "5px", color: "#24D153"}}><a className="mobfont" target="blank" href="https://dev.fobegolf.com/about-us.html" style={{color: "#24d153 !important", fontSize: "20px"}}>About Us</a></li>
                        <li className="backforim" style={{paddingTop: "5px", width: "20%", display: "block", margin: "0 auto"}}><a href="https://dev.fobegolf.com/contact-us.html" target="blank" type="button" className="fontbut btn-success " style={{fontSize: "16px !important", backgroundColor: "#24D153", borderColor: "#24D153", padding: "11px 12px", color: "#fff", borderRadius: "3px"}}>Schedule Demo</a></li>
                        {/* <li className="backforim" style={{paddingTop: "5px", width: "20%", display: "block", margin: "0 auto"}}><a className="fontbut btn-success " target="blank" href="https://dev.fobegolf.com/contact-us.html" type="button" style={{fontSize: "16px !important", backgroundColor: "#24D153", borderColor: "#24D153", padding: "11px 12px", color: "#fff", borderRadius: "3px"}}>Request Info</a></li> */}
                        <li style={{paddingTop: "5px", color: "#24D153"}}><a className="mobfont" href="https://newapp.fobegolf.com/" style={{color: "#24d153 !important", fontWeight: "900", fontSize: "16px !important"}}>LOGIN</a></li>
                                            </ul>
                </div>
            </div>
        </div>


      <nav className="navbar navbar-static-top  mt-2 desktopnone log-nav-fixed-top" style={{background: 'url(https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/golf-bg.jpg) repeat 100% 100%', display:'inline'}}>
  <div className="container-fluid main-header" style={{paddingRight:"28px" , paddingLeft:"28px", paddingBottom: '20px',     paddingTop: '40px'}}>
    <div className='col-md-12'>  
    <div className='row'>
      <div class="col-md-2 col-sm-2 col-xs-12">
    <div className="navbar-header">
           <div class="brand-logo">
                        <a href={"https://dev.fobegolf.com/"} title="Fobegolf-Logo">
                            <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/logo.png" class="imgheadone" alt="FobeGolf-Logo" title="FobeGolf-Logo"/>
                        </a>
                    </div>
    </div>
    </div>
    <div class="col-md-10 col-sm-10 col-xs-12">
      <div className='row'>
    <div class="info-cnt">
                                <div class="info-mble"><a href="tel:9045380780" class="num-a">904-538-0780</a></div>
                                <span class="lin-br"></span>
                                <div class="info-email">SUPPORT@FOBEGOLF.COM</div>
                                {/* <div class="info-social">  
                                    <li class=""> <a href="#"><i class="fa fa-facebook" title="facebook" style={{color:"#24D153",fontSize:"20px",paddingRight:"5px"}}></i></a></li>
                                    <li class=""><a href="#"><i class="fa fa-youtube-play" title="youtube"  style={{color:"#24D153",fontSize:"20px",paddingRight:"5px"}}></i> </a></li>
                                    <li class=""><a href="#"><i class="fa fa-linkedin" title="linkedin"  style={{color:"#24D153",fontSize:"20px",paddingRight:"5px"}}></i></a></li>
                                    <li class=""> <a href="#"><i class="fa fa-instagram" title="instagram"  style={{color:"#24D153",fontSize:"20px",paddingRight:"5px"}}></i></a></li>
                                   
                                </div> */}
                            </div>
                            </div>
                            <div className='row'>
    <div className="collapse navbar-collapse navbarSupportedContent">
    <ul class="nav navbar-nav">
                            {/* <li><a href='https://dev.fobegolf.com/services.html'>Services</a></li>
                                        <li><a href='https://dev.fobegolf.com/Blog'>Blog</a></li>
                        <li><a href='https://dev.fobegolf.com/about-us.html' key="aus">About Us</a></li>
                    <li><a href="https://dev.fobegolf.com/contact-us.html" type="button" class="btn btn-appstore btn-success btn_whit schedule-btn" style={{fontSize:'12px',padding:'6px 18px',fontWeight:'normal',marginTop:'8px'}}>Schedule Demo</a></li>

                              <li><a href="https://newapp.fobegolf.com/" key="lgn">Login</a></li> */}
                                         <li><a  className='link nav-opt' href="https://dev.fobegolf.com/services.html" style={{fontWeight: "600"}}>Services</a></li>
                                        <li><a  className='link nav-opt' href="https://dev.fobegolf.com/Blog" style={{fontWeight: "600"}}>Blog</a></li>
                                        <li><a className='link nav-opt' href="https://dev.fobegolf.com/about-us.html" style={{fontWeight: "600"}}>About Us</a></li>
                                        <li><a href="https://dev.fobegolf.com/contact-us.html" type="button" class="btn btn-appstore btn-success btn_whit schedule-btn" style={{fontSize:'12px',padding:'6px 18px',fontWeight:'normal',marginTop:'8px'}}>Schedule Demo</a></li>
                                         <li><a className='link nav-opt' href="https://newapp.fobegolf.com/" style={{fontWeight: "600",fontSize:'14px'}}>LOGIN</a></li> 
                                      
              
                                    </ul>
                                    </div> 
                                    </div>
                                    </div>
                                    </div> 
    </div>
  </div>
</nav> 
     </> 
    );
};

export default Header;