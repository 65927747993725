import "./App.css";
import Layout from "./Layout/Layout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { lazy, Suspense } from "react";
import Login from "./Login";
import Header from "./Header/Header";
import Pageheader from "./Header/Pageheader";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import BusinessJournal from "./Businessjournal/Businessjournal";
import Snapshot from "./snapshot/sanpshot";
import Dashboard from "./snapshot/Dashboard";
import Profile from "./Profile/profile";
import Manageteam from "./Manageteam/manageteam";
import Importteam from "./Importteam/importteam";
import { setAxiosDefauls } from "./middleware/interceptor";
import Pagefooter from "./Footer/Pagefooter";
import { PrivateRoute } from "./middleware/PrivateRoute";
import LoadingOverlay from "react-loading-overlay";
import { useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import Loader from "./loader";
import SignUp from "./User/SignUp";
import { useEffect } from "react";

import InvoiceDashboard from "./Tab_manageuser/InvoiceDashboard";
import Recovery from "./Recovery";
// const Login = lazy(() => import('./Login'));
setAxiosDefauls();
function App() {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <Loader isActive={isActive} />

      <Router>
      <Routes>
        <Route path="/recovery" element={<Recovery />}/>
      </Routes>
        <Routes>
          <Route
            element={
              <div>
                <Header /> <Outlet />
                <Footer />
              </div>
            }
          >
            <Route path="/" element={<Login setIsActive={setIsActive} />} />

            <Route path="/Signup" element={<SignUp />} />
          </Route>
        </Routes>

        <Routes>
          <Route
            element={
              <div>
                <Pageheader /> <Outlet />
                <Pagefooter />
              </div>
            }
          >
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/businessjournal"
              element={
                //  <Suspense fallback={<div>Loading Page...</div>}>
                <PrivateRoute>
                  <BusinessJournal />
                </PrivateRoute>
                // </Suspense>
              }
            />
            <Route path="/snapshot" element={<Snapshot />} />
            {/* <Route path="/Dashboard" element={<Dashboard />} /> */}
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Manageteam" element={<Manageteam />} />
            <Route path="/InvoiceDashboard" element={<InvoiceDashboard />} />
            <Route path="/Importteam" element={<Importteam />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
