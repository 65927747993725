import "./loader.css";

function Loader({ isActive }) {
  return (
    <>
      {isActive && (
        <div className="se-pre-con">
          <div className="child">
            <img className="im-g" src={require("./preloader.gif")} />
          </div>
        </div>
      )}
    </>
  );
}

export default Loader;
