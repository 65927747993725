import '../Home/Home.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';

function Home() {
    let navigate = useNavigate();
    const[teamMemberLogin,setteamMemberLogin]=useState(true);
    useEffect(()=>{
        const items= JSON.parse(localStorage.getItem('UserClaims'));

        if(items!=null){
        
         if(items.Member_Type==2){
           setteamMemberLogin(false)
         }
        }
    },[])
    //const UserClaims=JSON.parse(localStorage.getItem('UserClaims'));

    return (
        <div className='container-fluid' style={{paddingRight:'0px' , paddingLeft:'0px',marginTop:'97px'}}>
                        
                <div className='container-fluid' style={{paddingRight:'0px' , paddingLeft:'0px'}}>
                    <div className='row'>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className='homediv container-fluid'>
            <p class="hometitlee">Home</p>   
            </div> 
            </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className='row'>
                    <div className='landing-bg'>
                <div className='maincontainer '>
                <div className='center-content container'>
                       {teamMemberLogin &&( <div className='col-md-4 col-sm-12' style={{textAlign:'center'}}>
                            <figure className='bg-content' style={{fontSize:"30px",padding:"18px",color:"#01d95c"}} onClick={() => navigate('/businessjournal')}>
                                {/* <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/bussinessjournal.png" width="50" height="50" className="img-cls" alt=""

                                /> */}  <div  alt=""

                                />Business Outlook
                            </figure>
                            {/* <p className='service-info-title'>Business Outlook</p> */}
                        </div>)}

                        <div className='col-md-4 col-sm-12'style={{textAlign:'center'}}>
                            <figure className='bg-content' style={{fontSize:"30px",padding:"18px",color:"#01d95c"}} onClick={() => navigate('/snapshot')}>
                                {/* <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/hampton_connect/assets/images/snapshot.png" width="50" height="50" className="img-cls" alt=""

                                />   */}
                                <div    alt="" 
                                   
                                />At a Glance
                            </figure>
                            {/* <p className='service-info-title'>At a Glance</p> */}
                        </div>

                    </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Home;