import "bootstrap/dist/css/bootstrap.min.css";
import "../Tab_manageuser/Tab_user.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { mainAxios } from "../middleware/interceptor";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import React from "react";
import Popup from "reactjs-popup";
import { Spinner } from "../Component/Spinner";
import { color, fontSize } from "@mui/system";
import { Hidden } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { trim } from "jquery";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

let courseornot = false;
let deptornot = false;
let Roles = [
  // {id:0,Role:'General Manager'},
  // { id:4,Role:'Super Admin'},
  // { id:1,Role:'Department Head'},
  // { id:2,Role:'Team Member'}
];

const defaultFormState = {
  selectRole: "",
  firstName: "",
  lastName: "",
  PMail:"",
  email: "",
  password: "",
  Phone: "",
  Courseid: "",
  Deptid: "",
  State: "",
  Country: "",
  Zipcode: "",
  Location: "",
  Address: "",
  Street_number: "",
  City: "",
};

function AddTeamMember({ editUser, setEditUser,showDept,setShowDept}) {
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const [Role, setRole] = useState(Roles);
  const [showAddcourse, setshowAddcourse] = useState(false);
  const [DeptHead, setDeptHead] = useState(false);
  const [comid, setcomid] = useState();
  const [roleid, setroleid] = useState();
  const [course, setCourse] = useState(null);
  const [update, setupdate] = useState(null);
  const [dept, setdept] = useState();
  const [coursepopup, setcoursepopup] = useState(false);
  //const [showDept, setShowDept] = useState(false);
  const [v, setv] = useState(true);
  const [CourseValue, SetCourseValue] = useState();
  const [DeptValue, SetDeptValue] = useState();
  const [disabledept, setdisabledept] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const userSchema = yup.object().shape({
    selectRole: yup.string().required("Please Select Role"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),

    PMail:  yup.string().required("Email is required").email('Invalid Email format').matches("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$",
    "Invalid Email Format"),
    // .test("check-valid-email", "User Name already exists"),
    email:  yup.string().required("User Name is required").email('Invalid User Name format').matches("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$",
      "Invalid User Name Format")
      .test("check-valid-email", "User Name already exists",
      
      function (value) 
      {

console.log(value,"Valuee")

        // if (!value) {
        //   return true;

        // }
        
        if (editUser) {
          return true;
        } else {
          return mainAxios
            .get("Api/User/CheckEmailValid/" + value)
            .then((res) => {
              return !res.data;
            })
            .catch((err) => {
              console.log(err);
              return false;
            });
        }
      }),
    password: yup.string().required("Password is required"),

    
    // Phone: yup.string().matches(/^\d{10}$/, 'Phone number must be a 10-digit number').required('Phone number is required'),
    Phone: yup.string().required("Contact Number is required").min(10, "Contact Number should be 10 digit").max(10).matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),

    Courseid: yup.string().required("Please Select Course"),
    State: yup.string().when(() => {
      if (ShowCourse == true) {
        return yup.string().required("State is required");
      } else {
        return yup.string();
      }
    }),
    Country: yup.string().when(() => {
      if (ShowCourse == true) {
        return yup.string().required("Country is required");
      } else {
        return yup.string();
      }
    }),
    Zipcode: yup.string().when(() => {
      if (ShowCourse == true) {
        return yup.string().required("Zip is required");
      } else {
        return yup.string();
      }
    }),
    Location: yup.string().when(() => {
      if (ShowCourse == true) {
        return yup.string().required("Location is required");
      } else {
        return yup.string();
      }
    }),
    Address: yup.string().when(() => {
      if (ShowCourse == true) {
        return yup.string().required("Address is required");
      } else {
        return yup.string();
      }
    }),
    Street_number: yup.string().when(() => {
      if (ShowCourse == true) {
        return yup.string().required("Street Name is required");
      } else {
        return yup.string();
      }
    }),
    City: yup.string().when(() => {
      if (ShowCourse == true) {
        return yup.string().required("City is required");
      } else {
        return yup.string();
      }
    }),
    Deptid: yup.string().when("selectRole", (selectRole) => {
      if (selectRole == 2 || selectRole == 1) {
        return yup.string().required("Please Select Department");
      }
    }),
  });

  const DeclareFunction = (data, departmentData) => {
    let Member_Type = items.Member_Type;
    let splUser = items.Special_user;
    let Acc_type = items.Account_Type;
    let company = items.Company;
    console.log(Member_Type, "Member Type");
    if (Member_Type == 4) {
      setshowAddcourse(true);
      setRole([
        { id: 0, Role: "General Manager" },
        { id: 4, Role: "Super Admin" },
        { id: 1, Role: "Department Head" },
        { id: 2, Role: "Team Member" },
      ]);
      setdept(departmentData);
      setCourse(data.AllUserTypes);
    } else if ((splUser == 0 || splUser == 2) && Acc_type == 0) {
      setRole([
        { id: 1, Role: "Department Head" },
        { id: 2, Role: "Team Member" },
      ]);
      SetCourseValue(items.Company);
      setshowAddcourse(false);
      setCourse(data.AllUserTypes.filter((x) => x.Coursename == items.Company));
      // defaultFormState.Courseid = items.Company
      courseornot = true;
      setcomid(company);
      setdept(departmentData);
    } else if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      setRole([{ id: 2, Role: "Team Member" }]);
      SetCourseValue(items.Company);
      setshowAddcourse(false);
      setCourse(data.AllUserTypes.filter((x) => x.Coursename == items.Company));
      // defaultFormState.Courseid = items.Company
      courseornot = true;
      deptornot = true;
      // defaultFormState.Deptid = departmentData?.filter(z => z.Account_name == items.Account_Name)[0].Account_id
      setdept(
        departmentData?.filter((z) => z.Account_name == items.Account_Name)
      );
      SetDeptValue(
        departmentData?.filter((z) => z.Account_name == items.Account_Name)[0]
          .Account_id
      );
    } else {
      setRole([]);
      setshowAddcourse(false);
    }
  };




  useEffect(() => {
    if(items.Account_Type === 0) {
         updatedepts();
    }
  
 
  },[])
  const updatedepts = async () => {

    const { data } = await mainAxios.get(`Api/Hampton/UpdateallowedDepts?rest_id=${items.Restaurant_id}&company=${items.Company}`);

    //setdept(data);
  }
  
  const getData = async () => {
    const { data } = await mainAxios.get(`Api/Hampton/GetAllCouseTypes`);
    mainAxios.get(`Api/User/GetAllUserTypes?id=5`).then((res) => {
      DeclareFunction(data, res.data.AllUserTypes);
    });

    // console.log(items, "item")
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("edituser:", editUser);
    console.log(typeof editUser);
    if (typeof editUser == "string") {
      editUser = JSON.parse(editUser);
    }
    setupdate(editUser);
    reset(editUser);
    if (editUser?.selectRole == 0 || editUser?.selectRole == 1)
      setShowDept(true);

    console.log(update, "update");
    console.log("Userid", update != null ? update.Userid : "No");
    if (editUser !== null) {
      setv(false);
    }
    if (editUser?.selectRole == 0) setShowDept(false);
  }, [editUser]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    resetField,

    formState: { touched, errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: defaultFormState,
    resolver: yupResolver(userSchema),
  });

  // const CheckValidEmail = (e) => {
  //   console.log("fregje", defaultFormState);
  //   let filter =
  //     /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  //   if (!filter.test(e)) {
  //     console.log("Please provide a valid email address");
  //     //email.focus;
  //     //return false;
  //   } else {
  //     mainAxios.get("Api/User/CheckEmailValid/" + e).then((res) => {
  //       console.log(res.data, "EmailValid");

  //       if (res.data !== 0) {
  //         toast.error("Email already exists");
  //         setEmailExistsError(true);
  //       } else if (res.data == 0) {
  //         setEmailExistsError(false);
  //         //resetField("email", { defaultValue: e });
  //       }
  //     });
  //   }
  // };

  const handleRole = (value) => {
    if (value == 4 || value == 0) {
      setShowDept(false);
      setShowCourse(false);
    } else {
      setShowDept(true);
      setShowCourse(false);
    }

    if (value == 0) {
      setDeptHead(true);
    } else {
      setDeptHead(false);
      setShowCourse(false);
    }
  };

  const onSubmit = (data) => {
    //editUser?updateapi:addapi

    setShowCourse(false);
    setcoursepopup(false)
    setEditUser(null);
    console.log(data, "datasonSubmit");
    //alert(data.Account_id)
    if (v) {
      if (!emailExistsError) {
        reset(defaultFormState);
        resetField("email", { defaultValue: "" });
        defaultFormState.email = "";
        resetField("email", { defaultValue: "" });
        setShowDept(false);
        console.log("add");
        var usrNew = {
          course: data.Courseid == "" ? CourseValue : data.Courseid,
          PrimaryEmail:data.Pmail,
          Email: data.email,
          UserId: data.userId,
          cAddress: data.Address,
          cCity: data.City,
          cCountry: data.Country,
          cLocation: data.Location,
          cState: data.State,
          cStreet_number: data.Street_number,
          cZipcode: data.Zipcode,

          department: data.Deptid,
          fname: data.firstName,
          lname: data.lastName,
          phone: data.Phone,
          psw: data.password,
          role: data.selectRole,
        };
        mainAxios.post(`Api/Hampton/AddNewUser`, usrNew).then((res) => {
          if (res.data.length > 0) {
            res.data.map((x) => {
              toast.success(x.Message);
            });
          }
          console.log(res.data);

          //alert(res.data[0].message)
        });
      } else {
        toast.error("User Name Already Exists");
      }
    } else {
      setShowDept(false);

      reset(defaultFormState);
      resetField("email", { defaultValue: "" });
      defaultFormState.email = "";

      console.log("update");
      var usrNew = {
        course: data.Courseid,
        UserName:data.email,
        PrimaryEmail:data.email1,
        Email: data.email,
        UserId: update != null ? update.Userid : "",
        cAddress: data.Address,
        cCity: data.City,
        cCountry: data.Country,
        cLocation: data.Location,
        cState: data.State,
        cStreet_number: data.Street_number,
        cZipcode: data.Zipcode,

        department: data.Deptid,
        fname: data.firstName,
        lname: data.lastName,
        phone: data.Phone,
        psw: data.password,
        role: data.selectRole,
      };
      mainAxios.post(`Api/hampton/Updateuser`, usrNew).then((res) => {
        if (res.data.length > 0) {
          res.data.map((x) => {
            setv(true);
            toast.success(x.Message);
          });
        }
      });
    }
  };
  const [clr, setclr] = useState("success");
  const [courseName, setcourseName] = useState("");
  const [ShowCourse, setShowCourse] = useState(false);


  const [CourseError,setCourseerror]=useState('Course Name Is Exist!')

  const [enable, setenable]=useState(1);



  const[errorrs,seterrorrs]=useState('')

  console.log(courseName,'Course')

  useEffect(() => {
    const fetchData = async () => {
      try {

        const encodedString = btoa(courseName);
        console.log(encodedString,"Encode")
        const response = await mainAxios.get(`Api/User/CheckCourseValid/${encodedString}/`);
        console.log(response.data);


if(response.data==0)
{

  setenable(0)
//toast.error('Course is already there')

}
else{
  setenable(1)

  // setCourseerror("")

}

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [courseName]);


  const handleCourse = () => {
    if (courseName !== "" && courseName !== undefined) {





      setCourse([...course, { Coursename: courseName }]);

      setcourseName();
      setShowCourse(true);
    } else {
      setShowCourse(false);
      //   setclr('error')
    }
    const handlePaste = (event) => {
      event.preventDefault();
    };
  };

  const rl =
    Role.length > 0
      ? Role.map((x) => <option value={x.id}>{x.Role}</option>)
      : "";
  const handlePaste = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {dept !== undefined ? (
        <div className="container-fluid">
          <form className="ipad-form"
            style={{ width: "70%", marginLeft: "20%" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div class="row ">
              <div className="row">
                <div class="col-sm-6 col-md-5 form-group form-group1">
                  <label for="name-c" class="labeladduser">
                    First Name *
                  </label>

                  <input
                    type="text"
                    class="form-control form-control-lg inputadduser"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    style={{ height: "45px" }}
                    {...register("firstName")}
                  />
                  <p className="error-msg">{errors.firstName?.message}</p>
                </div>
                <div class="col-sm-6 col-md-5 form-group form-group1">
                  <label for="name-l" class="labeladduser">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-lg inputadduser"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    style={{ height: "45px" }}
                    {...register("lastName")}
                  />
                  <p className="error-msg">{errors.lastName?.message}</p>
                </div>
              </div>

              <div className="row">
              <div class="col-sm-6 col-md-5 form-group form-group1">
                  <label for="primaryEmail" class="labeladduser">
                    Primary Email *
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-lg inputadduser"
                    name="PMail"
                    id="PMail"
                    placeholder="Primary Email"
                    {...register("PMail")}
                    style={{ height: "45px" }}
                  />
                  <p className="error-msg">{errors.PMail?.message}</p>
                </div>
                <div class="col-sm-6 col-md-5 form-group form-group1">
                  <label for="name-c" class="labeladduser">
                    Select Role *
                  </label>
                  <select
                    type="text"
                    disabled={editUser}
                    onClick={(e) => {
                      handleRole(e.target.value);
                      if(e.target.value==="0"){    
                        setcoursepopup(true);  
                      }
                      
                    }}
                    class="form-control form-control-lg selectoption1 inputadduserselect"
                    aria-label="Default select example"
                    name="selectRole"
                    id="selectRole"
                    placeholder="Select Your Option"
                    style={{
                      height: "45px",
                      color: "#8b8b8b",
                      borderColor: "#8d8d8d",
                      fontWeight: "500",
                      borderWidth: "0.5px",
                      fontSize: "12px",
                    }}
                    {...register("selectRole")}
                  >
                    <option value="" disabled selected>
                      Select your option
                    </option>

                    {rl}
                    {/* <option value="4">Super Admin</option>
                            <option value="0">General Manager</option>
                            <option value="2">Department Head</option>
                            <option value="1">Team Member</option> */}
                  </select>
                  <p className="error-msg">{errors.selectRole?.message}</p>
                </div>
{
  console.log(errors.email,"Paravaiye")
}                
              </div>
              <div className="row">
                <div class="col-sm-6 col-md-5 form-group form-group1">
                  <label for="email" class="labeladduser">
                    User Name *
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-lg inputadduser"
                    name="email"
                    id="email"
                    placeholder="UserName"
                    style={{ height: "45px" }}
                    {...register("email")}
                    // onChange={(e) => {
                    //   CheckValidEmail(e.target.value);
                    // }}
                  />
                  <p className="error-msg">{errors.email?.message}</p>
                </div>

               
                <div class="col-sm-6 col-md-5 form-group form-group1">
                  <label for="address-1" class="labeladduser">
                    Password *
                  </label>
                  <input
                    type="address"
                    class="form-control form-control-lg inputadduser"
                    id="password"
                    name="password"
                    style={{ height: "45px" }}
                    placeholder="**********"
                    {...register("password")}
                  />
                  <p className="error-msg">{errors.password?.message}</p>
                </div>
              </div>

              <div className="row">
                <div class="col-sm-6 col-md-5 form-group form-group1">
                  <label for="address-1" class="labeladduser">
                    Phone Number *
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-lg inputadduser"
                    name="Phone"
                    id="Phone"
                    placeholder="Phone Number"
                    style={{ height: "45px" }}
                    {...register("Phone")}
                    onInput={
                      
                      function restrictInputLength(event) {
                      const input = event.target;
                      const inputValue = input.value.replace(/\D/g, ""); // Remove non-digit characters
                      const maxLength = 10;
                  
                      if (inputValue.length > maxLength) {
                        input.value = inputValue.slice(0, maxLength);
                      } else {
                        input.value = inputValue;
                      }
                    }}
                  />                       
                       
                  <p className="error-msg">{errors.Phone?.message}</p>
                </div>
                <div class="col-sm-6 col-md-5 form-group form-group1">
                  {/* <div class="form-group"> */}
                  {/* style={{ width: "35em", marginTop: "-26px" }} */}
                  <label
                    for="address-1"
                    class="labeladduserselect labeladduser"
                  >
                    Select Course *
                  </label>

                  {/* {['Coursename'].map(key=>{
                      <select type="text" class="form-control n-fm" aria-label="Default select example" name="Course" id="Course" placeholder="Enter Course Name" {...register("Course")} >
                     {course!=null?course.map(({ [key]: value }) => <option key={value}>{value}</option>): <option value="1">Blue Sky</option>}
                     </select>
                })} */}
                  <select
                    type="text"
                    disabled={editUser}
                    class="form-control form-control-lg selectoption2 inputadduserselect"
                    aria-label="Default select example"
                    name="Courseid"
                    id="Courseid"
                    style={{
                      height: "45px",
                      color: "#8b8b8b",
                      borderColor: "#8d8d8d",
                      fontWeight: "500",
                      borderWidth: "0.5px",
                      fontSize: "12px",
                    }}
                    placeholder="Enter Course Name"
                    {...register("Courseid")}
                  >
                    <option value="" disabled selected>
                      Select your option
                    </option>
                    {course != null
                      ? course.map((result) => (
                          <option value={result.Coursename}>
                            {result.Coursename}
                          </option>
                        ))
                      : ""}
                  </select>
                  {/* <option value="1">Blue Sky</option>
  <option value="2">Country Club of Ocala</option>
  <option value="3">DEMO</option>
  <option value="4">Eagle Harbor</option>
  <option value="6">Elizabethton</option>
  <option value="7">Esplanade at Azario Lakewood Ranch</option>
  <option value="8">Everglades</option> */}

                  <p className="error-msg">{errors.Courseid?.message}</p>
                  {/* {showAddcourse ? DeptHead ? (<><input type="text" id="courseName" value={courseName} onChange={(e) => { setcourseName(e.target.value) }}></input>
                                    <button type="button" onClick={handleCourse} class="btn btn-primary n-btn">Add Course</button></>) : null : null} */}
                </div>

                {showDept && (
                  <div class="col-sm-12 col-md-10 form-group">
                    {/* style={{ width: "420px", marginLeft: " 59px", marginTop: "-26px" }} */}
                    <label
                      for="address-1"
                      class="labeladduserselect labeladduser"
                      style={{ paddingBottom: "2%" }}
                    >
                      Department Name *
                    </label>
                    <select
                      disabled={editUser}
                      type="text"
                      class="form-control form-control-lg selectoption2 inputadduserselect"
                      aria-label="Default select example"
                      name="Deptid"
                      id="Deptid"
                      placeholder="Department Name"
                      style={{ height: "45px", color: "#8b8b8b",fontSize: "12px", }}
                      {...register("Deptid")}
                    >
                      <option value="" disabled>
                        Select Your Option
                      </option>
                      {dept != null
                        ? dept.map((result) => (
                            <option value={result.Account_id}>
                              {result.Account_name}
                            </option>
                          ))
                        : ""}
                    </select>

                    {/* <input type="address" class="form-control n-fm" name="Department" id="Department" placeholder="Enter Department Name" {...register("Department")} /> */}
                    <p className="error-msg">{errors.Deptid?.message}</p>
                  </div>
                )}

                {showAddcourse && coursepopup ?  (
                  DeptHead ? (
                    <Popup
                      trigger={
                        <Tooltip style={{position:'absolute'}}
                          title={
                            <span className="my-tooltip-title">ADD COURSE</span>
                          }
                        >
                          <IconButton className="icon">
                            <i
                              class="bi bi-plus"
                              style={{
                                cursor: "pointer",
                                color: "black",
                                fontSize: "25px",
                              }}
                            ></i>
                          </IconButton>
                        </Tooltip>
                      }
                      modal
                      nested
                    >
                      {/* if(eventKey=="Enter"){
                                            if(courseName!==undefined){setclr('success');handleCourse();close()}else{setclr('error')}
                                        } */}
                      {(close) => (
                        <div
                          class="modals"
                          onKeyPress={(eventKey) => {
                            if (eventKey.key == "Enter") {
                              if (
                                courseName !== undefined &&
                                courseName !== null &&
                                courseName !== ""
                              ) {
                                if (
                                  course.filter(
                                    (x) => x.Coursename == courseName
                                  ).length == 0
                                ) {
                                  setclr("success");
                                  handleCourse();
                                  close();
                                } else {
                                  setclr("error");
                                }
                              } else {
                                setclr("error");
                              }
                            }
                          }}
                        >
                          <div>
                              <div>
                            <button
                              className="close"
                              onClick={close}
                              // style={{ marginTop: "-136px" }}
                            >
                              &times;
                            </button>
                          </div>
                            <TextField
                              value={courseName}
                              required={true}
                              color={clr}
                              autofocus={true}
                              onChange={(e) => {
                                setcourseName(e.target.value);
                              }}
                              label="Course"
                              InputProps={{ style: { fontSize: 17 } }}
                              InputLabelProps={{ style: { fontSize: 17 } }}
                              className="CourseTextField"
                              style={{
                                width: "189px",
                                marginLeft: " 17px",
                                marginTop: "15px",
                              }}
                            />
                        {
                       enable==0&&courseName!="" ?(
                        <div style={{marginTop:"15px",textAlign:'center',marginTop:'10px'}}> <p style={{fontSize:'12px',color:'red',marginBottom:'5px'}}>{CourseError}</p></div>
                         
                          ): <p>{""}</p>}
                          </div>
                          

                          {console.log(enable,"End")}
                          <div style={{ textAlign: "center" }}>
                            <Button

                            
                              variant="contained"
                              disabled={enable ==0 ? true : false}
                              onClick={() => {
                                if (
                                  courseName !== undefined &&
                                  courseName !== null &&
                                  courseName !== ""
                                ) {
                                  if (
                                    course.filter(
                                      (x) => x.Coursename == courseName
                                    ).length == 0 && enable==1
                                  ) {
                                    setclr("success");
                                    handleCourse();
                                    
                                    close();
                                  } else {
                                    setclr("error");
                                  }
                                } else {
                                  setclr("error");
                                }
                              }}
                              style={{
                                border: "none",
                                // marginTop: "30px",
                                backgroundColor: "#24D153",
                                boxShadow: "none",
                                textTransform: "capitalize",
                                fontSize: "15px",
                                fontWeight: "500",
                                borderRadius: "3px",
                                padding: "7px 10px",
                                opacity: enable==0 ? 0.65 : 1, // Apply opacity conditionally based on disabled prop
                                pointerEvents: enable==0  ? "none" : "auto", // Apply pointerEvents conditionally based on disabled prop
                                cursor: enable==0  ? "not-allowed" : "pointer",
                              }}
                            >
                              {" "}
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                Add Course{" "}
                              </span>{" "}
                            </Button>
                          </div>
                        
                        </div>
                      )}
                    </Popup>
                  ) : null
                ) : null}
              </div>
              {ShowCourse && (
                <div>
                  <div className="row">
                    <div class="col-sm-12 form-group">
                      <div class="course_design" style={{ width: "83%" }}>
                        Course Address
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-5 form-group">
                      <label for="name-l" class="n-lab-el labeladduser">
                        Location *
                      </label>
                      <input
                        type="text"
                        style={{ height: "45px" }}
                        class="form-control inputadduser"
                        name="Location"
                        id="Location"
                        placeholder="Enter Location"
                        {...register("Location")}
                      />
                      <p className="error-msg">{errors.Location?.message}</p>
                    </div>
                    <div class="col-sm-5 form-group">
                      <label for="name-c" class="n-lab-el labeladduser">
                        Street Name *
                      </label>
                      <input
                        type="text"
                        style={{ height: "45px" }}
                        class="form-control inputadduser"
                        name="Street_number"
                        id="Street_number"
                        placeholder="Enter Street Name"
                        {...register("Street_number")}
                      />
                      <p className="error-msg">
                        {errors.Street_number?.message}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-5 form-group">
                      <label for="name-l" class="n-lab-el labeladduser">
                        Address *
                      </label>
                      <input
                        type="text"
                        style={{ height: "45px" }}
                        class="form-control inputadduser"
                        name="Address"
                        id="Address"
                        placeholder="Enter Address"
                        {...register("Address")}
                      />
                      <p className="error-msg">{errors.Address?.message}</p>
                    </div>
                    <div class="col-sm-5 form-group">
                      <label for="email" class="n-lab-el labeladduser">
                        City *
                      </label>
                      <input
                        type="text"
                        style={{ height: "45px" }}
                        class="form-control inputadduser"
                        name="City"
                        id="City"
                        placeholder="Enter City"
                        {...register("City")}
                      />
                      <p className="error-msg">{errors.City?.message}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-5 form-group">
                      <label for="address-1" class="n-lab-el labeladduser">
                        State *
                      </label>
                      <input
                        type="text"
                        style={{ height: "45px" }}
                        class="form-control inputadduser"
                        id="State"
                        placeholder="Enter State"
                        name="State"
                        {...register("State")}
                      />
                      <p className="error-msg">{errors.State?.message}</p>
                    </div>
                    <div class="col-sm-5 form-group">
                      <label for="address-1" class="n-lab-el labeladduser">
                        Country *
                      </label>
                      <input
                        type="address"
                        style={{ height: "45px" }}
                        class="form-control inputadduser"
                        name="Country"
                        id="Country"
                        placeholder="Enter Country"
                        {...register("Country")}
                      />
                      <p className="error-msg">{errors.Country?.message}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-5 form-group">
                      <label for="address-1" class="n-lab-el labeladduser">
                        Zip *
                      </label>
                      <input
                        type="text"
                        style={{ height: "45px" }}
                        class="form-control inputadduser"
                        onPaste={handlePaste}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        id="Zipcode"
                        placeholder="Enter Zip Code"
                        name="Zipcode"
                        {...register("Zipcode")}
                      />
                      <p className="error-msg">{errors.Zipcode?.message}</p>
                      <p className="error-msg">
                        *This zip code will mapped to weather report.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* <div class="col-sm-10"> */}
              <button class="buttonadd" type="submit">
                <span class="n-up-pr">
                  {editUser ? "Update User" : "Add User"}
                </span>
              </button>
              {/* </div> */}
              <div>
                <div></div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}
export default AddTeamMember;
