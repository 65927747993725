
import Table from 'react-bootstrap/Table';
import '../TableComponent'
import { useState } from 'react';
import moment from 'moment'
import CurrencyFormat from "react-currency-format";

const BookedTenderedRoundsTable= ({handleActualRound, header, bookedRoundData}) => {

const items = JSON.parse(localStorage.getItem("UserClaims"));

 
    return (
  
      <div className='table-scroll table-responsive table1 '>
        <Table className='bookten' striped bordered hover>
          <thead>
            <tr>
          
            {header?.map((head, index) =>              
                <th> <div className='tab-card-new admin-budget-th'>{head.column}</div></th>
                // style={{padding:'15px 8px 15px 0px'}}
            )}

            </tr>
            
          </thead>
          <tbody>
            
            {bookedRoundData?.map(item =>
              {

              {console.log('k',new Date(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]))}

              return <tr>
                 <td className='booked-tabledata admin-budget-td' style={{width:'35%'}}>
                  <div className='row'>
                    <div className='col-md-3 col-lg-3'>
                       <p> {moment(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]).format('dddd')}</p>
                    </div>
                     <div style={{marginTop: '-5px'}} className='col-md-9 col-lg-9 booked-table'>
                       <i class="bi bi-calendar3"></i> {`${moment(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]).format('MMM')} ${moment(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]).format('D')},${moment(item.date.split(' ')[0].split('-')[2]+'-'+item.date.split(' ')[0].split('-')[1]+'-'+item.date.split(' ')[0].split('-')[0]).format('yyyy')}`}
                    </div>
                  </div>
                  {/* <div style={{display:'flex'}}><span></span><span style={{marginLeft:'10px'}}></span></div> */}
                 
                    </td>
               
                <td className='booked-tabledata' style={{width:'35%'}}> <p className=" fl-in" style={{width:'114%'}}>
                
                <input type='text' style={{marginTop:'2px'}}
                    className="input-edit-monthly admin-budget-td"
                    // {
                    //   cat.Editable_Status === 1
                    //     ? "input-edit-monthly"
                    //     : "non-edit-cs"
                    // }
                   
                    id={item.Actual_Rounds}
                    name={item.Actual_Rounds}
                    value={item.Actual_Rounds}                   
                    disabled={(item.Actual_Editable_Status==1 && items.Account_Type==2) || (item.Actual_Editable_Status==1 && items.Member_Type==2 && items.Account_Name=="Golf Operations")?false:true}
                    onChange={(e) => handleActualRound(item.date, e.target.value)
                    }
                  />
                  </p></td>
                <td className='admin-budget-td dash-td' style={{width:'35%'}}><p>{item.Forecast_Rounds}</p></td>
               
              </tr>
})}         
  
          </tbody>
  
        
          
        </Table>
  
      </div>
  
    );
  }

export default BookedTenderedRoundsTable;