import Table from "react-bootstrap/Table";
import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../Datepagenator.css";
import "./TableComponent.css";
import "../../index.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { mainAxios } from "../../middleware/interceptor";
import Pagination from "@mui/material/Pagination";
import { ToastContainer, toast } from "react-toastify";
import { PaginationItem } from "@mui/material";

import image from "../../folder.png";
const TableComponent = ({
  user_up,
  set_user_up,
  header,
  data,
  nestedColumn,
  searchFunction,
  Search,
  pagination,
  getPage,
  isFooter,
  editUser,
  loader,
  DeleteUser,
  searchstate,
  recordPerPage,
  setRecordPerPage,
}) => {
  console.log(searchstate, "dataa");

  const renderTooltip = (props) => <Tooltip {...props}>Edit</Tooltip>;
  const renderTooltip1 = (props) => <Tooltip {...props}>Delete</Tooltip>;
  const [show, setShow] = useState(false);
  //const [recordPerPage, setRecordPerPage] = useState(5);
  const [users, setUser] = useState(false);
  const [deleteitem, setitem] = useState();
  let [length, setlength] = useState(recordPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.list.length / recordPerPage);

  useEffect(() => {
    if (data.list.length > 0) {
      setUser(data.list.slice(0, recordPerPage));
      console.log("data:", users);
      setShow(true);
    } else {
      setShow(false);
    }
  }, [data]);

  // useEffect(() => {
  //   setUser(
  //     data.list.slice(0 * recordPerPage, 0 * recordPerPage + recordPerPage)
  //   );
  // }, [recordPerPage, length]);

  useEffect(() => {
    setUser(data.list.slice((currentPage - 1) * recordPerPage, currentPage * recordPerPage));
  }, [recordPerPage, currentPage, data]);

  useEffect(() => {
    if (data.list.length > 0) {
      setUser(data.list.slice(0, recordPerPage));
      setShow(true);
    } else {
      setShow(false);
    }
  }, [data]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchstate]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handlechange = (e) => {
    editUser(e);
  };

  const handledelete = async (e) => {
    loader(true);
    console.log("DElete", e);
    var usrNew = {
      UserId: e.Userid,
      Posid: e.Posid,
      status: 3,
    };

    mainAxios
      .post(`Api/Hampton/DeleteUser`, usrNew)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.map((x) => {
            setShow(true);

            toast.success(x.Message);
            setTimeout(() => {
              set_user_up(!user_up);
              loader(false);
            }, 500);
          });
        }
      })
      .catch(() => {
        loader(false);
      });
  };

  const ActiveorDeActive = (e, x) => {
    loader(true);
    console.log(e, "changingActive");
    console.log("DElete", x);
    var usrNew = {
      UserId: x.Userid,
      Posid: x.Posid,
      status: e ? 1 : 2,
    };

    mainAxios.post(`Api/Hampton/DeleteUser`, usrNew).then((res) => {
      if (res.data.length > 0) {
        res.data
          .map((x) => {
            setShow(true);

            toast.success(x.Message);
            setTimeout(() => {
              set_user_up(!user_up);
              loader(false);
            }, 500);
          })
          .catch(() => {
            loader(false);
          });
      }
    });
  };

  const [shows, setShows] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(false);

  return (
    <>
      <div>
        <div>
          <div class="row">
            <div class="col-sm-6" style={{ float: "left" }}>
              {/* <span>
                    {" "}
                    <b>Show</b>
                  </span>
                  <Form.Select
                    className="dr-sel"
                    aria-label="Default select example"
                    onChange={(e) => setRecordPerPage(+e.target.value)}
                  >
                    <option value="5">5</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </Form.Select>
                  <span>
                    <b>Entries</b>
                  </span>
                </div>

                <div class="col-sm-6">
                  {Search && (
                    <div class="row">
                      <div class="col-sm-6">
                        <i
                          class="bi bi-search"
                          style={{
                            marginLeft: "0em",
                            float: "right",
                            marginRight: "1em",
                            color: "#686464",
                          }}
                        />
                      </div>
                      <div class="col-sm-6">
                        <input
                          id="search-box"
                          value={searchstate}
                          class="sear"
                          placeholder="Global Filter"
                          onChange={(e) => searchFunction(e.target.value)}
                        />
                      </div>
                    </div>
                  )} */}
            </div>
          </div>
          {show ? (
            <div class="table-scroll">
              <Table className="forbusidailytabi" striped bordered hover>
                <thead>
                  <tr>
                    {/* {!nestedColumn &&
                        header.map((head) => (
                          <th style={{ zIndex: 1 }}>
                            <div className="tab-card-new admin-budget-th">{head.column}</div>
                          </th>
                        ))} */}
                    <th style={{ zIndex: 1 }}>
                      <div className="tab-card-new admin-budget-th">
                        First Name
                      </div>
                    </th>
                    <th style={{ zIndex: 1 }}>
                      <div className="tab-card-new admin-budget-th">
                        Last Name
                      </div>
                    </th>
                    <th style={{ zIndex: 1 }}>
                      <div className="tab-card-new admin-budget-th">Email</div>
                    </th>
                    <th style={{ zIndex: 1 }}>
                      <div className="tab-card-new admin-budget-th">
                        Password
                      </div>
                    </th>
                    <th style={{ zIndex: 1 }}>
                      <div className="tab-card-new admin-budget-th">Course</div>
                    </th>
                    <th>
                      <div className="tab-card-new admin-budget-th">
                        Department
                      </div>
                    </th>
                    <th style={{ zIndex: 1, width: "5%" }}>
                      <div className="tab-card-new admin-budget-th">Role</div>
                    </th>
                    <th style={{ zIndex: 1, width: "5%" }}>
                      <div className="tab-card-new admin-budget-th">Status</div>
                    </th>

                    <th style={{ zIndex: 1 }}>
                      <div className="tab-card-new admin-budget-th">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody class="">
                  {users?.map((item) => {

                    
                    console.log(item, "fromitemt");
                    let user = Object.assign({}, item);
                    delete user["Phone"];
                    delete user["Courseid"];

                    delete user["Deptid"];
                    delete user["selectRole"];
                    delete user["Userid"];
                    delete user["Posid"];
                    delete user["status_d"];
                    delete user["FobesoftStatus"];
                    delete user["PMail"];
                    // console.log("ditem:",item);

                    return (
                      <tr>
                        {Object.keys(user).map((key) => {
                          let color;


                          {console.log(item[key],"Item")}
                          if (item[key].toString().toUpperCase().replace(/\s/g, "") === "SUPERUSER")
                            
                            {
                            color = "label-success";
                          }
                          if (
                            item[key]
                              .toString()
                              .toUpperCase()
                              .replace(/\s/g, "") === "GENERALMANAGER"
                          ) {
                            color = "label-info";
                          }
                          if (
                            item[key]
                              .toString()
                              .toUpperCase()
                              .replace(/\s/g, "") === "DEPARTMENTHEAD"
                          ) {
                            color = "label-primary";
                          }
                          if (
                            item[key]
                              .toString()
                              .toUpperCase()
                              .replace(/\s/g, "") === "TEAMMEMBER"
                          ) {
                            color = "label-warning";
                          }

                          return (
                            <>
                              <td className="admin-budget-td">
                                {key === "Role" && (
                                  <span style={{fontSize:'14px'}} class={"label " + color}>
                                    {item[key]}
                                  </span>
                                )}

                                {/* {key === "FobesoftStatus" && (
                                    <span class="label label-default">
                                      {item[key]}
                                    </span>
                                  )} */}
                                  {console.log("Before transformation:", item[key])}

{key === "Status" ? (
  <BootstrapSwitchButton
    checked={item[key]}
    size="sm"
    onChange={(e) => {
      ActiveorDeActive(e, item);
      searchFunction("");
    }}
  />
) : key !== "Role" && key !== "Status" && key !== "FobesoftStatus" && key!=='firstName'&& key!=='lastName' ? (
  item[key]
) : key === 'firstName' || key ==='lastName'?
 (
  item[key].trim().charAt(0).toUpperCase() + item[key].trim().slice(1)
  ) : null}
                              </td>{" "}
                            </>
                          );
                        })}
                        <td style={{ textAlign: "center" }}>
                          <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip}
                          >
                            <i
                              style={{
                                cursor: "pointer",
                                color: "#7b7b7b",
                                marginRight: "25px",
                              }}
                              class="bi bi-pencil-square pencil-icon"
                              onClick={() => handlechange(item)}
                            ></i>
                          </OverlayTrigger>{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip1}
                          >
                            <i
                              style={{ cursor: "pointer", color: "#7b7b7b" }}
                              class="bi-trash trash-icon"
                              onClick={() => {
                                setShows(true);
                                setitem(item);
                              }}
                            ></i>
                          </OverlayTrigger>
                        </td>

                        {/* <Modal
                            show={shows}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                          >
                            <Modal.Header>
                              <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure you want to delete account?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={(e) => {
                                  handleClose(e);
                                  setShows(false);
                                  setShow(true);
                                }}
                              >
                                No
                              </Button>

                              <Button
                                variant="primary"
                                onClick={(e) => {
                                  handledelete(item);
                                  handleClose(e);
                                  setShows(false);
                                }}
                              >
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal> */}
                      </tr>
                    );
                  })}
                </tbody>

                {isFooter && (
                  <tfoot>
                    <tr>
                      <td>TOTAL</td>
                      <td>
                        <span>$</span> <p className="fl-r">{data.total}</p>
                      </td>
                      <td>
                        <p className="fl-r">
                          {data.percentage}
                          <span>%</span>
                        </p>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </Table>
            </div>
          ) : (
            <div>
              <div class="table-scroll" style={{ overflow: "hidden" }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {!nestedColumn &&
                        header.map((head) => (
                          <th>
                            <div className="tab-card-new admin-budget-th">{head.column}</div>
                          </th>
                        ))}
                    </tr>
                  </thead>
                </Table>

                <img
                  src={image}
                  className="img"
                  style={{ margin: "0 auto", display: "block" }}
                />

                <div style={{ textAlign: "center" }}>
                  <span
                    style={{
                      fontWeight: "bolder",
                      fontSize: "15px",
                      opacity: "0.6",
                      fontFamily: "FontAwesome", 
                      margin:'0 auto'                   
                    }}
                  >
                    No Data Found
                  </span>
                </div>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "45px",
              marginBottom: "17px",
            }}
          >
            {pagination && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        size="sm"
        show={shows}
        onHide={handleClose}
        className="delete-account"
      >
  <Modal.Header style={{ border: "none", borderRadius: '5px 5px 0px 0px', background: "#FFBC00",padding: '25px 10px 20px 10px'}}>
          <Modal.Title
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ color: "#fff",fontSize:'24px' }}>
              {" "}
              <i
                class="bi bi-exclamation-triangle"
                style={{ color: "#fff",fontSize:'25px' }}
              ></i>{" "}
              Warning
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "10px 30px",
          }}
        >
          {" "}
          <h1 style={{ fontSize: "16px", fontweight: "500",marginTop:'15px',marginBottom:'15px' }}>
            Are you sure you want to delete account?
          </h1>
        </Modal.Body>
        <Modal.Footer
          style={{
            textAlign: "center",
            marginRight: "0em",
            border: "none",
            padding: "0px 15px 15px 15px",
          }}
        >
          <button
            className="btn btn-danger"
            variant="mute"
            style={{
              marginRight: "0.5em",
              backgroundColor: "#FF0C00",
              borderColor: "#FF0C00",
            }}
            onClick={(e) => {   setShows(false)  }}
          >
            Cancel
          </button>
          <button
            className="btn btn-success"
            variant="mute"
            style={{ backgroundColor: "#24D153", borderColor: "#24D153" }}
            onClick={(e) => {
              handledelete(deleteitem);
              handleClose(e);
              setShows(false);
              searchFunction("");
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TableComponent;
