import "bootstrap/dist/css/bootstrap.min.css";
import "./TableComponent.css";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import React, { useState, Fragment, useRef } from "react";
import { fobeAxios } from "../../middleware/interceptor";
import { ToastContainer, toast } from "react-toastify";
import ReactToPrint from "react-to-print";
function EditableRow({
  editFormData,
  handleEditFormChange,
  EditFormSave,
  handleCancelClick,
  options,
  setEditFormData,
  handleEditFormChanges
}) {
  return (
    <tr>
      {/* <td className="admin-budget-td">{editFormData.Cat_name}</td> */}
      <td>
      <Select
          id="Cat_name"
          name="new_cat_name"
          placeholder="Select Category"
          options={options}
          value={options.find(option => option.value === editFormData.Cat_id)}
          onChange={(selectedOption) => handleEditFormChanges({ target: { name: selectedOption.label, value: selectedOption.value }})}
          menuPosition="fixed"
        />
      </td>
      <td>
        <span class="fl-lm">$</span>
        <CurrencyFormat
          className="input_field fl-r form-control"
          id="Cat_amt"
          value={editFormData.new_Cat_amt}
          onChange={handleEditFormChange}
          name="new_Cat_amt"
        />
      </td>
      <td>
      <input
          type="text"
          value={editFormData.new_Purveyor}
          onChange={handleEditFormChange}
          name="new_Purveyor"
          className="form-control"
        />
      </td>
      <td>
      <input
          type="text"
          value={editFormData.new_Details}
          onChange={handleEditFormChange}
          name="new_Details"
          className="form-control"
        />
        
      </td>
      <td style={{ verticalAlign: "middle", textAlign: "center" }}>
        <i
          class="fa fa-check"
          aria-hidden="true"
          style={{
            color: "green",
            fontSize: "1.5em",
            cursor: "pointer",
            marginRight: "1em",
          }}
          onClick={() => EditFormSave(editFormData)}
        ></i>
        <i
          class="fa fa-times"
          aria-hidden="true"
          style={{ color: "red", fontSize: "1.5em", cursor: "pointer" }}
          onClick={handleCancelClick}
        ></i>
      </td>
    </tr>
  );
}
const ReadOnlyRow = ({ row, handleEditClick, handleDeleteClick }) => {
  const items = JSON.parse(localStorage.getItem("UserClaims"));

  return (
    <tr>
      <td className="admin-budget-td">{row.Cat_name}</td>
      <td className="admin-budget-td">
        <span class="fl-lm admin-budget-td" style={{ marginTop: "0px" }}>
          $
        </span>{" "}
        <CurrencyFormat
          className="input_fieldread fl-r"
          style={{ backgroundColor: "transparent", color: row.Cat_amt < 0 ? "red" : "inherit", }}
          id="row.Cat_amt"
          fixedDecimalScale={2}
          decimalScale={2}
          prefix={row.Cat_amt < 0 ? "(" : ""}
          suffix={row.Cat_amt  < 0 ? ")" : ""}

          value={Math.abs(row.Cat_amt)}
          name="row.Cat_amt"
          ReadOnly
          disabled={true}
        />
      </td>
      <td className="admin-budget-td">{row.Purveyor}</td>
      <td className="admin-budget-td">{row.Details}</td>




      {(items.Account_Type !=0) &&

      <td style={{ verticalAlign: "middle", textAlign: "center" }}>
        <i
          class="bi bi-pencil-square pencil-icon"
          aria-hidden="true"
          style={{
            color: "#7b7b7b",
            fontSize: "1.5em",
            cursor: "pointer",
            marginRight: "1em",
          }}
          onClick={(event) => handleEditClick(event, row)}
        ></i>
        <i
          class="fa fa-trash"
          aria-hidden="true"
          style={{ color: "#7b7b7b", fontSize: "1.5em", cursor: "pointer" }}
          onClick={() => handleDeleteClick(row)}
        ></i>
      </td>
}
    </tr>
  );
};

const TableInvoice_report = ({
  rows,
  EditFormSave,
  editContactId,
  handleDeleteClick,
  handleEditFormChange,
  handleCancelClick,
  Total,
  handleEditClick,
  editFormData,
  options,
  setEditFormData,
}) => {
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const handleEditFormChanges = (eventOrSelect) => {
    const { name, value } = eventOrSelect.target || eventOrSelect;
    setEditFormData((prevData) => ({      
      ...prevData,
            new_cat_name: name,
            Cat_id: value     }));
  }; 
  return (
    <>
      {rows != "" && (
        <div className=" table-responsive table-scroll-2">
          <Table striped bordered hover>
            <thead style={{ zIndex: "0" }}>
              <tr>
                <th className="scrollviewfront">
                  <div className="tab-card-new admin-budget-th">CATEGORY</div>
                </th>
                <th>
                  <div className="tab-card-new admin-budget-th">AMOUNT</div>
                </th>
                <th>
                  <div className="tab-card-new admin-budget-th">PURVEYOR</div>
                </th>
                <th>
                  <div className="tab-card-new admin-budget-th">DETAILS</div>
                </th>

              {console.log(items.Account_Type,"NAmnajna")}

                {(items.Account_Type !=0) &&
                <th>
                  <div className="tab-card-new admin-budget-th">ACTION</div>
                </th>
                }

              </tr>
              
            </thead>
            <tbody class="tab-gf">
              {rows.map((row, index) => (

                
                <Fragment>
                  {editContactId === row.Invoice_id ? (
                    <EditableRow
                      editFormData={editFormData}
                      handleEditFormChange={handleEditFormChange}
                      handleCancelClick={handleCancelClick}
                      EditFormSave={EditFormSave}
                      options = {options}
                      setEditFormData = {setEditFormData}
                      handleEditFormChanges = {handleEditFormChanges}
                    />
                  ) : (
                    <ReadOnlyRow
                      row={row}
                      handleEditClick={(e) => {
                        if (
                          (items.Special_user == 1 ||
                            items.Special_user == 0) &&
                          items.Account_Type != 0
                        ) {
                          handleEditClick(e, row);
                        }
                      }}
                      handleDeleteClick={() => {
                        if (
                          (items.Special_user == 1 ||
                            items.Special_user == 0) &&
                          items.Account_Type != 0
                        ) {
                          handleDeleteClick(row);
                        }
                      }}
                    />
                  )}
                </Fragment>
              ))}
              <tr style={{ background: "#f1f1f1" }}>
                <td className="admin-budget-td">
                  <p>Total</p>
                </td>
                <td className="admin-budget-td">
                  <span
                    class="fl-lm admin-budget-td"
                    style={{ marginTop: "0px" }}
                  >
                    $
                  </span>
                  <CurrencyFormat
                    className="input_fieldread fl-r admin-budget-td"
                    id="Total"
                    style={{ backgroundColor: "transparent" ,color: Total < 0 ? "red" : "inherit", }}
                    prefix={Total < 0 ? "(" : ""}
                    suffix={Total  < 0 ? ")" : ""}

                    fixedDecimalScale={2}
                    decimalScale={2}
                    value={Math.abs(Total)}
                    name="Total"
                    ReadOnly
                    disabled={true}
                  />
                </td>
                <td className="admin-budget-td"></td>
                <td className="admin-budget-td"></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};
export default TableInvoice_report;
