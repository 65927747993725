import "bootstrap/dist/css/bootstrap.min.css";
import "./TableComponent.css";
import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import React from "react";
import CurrencyInput from "react-currency-input-field";
import CurrencyFormat from "react-currency-format";
const monthList = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const dayList = [
  {id:0,name:'Monday'},
  {id:1,name:'Tuesday'},
  {id:2,name:'Wednesday'},
  {id:3,name:'Thursday'},
  {id:4,name:'Friday'},
  {id:5,name:'Saturday'},
  {id:6,name:'Sunday'},
]

const SnapshotMonthlyViewTable = (props) => {
  const {Day_List,perFlag, TotalPlan_List, handleTotalRound, changeData, title, isBudget } = props;
  
  return (
    <div className=" table-scroll table-responsive table2" style={{marginTop:'20px'}}>
      
      <Table striped bordered hover>
        <thead>
          <tr>
            <th rowSpan={2}>
              <div className="title-tab Day-title"> {title} </div>
            </th>
            {TotalPlan_List?.map((item) => (
              <th colSpan={2}>
                <div className="tab-card-new admin-budget-th">
                  {monthList.find((m) => m.id == item.Fore_Month).name}
                </div>
              </th>
            ))}
          </tr>

          <tr>
            {TotalPlan_List?.map((item) => (
              <>
                <th>
                  <div className="thead-row admin-budget-td" style={{textAlign:'right',fontSize:'12px'}}>{"Round"}</div>
                </th>
                <th>
                  <div className="thead-row admin-budget-td" style={{textAlign:'right',fontSize:'12px'}}>Per(%)</div>
                </th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="estimeted-tabledata admin-budget-td">Total Number of Rounds <span style={{fontSize:'12px'}}>(Estimated) </span></td>
            {TotalPlan_List?.map((cat) => (
              <>
                <td className="admin-budget-td round-td">
                  {/* <span className="fl-lf">$</span> */}
                  {" "}
                  <p className=" fl-in">
                
                <CurrencyFormat
                    className="input-edit-monthly"
                    // {
                    //   cat.Editable_Status === 1
                    //     ? "input-edit-monthly"
                    //     : "non-edit-cs"
                    // }
                    
                    id={cat.Fore_Rounds}
                    name={cat.Fore_Rounds}
                    value={cat.Fore_Rounds?Math.round(cat.Fore_Rounds):0}
                    decimalScale={2}
                    disabled={false}
                    onValueChange={(value, name) =>
                      handleTotalRound(cat.Fore_Month, value)
                    }
                  />
                  </p>
                </td>
                <td>
                  <p className="fl-r at-per-medium" style={{display:'flex'}}>
                    <input
                      type="text"
                      class="non-edit-cs admin-budget-td"
                      value={cat.Fore_Per?cat.Fore_Per:0}
                    />
                    <span className="fl-ri admin-budget-td">%</span>
                  </p>
                </td>
              </>
            ))}
          </tr>

          {Day_List?.map((item, index) => {
            let temp = [];            
              temp.push([...item]);             
            return (
              <tr>
                <td className="admin-budget-td">{dayList.find(x => x.id==index).name}</td>{" "}
                {temp[0].map((cat) => {
                  
                  return (
                    <>
                      <td className="admin-budget-td">
                        {/* <span className="fl-lf">$</span> */}
                        {" "}
                        <p className="fl-r admin-budget-td">
                         
                          <CurrencyInput
                            className="non-edit-cs admin-budget-td"
                           // fixedDecimalScale={cat.Fore_Rounds?2:0}
                             decimalScale={cat.Fore_Rounds?2:0}
                            // {
                            //   cat.Editable_Status == 1
                            //     ? "input-edit-monthly"
                            //     : "non-edit-cs"
                            // }
                            id={cat.Fore_Month}
                            name={cat.Fore_Month}
                            value={cat.Fore_Rounds?Math.round(cat.Fore_Rounds):0}
                            defaultValue={cat.Fore_Rounds}
                            decimalsLimit={2}
                            disabled={cat.Editable_Status == 1 ? false : true}
                            // onValueChange={(value, name) =>
                            //   changeData(cat.Cat_id, value)
                            // }
                          />
                        </p>
                      </td>
                      <td>
                        <p className="fl-r admin-budget-td at-per">
                          <CurrencyInput
                            className="input-edit-monthly"
                            //  {
                            //   cat.Editable_Status == 1
                            //     ? "input-edit-monthly"
                            //     : "non-edit-per-cs"
                            // }
                            id={cat.Fore_Month}
                            name={cat.Fore_Month}
                            value={cat.Fore_Per?cat.Fore_Per:0}
                            defaultValue={cat.Fore_Per?cat.Fore_Per:0}
                            decimalsLimit={2}
                            disabled={false}
                            onValueChange={(value, name) =>
                              changeData(cat.Fore_Month, value,index)
                            }
                          />
                          <span className="fl-ri">%</span>
                        </p>
                      </td>
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>  

       
    </div>
     
  );
};
export default SnapshotMonthlyViewTable;
