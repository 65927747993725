import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { LocalStorageService } from "./Services/LocalStorageService";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { mainAxios, FobeAxios, fobeAxios } from "./middleware/interceptor";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { event } from "jquery";
import Recovery from "./Recovery";

import * as Yup from "yup";
import Modal from 'react-bootstrap/Modal';

const loginSchema = yup.object().shape({
userName: Yup.string().email("Invalid email format").required("Username is required"),

  password: yup.string().required("Password is required"),

});

function Login({ setIsActive }) {
  const key = "user_details";
  let navigate = useNavigate();
  const [pwd, setPwd] = useState("password");
  const [cls, setcls] = useState("bi bi-eye-slash-fill");
  const [items, setitems] = useState();
  const [Remember, setRemember] = useState(false);
  const[Email,setEmail]=useState("")
  const[usernme,setusernme]=useState()
  const[click1,setclick1]=useState(false)
  const[click2,setclick2]=useState(false)
  const[isValid,setisValid]=useState(null)
  const[mail,setmail]=useState(false)
  const[blank,setblank]=useState(false)
  const[blank1,setblank1]=useState(false)
  const[mail1,setmail1]=useState(false)
  const[Pass,setPass]=useState("")
  // function setWithExpiry(key, value,ttl)
  function setWithExpiry(key, value) {
    // const now = new Date()
    const item = {
      value: value,
      // expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    // const now = new Date()

    // if (now.getTime() > item.expiry) {

    //     localStorage.removeItem(key)
    //     return null
    // }
    return item.value;
  }

  let user = getWithExpiry(key);

  // console.log(user)
  let user_name;
  let password;

  if (user !== null) {
    user_name = user.userName;
    password = user.password;
  } else {
    user_name = "";
    password = "";
  }

  let {
    register,
    handleSubmit,
    setValue,
    formState: { touched, errors },
  } = useForm({
    defaultValues: {
      userName: user_name,
      password: password,
    },
    resolver: yupResolver(loginSchema),
  });
  // var data = "username=" + combainClienId + "&password=" + password + "&grant_type=password";
  const localStorageService = LocalStorageService.getService();
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Accept-Encoding": "gzip, deflate, br",
    Accept: "*/*",
    Connection: "keep-alive",
  };
  const token1 = localStorageService.getAccessToken("auth-token");
  const normalheaders = {
    Authorization: "Bearer " + token1,
    "Content-Type": "application/json",
  };

const sub=()=>{
  
console.log(blank,blank1,mail,mail1,'rrr')

    let data={
    join_email : Email,
    primaryEmail:Pass,
    }

  mainAxios.post("Api/hampton/Recovery_password",data) .then((resp) => {
    console.log(resp.data.Mail_Status[0].Message,'ooo')
  if(resp.data.Mail_Status[0].Message==="Mail Sent successfully"){
    toast.success('Mail sent Successfully')
    localStorage.setItem('email',Email)
    handleClose()
    setPass("")
  }
  else if(resp.data.Mail_Status[0].Message==="This Mail ID not Available in Our Server"){
    toast.error('This Mail ID not Available in Our Server')
  }
         })
  
  
}
const submithandle =async ()=>{
console.log(Pass,'l00')
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const isValidEmail = emailPattern.test(Email);
  const isValidEmail1 = emailPattern.test(Pass);
  //setisValid(isValidEmail)
  console.log(isValidEmail1,'p00')
  
    if(Email===""){
    setblank(true)
    setmail(false)
    
      }
   if(usernme!==Email && Email!==""){

        setmail(true)
        setblank(false)
      
        }
   if( usernme===Email && isValidEmail===false){
  
    setmail(true)
    setblank(false)

  }

  
   if(Pass===""){
    setblank1(true)
    setmail1(false)
    }
  
   if(isValidEmail1===false && Pass!=="") {
  setmail1(true)
  setblank1(false)
  }
  
  if(usernme===Email && isValidEmail)
    {
      setmail(false)
      setblank(false)
  

    }
    if(pass!=="" && isValidEmail1)
    {
      setmail1(false)
      setblank1(false)
  

    }
    if(usernme===Email && isValidEmail && pass!=="" && isValidEmail1 ){
sub()
    }
}

const   getprimail=()=>{
  mainAxios.get("Api/hampton/get_primaryMailid/"+Email).then((resp)=>{
    setPass(resp.data.Mail_Status[0].PrimaryMailId)
  })
}
const email=  (event)=>
{

  setEmail(event.target.value)
isValid(false)
  console.log(event.target.value,'ggg')
}

const pass =(event)=>{

setPass(event.target.value)
}
  const onSubmit = async (data) => {
    setIsActive(true);

    localStorage.clear();
    var datas =
      "username=" +
      data.userName +
      "-100" +
      "&password=" +
      data.password +
      "&grant_type=password";
    if (Remember) {
      var user_info = {
        userName: data.userName,
        password: data.password,
      };

      // var ttl=10000000000;

      // setWithExpiry(key,user_info,10000000000);

      setWithExpiry(key, user_info);
    }
    return await mainAxios
      .post("/token", datas, {
        headers: headers,
      })
      .then((res) => {
        localStorageService.setToken(res.data.access_token);
        if (res.data.access_token) {
          mainAxios
            .get("/Api/User/GetUserClaims")
            .then((resp) => {
              localStorage.setItem("UserClaims", JSON.stringify(resp.data));
              setIsActive(false);
              navigate("/home");
            })
            .catch((error) => {
              return Promise.reject("invalid");
            });

          console.log("login:", res);
        } else {
          setIsActive(false);
        }
        //  setIsActive(false)

        // console.log("ST",items);
      })
      .catch((error) => {
        setIsActive(false);
        toast.error("Username or Password is incorrect", {
          className: "custom-cls",
        });
        return Promise.reject("invalid");
      });
  };
  const toggle = (e) => {
    console.log("Pass");
    if (pwd == "password") {
      setPwd("text");
      setcls("bi bi-eye-fill");
    } else {
      setPwd("password");
      setcls("bi bi-eye-slash-fill");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {setShow(false);
 setmail(false)
 setblank(false)
 setmail1(false)
 setblank1(false)
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="container-fluid page-head">
        <div className="row">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h1 className="text-center">Log In</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid login-sec">
                <div className="row login-row" style={{marginTop:'30px'}}>
          <div className="container">
            <div className="row">
              <form id="loginform" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-4 col-lg-offset-4 col-md-5 col-md-offset-5 col-sm-6 col-sm-offset-3 col-xs-6 col-xs-offset-1 login-wrap">
                  <div className="form-group">
                    <label for="first" className="label_name">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="userName"
                      name="userName"

                      
                      {...register("userName")}
                      
                      onBlur={(e) => {
                        setValue("userName", e.target.value, { shouldValidate: true });
                      }}
                      // onChange={(e) => {setValue("userName", e.target.value)

                      // }}         

                      // {...register("userName")}
                      // onChange={(e)=>{setEmail(e.target.value)
                      //                  setusernme(e.target.value) }}
                     
                    />
                    <p className="error-txt">{errors.userName?.message}</p>
                  </div>
                  <div className="form-group">
                    <label for="first" className="label_name">
                      Password
                    </label>
                      {/* <i class="bi bi-eye-fill input-show" style={{display:'block'}}></i>
                       <i class="bi bi-eye-slash-fill input-hide" style={{display:'none'}}></i>  */}
                         <i class={cls} onClick={() => toggle(event)}></i>
                    <input
                      type={pwd}
                      className="form-control"
                      id="password"
                      name="password"
                      // {...register("password")}
                      
          {...register("password")}
          onChange={(e) => {
            setValue("password", e.target.value);
          }}
                    />
                    <p className="error-txt">{errors.password?.message}</p>
                  
                  </div>
                                    <div class="form-check" style={{ float: 'inherit', marginLeft: "15px" }}>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="defaultCheck1"
                      name="checkbox"
                      value={Remember}
                      onChange={() => setRemember((Remember) => !Remember)}
                    />
                    <label
                      class="form-check-label"
                      key="want"
                      for="defaultCheck1"
                    >
                      Remember Me
                    </label>
                  </div>

                  {/* <div class="form-check" style={{ float: 'inherit' }}>
                                        <input type="checkbox" class="form-check-input" id="defaultCheck1" name="checkbox" value="0" onclick="check()" />
                                        <label class="form-check-label" key="want" for="defaultCheck1">
                                            Remember Me
                                        </label>
                                    </div> */}

                  <div style={{width:'100%',display:'flex',justifyContent:'flex-start',marginLeft:'15px'}}>
                    <a onClick={handleShow} href="#" title="Forgot Password?" className="forgetpsd login-signup">Forgot Password?</a>
                  </div>
               
                  <Modal show={show} onHide={handleClose} style={{paddingTop: '100px'}} className="passRestModal">
                  <Modal.Header className="category-header">
              <Modal.Title 
               style={{
                textAlign: "left",
                color: "#000",
                background: "#fff",
                paddingBottom: '5px',
                borderBottom: "2px solid #BCBCBC",
              }}
              >
                <h4
                  style={{
                    color: "#000",
                    textAlign: "left",
                    fontSize: "22px",
                  }}
                >Forgot Password</h4>
                <i
                  class="bi-x-circle-fill"
                  aria-hidden="true"
                  data-dismiss="modal"
                  style={{
                    cursor: "pointer",
                    color: "#24D153",
                    fontSize: "23px",
                    float: "right",
                    marginTop: "-34px",
                    marginRight: "0px",
                  }}
                  onClick={handleClose}
                ></i>

              </Modal.Title>
            </Modal.Header>
        <Modal.Body style={{width: "80%",margin: '0 auto'}}>
        <form action="/action_page.php" >
          <div class="mb-0">
            <label for="email" className="label_name reset_label" style={{margin: '0'}} >Username</label>
            <input type="email" onChange={(event)=>email(event)} value={Email} className="resetpassword" style={{paddingLeft: '10px'}} id="email" placeholder="Enter username" name="email" required/>
      {  ( blank===true)&&
            <div class="invalid-feedback reset-feedback">Please fill out this field.</div>}
           
          { mail ? 
           <div class="invalid-feedback reset-feedback">Invalid UserName.</div>:''}
           
        
          </div>
          <div class="mb-0">
            <label for="pwd" className="label_name reset_label" style={{margin: '0'}}>Primary Email</label>
            <input type="email" onClick={()=>getprimail()} value={Pass} onChange={(event)=>pass(event)}className="resetpassword" style={{paddingLeft: '10px'}} id="pwd" placeholder="Enter primary email" name="pswd" required/>
            <>{(
           blank1 ?
            <div class="invalid-feedback reset-feedback">Please fill out this field.</div>:''
            ) }
             { mail1 ? 
           <div class="invalid-feedback reset-feedback">Invalid Mail.</div>:''}
            </>
          </div>
        </form>
        </Modal.Body>

        <Modal.Footer style={{ textAlign: "center", marginRight: "0em", border: "none" }}>
          
          <button
            className="btn btn-mute popup-cancel cancel-btn" 
            style={{padding: '8px 25px',}}
            onClick={handleClose}
          >
            Cancel{" "}
          </button>
          <button
          onClick={(event)=>submithandle()}
          type="submit"
            className="btn btn-success popup-save nxt-btn"
            style={{
              marginRight: "0.5em",
              marginLeft: '15px',
              background: "#24D153",
              borderColor: "#24D153",
              padding: '8px 25px',
            }}
           // disabled={blankblank1&&mail&&mail1===true}
          >
            Next 
          </button>
        </Modal.Footer>
      </Modal>
  
                  {/* <span>Don't have an account?<a href="./sign-up.html" title="Sign Up" className="signupbtn"><u>Sign Up</u></a></span> */}
                  {/* <p className='error-txt'>{errors.password?.message}</p> */}
                  <p className="sppar">
                    Don't have an account?
                    <a href="/Signup" title="Sign Up" className="signupbtn">
                      <u className="login-signup" style={{textDecoration:'none'}}>Sign Up</u>
                    </a>
                  </p>
                  {/* <button type="submit" className="loginbtn" key="Submit" >Log In</button> */}
                  <Button
                    type="submit"
                    className="loginbtn1"
                    onClick={scrollToTop}
                    style={{ background: "#24D153", boxShadow:'none', marginRight:'10px',marginTop:'-7px' }}
                    key="Submit"
                    variant="contained"
                  >
                    Log In
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* href="/Signup"  */}
      {/* -----------Footer--------------- */}
    </>
  );
}

export default Login;
