import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
import "./Businessjournal.css";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Popup from "reactjs-popup";
import { Tabs, Tab, TabPane } from "react-bootstrap";
import Budget from "../Tab/Budget";
import DailySales from "../Tab/DailySales";
import Reports from "../Tab/Reports";
import Dropdown from "react-bootstrap/Dropdown";
import P_L from "../Tab/P_L";
import { useState, useEffect, useRef } from "react";

import Form from "react-bootstrap/Form";
import { mainAxios } from "../middleware/interceptor";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { BsExclamationTriangle } from "react-icons/bs";
import { fobeAxios } from "../middleware/interceptor";
import { toast, ToastContainer } from "react-toastify";
import numeral from "numeral";

import "./../index.css";
function BusinessJournal() {
  const [selectedDepartment, setselectedDepartment] = useState(null);
  const [department, setdepartment] = useState([]);
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const [local, setlocal] = useState(items.Boss_id);
  const [id, setid] = useState(0);
  const fileInputRef = useRef(null);
  const [bossid, setbossid] = useState(items.Boss_id);
  const [selectDepartment, SetselectDepartment] = useState("All");
  const [tab, setTab] = useState("Budget");
  const [btab, setbtab] = useState("Revenue");
  const [selectedCourse, setselectedCourse] = useState(items.Company);
  const [Courseflage, setCourseflage] = useState(true);
  const [Course, SetCourse] = useState();
  const [triggervariable, settriggervariable] = useState(false);
  const [showCourses, setshowCourses] = useState(false);
  const [showdept, setshowdept] = useState(false);
  const [checkingForNoBudget, setcheckingForNoBudget] = useState(false);
  const [Budgetlength, setBudgetlength] = useState();
  let [budget_type, setbudget_type] = useState(items.budget_type);
  let newDate = new Date();
  let year = newDate.getFullYear();
  const [ye, sety] = useState(year);
  const [y, setY] = useState([]);
  const [selectYear, setSelectYear] = useState(newDate.getFullYear());
  const [Courseid, setCourseid] = useState(items.Restaurant_id);
  const [alldepartments, setalldepartments] = useState(items.Restaurant_id);
  const [bossalldepart, setbossalldepart] = useState(1);
  const [Edit, setEdit] = useState(false);
  const [showtable, setshowtable] = useState(false);
  const [logoimage, setLogoImage] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [filepath, setFilepath] = useState([]);
  const [filedname, setFileDName] = useState([]);
  const [showCat, setShowCat] = useState(true);
  const [dept, setdept] = useState();
  const [size, setSize] = useState(0);
  const cFile = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
  const compressImage = null; // replace with actual implementation
  const [selectedDept, setSelectedDept] = useState();

const [budget,setbudget]=useState();




  const getdeptdata = async () => {
    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
   
    setSelectedDept(localItems?.Account_Name, "localItems?.Account_id");

    await mainAxios.get(`Api/User/GetAllUserTypes?id=5`).then((res) => {
      setdept(res.data.AllUserTypes);
    });
    await mainAxios
      .get("api/Common/getRestaurantList/" + Courseid + "/" + ye)
      .then((res) => {
        console.log(res.data.Restaurants,Courseid, "heybyy");
        setdepartment(res.data.Restaurants);
      });
  };
  useEffect(()=>{
    if (items.Member_Type != "4") {
      setCourseid(items.Restaurant_id);
    }
  },[])
  useEffect(() => {

    getdeptdata();
  }, [Courseid]);
  useEffect(() => {
    GetCourse();
    getData();

    //||((items.Special_user == 0 || items.Special_user == 2) && items.Account_Type == 0)
    if (items) {
      if (items.Member_Type == 4) {
        setCourseflage(false);
        setshowCourses(true);
      } else {
        setshowCourses(false);
        setCourseflage(true);
      }
      if (
        (items.Special_user == 0 || items.Special_user == 2) &&
        items.Account_Type == 0
      ) {
        setshowdept(true);
      } else {
        setshowdept(false);
      }
    }
  }, [Courseid, triggervariable, budget_type]);

  useEffect(() => {
    getData();
  }, [Courseid, selectYear,bossid, Budgetlength]);

  const getData = async (labelName) => {
    let GetBudgetURL, GetRevenueMonthurl, GetLabelURL;
    //get label/
    if (
      (items.Special_user == 0 || items.Special_user == 1) &&
      items.Account_Type != 0
    ) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);
    setbudget_type(data.LabelAndYear[1].Year_List.find(item=>item.Year==selectYear).budget_type);
    setY(data.LabelAndYear[1].Year_List);
    if (
      (items.Special_user == 0 || items.Special_user == 1) &&
      items.Account_Type != 0
    ) {
      if (items.budget_type == "1") {
        GetBudgetURL = "Api/BudgetPeriod/GetBudget/" + selectYear;
        GetRevenueMonthurl = "Api/BudgetPeriod/GetRevenueMonth/" + selectYear;
      } else {
        GetBudgetURL = `Api/Budget/GetBudget/` + selectYear;
        GetRevenueMonthurl = `Api/Budget/GetRevenueMonth/` + selectYear;
      }
    } else {
      GetBudgetURL = `Api/Boss/GetBossBudget/` + bossid + "/" + ye;
      GetRevenueMonthurl = `api/Boss/GetBossRevenueMonth/` + bossid + "/" + ye;
    }

    console.log(labelName, "labelName");

    await fobeAxios.get(GetBudgetURL).then((res) => {
      setBudgetlength(res.data.BudgetRevenue.length);
      if (res.data.BudgetRevenue.length > 0) {
      }
      if (res.data.BudgetRevenue.length == 0) {
        setShow(true);
        document.getElementById("app-root").style.filter = "blur(2px)";
      }
    });
  };
  const GetCourse = async () => {
    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
    console.log(localItems, "localItems");
    // setselectedCourse(localItems.Company)
    const { data } = await mainAxios.get(`Api/Hampton/GetAllCouseTypes`);
    console.log(data.AllUserTypes, "fromCourse");
    SetCourse(data.AllUserTypes);
  };

  const[Events,setEvents]=useState(selectedCourse)


  const [selectedDepartment_active, setselectedDepartment_active] =
    useState(null);
  const handleSelect = (key, event) => {

    const partss=key.split('`')


    setEvents(partss[1])
    console.log(partss[1],"Events");

    setcheckingForNoBudget(false);
    setselectedDepartment_active(key);
    SetselectDepartment("All ");
    setselectedCourse(key.split("`")[1]);
    setCourseid(key.split("`")[0]);
    setalldepartments(key.split("`")[0]);

    setCourseflage(true);

    setbossid(key.split("`")[0]);
  };

  // const[periodvalue2,setPeriodValue2]=useState("2023")

  const handleSelect1 = (event) => {

    //alert(event)
    sety(event)
setSelectYear(event)
  };







  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setbossid(items.Boss_id);
    setCourseid(items.Restaurant_id);
    setalldepartments(items.Restaurant_id);
    setselectedCourse(items.Company);
    SetselectDepartment("All ");
    document.getElementById("app-root").style.filter = "blur(0px)";
  };
  const handleCloses = () => {
    setShow(false);
    setLogoImage([]);
    setMyFiles([]);
    setFilepath([]);
    setSize(0);
  };
  const handleShows = () => setShow(true);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [fileSize, setFileSize] = useState(0);
  const handleInputChange = (event) => {
    const file = event.target.files;
    console.log(file, "file");
    let check;
    for (let i = 0; i < cFile.length; i++) {
      check = false;
      if (file[0].type === cFile[i]) {
        if (
          file[0].size / 1000000 > 1 &&
          file[0].type !== "application/pdf" &&
          file[0].type !== "image/png"
        ) {
          compressImage.compress(file[0]).then((data) => {
            setMyFiles([...myFiles, data]);
            setFilepath([...filepath, logoimage]);
            setFileDName([...filedname, data.name]);
            setSize(size + data.size / 1000000);
          });
          i = cFile.length;
          check = true;
        } else {
          setMyFiles([...myFiles, file[0]]);
          setFilepath([...filepath, logoimage]);
          setFileDName([...filedname, file[0].name]);
          setSize(size + file[0].size / 1000000);
          i = cFile.length;
          check = true;
        }
      }
    }
    if (!check) {
      toast.warning("Supported files only accepted");
    }
    console.log("file", myFiles, filepath);
  };


  const [Eventkey,setEventkey]=useState('All');


  const handleSelectdrp = (eventKey, event) => {
  const parts =  eventKey.split('`');

    setEventkey(parts[1])


    console.log(parts[1],"eventKey")



    if (eventKey.split("`")[1] === "All ") {
      setbossalldepart(1);
    } else {
      setbossalldepart(0);
    }
    setcheckingForNoBudget(false);
    setselectedDepartment(eventKey);
    SetselectDepartment(eventKey.split("`")[1]);
    setbossid(eventKey.split("`")[0]);
    setbtab("Revenue");
    setEdit(false);
    setshowtable(true);
  };



  useEffect(() => {
    console.log(department, "departments");
  }, [department]);
  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const files = event.dataTransfer.files;
  //   // filter files to only include PDF, PNG, JPG file types
  //   const filteredFiles = Array.from(files).filter(file => file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg');
  //   for (let i = 0; i < files.length; i++) {
  //     filteredFiles.push(files[i]);
  //   }
  //   setSelectedFiles(filteredFiles);
  // };
  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // };

  // const uploadFiles = () => { alert("hkjhkj")
  //   // TODO: implement file upload logic here
  //   console.log(selectedFiles);
  // };
  const uploadFiles = async () => {
    console.log("vvvvvvvvvvvvv", myFiles, filepath);
    const frmData1 = new FormData();
    frmData1.append("uploadimage", "c:/temp/abc.png");
    if (id !== "") {
      frmData1.append("uploadid", id);
    } else {
      frmData1.append("uploadid", id);
    }
    for (let i = 0; i < myFiles.length; i++) {
      frmData1.append("invoicedetails", myFiles[i], myFiles[i].name);
    }
    console.log("file uploaded data", frmData1);

    await mainAxios
      .post("/Api/daily/upload_invoiceinfo/", frmData1)
      .then((resp) => {
        console.log("resp", resp);
        if (resp.data == 1) {
          toast.success("File uploaded successfully");
          setMyFiles([]);
          setFilepath([]);
          setSize(0);
          setShow(false);
        } else {
          toast.error("Something went wrong");
        }
      });
  };
  // removeFile(i,size) {
  //   console.log('asdfj;asdlfk',i,size);
  //   this.logoimage='';
  //   if(this.myFiles.length == 1 ){
  //     this.size = 0 ;
  //   }else{
  //     this.size -= size/1000000;
  //   }
  //   this.myFiles.splice(i,1);
  //   this.filepath.splice(i,1);
  //  }

  const removeFile = (i) => {
    setLogoImage([]);
    if (myFiles.length == 0) {
      setSize(0);
    } else {
      setSize(size - myFiles[i].size / 1000000);
    }
    setMyFiles(myFiles.filter((file, index) => index !== i));
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // setFilepath(filepath.filter((path, index) => index !== i));
    // setFileDName(filedname.filter((name, index) => index !== i));
    // const fileInput = document.querySelector('input[type="file"]');
    // fileInput.value = '';
  };

  return (
    <div className="container-fluid" style={{marginTop:'97px'}}>
      <ToastContainer autoClose={3000} />
      <div className="row" style={{ marginRight: "10px", marginLeft: "10px",marginTop:'-8px'}}>
        <div className="col">
          <div className="tab-sections">
            <div className="col-md-5 col-sm-12 tabs">
              <Tabs
                defaultActiveKey={tab}
                id="uncontrolled-tab-example"
                onSelect={(k) => setTab(k)}
                className="mb-3"
              >
                <Tab eventKey="Budget" title="Budget"></Tab>
                <Tab eventKey="Daily Sales" title="Daily Sales"></Tab>
                <Tab eventKey="P-L" title="P & L"></Tab>
              </Tabs>
            </div>
            <div
              className="col-md-7 col-sm-12 dropdowns"
              style={{ marginBottom: "30px", marginTop: "39px" }}
            >
              <div
                className="forcourdep"
                style={{ display: "flex", gap: "15px", float: "right" }}
              >
                {(showCourses || showdept) && (
                  <>
                    {" "}
                    <div className="col">
                      <p
                        className="year-drp"
                        style={{
                          color: "#7B7B7B",
                          font: "normal normal 500 12px/33px Roboto",
                        }}
                      >
                        Year
                      </p>
                    </div>
                    <div className="col">
                      <Dropdown onSelect={handleSelect1}>
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          className="daily-sales-dropml"
                          variant="secondary"
                        >
                          <span>{selectYear} &nbsp; <i style={{color:'#24D153',fontWeight:'600',fontSize:'15px'}} class="fa fa-angle-down icon-drop"></i></span>
                         
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant="dark"
                          className="responsedropyear year-drop-dwn"
                        >
                          {y&&y.length>0?
                          y.map((item,i)=>{return <Dropdown.Item value={item.Year} active={selectYear === item.Year} eventKey={item.Year} key={i}>{item.Year}</Dropdown.Item>}):""}
                        </Dropdown.Menu>
                      </Dropdown>{" "}
                    </div>{" "}
                  </>
                )}
                {showCourses && (
                  <>
                    {" "}
                    <div className="col">
                      <p
                        className="course-drp"
                        style={{
                          color: "#7B7B7B",
                          font: "normal normal 500 12px/33px Roboto",
                        }}
                      >
                        Course
                      </p>
                    </div>
                    <div className="col">
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          className="course-dropml"
                          variant="secondary"
                        >
                         <span>{selectedCourse ? selectedCourse.slice(0,10) : "Select Course"}&nbsp; <i style={{color:'#24D153',fontWeight:'600',fontSize:'15px'}} class="fa fa-angle-down icon-drop"></i></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant="dark"
                          className="responsedropcourse"
                        >
                          {Course?.map((item, index) => {
                            return (
                              <>
                                <Dropdown.Item
                                  eventKey={
                                    item.Courseid + "`" + item.Coursename
                                  }
                                  className={
                                    selectedDepartment_active ===
                                    item.Courseid + "`" + item.Coursename
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {item.Coursename}
                                </Dropdown.Item>
                              </>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>{" "}
                  </>
                )}
                {(showdept || showCourses) && (
                  <>
                    <div className="col">
                      <p
                        className="depart-drp"
                        style={{
                          color: "#7B7B7B",
                          font: "normal normal 500 12px/33px Roboto",
                        }}
                      >
                        Department
                      </p>
                    </div>
                    <div className="col">
                      <Dropdown onSelect={handleSelectdrp}>
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          className="depar-dropml"
                          variant="secondary"
                        >
                          <span>  {selectDepartment
                            ? selectDepartment.slice(0,10)
                            : "All Department"}&nbsp; <i style={{color:'#24D153',fontWeight:'600',fontSize:'15px'}} class="fa fa-angle-down icon-drop"></i></span>
                        
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="dark" className="forAll">
                          <Dropdown.Item
                            eventKey={alldepartments + "`" + "All "}
                          >
                            All
                          </Dropdown.Item>
                          {console.log(department,"kkk")}
                          {department?.map((x) => (
                            (x.Rest_Name != null &&
                            <Dropdown.Item
                              eventKey={x.Rest_id + "`" + x.Rest_Name}
                              className={selectedDepartment === x.Rest_id + "`" + x.Rest_Name? "active"
                                  : ""
                              }
                            >
                              {x.Rest_Name}
                            </Dropdown.Item>
                          )
                           
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                    </div>{" "}
                  </>
                )}
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div
            className="sections"
            style={{ paddingLeft: "28px", paddingRight: "28px" }}
          >
            {tab === "Budget" && (
              <TabPane eventKey="Budget">
                <Budget
                  course_id={Courseid}
                  tabss={tab}
                  setCourseflage={setCourseflage}
                  Courseflage={Courseflage}
                  setbossid={setbossid}
                  bossid={bossid}
                  SetselectDepartment={SetselectDepartment}
                  selectDepartment={selectDepartment}
                  setshowCourses={setshowCourses}
                  settriggervariable={settriggervariable}
                  triggervariable={triggervariable}
                  Budgetlength={Budgetlength}
                  setBudgetlength={setBudgetlength}
                  setcheckingForNoBudget={setcheckingForNoBudget}
                  checkingForNoBudget={checkingForNoBudget}
                  alldepartments={alldepartments}
                  setbudget_type={setbudget_type}
                setSelectYear={setSelectYear}
                selectYear={selectYear}
                  sety={sety}
                ye={ye}
                  budget_type={budget_type}
                  butab={btab}
                  setbutab={setbtab}
                  setbudget={setbudget}
                />
              </TabPane>
            )}
            {console.log(budget,"budget")}
            {tab === "Daily Sales" && (
              <TabPane eventKey="Daily Sales">
                <DailySales
                  setbossid={setbossid}
                  bossid={bossid}
                  course_id={Courseid}
                  tabss={tab}
                  SetselectDepartment={SetselectDepartment}
                  selectDepartment={selectDepartment}
                  setSelectYear={setSelectYear}
                  selectYear={selectYear}
                 sety={sety}
                  ye={ye}
                  settriggervariable={settriggervariable}
                  triggervariable={triggervariable}
                  Budgetlength={Budgetlength}
                  setBudgetlength={setBudgetlength}
                  setcheckingForNoBudget={setcheckingForNoBudget}
                  checkingForNoBudget={checkingForNoBudget}
                  setbudget_type={setbudget_type}
                  budget_type={budget_type}
                  budget={budget}
                />
              </TabPane>
            )}
            {tab === "P-L" && (
              <TabPane eventKey="P-L">
                <P_L
                Events={Events}
                Eventkey={Eventkey}
                  tab={tab}
                  setTab={setTab}
                  setbossid={setbossid}
                  bossid={bossid}
                  course_id={Courseid}
                  SetselectDepartment={SetselectDepartment}
                  selectDepartment={selectDepartment}
                  settriggervariable={settriggervariable}
                  triggervariable={triggervariable}
                  Budgetlength={Budgetlength}
                  setBudgetlength={setBudgetlength}
                  setcheckingForNoBudget={setcheckingForNoBudget}
                  checkingForNoBudget={checkingForNoBudget}
                  setbudget_type={setbudget_type}
                  budget_type={budget_type}
                  budget={budget}
                  setSelectYear={setSelectYear}
                  selectYear={selectYear}
                 sety={sety}
                  ye={ye}
                />
              </TabPane>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default BusinessJournal;

// return (
//   <div className="container-fluid">
//     <ToastContainer autoClose={3000} />
//     <div className="tab-sections"></div>
//     <div className="row">
// <div className="forcourdep" style={{display: 'flex', gap: '15px', float: 'right'}}>
//            <div className="col" style={{display: 'flex'}}>
//             <p style={{marginRight: '25px', color: '#7B7B7B', font: 'normal normal 500 12px/33px Roboto'}}>Course</p>
//            <Dropdown onSelect={handleSelect}>
//               <Dropdown.Toggle
//                 id="dropdown-button-dark-example1"
//                 className="drop-ml "
//                 variant="secondary"
//               >
//               The Honors Course
//               </Dropdown.Toggle>
//               <Dropdown.Menu variant="dark" className="responsedrop">

//                   <Dropdown.Item

//                   >
//                    The Honors Course
//                   </Dropdown.Item>

//               </Dropdown.Menu>
//             </Dropdown>
//            </div>
//            <div className="col" style={{display: 'flex'}}>
//             <p style={{marginRight: '25px', color: '#7B7B7B', font: 'normal normal 500 12px/33px Roboto'}}>Department</p>
//             <Dropdown onSelect={handleSelect}>
//               <Dropdown.Toggle
//                 id="dropdown-button-dark-example1"
//                 className="drop-ml "
//                 variant="secondary"
//               >
//               The Honors Course
//               </Dropdown.Toggle>
//               <Dropdown.Menu variant="dark" className="responsedrop">

//                   <Dropdown.Item

//                   >
//                    The Honors Course
//                   </Dropdown.Item>

//               </Dropdown.Menu>
//             </Dropdown>
//            </div>

//           </div>
//           </div>

//     <div className="row">
// <div className="col-md-12">
//   <div className="tab-sections" style={{ paddingLeft: "28px" }}>
//     <Tabs
//       defaultActiveKey={tab}
//       id="uncontrolled-tab-example"
//       onSelect={(k) => setTab(k)}
//       className="mb-3"
//     >
//       <Tab eventKey="Budget" title="Budget">
//       <Budget
//                 course_id={Courseid}
//                 tabss={tab}
//                 setCourseflage={setCourseflage}
//                 Courseflage={Courseflage}
//                 setbossid={setbossid}
//                 bossid={bossid}
//                 SetselectDepartment={SetselectDepartment}
//                 selectDepartment={selectDepartment}
//                 setshowCourses={setshowCourses}
//                 settriggervariable={settriggervariable}
//                 triggervariable={triggervariable}
//                 Budgetlength={Budgetlength}
//                 setBudgetlength={setBudgetlength}
//                 setcheckingForNoBudget={setcheckingForNoBudget}
//                 checkingForNoBudget={checkingForNoBudget}
//                 alldepartments={alldepartments}
//                 setbudget_type={setbudget_type}
//                 budget_type={budget_type}
//               />
//       </Tab>
//       <Tab eventKey="Daily Sales" title="Daily Sales">
//       <DailySales
//                 setbossid={setbossid}
//                 bossid={bossid}
//                 course_id={Courseid}
//                 tabss={tab}
//                 SetselectDepartment={SetselectDepartment}
//                 selectDepartment={selectDepartment}
//                 settriggervariable={settriggervariable}
//                 triggervariable={triggervariable}
//                 Budgetlength={Budgetlength}
//                 setBudgetlength={setBudgetlength}
//                 setcheckingForNoBudget={setcheckingForNoBudget}
//                 checkingForNoBudget={checkingForNoBudget}
//                 setbudget_type={setbudget_type}
//                 budget_type={budget_type}
//               />
//       </Tab>
//       <Tab eventKey="P-L" title="P & L">
//       <P_L
//                 tab={tab}
//                 setTab={setTab}
//                 setbossid={setbossid}
//                 bossid={bossid}
//                 course_id={Courseid}
//                 SetselectDepartment={SetselectDepartment}
//                 selectDepartment={selectDepartment}
//                 settriggervariable={settriggervariable}
//                 triggervariable={triggervariable}
//                 Budgetlength={Budgetlength}
//                 setBudgetlength={setBudgetlength}
//                 setcheckingForNoBudget={setcheckingForNoBudget}
//                 checkingForNoBudget={checkingForNoBudget}
//                 setbudget_type={setbudget_type}
//                 budget_type={budget_type}
//               />
//       </Tab>
//     </Tabs>
{
  /* {showCourses && (
      <Form>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
          <Form.Label column sm="4" style={{ textAlign: "right" }}>
            Course:
          </Form.Label>
          <Col sm="8">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                className="drop-ml "
                variant="secondary"
              >
                {selectedCourse ? selectedCourse : "Select Course"}
              </Dropdown.Toggle>
              <Dropdown.Menu variant="dark" className="responsedrop">
                {Course?.map((item, index) => (
                  <Dropdown.Item
                    key={item.Courseid}
                    eventKey={item.Courseid + "`" + item.Coursename}
                    className={
                      selectedDepartment_active === item.Courseid + "`" + item.Coursename
                        ? "active"
                        : ""
                    }
                  >
                    {item.Coursename}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Form.Group>
      </Form>
    )} */
}
//   </div>
// </div>
// </div>

//   </div>
// );
// }
