import "../Header/header.css";
import "bootstrap/dist/css/bootstrap.css";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LocalStorageService } from "../Services/LocalStorageService";

const Pageheader = () => {
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const userDetail = JSON.parse(localStorage.getItem("UserClaims"));
  const [showuploadinvoice, setshowuploadinvoice] = useState(false);
  const [name, setName] = useState("USER");
  const [token, setToken] = useState(null);
  let navigate = useNavigate();
  const nav = () => {
    localStorage.removeItem("auth-token");
    localStorage.removeItem("UserClaims");

    navigate("/");
  };

  useEffect(() => {
    const localStorageService = LocalStorageService.getService();
    setToken(localStorageService.getAccessToken("auth-token"));
    if (userDetail.Account_Type != 0 || (userDetail.Account_Type == 0 && userDetail.Account_id==5) ) {
      setshowuploadinvoice(true);
    } else {
      setshowuploadinvoice(false);
    }
  }, []);
  const homeorlogin = (e) => {
    navigate("/home");
  };
  const [teamMemberLogin, setteamMemberLogin] = useState(false);
  const [gmlogin, setgmlogin] = useState(false);
  const [departmentlogin, setdepartmentlogin] = useState(false);
  const [superadminlogin, setsuperadminlogin] = useState(false);

  useEffect(() => {
    if (items != null) {
      if (items.Member_Type == 2) {
        setteamMemberLogin(true);
      } else {
        setteamMemberLogin(false);
      }
      if (
        (items.Special_user == 1 || items.Special_user == 0) &&
        items.Account_Type != 0
      ) {
        setdepartmentlogin(true);
      } else {
        setdepartmentlogin(false);
      }
      if (items.Member_Type == 4) {
        setsuperadminlogin(true);
      } else {
        setsuperadminlogin(false);
      }
      if (
        (items.Special_user == 0 || items.Special_user == 2) &&
        items.Account_Type == 0
      ) {
        setgmlogin(true);
      } else {
        setgmlogin(false);
      }

      setName(items.Username);
    }
  }, []);

  const [activeItem, setActiveItem] = useState("");

  const handleSelect = (eventKey) => {
    setActiveItem(eventKey);
  };

  const toggleheader = () => { var name = document.getElementById('gets'); if (name.classList.contains('open')) { name.classList.remove('open'); } else { name.classList.add('open'); } };
  return (
    <>
      <nav className="navbar navbar-static-top nav-fixed-top" style={{zIndex: '999'}}>
        <div
          className="container-fluid page-header"
          style={{ paddingLeft: "5px", paddingRight: "40px" }}
        >
          <div
            className=""
            style={{ paddingBottom: "3px"}}
          >
            <div className="row">
              <div class="col-md-4 col-sm-3 col-xs-12 brand-logo hdrogo">
                <div className="navbar-header">
                  <a href={token ? "/home" : "/"} title="Fobegolf-Logo">
                    <img
                      src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/logo.png"
                      class="img100 pg-head-img"
                      alt="FobeGolf-Logo"
                      title="FobeGolf-Logo"
                      style={{ transform: 'scale(0.8)' }}
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-8 col-sm-9 col-xs-12">
                <div
                  className="collapse collapse2 navbar-collapse navbarSupportedContent"
                  id="NavBars"
                  style={{ marginTop: "-28px" }}
                >
                  <div className="row">
                    <a
                      href="#"
                      className="dropdangle"
                      style={{
                        paddingRight: "0",
                        float: "right",
                        fontSize: "17px",
                        textDecoration: "none",
                        cursor:'context-menu'
                      }}
                    >
                      <div className="show-parent"> 
                      <Dropdown className="dr_op" onSelect={handleSelect} style={{marginTop:'0px',marginBottom:'-20px'}}>
                        <i
                          _aria-hidden="true"
                          class="bi bi-person-circle header-icon"
                          style={{
                            fontSize: "24px",
                            color: "#7b7b7b",
                            paddingRight: "5px",
                            cursor:'context-menu',
                            marginTop:'1px'
                          }}
                        ></i>
                        <span
                          className="header-text"
                          style={{
                            paddingRight: "5px",
                            color: "#7b7b7b",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "36px",
                               cursor:'context-menu'
                          }}
                        >
                          Welcome
                        </span>
                        <span
                          class="sp_an header-text"
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "36px",
                               cursor:'context-menu',
                               marginRight:'12px'
                          }}
                        >
               {name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}
                        </span>
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          className="dr-ml header-btn header-btn1"
                          variant="dark"
                          style={{padding:'0px',marginRight:'12px'}}
                        >
                          <i
                            class="anglespan fa fa-angle-down"
                            style={{ color: "#fff", fontWeight: "600" }}
                          ></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="dark" className="pageheaddrop">
                          <Dropdown.Item
                            onClick={() => navigate("/Profile")}
                            className={activeItem === "Profile" ? "active" : ""}
                            eventKey={"Profile"}
                          >
                            <i
                              class="bi bi-person-fill"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            Profile
                          </Dropdown.Item>{" "}
                          {!teamMemberLogin && (
                            <>
                              <Dropdown.Item
                                onClick={() => navigate("/Manageteam")}
                                className={
                                  activeItem === "ManageTeam" ? "active" : ""
                                }
                                eventKey={"ManageTeam"}
                              >
                                <i class="bi bi-people-fill"></i> Manage Team
                                Members
                              </Dropdown.Item>
                            </>
                          )}
                          {showuploadinvoice ? (
                            <Dropdown.Item
                              onClick={() => navigate("/InvoiceDashboard")}
                              className={
                                activeItem === "Invoice Dashboard" ? "active" : ""
                              }
                              eventKey={"Invoice Dashboard"}
                            >
                              <i class="bi bi-cloud-download"></i> Invoice
                              Dashboard
                            </Dropdown.Item>
                          ) : null}
                          {!teamMemberLogin && !departmentlogin && (
                            <>
                              <Dropdown.Item
                                onClick={() => navigate("/Importteam")}
                                className={
                                  activeItem === "Importteam" ? "active" : ""
                                }
                                eventKey={"Importteam"}
                              >
                                <i
                                  class="bi bi-file-earmark-text"
                                  style={{ fontSize: "21px" }}
                                ></i>{" "}
                                Import Team Members
                              </Dropdown.Item>
                            </>
                          )}
                          <Dropdown.Item
                            onClick={() => nav()}
                            className="la_st"
                          >
                            <i class="bi bi-box-arrow-right"></i> Log Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      </div>

                    </a>
                  </div>
                  <div className="row">
                    <ul class="nav navbar-nav">
                      <li>
                        <a className={"link nav-opt"} onClick={() =>{navigate("/home");setActiveItem("")}}
                        
                              >
                          Home
                        </a>
                      </li>
                      {!teamMemberLogin && (
                        <li>
                          <a
                            className="link nav-opt"
                            onClick={() => {navigate("/businessjournal");setActiveItem("")}}
                          >
                            Business Outlook
                          </a>
                        </li>
                      )}
                      <li>
                        <a
                          className="link nav-opt"
                          onClick={() => {navigate("/snapshot");setActiveItem("")}}
                        >
                          {" "}
                          At a Glance{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                    <div className="col-md-2 cotol">
                        <button type="button" onClick={toggleheader} id="gets" className="navbar-toggle1 mbl-toggle" data-toggle="collapse" data-target="#NavBars" style={{backgroundColor: "#24D153"}}>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>

                </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Pageheader;
