import '../Component/Spinner.css';
import ReactLoading from 'react-loading';

export const Spinner = props => {


    return (
        <div className='fp-container'>
            <span className="fp-loader">
                <ReactLoading type={'balls'} color={'Green'} height={30} width={50} />
            </span>
        </div>
    );
}