import "bootstrap/dist/css/bootstrap.min.css";
import P_L_Table from "../Component/Tables/SnapshotDashboardTable/P_L_Table";
import DatesPaginator from "../Component/Datepagenator";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import CurrencyFormat from "react-currency-format";
import { ConstructSharp } from "react-ionicons";
// import DateRangeDropdown from '../Component/DateRangePicker/DateRangeDropdown'
import DateRangeList from "../Component/DateRangePicker/DateRangeList";
import { Spinner } from "../Component/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import "../Home/Home.css";
import Moment from "moment";
import Button from "@mui/material/Button";
import { DateRange } from "react-date-range";
import YearTable from "../Component/Tables/YearTable";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import ReactToPrint from "react-to-print";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useRef } from "react";
import "../index.css";
import { event } from "jquery";
import ExcelJS from 'exceljs';


const revenue = [];
//   const cog = {
//     list: [{ category: 'Green and Cart Fee', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: [{ dailyTotalAmt: 10, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }]
//   }

//   const labor = {
//     list: [{ category: 'Salary', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: { dailyTotalAmt: 10, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }
//   }

//   const Controllable = {
//     list: [{ category: 'Salary', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: { dailyTotalAmt: 30, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }
//   }
//   const NonControllable = {
//     list: [{ category: 'Salary', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: { dailyTotalAmt: 30, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }
//   }
//   const PROFITLOSS = {
//     list: [{ category: 'Salary', dailyAmt: 5235, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 },
//     { category: 'Other Golf Revenue', dailyAmt: 10, dailyPer: 20, monthAmt: 30, monthPer: 30, budgetAmt: 40, budgetPer: 40 }],
//     total: { dailyTotalAmt: 30, dailyTotalPer: 20, monthTotalAmt: 30, monthTotalPer: 30, budgetTotalAmt: 40, budgetTotalPer: 40 }
//   }

const header = [{ column: "YEAR TO DATE" }, { column: "BUDGET TO DATE" }];
const periods = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
const Days=[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]



function P_L({
  tab,
  setTab,
  course_id,
  setbossid,
  bossid,
  selectDepartment,
  SetselectDepartment,
  ref,
  settriggervariable,
  triggervariable,
  Budgetlength,
  setcheckingForNoBudget,
  checkingForNoBudget,
  setbudget_type,
  budget_type,
  Eventkey,
  Events,
  budget,
  setSelectYear,
  selectYear,
  sety,
  ye,           
}) {
  let date
  const today = new Date();
  const [sdate, setsdate] = useState(parseInt(selectYear) + "-" + (today.getMonth() + 1) + "-" + today.getDate());
  const [edate, setedate] = useState(parseInt(selectYear) + "-" + (today.getMonth() + 1) + "-" + today.getDate());

  useEffect(() => {
    console.log('mainusegetdata2')
    setSelectedRange(
      selectYear==today.getFullYear()?
      Moment(new Date()).format("LL") + " - " + Moment(new Date()).format("LL")
      :Moment(new Date(selectYear,new Date().getMonth(),new Date().getDate())).format("LL") + " - " + Moment(new Date(selectYear,new Date().getMonth(),new Date().getDate())).format("LL")
    );
    
    if(parseInt(selectYear)==today.getFullYear())
 {  setsdate
(    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate());
  setedate( today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate());
  }

  else{
    setsdate( selectYear + "-" + (today.getMonth() + 1) + "-" + today.getDate());
     setedate( selectYear + "-" + (today.getMonth() + 1) + "-" + today.getDate());
  }
  setState(parseInt(selectYear)==today.getFullYear()?[
    {
     startDate: new Date(),
     endDate: new Date(),
     key: "selection",
   }
 ]:[{
   startDate: new Date(selectYear, today.getMonth(),today.getDate()),
   endDate: new Date(selectYear,today.getMonth(),today.getDate()),
   key: "selection",
 }])
  }, [ye,selectYear]);  
  console.log(budget_type,budget, Budgetlength,parseInt(selectYear), "sss")
  
  let GetCustomplReport, data_3, defaultperiod;

 //const[defaultperiod1,setdefaultperiod1]=useState(1)

 
  
  const [listperiod1, listsetperiod1] = useState(periods)
  const [listperiod2, listsetperiod2] = useState(periods)
  const [listday1, listsetday1]=useState(Days)
  const [listday2, listsetday2]=useState(Days)
  const componentRef = useRef();
  const [selectedRange, setSelectedRange] = useState( selectYear==today.getFullYear()?
    Moment(new Date()).format("LL") + " - " + Moment(new Date()).format("LL")
    :Moment(new Date(selectYear,new Date().getMonth(),new Date().getDate())).format("LL") + " - " + Moment(new Date(selectYear,new Date().getMonth(),new Date().getDate())).format("LL"));
  const [isOpen, setIsOpen] = useState(false);
  const [disabledPeriod2, setdisabledPeriod2] = useState(false)
  const [b, setb] = useState(checkingForNoBudget);
  const containerRef = useRef(null);
  const [day1, setday1] = useState("1");
  const [day2, setday2] = useState("1");
  const [period1, setperiod1] = useState("1");
  const [periodvalue, setperiodvalue] = useState("Period 1");
  const [period2, setperiod2] = useState("1");
  const [datepickershow, setdatepickershow] = useState(false);

         const [array,setarray]=useState({})


  let dataArray = {};
  useEffect(() => {
    if (period1 != null && period1 != undefined && period1 != "") {
     
      const filterPeriod2 = periods.filter((y)=>period1<=y)
      listsetperiod2(filterPeriod2);
      setPeriodValue2("Period "+filterPeriod2[0])
      //setperiod2(filterPeriod2[0]);
      console.log(filterPeriod2,"ftc")
    }


  }, [period1])
 
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  let startDate
  let endDate
  if(selectYear==currentDate)
  {   startDate = new Date(currentYear, 0, 1);
   
    // Set the end date to the end of the current year
     endDate = new Date(currentYear, 11, 31);}
  
     else{
  
      startDate = new Date(parseInt(selectYear), 0, 1);
      endDate = new Date(parseInt(selectYear),11,31);
     }
     console.log(startDate,endDate,parseInt(selectYear),'startDate,endDate')

  useEffect(()=>{
    if (period2 != null && period2 != undefined && period2 != "") {

         const filterday2 =Days.filter((x)=>day1<=x)
         listsetday2(filterday2);
         setday2value("Day"+filterday2[0])
        // setday1(filterday2[0])
    }
  },[day1])



  
  useEffect(() => {
   
    console.log(array, "array")
  }, [array])

  const handleClick = (e) => {
    //console.log("aamada",containerRef)

    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setdatepickershow(false);
    }
  };
  //get current date

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);


  const [state, setState] = useState(parseInt(selectYear)==today.getFullYear()?[
    {
     startDate: new Date(),
     endDate: new Date(),
     key: "selection",
   }
 ]:[{
   startDate: new Date(selectYear, 0, 1),
   endDate: new Date(selectYear,0,1),
   key: "selection",
 }]);
  const items = JSON.parse(localStorage.getItem("UserClaims"));
//   if(parseInt(selectYear)==today.getFullYear())
//  {  date =
//     today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
//   }
//   else{
//      date = selectYear + "-" + 1 + "-" + 1;
//   }
  console.log(date, "current date");
  // let date = newDate.getDate();
  // let month = newDate.getMonth() + 1;
  let year = today.getFullYear();
  // const [d, setd] = useState(date);
  // const [m, setm] = useState(month);
  //const [ye, sety] = useState(year);
  const [Toltal, setToltal] = useState({ YearToDay: "", MonthMTD: "" });

  const [data5, setData5] = useState(revenue);
  const [data1, setData1] = useState(revenue);
  const [data2, setData2] = useState(revenue);
  const [data3, setData3] = useState(revenue);
  const [data4, setData4] = useState(revenue);
  const [data, setData] = useState(revenue);
  const [loading, setLoading] = useState(true);

  const [department, setdepartment] = useState();
 

  let splUser = items.Special_user;
  let Acc_type = items.Account_Type;
  const [dept, setdept] = useState();


  const [showCourse, setShowCourse] = useState(false);

  const getdata = async () => {

    setLoading(true);

    ///gat label for get budget type
    let GetLabelURL;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      GetLabelURL = `/Api/Common/getLabel`;
    } else {
      GetLabelURL = "Api/Common/getBossLabel/" + bossid + "/" + ye;
    }
    const { data } = await fobeAxios.get(GetLabelURL);

     //setbudget_type(data.LabelAndYear[1].Year_List.find(item=>item.Year==ye).budget_type);
    ///////

    
    //getdefaultbudgetperiod day period from service
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      data_3 = await fobeAxios.get("/Api/Common/getLabel");

      defaultperiod = data_3.data.LabelAndYear[1].Year_List.find(
        (budget) => budget.Year == selectYear
      );
    } else {
      data_3 = await mainAxios.get(
        "Api/DailyPeriod/GetPeriodDetails/" + ye + "/" + bossid
      );
      defaultperiod = data_3.data;
    }

    if (data_3) {
      if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
        setperiod2(defaultperiod.budget_period);
        setday2(defaultperiod.budget_period_day);
        setPeriodValue2("Period "+ defaultperiod.budget_period);
        setday2value("Day "+ defaultperiod.budget_period_day)
       
      } else {
        if (
          defaultperiod.budget_period == 0 &&
          defaultperiod.budget_period_day == 0
        ) {
          setperiod2("1");
          setday2("1");
        } else {
          setperiod2(defaultperiod.budget_period);
          setday2(defaultperiod.budget_period_day);
          setPeriodValue2("Period" +defaultperiod.budget_period);
          setday2value("Day "+ defaultperiod.budget_period_day)
        }
      }

      //getdata
      if (budget_type == 1) {
        //fortotal

        ////

        if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
          GetCustomplReport =
            "Api/DailyPeriod/GetCustomplReport/" + period1 + "/" + (period==0? defaultperiod.budget_period : period2) + "/" + day1 + "/" +(defaultday==0? defaultperiod.budget_period_day :day2) + "/" + ye;

        } else {
          GetCustomplReport =
            "Api/Boss/GetCustomplReport/" +
            period1 +
            "/" +
            period2 +
            "/" +
            day1 +
            "/" +
            day2 +
            "/" +
            ye +
            "/" +
            bossid;
        }
      } else {
        if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
          GetCustomplReport =
            "Api/Daily/GetCustomplReport/" + sdate + "/" + edate;
        } else {
          GetCustomplReport =
            "Api/Boss/GetBossCustomplReport/" +
            sdate +
            "/" +
            edate +
            "/" +
            bossid;
        }
      }
    }

    //setLoading(false);
    
    fobeAxios.get(GetCustomplReport).then((res) => {
      
      setarray(res.data)
      dataArray = res.data;

      console.log(res.data, "P&L");
      if (res.data.Profitloss.length > 0) {
        setb(true);
        setData(res.data.Profitloss);
        setToltal(res.data);
        setData5(res.data.Revenue);
        setData1(res.data.Cost_of_goods);
        setData2(res.data.Labor);
        setData3(res.data.Controllable);
        setData4(res.data.Non_controllable);
        setLoading(false);
      } else {


        setb(false);
        setData(revenue);
        setToltal(revenue);
        setData5(revenue);
        setData1(revenue);
        setData2(revenue);
        setData3(revenue);
        setData4(revenue);
        setLoading(false);
      }
    });
  };
  const getdataforperiod = async () => {
    let GetCustomplReport;
    //getdata
    if (budget_type == 1) {
      if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
        setLoading(true)
        GetCustomplReport =
          "Api/DailyPeriod/GetCustomplReport/" +
          period1 +
          "/" +
          period2 +
          "/" +
          day1 +
          "/" +
          day2 +
          "/" +
          ye;
      } else {
        setLoading(true)
        GetCustomplReport =
          "Api/Boss/GetCustomplReport/" +
          period1 +
          "/" +
          period2 +
          "/" +
          day1 +
          "/" +
          day2 +
          "/" +
          ye +
          "/" +
          bossid;
      }
    } else {
      if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
        GetCustomplReport =
          "Api/Daily/GetCustomplReport/" + sdate + "/" + edate;
      } else {
        GetCustomplReport =
          "Api/Boss/GetBossCustomplReport/" +
          sdate +
          "/" +
          edate +
          "/" +
          bossid;
      }
    }

    fobeAxios.get(GetCustomplReport).then((res) => { 
      dataArray = res.data;

      setarray(res.data)
      console.log(res.data, "P&L");
      if (res.data.Profitloss.length > 0) {
        setb(true);
        setData(res.data.Profitloss);
        setToltal(res.data);
        setData5(res.data.Revenue);
        setData1(res.data.Cost_of_goods);
        setData2(res.data.Labor);
        setData3(res.data.Controllable);
        setData4(res.data.Non_controllable);
        setLoading(false);
      } else {
        setb(false);
        setData(revenue);
        setToltal(revenue);
        setData5(revenue);
        setData1(revenue);
        setData2(revenue);
        setData3(revenue);
        setData4(revenue);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getdata();
    getdeptdata();
    if (
      items.Member_Type == 4 ||
      ((items.Special_user == 0 || items.Special_user == 2) &&
        items.Account_Type == 0)
    ) {
      setShowCourse(true);
    } else {
      setShowCourse(false);
    }
  }, [
    selectYear,
    ye,
    tab,
    sdate,
    edate,
    course_id,
    bossid,
    selectedRange,
    triggervariable,
    budget_type,
  ]);

  const getdeptdata = async () => {
    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
    if (localItems.Member_Type != "4") {
      course_id = localItems.Restaurant_id;
    }
    // setSelectedDept(localItems?.Account_Name, "localItems?.Account_id");
    mainAxios.get(`Api/User/GetAllUserTypes?id=5`).then((res) => {
      setdept(res.data.AllUserTypes);
    });
    mainAxios
      .get("api/Common/getRestaurantList/" + course_id + "/" + ye)
      .then((res) => {
        console.log(res.data.Restaurants, "Restaurants");
        setdepartment(res.data.Restaurants);
      });
  };
  const [selectDepartment_active, setselectedDepartment_active] =
    useState(null);
  const handleSelectdrp = (eventKey, event) => {
    setcheckingForNoBudget(false);
    setselectedDepartment_active(eventKey);
    SetselectDepartment(eventKey.split("`")[1]);
    setbossid(eventKey.split("`")[0]);
  };
  const datepicker = (e) => {
    console.log(e, "eeee");
    console.log(e[0].startDate, "k");
    console.log(e[0].endDate, "k");
    var date = new Date(e[0].startDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    console.log([date.getFullYear(), mnth, day].join("-"), "formatted date");
    setsdate([date.getFullYear(), mnth, day].join("-"));

    var datee = new Date(e[0].endDate),
      mnth1 = ("0" + (datee.getMonth() + 1)).slice(-2),
      day1 = ("0" + datee.getDate()).slice(-2);
    console.log(
      [datee.getFullYear(), mnth1, day1].join("-"),
      "formatted staetdate"
    );
    setedate([datee.getFullYear(), mnth1, day1].join("-"));

    setSelectedRange(
      Moment([date.getFullYear(), mnth, day].join("-")).format("LL") +
        " - " +
        Moment([datee.getFullYear(), mnth1, day1].join("-")).format("LL")
    );
  };

  ///13 period///
  const perioddropdown = (event, keyName) => {
    console.log(event.eventKey);
    if (keyName === "d1") {
      setday1(event.target.value);
    } else if (keyName === "d2") {
      setday2(event.target.value);
    } else if (keyName === "p1") {
      setperiod1(event.target.value);
    } else if (keyName === "p2") {
      setperiod2(event.target.value);
    }
  };
  const[periodvalue2,setPeriodValue2]=useState("Period 1")
  const[day1value,setday1value]=useState("Day 1");
  const[day2value,setday2value]=useState("Day 1")
  const [defaultday, setdefaultday]= useState(0);
  const[period,setperiod]=useState(0)

  useEffect(() => {

    setperiod2(period1);

 

  },[period1])

  
  useEffect(() => {

    setday2(day1);

 

  },[day1])

  const handleSelect = (eventKey) => {
    setperiodvalue(eventKey)
  }
  const handleSelect2=(eventKey)=>{
    setPeriodValue2(eventKey)
    setperiod(1);
  }
  const dayhandleSelect1=(eventKey)=>{
    setday1value(eventKey)
  }
  const dayhandleSelect2=(eventKey)=>{
    setday2value(eventKey)
    setdefaultday(1);

  }


  //console.log( array.Profitloss[1].List[0].Cat_amt,'array1')
  //console.log( array.Profitloss[1].List[0].Cat_name,'array1')






const handleDownload = async () => {

  const items1 = JSON.parse(localStorage.getItem("UserClaims"));


console.log(items1,"ITEMS")

    const workbook = new ExcelJS.Workbook();


let Name='';

    for(let sheetIndex = 0; sheetIndex < 6; sheetIndex++){
    
    
const worksheet = workbook.addWorksheet(`Sheet${sheetIndex + 1}`);
    
worksheet.getCell('A3').value = 'PROFIT & LOSS';
worksheet.getCell('A2').value = 'Time Period ('+selectedRange.toString()+')';

if(items1.Account_Type==0 && items1.Account_id==5)
{

  console.log(Eventkey,"INside")

  if(Eventkey==='All'){



    console.log(Eventkey,"Length")

    Name=items1.Company +' -' + "All";
    console.log(items1.Company,"res")
    worksheet.getCell('A1').value =items1.Company +' -' + "All";
    const cell = worksheet.getCell('A1');
  
    cell.alignment = {
      horizontal: 'center', // Center the text horizontally
    }

  }
  else{


    console.log(Eventkey,"outside")


    console.log(Eventkey,"EventKey")
         Name=Eventkey;
    worksheet.getCell('A1').value = Eventkey;
  
    const cell = worksheet.getCell('A1');
  
    cell.alignment = {
      horizontal: 'center', // Center the text horizontally
    }
  }
 

}
else{

  Name=items.Company +' - '+items.Account_Name;
    console.log(items.Account_Name,"123333")
    worksheet.getCell('A1').value = Name
    
    const cell = worksheet.getCell('A1');
  
    cell.alignment = {
      horizontal: 'center', // Center the text horizontally
    }; 
  }



 if(items.Account_Name==''){


  const parts=Eventkey.split('-')


  if(Eventkey=='All'){

    Name=Events +'-'+parts[0];
    worksheet.getCell('A1').value = Events +'-'+parts[0];
  
    const cell = worksheet.getCell('A1');
  
    cell.alignment = {
      horizontal: 'center', // Center the text horizontally
    }
  }
else{
  Name=Events +'-'+parts[1];  
  worksheet.getCell('A1').value = Events +'-'+parts[1];
  
  const cell = worksheet.getCell('A1');

  cell.alignment = {
    horizontal: 'center', // Center the text horizontally
  }
}
 
}





          const cell = worksheet.getCell('A2');

          cell.alignment = {
            horizontal: 'center', // Center the text horizontally
          }; 

          worksheet.mergeCells('A3:A4');
          worksheet.mergeCells('A1:E1');
          worksheet.mergeCells('A2:E2');
          worksheet.mergeCells('B3:C3');
          worksheet.mergeCells('D3:E3');
  

          worksheet.getCell('B3').value = 'YEAR TO DATE';
          worksheet.getCell('D3').value = 'BUDGET TO DATE';
          worksheet.getCell('B4').value = 'AMOUNT($)';
          worksheet.getCell('C4').value = 'PER (%)';
          worksheet.getCell('D4').value = 'AMOUNT($)';
          worksheet.getCell('E4').value = 'PER (%)';
    
    
    
    
     // for (let row = 3; row <= 3; row++) {
            const cells = worksheet.getCell('A3');
            cells.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF00ABB5' }, // Background color
            };
            cells.border = {
                top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              };
              cells.font = {
                color: { argb: 'FFFFFFFF' }, // Text color
                size: 13, // Text size in points
                
              };
              cells.alignment = {
                horizontal: 'center', // Center the text horizontally
                vertical: 'middle', // Center the text vertically
              }; 
    
          //  }
    
    
    
    
          if(sheetIndex===0){

            if(array.Profitloss ?.length > 0){


       
            let rowIndex = 5;

            let  count=0;
          array.Profitloss[0].List.forEach((row) => {


            if (items.Account_Type === 4 || items.Account_Type === 5 || items.Account_Type === 9 || items.Account_Type === 10) {

              if (row.Cat_name === 'Rent' || row.Cat_name === 'CAM Charges') {

                return ;

              }

            }

            worksheet.getCell(`A${rowIndex}`).value = row.Cat_name;
            worksheet.getCell(`B${rowIndex}`).value = Math.round(row.Cat_amt);
            worksheet.getCell(`C${rowIndex}`).value =  Math.round(row.Cat_per);

            worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
            worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
    
           const cell = worksheet.getCell(`A${rowIndex}`);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF00ABB5' }, // Background color
            };
            cell.border = {
                top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              };
              cell.font = {
                color: { argb: 'FFFFFFFF' }, // Text color
                           
              };




  


              // if(row.Cat_name=='Total Revenue')
              // {
              //   console.log(array.Profitloss[1].List[2].Cat_amt,"array1")
              //    if(row.Cat_amt  <= array.Profitloss[1].List[2].Cat_amt )
              //    {



              //     console.log(row.Cat_amt ,"array1")
              //     worksheet.getCell(`B${rowIndex}`).font = {
              //       color: { argb: 'FFFF0000' }, // Red text color for negative values
              //     };
              //     worksheet.getCell(`C${rowIndex}`).font = {
              //         color: { argb: 'FFFF0000' }, // Red text color for negative values
              //       };

              //    }
              //    else{
              //     worksheet.getCell(`B${rowIndex}`).font = {
              //       color: { argb: 'FF008000' }, 
              //     };
              //     worksheet.getCell(`C${rowIndex}`).font = {
              //       color: { argb: 'FF008000' }, 
              //     };

              //    }
                     
              // }


            // if (row.Cat_amt <0) {
            //     worksheet.getCell(`B${rowIndex}`).font = {
            //       color: { argb: 'FFFF0000' }, // Red text color for negative values
            //     };
            //     worksheet.getCell(`C${rowIndex}`).font = {
            //         color: { argb: 'FFFF0000' }, // Red text color for negative values
            //       };
             
            //   }
            //   else {
            //       worksheet.getCell(`B${rowIndex}`).font = {
            //         color: { argb: 'FF008000' }, 
            //       };
            //       worksheet.getCell(`C${rowIndex}`).font = {
            //         color: { argb: 'FF008000' }, 
            //       };
    
            //   }
    
    
            rowIndex++; // Increment the row index for the next row
          });
    
          let rowIndex1 = 5;
    
          array.Profitloss[1].List.forEach((row) => {
            if (items.Account_Type === 4 || items.Account_Type === 5 || items.Account_Type === 9 || items.Account_Type === 10) {

              if (row.Cat_name === 'Rent' || row.Cat_name === 'CAM Charges') {

                return ;

              }

            }
            
            worksheet.getCell(`D${rowIndex1}`).value = Math.round(row.Cat_amt)  
            worksheet.getCell(`E${rowIndex1}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
            worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';

            rowIndex1++; 
          });
          let rowss=5;

          let rowper=5;

          for (let i = 0; i < array.Profitloss[0].List.length; i++) {
        
            const row1 = Math.round(array.Profitloss[0].List[i].Cat_amt);
            const row2 = Math.round(array.Profitloss[1].List[i].Cat_amt);

            const row3 = Math.round(array.Profitloss[0].List[i].Cat_per);
            const row4 = Math.round(array.Profitloss[1].List[i].Cat_per);
        
        if(array.Profitloss[0].List[i].Cat_name=='Total Revenue' || array.Profitloss[0].List[i].Cat_name=='Operating Profit/Loss' || array.Profitloss[0].List[i].Cat_name=='Net Profit/Loss')
        {
        
        if(row1<row2){
            console.log(row1.Cat_amt ,"array1")
            worksheet.getCell(`B${rowss}`).font = {
              color: { argb: 'FFFF0000' }, // Red text color for negative values
            };
            // worksheet.getCell(`C${rowss}`).font = {
            //     color: { argb: 'FFFF0000' }, // Red text color for negative values
            //   };

              rowss++;
        
        }
        else{
        
            worksheet.getCell(`B${rowss}`).font = {
                color: { argb: 'FF008000' }, 
              };
              // worksheet.getCell(`C${rowss}`).font = {
              //   color: { argb: 'FF008000' }, 
              // };
              rowss++;
        }
        if(row3<row4){
          // console.log(row1.Cat_amt ,"array1")
          // worksheet.getCell(`B${rowss}`).font = {
          //   color: { argb: 'FFFF0000' }, // Red text color for negative values
          // };
          worksheet.getCell(`C${rowper}`).font = {
              color: { argb: 'FFFF0000' }, // Red text color for negative values
            };

            rowper++;
      
      }
      else{
      
          // worksheet.getCell(`B${rowss}`).font = {
          //     color: { argb: 'FF008000' }, 
          //   };
            worksheet.getCell(`C${rowper}`).font = {
              color: { argb: 'FF008000' }, 
            };
            rowper++;
      }

        
        }
        
                
        
        
        else{

            if(row1 > row2){
        
                console.log(row1.Cat_amt ,"array1")
                worksheet.getCell(`B${rowper}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
                // worksheet.getCell(`C${rowss}`).font = {
                //     color: { argb: 'FFFF0000' }, // Red text color for negative values
                //   };
                  rowss++;
        
            
            }
            else{
        
              
                
                worksheet.getCell(`B${rowss}`).font = {
                  color: { argb: 'FF008000' }, 
                };
                // worksheet.getCell(`C${rowss}`).font = {
                //   color: { argb: 'FF008000' }, 
                // };
                rowss++;
            
            }
            if(row3 > row4){
        
              // console.log(row1.Cat_amt ,"array1")
              // worksheet.getCell(`B${rowss}`).font = {
              //   color: { argb: 'FFFF0000' }, // Red text color for negative values
              // };
              worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
                rowper++;
      
          
          }
          else{
      
            
              
              // worksheet.getCell(`B${rowss}`).font = {
              //   color: { argb: 'FF008000' }, 
              // };
              worksheet.getCell(`C${rowper}`).font = {
                color: { argb: 'FF008000' }, 
              };
              rowper++;
          
          }

        
        }

     
          }



        }
        
        
        
        
        else{

          workbook.removeWorksheet(`Sheet${sheetIndex + 1}`);
    
        }













        worksheet.name = 'Profit & Loss';

          }
          if(sheetIndex===1){


            if(array.Revenue ?.length >0){



           
            worksheet.getCell('A3').value = 'Revenue';
    
            let rowIndex = 5;
            let rowss=5;
            let rowper=5;
          array.Revenue[0].List.forEach((row) => {
            worksheet.getCell(`A${rowIndex}`).value = row.Cat_name;
            worksheet.getCell(`B${rowIndex}`).value =  Math.round(row.Cat_amt);
            worksheet.getCell(`C${rowIndex}`).value = Math.round(row.Cat_per);

            worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
            worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
    
            const cell = worksheet.getCell(`A${rowIndex}`);


            for (let i = 0; i < array.Revenue[0].List.length; i++) {
        
              const row1 = Math.round(array.Revenue[0].List[i].Cat_amt);
              const row2 = Math.round(array.Revenue[1].List[i].Cat_amt);
              const row3 = Math.round(array.Revenue[0].List[i].Cat_per);
              const row4 = Math.round(array.Revenue[1].List[i].Cat_per);
                            
          if(row1<row2){
              
              console.log(row1.Cat_amt ,"array1")
              worksheet.getCell(`B${rowss}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
            
                rowss++;
          
          }
          
          else{
          
              worksheet.getCell(`B${rowss}`).font = {
                  color: { argb: 'FF008000' }, 
                };
               

                rowss++;
          }


          if(row3<row4){
            worksheet.getCell(`C${rowper}`).font = {
              color: { argb: 'FFFF0000' }, // Red text color for negative values
            };

            rowper++;



          }
          else{
            worksheet.getCell(`C${rowper}`).font = {
              color: { argb: 'FF008000' }, 
            };
            rowper++;

          }
        }
            // cell.fill = {
            //   type: 'pattern',
            //   pattern: 'solid',
            //   fgColor: { argb: 'FF00ABB5' }, // Background color
            // };
            // cell.border = {
            //     top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //   };
            //   cell.font = {
            //     color: { argb: 'FFFFFFFF' }, // Text color
                           
            //   };
    
            // if (row.Cat_amt <0) {
            //     worksheet.getCell(`B${rowIndex}`).font = {
            //       color: { argb: 'FFFF0000' }, // Red text color for negative values
            //     };
            //     worksheet.getCell(`C${rowIndex}`).font = {
            //         color: { argb: 'FFFF0000' }, // Red text color for negative values
            //       };
             
            //   }
            //   else {
            //       worksheet.getCell(`B${rowIndex}`).font = {
            //         color: { argb: 'FF008000' }, 
            //       };
            //       worksheet.getCell(`C${rowIndex}`).font = {
            //         color: { argb: 'FF008000' }, 
            //       };
    
             // }
    
            rowIndex++; // Increment the row index for the next row
          });
         // array.Revenue[0].forEach((row) => {
            worksheet.getCell(`A${rowIndex}`).value ='TOTAL'
    
            worksheet.getCell(`B${rowIndex}`).value = Math.round(array.Revenue[0].Total);
            worksheet.getCell(`C${rowIndex}`).value = Math.round(array.Revenue[0].per);
            
            worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
            worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
    
            const row = worksheet.getRow(rowIndex);
            row.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'f2f0f1' }
            };
            if (array.Revenue[0].Total < array.Revenue[1].Total )   {
                worksheet.getCell(`B${rowIndex}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
                // worksheet.getCell(`C${rowIndex}`).font = {
                //     color: { argb: 'FFFF0000' }, // Red text color for negative values
                //   };
             
              }
              else {
                worksheet.getCell(`B${rowIndex}`).font = {
                  color: { argb: 'FF008000' }, 
                };
                // worksheet.getCell(`C${rowIndex}`).font = {
                //   color: { argb: 'FF008000' }, 
                // };
    
            }
            if (array.Revenue[0].per < array.Revenue[1].per )   {
              // worksheet.getCell(`B${rowIndex}`).font = {
              //   color: { argb: 'FFFF0000' }, // Red text color for negative values
              // };
              worksheet.getCell(`C${rowIndex}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
           
            }
            else {
            //   worksheet.getCell(`B${rowIndex}`).font = {
            //     color: { argb: 'FF008000' }, 
            //   };
              worksheet.getCell(`C${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
  
          }

            row.border = {
                top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              };
            
    
        //  })
    
        //array.Revenue[1].Total
          let rowIndex1 = 5;
    
          array.Revenue[1].List.forEach((row) => {
            worksheet.getCell(`D${rowIndex1}`).value =  Math.round(row.Cat_amt);
            worksheet.getCell(`E${rowIndex1}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
            worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
    
    
    
    
            rowIndex1++; // Increment the row index for the next row
          });
    
           worksheet.getCell(`D${rowIndex1}`).value =Math.round(array.Revenue[1].Total);
            worksheet.getCell(`E${rowIndex1}`).value = Math.round(array.Revenue[1].per);
            worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
            worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
    
    
          }
          else{

            workbook.removeWorksheet(`Sheet${sheetIndex + 1}`);

          }
          
          worksheet.name = 'Revenue';
          }

          if(sheetIndex===2){

            if(array.Cost_of_goods?.length > 0){

           
            worksheet.getCell('A3').value = 'Cost Of Goods';
    
            let rowIndex = 5;
            let rowss=5;
            let rowper=5;
          array.Cost_of_goods[0].List.forEach((row) => {
            worksheet.getCell(`A${rowIndex}`).value = row.Cat_name;
            worksheet.getCell(`B${rowIndex}`).value = Math.round(row.Cat_amt);
            worksheet.getCell(`C${rowIndex}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
            worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
            const cell = worksheet.getCell(`A${rowIndex}`);

            for (let i = 0; i < array.Cost_of_goods[0].List.length; i++) {
        
              const row1 =  Math.round(array.Cost_of_goods[0].List[i].Cat_amt);
              const row2 =  Math.round(array.Cost_of_goods[1].List[i].Cat_amt);

              const row3 =  Math.round(array.Cost_of_goods[0].List[i].Cat_per);
              const row4 =  Math.round(array.Cost_of_goods[1].List[i].Cat_per);

          if(row1>row2){
              worksheet.getCell(`B${rowss}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
     
              rowss++;
          
          }
          
          else{

              worksheet.getCell(`B${rowss}`).font = {
                  color: { argb: 'FF008000' }, 
                };
            
                rowss++;
          }

          if(row3>row4){

            
              worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
                rowper++;
          
          }
          
          else{
 
                worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FF008000' }, 
                };

                rowper++;
          }





        }

            

            rowIndex++; // Increment the row index for the next row
          });
    
    
    
          worksheet.getCell(`A${rowIndex}`).value ='TOTAL'
    
          worksheet.getCell(`B${rowIndex}`).value =Math.round(array.Cost_of_goods[0].Total);
          worksheet.getCell(`C${rowIndex}`).value = Math.round(array.Cost_of_goods[0].per);
          
          worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
          worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
          const row = worksheet.getRow(rowIndex);
          row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f2f0f1' }
          };
          if (array.Cost_of_goods[0].Total >  array.Cost_of_goods[1].Total)   {
              worksheet.getCell(`B${rowIndex}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
              worksheet.getCell(`C${rowIndex}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
           
            }
            else {
              worksheet.getCell(`B${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
              worksheet.getCell(`C${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
    
          }
          row.border = {
              top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            };
    
    
          let rowIndex1 = 5;
    
          array.Cost_of_goods[1].List.forEach((row) => {
            worksheet.getCell(`D${rowIndex1}`).value =  Math.round(row.Cat_amt);
            worksheet.getCell(`E${rowIndex1}`).value = Math.round(row.Cat_per);

            worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
            worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
            
            rowIndex1++; // Increment the row index for the next row
          });
    
          worksheet.getCell(`D${rowIndex1}`).value =Math.round(array.Cost_of_goods[1].Total);
          worksheet.getCell(`E${rowIndex1}`).value = Math.round(array.Cost_of_goods[1].per);
          
          worksheet.getCell(`D${rowIndex}`).numFmt = '0.00';
          worksheet.getCell(`E${rowIndex}`).numFmt = '0.00';
        }
            
        else{

          workbook.removeWorksheet(`Sheet${sheetIndex + 1}`);
          //workbook.addWorksheet(`Sheet${sheetIndex + 1}`);
          //const worksheet = workbook.addWorksheet(`Sheet${sheetIndex + 1}`);

        }
        worksheet.name = 'Cost Of Goods';
          }
          if(sheetIndex===3){


            if(array.Labor ?.length >0){


          
            worksheet.getCell('A3').value = 'Labor';
            let rowsser=5;
            let rowIndex = 5;
            let rowper=5;
          array.Labor[0].List.forEach((row) => {
            worksheet.getCell(`A${rowIndex}`).value = row.Cat_name;
            worksheet.getCell(`B${rowIndex}`).value =  Math.round(row.Cat_amt);
            worksheet.getCell(`C${rowIndex}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
            worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';


            for (let i = 0; i < array.Labor[0].List.length; i++) {
        
              const row1 =  Math.round(array.Labor[0].List[i].Cat_amt);
              const row2 =  Math.round(array.Labor[1].List[i].Cat_amt);

              const row3 =  Math.round(array.Labor[0].List[i].Cat_per);
              const row4 =  Math.round(array.Labor[1].List[i].Cat_per);

          if(row1>row2){
              worksheet.getCell(`B${rowsser}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
     
                rowsser++;
          
          }
          
          else{

              worksheet.getCell(`B${rowsser}`).font = {
                  color: { argb: 'FF008000' }, 
                };
            
                rowsser++;
          }

          if(row3>row4){

            
              worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
                rowper++;
          
          }
          
          else{
 
                worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FF008000' }, 
                };

                rowper++;
          }





        }


        //     for (let i = 0; i < array.Labor[0].List.length; i++) {
        
        //       const row1 = array.Labor[0].List[i].Cat_amt;
        //          const row2 = array.Labor[1].List[i].Cat_amt;

        //   if(row1>row2){
              
        //       console.log(row1.Cat_amt ,"array1")
        //       worksheet.getCell(`B${rowss}`).font = {
        //         color: { argb: 'FFFF0000' }, // Red text color for negative values
        //       };
        //       worksheet.getCell(`C${rowss}`).font = {
        //           color: { argb: 'FFFF0000' }, // Red text color for negative values
        //         };
        //         rowss++;
          
        //   }
          
        //   else{
          
        //       worksheet.getCell(`B${rowss}`).font = {
        //           color: { argb: 'FF008000' }, 
        //         };
        //         worksheet.getCell(`C${rowss}`).font = {
        //           color: { argb: 'FF008000' }, 
        //         };

        //         rowss++;
        //   }
        // }
          
    
            rowIndex++; // Increment the row index for the next row
          });
          worksheet.getCell(`A${rowIndex}`).value ='TOTAL'
    
          worksheet.getCell(`B${rowIndex}`).value =Math.round(array.Labor[0].Total);
          worksheet.getCell(`C${rowIndex}`).value = Math.round(array.Labor[0].per);
          worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
          worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
    
          const row = worksheet.getRow(rowIndex);
          row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f2f0f1' }
          };
          if (array.Labor[0].Total > array.Labor[1].Total )   {
              worksheet.getCell(`B${rowIndex}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
              worksheet.getCell(`C${rowIndex}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
           
            }
            else {
              worksheet.getCell(`B${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
              worksheet.getCell(`C${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
    
          }
          row.border = {
              top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            };
          let rowIndex1 = 5;
    
          array.Labor[1].List.forEach((row) => {
            worksheet.getCell(`D${rowIndex1}`).value =  Math.round(row.Cat_amt);
            worksheet.getCell(`E${rowIndex1}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
            worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
      
        
            rowIndex1++; // Increment the row index for the next row
          });
          worksheet.getCell(`D${rowIndex1}`).value =Math.round(array.Labor[1].Total);
          worksheet.getCell(`E${rowIndex1}`).value = Math.round(array.Labor[1].per)
          worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
          worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
    
        }else{

          workbook.removeWorksheet(`Sheet${sheetIndex + 1}`);

        }
        worksheet.name = 'Labor';
          }
          
          if(sheetIndex===4){

            if(array.Controllable ?.length >0){


            
            worksheet.getCell('A3').value = 'Controllable';
    
            let rowIndex = 5;
            let rowsser=5;

            let rowper=5;
          array.Controllable[0].List.forEach((row) => {
            worksheet.getCell(`A${rowIndex}`).value = row.Cat_name;
            worksheet.getCell(`B${rowIndex}`).value = Math.round(row.Cat_amt);
            worksheet.getCell(`C${rowIndex}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
            worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
            for (let i = 0; i < array.Controllable[0].List.length; i++) {
        
              const row1 =  Math.round(array.Controllable[0].List[i].Cat_amt);
              const row2 =  Math.round(array.Controllable[1].List[i].Cat_amt);

              const row3 =  Math.round(array.Controllable[0].List[i].Cat_per);
              const row4 =  Math.round(array.Controllable[1].List[i].Cat_per);

          if(row1>row2){
              worksheet.getCell(`B${rowsser}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
     
                rowsser++;
          
          }
          
          else{

              worksheet.getCell(`B${rowsser}`).font = {
                  color: { argb: 'FF008000' }, 
                };
            
                rowsser++;
          }

          if(row3>row4){

            
              worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
                rowper++;
          
          }
          
          else{
 
                worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FF008000' }, 
                };

                rowper++;
          }





        }
            // const cell = worksheet.getCell(`A${rowIndex}`);
            // cell.fill = {
            //   type: 'pattern',
            //   pattern: 'solid',
            //   fgColor: { argb: 'FF00ABB5' }, // Background color
            // };
            // cell.border = {
            //     top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //   };
            //   cell.font = {
            //     color: { argb: 'FFFFFFFF' }, // Text color
                           
            //   };
    
            // if (row.Cat_amt <0) {
            //     worksheet.getCell(`B${rowIndex}`).font = {
            //       color: { argb: 'FFFF0000' }, // Red text color for negative values
            //     };
            //     worksheet.getCell(`C${rowIndex}`).font = {
            //         color: { argb: 'FFFF0000' }, // Red text color for negative values
            //       };
             
            //   }
            //   else {
            //       worksheet.getCell(`B${rowIndex}`).font = {
            //         color: { argb: 'FF008000' }, 
            //       };
            //       worksheet.getCell(`C${rowIndex}`).font = {
            //         color: { argb: 'FF008000' }, 
            //       };
    
            //   }
            rowIndex++; // Increment the row index for the next row
          });
          worksheet.getCell(`A${rowIndex}`).value ='TOTAL'
    
          worksheet.getCell(`B${rowIndex}`).value =Math.round(array.Controllable[0].Total);
          worksheet.getCell(`C${rowIndex}`).value = Math.round(array.Controllable[0].per);
          worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
          worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
    
          const row = worksheet.getRow(rowIndex);
          row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f2f0f1' }
          };
          if (array.Controllable[0].Total > array.Controllable[1].Total)   {
              worksheet.getCell(`B${rowIndex}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
              worksheet.getCell(`C${rowIndex}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
           
            }
            else {
              worksheet.getCell(`B${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
              worksheet.getCell(`C${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
    
          }
          row.border = {
              top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            };
          let rowIndex1 = 5;
    
          array.Controllable[1].List.forEach((row) => {
            worksheet.getCell(`D${rowIndex1}`).value = Math.round(row.Cat_amt);
            worksheet.getCell(`E${rowIndex1}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
            worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
         
            rowIndex1++; // Increment the row index for the next row
          });
          worksheet.getCell(`D${rowIndex1}`).value =Math.round(array.Controllable[1].Total);
          worksheet.getCell(`E${rowIndex1}`).value = Math.round(array.Controllable[1].per);
          worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
          worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
        }
        else{
          workbook.removeWorksheet(`Sheet${sheetIndex + 1}`);
        }
        worksheet.name = 'Controllable';
          }
          if(sheetIndex===5){

            if(array.Non_controllable ?.length > 0){


            worksheet.getCell('A3').value = 'Non Controllable';
    
            let rowIndex = 5;
            let rowss=5;
            let rowper=5;
          array.Non_controllable[0].List.forEach((row) => {
            worksheet.getCell(`A${rowIndex}`).value = row.Cat_name;
            worksheet.getCell(`B${rowIndex}`).value =  Math.round(row.Cat_amt);
            worksheet.getCell(`C${rowIndex}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
            worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';

            for (let i = 0; i < array.Non_controllable[0].List.length; i++) {
        
              const row1 =  Math.round(array.Non_controllable[0].List[i].Cat_amt);
              const row2 =  Math.round(array.Non_controllable[1].List[i].Cat_amt);

              const row3 =  Math.round(array.Non_controllable[0].List[i].Cat_per);
              const row4 =  Math.round(array.Non_controllable[1].List[i].Cat_per);

          if(row1>row2){
              worksheet.getCell(`B${rowss}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
     
              rowss++;
          
          }
          
          else{

              worksheet.getCell(`B${rowss}`).font = {
                  color: { argb: 'FF008000' }, 
                };
            
                rowss++;
          }

          if(row3>row4){

            
              worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
                rowper++;
          
          }
          
          else{
 
                worksheet.getCell(`C${rowper}`).font = {
                  color: { argb: 'FF008000' }, 
                };

                rowper++;
          }





        }


            // const cell = worksheet.getCell(`A${rowIndex}`);
            // cell.fill = {
            //   type: 'pattern',
            //   pattern: 'solid',
            //   fgColor: { argb: 'FF00ABB5' }, // Background color
            // };
            // cell.border = {
            //     top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //     right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            //   };
            //   cell.font = {
            //     color: { argb: 'FFFFFFFF' }, // Text color
                           
            //   };
    
            // if (row.Cat_amt <0) {
            //     worksheet.getCell(`B${rowIndex}`).font = {
            //       color: { argb: 'FFFF0000' }, // Red text color for negative values
            //     };
            //     worksheet.getCell(`C${rowIndex}`).font = {
            //         color: { argb: 'FFFF0000' }, // Red text color for negative values
            //       };
             
            //   }
            //   else {
            //       worksheet.getCell(`B${rowIndex}`).font = {
            //         color: { argb: 'FF008000' }, 
            //       };
            //       worksheet.getCell(`C${rowIndex}`).font = {
            //         color: { argb: 'FF008000' }, 
            //       };
    
            //   }
            rowIndex++; // Increment the row index for the next row
          });
          worksheet.getCell(`A${rowIndex}`).value ='TOTAL'
    
          worksheet.getCell(`B${rowIndex}`).value =Math.round(array.Non_controllable[0].Total);
          worksheet.getCell(`C${rowIndex}`).value = Math.round(array.Non_controllable[0].per);
          worksheet.getCell(`B${rowIndex}`).numFmt = '0.00';
          worksheet.getCell(`C${rowIndex}`).numFmt = '0.00';
    
          const row = worksheet.getRow(rowIndex);
          row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f2f0f1' }
          };
          if (array.Non_controllable[0].Total > array.Non_controllable[1].Total  )   {
              worksheet.getCell(`B${rowIndex}`).font = {
                color: { argb: 'FFFF0000' }, // Red text color for negative values
              };
              worksheet.getCell(`C${rowIndex}`).font = {
                  color: { argb: 'FFFF0000' }, // Red text color for negative values
                };
           
            }
            else {
              worksheet.getCell(`B${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
              worksheet.getCell(`C${rowIndex}`).font = {
                color: { argb: 'FF008000' }, 
              };
    
          }
          row.border = {
              top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
            };
          let rowIndex1 = 5;
    
          array.Non_controllable[1].List.forEach((row) => {
            worksheet.getCell(`D${rowIndex1}`).value =  Math.round(row.Cat_amt);
            worksheet.getCell(`E${rowIndex1}`).value = Math.round(row.Cat_per);
            worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
            worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
         
            rowIndex1++; // Increment the row index for the next row
          });
          worksheet.getCell(`D${rowIndex1}`).value =Math.round(array.Non_controllable[1].Total);
          worksheet.getCell(`E${rowIndex1}`).value = Math.round(array.Non_controllable[1].per);
          worksheet.getCell(`D${rowIndex1}`).numFmt = '0.00';
          worksheet.getCell(`E${rowIndex1}`).numFmt = '0.00';
          
        }else{
          workbook.removeWorksheet(`Sheet${sheetIndex + 1}`);

        }

        worksheet.name = 'Non Controllable';
          }
    
    
    
        
    
          const startRow1 = 3;
          const endRow1 = 4;
          for (let row = startRow1; row <= endRow1; row++) {
            const cell = worksheet.getCell(`B${row}`);
            const cell1 = worksheet.getCell(`D${row}`);
    
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF00ABB5' }, // Background color
            };
            cell1.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF00ABB5' }, // Background color
              };
              cell.font = {
                color: { argb: 'FFFFFFFF' }, // Text color
                           
              };
              cell1.font = {
                color: { argb: 'FFFFFFFF' }, // Text color
                           
              };
              cell.alignment = {
                horizontal: 'center', // Center the text horizontally
              }; 
              cell1.alignment = {
                horizontal: 'center', // Center the text horizontally
              }; 
    
    
    
              if(row==4){
                const cell = worksheet.getCell(`C${row}`);
            const cell1 = worksheet.getCell(`E${row}`);
    
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF00ABB5' }, // Background color
            };
            cell1.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF00ABB5' }, // Background color
              };
              cell.font = {
                color: { argb: 'FFFFFFFF' }, // Text color
                           
              };
              cell1.font = {
                color: { argb: 'FFFFFFFF' }, // Text color
                           
              };
              cell.alignment = {
                horizontal: 'center', // Center the text horizontally
                
              }; 
              cell1.alignment = {
                horizontal: 'center', // Center the text horizontally
                
              }; 
             
    
              }
              cell.border = {
                top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              };
              cell1.border = {
                top: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                left: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                bottom: { style: 'thin', color: { argb: 'FFDDDDDD' } },
                right: { style: 'thin', color: { argb: 'FFDDDDDD' } },
              };
    
    
    
    
          }
    
    
        //   for (let row = startRow1; row <= endRow1; row++) {
        //     const cell = worksheet.getCell(`D${row}`);
    
        //     cell.fill = {
        //       type: 'pattern',
        //       pattern: 'solid',
        //       fgColor: { argb: 'FF03DBFC' }, // Background color
        //     };
        //   }
    
    
    
    
    
    
    
          // Apply styling to cells
        //   const cellA1 = worksheet.getCell('A1');
        //   cellA1.font = { color: { argb: '#03dbfc' } }; // Red font color
        //   const cellB1 = worksheet.getCell('B1');
        //   cellB1.fill = {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: '#03dbfc' } // Green background color
        //   };
    
          // Auto-fit column widths
          worksheet.columns.forEach((column) => {
            column.width = 20;
          });
    
        }
    
    
          // Generate a buffer from the workbook
          const buffer = await workbook.xlsx.writeBuffer();
    
          // Create a blob from the buffer
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
          // Download the file
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = Name+'.xlsx';
          link.click();
    
          // Cleanup
          URL.revokeObjectURL(url);
  
  }
  ///////////////////
  const tablesData = [
    { title: "PROFIT/LOSS", data: data },
    { title: "REVENUE", data: data5 },
    { title: "Cost Of Goods", data: data1 },
    { title: "Labor", data: data2 },
    { title: "Controllable", data: data3 },
    { title: "Non Controllable", data: data4 },
  ];
  
  console.log(tablesData,"ttt")
  const nonEmptyTablesData = tablesData.filter(table => table.data.length !==0 );

  return (
    <>
      <div className="container-fluid">
        {/* <div className="row">
          <div className="col-md-6 col-sm-4"></div>
          {showCourse && (
            <div className="col-md-6 col-sm-8" style={{ width: "47%" }}>
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Col md="7" sm="8">
                    <Form.Label style={{ float: "right", marginTop: "6px" }}>
                      Select Department
                    </Form.Label>
                  </Col>
                  <Col md="5" sm="4">
                    <Dropdown onSelect={handleSelectdrp}>
                      <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        className="drop-ml "
                        variant="secondary"
                      >
                        {selectDepartment
                          ? selectDepartment.slice(0, 23)
                          : "All Department"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark" className="responsedrop">
                        <Dropdown.Item
                          eventKey={items.Boss_id + "`" + "All Department"}
                        >
                          All Department
                        </Dropdown.Item>
                        {department?.map((x) => (
                          <Dropdown.Item
                            eventKey={x.Rest_id + "`" + x.Rest_Name}
                            className={
                              selectDepartment_active ===
                              x.Rest_id + "`" + x.Rest_Name
                                ? "active"
                                : ""
                            }
                          >
                            {x.Rest_Name}
                          </Dropdown.Item>
                        ))}

                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Form.Group>
              </Form>
            </div>
          )}
        </div> */}
        <div className="col-md-12">
          <div
            className="row"
            style={{ marginLeft: "-50px", marginRight: "-50px" }}
          >
            <div className="col-lg-6 col-md-8 col-sm-8">
              <p
                className="Daily-font"
                style={{
                  textAlign: "left",
                  fontSize: "30px",
                  letterSpacing: "0px",
                  color: "#7B7B7B",
                  opacity: "1",
                }}
              >
                Profit & Loss
              </p>
            </div>
            <div className="col-lg-6">
                <div>
                    <div style={{display:'flex',float:'right',gap:'10px'}}>
               <div className="col">
                    {budget != undefined && Budgetlength != 0 && (
                       <Button

                       onClick={handleDownload}
                    className="print-btn"
                    style={{
                      background: "#00D95C 0% 0% no-repeat padding-box",
                      height: " 35px",
                      marginBottom: "10px",
                      float: "right",
                    }}
                  >
                    {" "}
                    <i class="bi bi-download" style={{ color: "white" }}>
                      {" "}
                      <span
                        className="print-para"
                        style={{
                          color: "white",
                          fontSize: " 12px",
                          fontStyle: " normal",
                          textTransform: "initial",
                        }}
                      >
                        Export
                      </span>
                    </i>
                  </Button>
                    )}
            </div>
            <div className="col">
                    {budget != undefined && Budgetlength != 0 && (
              <ReactToPrint
                trigger={() => (
                  <Button
                    className="print-btn"
                    style={{
                      background: "#FFBC00 0% 0% no-repeat padding-box",
                      height: " 35px",
                      marginBottom: "10px",
                      float: "right",
                    }}
                  >
                    {" "}
                    <i class="bi bi-printer" style={{ color: "white" }}>
                      {" "}
                      <span
                        className="print-para"
                        style={{
                          color: "white",
                          fontSize: " 12px",
                          fontStyle: " normal",
                          textTransform: "initial",
                        }}
                      >
                        Print
                      </span>
                    </i>
                  </Button>
                )}
                content={() => componentRef.current}
              />
                    )}
            </div>
                    </div>
                </div>
            </div>

          </div>
        </div>
        {budget_type == 1 ? (
          <div className="R-list1">
            <div className="container-fluid">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div
                  className="row"
                  style={{ paddingTop: "15px", paddingBottom: "10px" }}
                >
                  <div
                    className="col-md-6 col-sm-6 col-xs-12"
                    style={{ lineHeight: "40px" }}
                  >
                    <div className="row">
                      <div class="col-md-4 col-sm-4 col-xs-6">
                        <p
                          className="drop-head"
                          style={{
                            fontWeight: "bold",
                            float: "right",
                            fontSize: "20px",
                          }}
                        >
                          Period
                        </p>
                      </div>
                      <div class="col-md-2 col-sm-3 col-xs-6">
                        <p
                          className="drop-text"
                          style={{
                            float: "right",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Start :
                        </p>
                      </div>
                      <div class="col-md-6 col-sm-5 col-xs-6">
                        <Dropdown
                          className="dr_op"
                          style={{
                            border: "1px solid #fff",
                            background: "#fff",
                            padding: "0px 10px",
                            borderRadius: "4px",
                            marginTop: "0px",
                          }}
                          onSelect={handleSelect}
                        >
                          <span
                            className="drop-header-text"
                            style={{
                              paddingRight: "5px",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "36px",
                            }}
                          >
                            {periodvalue}
                          </span>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            className="dr-ml header-btn"
                            variant="dark"
                            style={{ float: "right", padding: "8px 0px" }}
                          >
                            <i
                              class="bi bi-caret-down-fill"
                              style={{ color: "#19a73f", fontSize: "11px" }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            variant="dark"
                            className="pageheaddrop pageheaddrop1 period-drop"
                          >
                            {listperiod1.map((i) => {
                              return (<>
                                <Dropdown.Item
                                  eventKey={"Period " + i}
                                  onClick={() => setperiod1(i.toString())}
                                >
                                  Period {i.toString()}
                                </Dropdown.Item>
                              </>)
                            })

                            }

                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <select variant="secondary" value={period1} onChange={(e) => perioddropdown(e, "p1")} style={{width: "100%",background:"#ffffff",border:"2px solid #f5f5f5",height:"31px",borderRadius:"6px"}} >
                        <option value="1">Period 1</option>
                        <option value="2">Period 2</option>
                        <option value="3">Period 3</option>
                        <option value="4">Period 4</option>
                        <option value="5">Period 5</option>
                        <option value="6">Period 6</option>
                        <option value="7">Period 7</option>
                        <option value="8">Period 8</option>
                        <option value="9">Period 9</option>
                        <option value="10">Period 10</option>
                        <option value="11">Period 11</option>
                        <option value="12">Period 12</option>
                        <option value="13">Period 13</option>
                       
                        
                      </select> */}
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-4 col-sm-4 col-xs-6">
                        <p
                          className="drop-head"
                          style={{
                            fontWeight: "bold",
                            float: "right",
                            fontSize: "20px",
                          }}
                        >
                          Day
                        </p>
                      </div>
                      <div class="col-md-2 col-sm-3 col-xs-6">
                        <p
                          className="drop-text"
                          style={{
                            float: "right",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          From :
                        </p>
                      </div>
                      <div class="col-md-6 col-sm-5 col-xs-6">
                        <Dropdown
                          className="dr_op"
                          style={{
                            border: "1px solid #fff",
                            background: "#fff",
                            padding: "0px 10px",
                            borderRadius: "4px",
                            marginTop: "0px",
                          }}
                          onSelect={dayhandleSelect1}
                        >
                          <span
                            className="drop-header-text"
                            style={{
                              paddingRight: "5px",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "36px",
                            }}
                          >
                            
                            {day1value}
                          </span>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            className="dr-ml header-btn"
                            variant="dark"
                            style={{ float: "right", padding: "8px 0px" }}
                          >
                            <i
                              class="bi bi-caret-down-fill"
                              style={{ color: "#19a73f", fontSize: "11px" }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            variant="dark"
                            className="pageheaddrop pageheaddrop1 period-drop"
                          >
                           {
                            listday1.map((i)=>{

                              return(
                              <>
                              <Dropdown.Item
                              eventKey={"Day " + i}
                              onClick={() => setday1(i.toString())}
                            >
                              Day {i.toString()}
                            </Dropdown.Item>
                            </>
                              )

                            })
                           }
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <select  variant="secondary" value={day1} onChange={(e) => perioddropdown(e, "d1")} style={{width: "100%",background:"#ffffff",border:"2px solid #f5f5f5",height:"31px",borderRadius:"6px"}} >
                        <option value="1">Day 1</option>
                        <option value="2">Day 2</option>
                        <option value="3">Day 3</option>
                        <option value="4">Day 4</option>
                        <option value="5">Day 5</option>
                        <option value="6">Day 6</option>
                        <option value="7">Day 7</option>
                        <option value="8">Day 8</option>
                        <option value="9">Day 9</option>
                        <option value="10">Day 10</option>
                        <option value="11">Day 11</option>
                        <option value="12">Day 12</option>
                        <option value="13">Day 13</option>
                        <option value="14">Day 14</option>
                        <option value="15">Day 15</option>
                        <option value="16">Day 16</option>
                        <option value="17">Day 17</option>
                        <option value="18">Day 18</option>
                        <option value="19">Day 19</option>
                        <option value="20">Day 20</option>
                        <option value="21">Day 21</option>
                        <option value="22">Day 22</option>
                        <option value="23">Day 23</option>
                        <option value="24">Day 24</option>
                        <option value="25">Day 25</option>
                        <option value="26">Day 26</option>
                        <option value="27">Day 27</option>
                        <option value="28">Day 28</option>
                        
                      </select> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-4 col-xs-12"
                    style={{ lineHeight: "40px" }}
                  >
                    <div className="row">
                      <div class="col-md-3 col-sm-4 col-xs-6">
                        <p
                          className="drop-text"
                          style={{
                            float: "right",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          End :
                        </p>
                      </div>
                      <div class="col-md-9 col-sm-8 col-xs-6">
                        <Dropdown
                          className="dr_op"
                          style={{
                            border: "1px solid #fff",
                            background: "#fff",
                            padding: "0px 10px",
                            borderRadius: "4px",
                            marginTop: "0px",
                          }}
                          onSelect={handleSelect2}
                        >
                          <span
                            className="drop-header-text"
                            style={{
                              paddingRight: "5px",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "36px",
                            }}
                          >
                            
                            {periodvalue2}
                          </span>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            className="dr-ml header-btn"
                            variant="dark"
                            style={{ float: "right", padding: "8px 0px" }}
                          >
                            <i
                              class="bi bi-caret-down-fill"
                              style={{ color: "#19a73f", fontSize: "11px" }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            variant="dark"
                            className="pageheaddrop pageheaddrop1 period-drop"
                          >
                            {listperiod2.map((i) => {
                              return (<>
                                <Dropdown.Item
                                  eventKey={"Period " + i}
                                  onClick={() => setperiod2(i.toString())}
                                >
                                  Period {i.toString()}
                                </Dropdown.Item>
                              </>)
                            })

                            }
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <select  variant="secondary" value={period2} onChange={(e) => perioddropdown(e, "p2")} style={{width: "100%",background:"#ffffff",border:"2px solid #f5f5f5",height:"31px",borderRadius:"6px"}} >
                        <option value="1">Period 1</option>
                        <option value="2">Period 2</option>
                        <option value="3">Period 3</option>
                        <option value="4">Period 4</option>
                        <option value="5">Period 5</option>
                        <option value="6">Period 6</option>
                        <option value="7">Period 7</option>
                        <option value="8">Period 8</option>
                        <option value="9">Period 9</option>
                        <option value="10">Period 10</option>
                        <option value="11">Period 11</option>
                        <option value="12">Period 12</option>
                        <option value="13">Period 13</option>
                       
                        
                      </select> */}
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-3 col-sm-4 col-xs-6">
                        <p
                          className="drop-text"
                          style={{
                            float: "right",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          To :
                        </p>
                      </div>
                      <div class="col-md-9 col-sm-8 col-xs-6">
                        <Dropdown
                          className="dr_op"
                          style={{
                            border: "1px solid #fff",
                            background: "#fff",
                            padding: "0px 10px",
                            borderRadius: "4px",
                            marginTop: "0px",
                          }}
                          onSelect={dayhandleSelect2}
                        >
                          <span
                            className="drop-header-text"
                            style={{
                              paddingRight: "5px",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "36px",
                            }}
                          >
                            {day2value}
                          </span>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            className="dr-ml header-btn"
                            variant="dark"
                            style={{ float: "right", padding: "8px 0px" }}
                          >
                            <i
                              class="bi bi-caret-down-fill"
                              style={{ color: "#19a73f", fontSize: "11px" }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            variant="dark"
                            className="pageheaddrop pageheaddrop1 period-drop"
                          >
                            {
                            listday2.map((i)=>{

                              return(
                              <>
                              <Dropdown.Item
                              eventKey={"Day " + i}
                              onClick={() => setday2(i.toString())}
                            >
                              Day {i.toString()}
                            </Dropdown.Item>
                            </>
                              )

                            })
                           }
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <select  variant="secondary" value={day2} onChange={(e) => perioddropdown(e, "d2")} style={{width: "100%",background:"#ffffff",border:"2px solid #f5f5f5",height:"31px",borderRadius:"6px"}} >
                        <option value="1">Day 1</option>
                        <option value="2">Day 2</option>
                        <option value="3">Day 3</option>
                        <option value="4">Day 4</option>
                        <option value="5">Day 5</option>
                        <option value="6">Day 6</option>
                        <option value="7">Day 7</option>
                        <option value="8">Day 8</option>
                        <option value="9">Day 9</option>
                        <option value="10">Day 10</option>
                        <option value="11">Day 11</option>
                        <option value="12">Day 12</option>
                        <option value="13">Day 13</option>
                        <option value="14">Day 14</option>
                        <option value="15">Day 15</option>
                        <option value="16">Day 16</option>
                        <option value="17">Day 17</option>
                        <option value="18">Day 18</option>
                        <option value="19">Day 19</option>
                        <option value="20">Day 20</option>
                        <option value="21">Day 21</option>
                        <option value="22">Day 22</option>
                        <option value="23">Day 23</option>
                        <option value="24">Day 24</option>
                        <option value="25">Day 25</option>
                        <option value="26">Day 26</option>
                        <option value="27">Day 27</option>
                        <option value="28">Day 28</option>
                        
                      </select> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2 col-xs-12">
                    <button
                      className="btn btn-prim"
                      style={{
                        backgroundColor: "#2196f3",
                        marginTop: "58px",
                        color: "#fff",
                      }}
                      onClick={getdataforperiod}
                    >
                      GET P & L
                    </button>
                  </div>
                </div>
              </div>
              {/* <h4>Select Time Period : </h4>
            <div className='p&l-Dropdown rangedate'>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-autoclose-outside" className='drop-ml ' variant="secondary">
                  <i class="bi bi-calendar3"></i> <span>{selectedRange}</span>
                 
    
                </Dropdown.Toggle>
    
                <Dropdown.Menu variant="dark">
                 
                  <Dropdown.Item onClick={() => setPeriod(1)}  className={selectedDepartment_active===1?'active':""}>Today</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod(2)} className={selectedDepartment_active===2?'active':""}>Yesterday</Dropdown.Item>
                  
                  <Dropdown.Item onClick={() => setPeriod(3)} className={selectedDepartment_active===3?'active':""}>Last 7 Days</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod(4)} className={selectedDepartment_active===4?'active':""}>Last 30 Days</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod(5)} className={selectedDepartment_active===5?'active':""}>This Month</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod(6)} className={selectedDepartment_active===6?'active':""}>Last Month</Dropdown.Item>
                  <Dropdown.Item   onClick={() => setdatepickershow(true)
       } >Custom Range</Dropdown.Item>
                  
    
             
       
                </Dropdown.Menu>
              </Dropdown>
    
    
    
    
              
            </div> */}
            </div>
          </div>
        ) : (
          <DateRangeList
            setSelectedRange={setSelectedRange}
            selectedRange={selectedRange}
            setsdate={setsdate}
            setedate={setedate}
            setdatepickershow={setdatepickershow}
            Budgetlength={Budgetlength}
            budget={budget}
            tot={Toltal}
            state={state}
            selectYear={selectYear}
          />
        )}

        {datepickershow && (
          <div
            ref={containerRef}
            className="calender"
            style={{
              marginTop: "-14em",
              width: "333px",
              marginLeft: "-19px",
            }}
          >
            <DateRange
              editableDateInputs={false}
              minDate={startDate}
              maxDate={endDate}
              onChange={(item) => {
                setState([item.selection]);
                datepicker([item.selection]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
            ></DateRange>
          </div>
        )}
        <br />
        {/* <DateRangeDropdown /> */}
        {/* <DatesPaginator setsdate={setsdate} setedate={setedate} dt={""} dm={""}  ds={""} /> */}


        {console.log(budget,"BUdegt")}

        {budget!=undefined && Budgetlength != 0? (
          <>
            {/* <div className="row" style={{ justifyContent: "center" }}>
              <div
                className="col-md-12"
                style={{ zIndex: "-1", textAlign: "center" }}
              >
                <span>
                  <b>Period : </b>
                  {budget_type==2 ? (
                  <span>{sdate}</span>  ) : (
                    <span>{period1}</span>  )}
                   <b>&nbsp;&nbsp;To&nbsp;&nbsp;</b>
                   {budget_type==2 ? (
                    <span>{edate}&nbsp;&nbsp;</span> ) : (
                      <span>{period2}&nbsp;&nbsp;</span> )}
                  <b>P&nbsp;&&nbsp;L</b>=
                  <CurrencyFormat
                    prefix={"$"}
                    style={{
                      backgroundColor:
                        Math.sign(Toltal.YearToDay) < 0 ? "#d63737" : "#1c9f6f",
                      color: "white",
                      borderRadius: "5px",
                      width: "100px",
                      fontSize: "13px",
                      marginLeft: "4px",
                    }}
                    fixedDecimalScale={2}
                    className="non-edit-cs"
                    value={
                      Toltal.YearToDay == undefined ? "" : Toltal.YearToDay
                    }
                    decimalScale={2}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;<b>P&nbsp;&&nbsp;L To Date</b> =
                  <CurrencyFormat
                    prefix={"$"}
                    style={{
                      marginLeft: "-42px",
                      color: "White",
                      backgroundColor:
                        Math.sign(Toltal.MonthMTD) < 0 ? "#d63737" : "#1c9f6f",
                      width: "100px",
                      borderRadius: "5px",
                      fontSize: "13px",
                      marginLeft: "3px",
                    }}
                    fixedDecimalScale={2}
                    className="non-edit-cs"
                    value={Toltal.MonthMTD == undefined ? "" : Toltal.MonthMTD}
                    decimalScale={2}
                  />{" "}
                </span>
              </div>
            </div> */}
 
             {budget!=undefined && Budgetlength != 0 && loading ? (
              <Spinner />
            ) : (
              // <div  ref ={componentRef} className="printable-content" >

              //   <P_L_Table
              //     data={data}
              //     title="PROFIT/LOSS"
              //     header={header}
              //     isBudget={false}
              //     showexcel={true} 
              //   />
              //   <P_L_Table
              //     data={data5}
              //     title="REVENUE"
              //     header={header}
              //     isBudget={false}
              //     showexcel={false}
              //   />
              //   <P_L_Table
              //     data={data1}
              //     title="Cost Of Goods"
              //     header={header}
              //     isBudget={false}
              //     showexcel={false}
              //   />
              //   <P_L_Table
              //     data={data2}
              //     title="Labor"
              //     header={header}
              //     isBudget={false}
              //     showexcel={false}
              //   />
              //   <P_L_Table
              //     data={data3}
              //     title="Controllable"
              //     header={header}
              //     isBudget={false}
              //     showexcel={false}
              //   />
              //   <P_L_Table
              //     data={data4}
              //     title="Non Controllable"
              //     header={header}
              //     isBudget={false}
              //     showexcel={false}
              //   />
              // </div>
              <div ref={componentRef} className="printable-content">
              
               
                
              {nonEmptyTablesData.map((table, index) => (
               
               
                <P_L_Table
                  key={index} 
                  data={table.data}
                  title={table.title}
                  header={header}
                  isBudget={false}
                  showexcel={index === 0} 
                  Acctype={items.Account_Type}
                 
                 
                />
              
                 
                
              ))}
            </div>

            )}
          </>
        ) : (
          <>
            <div className="col-xs-12 text-center">
              <div className="col-xs-12 ">
                <figure className="info-img">
                  <img
                    src="https://d2a7u30t6wbkim.cloudfront.net/assets/videos/golf-gif.gif"
                    alt="Budget Image"
                    style={{ width: "5%" }}
                  />
                </figure>
              </div>
              <div className="col-xs-12  info-title">
                <h5 className="" style={{ fontWeight: "900" }}>
                  No Budget available..
                </h5>
                <div className="col-xs-12">

                  
                  <p className="awesome">
                    <span
                      className="info-span1"
                      style={{
                        fontSize: "13px",
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "#24d153",
                      }}
                    >
                      "Create a budget for a successful golf course"
                    </span>
                  </p>
                </div>
              </div>
              {( items.Account_Type!=0 && items.Account_id==5 &&
              <p
                className="res-tag"
                style={{
                  background: "#d0d8d8",
                  padding: "7px 12px",
                  borderRadius: "7px",
                  color: "#000",
                  letterSpacing: "1px",
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                <a
                  href="https://newapp.fobegolf.com/businessjournal"
                  onClick={() => {}}
                  style={{ color: "#2196f3" }}
                >
                  Click
                </a>{" "}
                here to budget!
              </p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default P_L;
