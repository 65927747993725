import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import "../Datepagenator.css";
import "./TableComponent.css";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import CurrencyFormat from "react-currency-format";
import { fobeAxios, mainAxios } from "../../middleware/interceptor";
import Modal from "react-bootstrap/Modal";
import { yellow } from "@material-ui/core/colors";
const TableComponent = ({
  tabs,
  menuId,
  editYearData,
  selectDepartment,
  header,
  data,
  nestedColumn,
  searchFunction,
  Search,
  pagination,
  year,
  bossid,
  getPage,
  tabsname,
  isFooter,
  editYearDatapercentage,
  changeData1,
  index,
  editabs,
  editabsper,
  editabsblur,
  editabsperblur,
  yearlock,
  setyearlock


}) => {
  const [items, setitems] = useState(
    JSON.parse(localStorage.getItem("UserClaims"))
  );



  const item = JSON.parse(localStorage.getItem("UserClaims"));

  const [isLoading, setIsLoading] = useState(false);
  const [showpop, setShowpop] = useState(false);
  const [label, setLabel] = useState(header);
  const[cadid,setcadid]=useState()
  const[dat,setdata]=useState([])
  const [edityearstatus, setedityearstatus]=useState(item.Edit_Year_Status);

  const handleCloses = () => {
    setShowpop(false);
  };
  const handleShows = async (e) => {
    setcadid(e)
    let controllerName, url;
    if ((e == 63 && tabsname == "Labor" &&  items.Member_Type!=4) || (e == 64 && tabsname == "Labor" &&  items.Member_Type!=4)) {
        setShowpop(true)


      if (
        (items.Special_user == 0 || items.Special_user == 1) &&
        items.Account_Type != 0
      ) {
      
        if (e == 63) {
          if (budget_type == "2") {
            url = "/Api/Budget/getMgntFront/" + year;
          } else {
            url = "/Api/Budget/getMgntFront/" + year;
          }
        } else if (e == 64) {
          if (budget_type == "2") {
            url = "/Api/Budget/getMngtKitchen/" + year;
          } else {
            url = "/Api/Budget/getMngtKitchen/" + year;
          }
        }
      } else {
        if (e == 63) {
          url = "/Api/Boss/getBossMgntFront/" + bossid + "/" + year;
        } else if (e == 64) {
          url = "/Api/Boss/getBossMngtKitchen/" + bossid + "/" + year;
        }

      
      }
    }
    await fobeAxios.get(url).then((res) => {
        let key
        if(e==63){
            setdata(res.data.Mngt_Front);
        }
        else if(e==64){
            setdata(res.data.Mngt_Kitchen);
        }
        console.log(res.data, "managersalary");
      });
  };
  
  const [show, setShow] = useState(true);

  let [budget_type, setbudget_type] = useState(items.budget_type);
const changeData=(id,value)=>{
    console.log(id,value,"value")
    let temp = dat?.map((x) => {
        if (x.Cat_id == id) {
          x.Cat_amt = value.value;
  
          return x;
        }
        return x;
      });
        setdata(temp)
        console.log(dat,"temp")
}
const save = async () => {

    let controllerName, url;
    if(budget_type=="2"){
        if(cadid==63){
            url="/Api/Budget/updateMgntFront"
        }
        else if(cadid==64){
            url="/Api/Budget/updateMgntKitchen"
        }
    
    }
    else{

        if(cadid==63){
            url="/Api/BudgetPeriod/updateMgntFront"
        }
        else if(cadid==64){
            url="/Api/BudgetPeriod/updateMgntKitchen"
        }
    }
    let budget = {
        Budget: dat,
        Budget_details: [
          { budget_year: items.budget_end_year },
          { budget_type: items.budget_type },
          { Restaurant_id: items.Restaurant_id },
        ],
      };
      await fobeAxios
        .post(url, budget)
        .then((resp) => {
            if(resp.data){
    toast.success("Updated Successfully");
            }
            console.log(resp.data,"resp")
     
        })
        .catch((err) => {
      toast.error("Updated Failed");
        //   setIsLoading(false);
        });

        changeData1(4, "Labor", index);


}
  useEffect(() => {}, []);


const[Errror,setError]=useState(true)
  const handlealert=(value)=>{
if(value.target.value){

setError(false)


}



}



console.log(yearlock,'tyt')

  return (
    <>
      {show && (
        <div>
          <div>
            {Search && (
              <>
                <i class="bi bi-search"></i>
                <input
                  id="search-box"
                  class="sear"
                  placeholder="Global Filter"
                  onChange={(e) => searchFunction(e.target.value)}
                />
              </>
            )}
          </div>
          <div class="table-scroll table-revenue">
            <Table className='forbusidailytabd' striped bordered hover>
              <thead>
                <tr>
                  {!nestedColumn &&
                    header.map((head) => (
                      <th style={{width:'30%',paddingBottom:'32px'}}>
                        <div className="tab-card-new admin-budget-th">{head.column}</div>
                      </th>
                    ))}
                </tr>
              </thead>

             
                          <tbody class="tab-gf">
                {data.list?.map((item) => (
                  
                  (item.Cat_name !=="CAM Charges" || (items.Account_Type !== 4 && items.Account_Type !== 5 && items.Account_Type !== 6 && items.Account_Type !== 9 && items.Account_Type !== 10)) &&
                    (item.Cat_name !=="Rent" || (items.Account_Type !== 4 && items.Account_Type !== 5 && items.Account_Type !== 6 && items.Account_Type !== 9 && items.Account_Type !== 10))&& (
                   
                  <tr>
                   
                     {console.log(items.Account_Type,"koo")}
                    <td className="admin-budget-td bud-td"
                      style={{
                        color:
                          (item.Cat_id == 63 && tabsname == "Labor" && items.Member_Type!=4) ||
                          (item.Cat_id == 64 && tabsname == "Labor" && items.Member_Type!=4 )
                            ? "green"
                            : "black",
                              cursor:
                          (item.Cat_id == 63 && tabsname == "Labor" && items.Member_Type!=4) ||
                          (item.Cat_id == 64 && tabsname == "Labor" && items.Member_Type!=4 )
                            ? "pointer"
                            : "auto",
                      }}
                      onClick={() => handleShows(item.Cat_id)}
                      
                    >
                      {item.Cat_name}
                    </td>
                    <td className="admin-budget-td">
                      {" "}
                      <span className="fl-lf admin-budget-td admin-budget-ipad admin-budget-medium1 admin-budget-medium">$</span>
                      <p className="fl-r">
                      {console.log(item.abs,"consol")}
                        <CurrencyFormat
                        style={{ color: item.Cat_amt < 0 ? "red" : "" }}
                                 className={
                            (items?.Special_user == 1 ||items?.Special_user == 0) && ((items?.Account_Type != 0 && items?.Account_Type != 4 && items?.Account_Type != 5 && items?.Account_Type != 9 && items?.Account_Type != 10 || tabsname =="Labor" || tabsname == "Controllable" || tabsname == "Non Controllable"   )&& item.Editable_Status == 1) ? (yearlock == 0 || yearlock==null)? "input-edit input-edit-rev admin-budget-td non-controllable-td"
                                : "non-edit-cs admin-budget-td"
                              : "non-edit-cs admin-budget-td"
                          }
                          onClick={()=>{editabs(item.Cat_id, false, menuId, "abs")}}
                          onBlur={()=>{ editabsblur(item.Cat_id, menuId,)}}
                     
                          

                          prefix={item.Cat_amt < 0 && item.abs ? "(" : ""}
                          suffix={item.Cat_amt < 0 && item.abs  ? ")" : ""}
                          readOnly={item.abs?true:false}
                          decimalScale={item.abs ? 2 : undefined}
                          fixedDecimalScale={item.abs ? true : false}
                          name={item.Cat_id}
                          id={item.Cat_id}
                          disabled={
                            (items?.Special_user == 1 || items?.Special_user == 0 ) && ((items?.Account_Type != 0 && items?.Account_Type != 4 && items?.Account_Type != 5 && items?.Account_Type != 9 && items?.Account_Type != 10 || tabsname =="Labor" || tabsname == "Controllable" || tabsname == "Non Controllable"   ) && item.Editable_Status == 1) ?(yearlock == 0 || yearlock==null)  ? false  : true : true
                          }
                           value = {item.abs ? (item.Cat_amt !== 0 ? Math.abs(item.Cat_amt) : "0.00") : item.Cat_amt}
                          //decimalScale={2}
                          //fixedDecimalScale={true}
                          onValueChange={(value, name) =>
                           
                            editYearData(item.Cat_id, value, menuId, "amt")
                          }
                          thousandSeparator={true}

                          // onValueChange={(value, name) =>  changeData(item.Cat_id, value)}
                        />

                        {/* <td>{item.Cat_amt}</td> */}
                        {/* <CurrencyInput
                                        className={(item.Editable_Status == 1 && item.Default_addon==1) ? 'input-edit' : 'non-edit-cs'}
                                        id={item.Cat_id}
                                        name={item.Cat_id}
                                        defaultValue={item.Cat_amt}
                                        decimalsLimit={2}
                                        disabled={(item.Editable_Status == 1 && item.Default_addon==1) ? false : true}
                                        onValueChange={(value, name) => changeData(item.Cat_id, value)}
                                    /> */}
                      </p>
                    </td>
                    <td>
                      <p className="fl-r fl-per per-medium">
                        {/* <td>{item.Cat_per}</td> */}
                        <CurrencyFormat

                        onClick={()=>{editabsper(
                          item.Cat_id,
                          false,
                          menuId,
                          "per"
                        )}}
                        onBlur={()=>{editabsperblur(
                          item.Cat_id,
                          false,
                          menuId,
                          "per"
                        )}}

                         style={{ color: item.Cat_per < 0 ? "red" : "" }}

                          prefix={item.Cat_per < 0 && item.abs ? "(" : ""}
                          suffix={item.Cat_per < 0 && item.abs  ? ")" : ""}
                          readOnly={item.abs?true:false}
                          // className={
                          //   (items?.Special_user == 1 ||
                          //     items?.Special_user == 0) &&
                          //   items?.Account_Type != 0
                          //     ? item.Editable_Status == 1
                          //       ? "input-edit input-edit-rev admin-budget-td admin-budget-td1"
                          //       : "non-edit-cs admin-budget-td admin-budget-td1"
                          //     : "non-edit-cs admin-budget-td admin-budget-td1"
                          // }

                          className={
                            (items ?.Account_Type ==1 || items ?.Account_Type ==2 ||items ?.Account_Type ==3 ||items ?.Account_Type ==6 ||items ?.Account_Type ==11)? (item.Editable_Status == 1 && yearlock == 0 || yearlock==null)  ? "input-edit input-edit-rev admin-budget-td non-controllable-td"
                                : "non-edit-cs admin-budget-td"
                              : "non-edit-cs admin-budget-td"  
                          }
                          disabled={item.abs? (items ?.Account_Type ==1 || items ?.Account_Type ==2 ||items ?.Account_Type ==3 ||items ?.Account_Type ==6 ||items ?.Account_Type ==11)? (item.Editable_Status == 1 && yearlock == 0 || yearlock==null)  ? false : true : true : false}

                          decimalScale={item.abs ? 2 : undefined}
                          fixedDecimalScale={item.abs ? true : false}
                          name={item.Cat_id}
                          id={item.Cat_id}
                          //value={item.Cat_per}
                          value = {item.abs ? (item.Cat_per !== 0 ? Math.abs(item.Cat_per) : "0.00") : item.Cat_per}
                         // value={Math.abs(item.Cat_per === 0 ? "0.00" : item.Cat_per)}
                           // value={item.Cat_per}
                          onValueChange={(value, name) =>
                            editYearDatapercentage(item.Cat_id,value,menuId,"per")
                          }
                          //disabled={(items?.Special_user == 1 || items?.Special_user == 0) &&items?.Account_Type != 0? item.Editable_Status == 1 ? false: true: true}
                          thousandSeparator={true}

                          // onValueChange={(value, name) => changeData(item.Cat_id, value)}
                        />
                        {/* <CurrencyInput
                                        className={(item.Editable_Status == 1 && item.Default_addon==2) ? 'input-edit' : 'non-edit-cs'}
                                        id={item.Cat_id}
                                        name={item.Cat_id}
                                        defaultValue={item.Cat_per}
                                        decimalsLimit={2}
                                        disabled={(item.Editable_Status == 1 && item.Default_addon==2) ? false : true}
                                        onValueChange={(value, name) => changeData(item.Cat_id, value)}
                                    /> */}
                        <span className="per-medium" style={{ color: item.Cat_per < 0 ? "red" : "" }}>%</span>
                      </p>
                    </td>
                  </tr>
                  )
                                  
                ))}
                {/* {data.list=""} */}
                {/* {testMethods(data)} */}
              </tbody>
              {tabs && (
                <tfoot>
                  <tr>
                    <td className="admin-budget-td total-ipad">TOTAL</td>
                    <td className="admin-budget-td">
                      <span className="fl-lf admin-budget-td admin-budget-ipad admin-budget-medium">$</span>{" "}
                      <p className="fl-r">
                        {" "}

                        {console.log(data.total,"VAlues")}
                        <CurrencyFormat
                          fixedDecimalScale={2}
                          className="non-edit-cs admin-budget-td"
                          decimalScale={2}

                          style={{ color:data.total < 0 ? "red" : "" }}
                          prefix={data.total < 0 ? "(" : ""}
                          suffix={data.total < 0 ?  ")" : ""}

                          value={Math.abs(data.total)}
                          thousandSeparator={true}
                          disabled={true}
                        />
                      </p>
                    </td>
                    <td>
                      <p className="fl-r fl-per per-medium">
                        <CurrencyFormat
                          className="non-edit-cs admin-budget-td"
                          fixedDecimalScale={2}
                          decimalScale={2}
                          
                          style={{ color: data.percentage< 0 ? "red" : "" }}
                          prefix={data.percentage < 0 ? "(" : ""}
                          suffix={data.percentage < 0 ?  ")" : ""}

                          value={Math.abs(data.percentage)}
                          disabled={true}
                        />
                        <span className="per-medium" style={{ color: data.total < 0 ? "red" : "" }}>%</span>
                      </p>
                    </td>
                  </tr>
                </tfoot>
              )}
            </Table>
          </div>
          <div>
            {pagination && (
              <Pagination className="pag">
                <Pagination.Prev />
                <Pagination.Item onClick={() => getPage()}>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Item>{5}</Pagination.Item>
                <Pagination.Next />
              </Pagination>
            )}
          </div>
          {console.log('items',item)}

          {(item.Account_Type!=0 && item.Account_id==5 &&
          <Modal  className="Recurring-popup" show={showpop} onHide={handleCloses} style={{ paddingTop: "10em" }}>
             <Modal.Header style={{ border: "none" ,padding:'45px 45px 20px 45px',background:'#fff',borderRadius:'6px'}}>
              <Modal.Title
            style={{
              fontSize:'24px',
              textAlign: "left",
              color: "#000",
              background: "#fff",
              padding: "0px 0px 25px",
              borderBottom:'2px solid #BCBCBC'
            }}
          >
                Update Your Management Values{" "}
                <i
              class="bi bi-x-circle-fill"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#24D153",
                fontSize: "23px",
                float: "right",
                marginTop: "-3px",
              }}
              onClick={handleCloses}
            ></i>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body
          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "20px",
          }}
        >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <div className="tab-card-new admin-budget-th">Cateogry</div>
                    </th>
                    <th>
                      <div className="tab-card-new admin-budget-th">Amount</div>
                    </th>
                  </tr>
                </thead>
                <tbody class="tab-gf">
                  {dat?.map((item) => (
                    <tr className="update-management-tr">
                      <td className="admin-budget-td"
                        style={{
                          color:
                            (item.Cat_id == 63 && tabsname == "Labor") ||
                            (item.Cat_id == 64 && tabsname == "Labor")
                              ? "green"
                              : "black",
                        }}
                        onClick={() => handleShows(item.Cat_id)}
                      >
                        {item.Cat_name}
                      </td>

                      <CurrencyFormat
                      className='input-edit'
                      decimalScale={2}
                   
                      name={item.Cat_id}
                      id={item.Cat_id}
                      value={item.Cat_amt}
                      disabled={yearlock==1 ? true: false}

                      // prefix={Math.round(item.Cat_amt) < 0 ? "(" : ""}
                      // suffix={ Math.round(item.Cat_amt) < 0 ? ")" : ""}

                    //   onValueChange={(value, name) =>
                    //     editYearDatapercentage(
                    //       item.Cat_id,
                    //       value,
                    //       menuId,
                    //       "per"
                    //     )
                    //   }
                      
                      thousandSeparator={true}

                      onValueChange={(value, name) => changeData(item.Cat_id, value)}
                    />
                    </tr>
                  ))}
                  {/* {data.list=""} */}
                  {/* {testMethods(data)} */}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer
          style={{ textAlign: "center", marginRight: "0em", border: "none",marginBottom:'1em',paddingTop:'0px' }}
        >
              <div className="d-flex justify-content-end w-100">
                <button className="btn btn-success popup-save" onClick={save} style={{ marginRight: "0.5em",background:'#24D153',borderColor:'#24D153'}} disabled={yearlock==1 ? true: false}>Save</button>
                <button
                className="btn btn-mute popup-cancel"
                  type="button"
                  variant="secondary"
                  onClick={handleCloses}
                >
                  Close
                </button>
              
              </div>
            </Modal.Footer>
          </Modal>
           )}
        </div>
      )}
    </>
  );
};

export default TableComponent;
