import "bootstrap/dist/css/bootstrap.min.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Tabcontend.css";
import Dropdown from "react-bootstrap/Dropdown";
import DatesPaginator from "../Component/Datepagenator";
import Tablespanrow from "../Component/Tables/Tablespanrow";
import TableInvoice from "../Component/Tables/TableInvoice";
import numeral from "numeral";
import Nav from "react-bootstrap/Nav";

import Form from "react-bootstrap/Form";
import { useState,useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import { fobeAxios, mainAxios } from "../middleware/interceptor";
import ReactLoading from "react-loading";
import { Spinner } from "../Component/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableInvoice_report from "../Component/Tables/TableInvoice_report";
import Modal from "react-bootstrap/Modal";
import { BsExclamationTriangle } from "react-icons/bs";
import { TabPane } from "react-bootstrap";
import { set } from "react-hook-form";
import { CodeSharp } from "@material-ui/icons";
import Buttons from 'react-bootstrap-button-loader';

const header = [{ column: "DAILY" }, { column: "MTD" }, { column: "BUDGET" }];

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
const revenue = {
  list: [
    {
      Name: "Daily",
      List: [],
      Total: 0,
      per: 0,
    },
    {
      Name: "MDT",
      List: [],
      Total: 0,
      per: 0,
    },
    {
      Name: "Budget",
      List: [],
      Total: 0,
      per: 0,
    },
  ],
  Date: 11,
  Month: 9,
  Year: 2022,
};

const DailySales = ({
  bossid,
  setcheckingForNoBudget,
  checkingForNoBudget,
  setbossid,
  course_id,
  selectDepartment,
  SetselectDepartment,
  setSelectYear,
  selectYear,
  ye,
  settriggervariable,
  triggervariable,
  Budgetlength,
  setBudgetlength,
  tabss,
  setbudget_type,
  budget_type,
  budget
}) => {
  const items = JSON.parse(localStorage.getItem("UserClaims"));
  //const [activeNoBudget, setActiveNoBudget] = useState(checkingForNoBudget);
  const [id, setid] = useState(0);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showCourse, setShowCourse] = useState(false);
  const [selectDay, SetselectDay] = useState("Day 1");
  const [selectMonth, SetselectMonth] = useState("Period 1");
  const [ActivePeriodpage, setActivePeriodpage] = useState("");
  const [ActiveMonthpage, setActiveMonthpage] = useState("");
  const [logoimage, setLogoImage] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [y, sety] = useState(selectYear);
  const [filepath, setFilepath] = useState([]);
  const [filedname, setFileDName] = useState([]);
  const [size, setSize] = useState(0);
  const [showuploadinvoice, setshowuploadinvoice] = useState(false);
  const cFile = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
  const compressImage = null; // replace with actual implementation
  let GetLabelURL;
  const userDetail = JSON.parse(localStorage.getItem("UserClaims"));
  const [nodata, setnodata] = useState(false);
  let splUser = userDetail.Special_user;
  let Acc_type = userDetail.Account_Type;
  const containerRef = useRef(null);
  const [d, setd] = useState(date);
  const [date_1, setdate1] = useState("01");
  const [Month, setMonth] = useState("01");
  const [prediction ,setprediction]=useState();

  const [old_cat_Id, setOld_cat_id] = useState();
  const [olddata,setOlddata]=useState({})

  const scrollRef = useRef(null);

  {
    console.log(Month, "diei im month");
  }
  console.log(ye,'ye')
  const [m, setm] = useState(month);
  let gatedate = async () => {
    let defaultperiod, data_3;
    //get default period(period and day)
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      data_3 = await fobeAxios.get("/Api/Common/getLabel");

      defaultperiod = data_3.data.LabelAndYear[1].Year_List.find(
        (budget) => budget.Year === y
      );
    } else {
      data_3 = await mainAxios.get(
        "Api/DailyPeriod/GetPeriodDetails/" + y + "/" + bossid
      );
      defaultperiod = data_3.data;
    }

    if (data_3) {
      if (
        defaultperiod.budget_period == 0 &&
        defaultperiod.budget_period_day == 0
      ) {
        setActiveMonthpage("01");
        setActivePeriodpage("01");
        setMonth("01");
        setdate1("01");
      } else {
        if (defaultperiod.budget_period < 10) {
          setActiveMonthpage("0" + defaultperiod.budget_period);
          setMonth("0" + defaultperiod.budget_period);
        } else {
          setActiveMonthpage(defaultperiod.budget_period);
        }
        setMonth(defaultperiod.budget_period);

        if (defaultperiod.budget_period_day < 10) {
          setActivePeriodpage("0" + defaultperiod.budget_period_day);
          setdate1("0" + defaultperiod.budget_period_day);
        } else {
          setActivePeriodpage(defaultperiod.budget_period_day);
          setdate1(defaultperiod.budget_period_day);
        }
      }
    }
  };
  useEffect(() => {
    gatedate();
  }, [tabss, bossid,y,selectYear, course_id]);


  const [tabs, setTab] = useState();

  const [department, setdepartment] = useState();

  const [label_second, setLabel] = useState([]);
  //console.log(date,month,year,"djbwekjf")

  const getData_1 = async () => {
    const localItems = JSON.parse(localStorage.getItem("UserClaims"));
    if (userDetail.Member_Type != "4") {
      course_id = localItems.Restaurant_id;
    }
    mainAxios
      .get("api/Common/getRestaurantList/" + course_id + "/" + y)
      .then((res) => {
        console.log(res.data.Restaurants, "Restaurants");
        setdepartment(res.data.Restaurants);
      });

    let get_daily_sales;
    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      get_daily_sales = `/Api/Common/getLabel`;
    } else {
      get_daily_sales = "Api/Common/getBossLabel/" + bossid + "/" + y;
    }

    await fobeAxios.get(get_daily_sales).then((res) => {
      {
        console.log(
          res.data.LabelAndYear[0].Labelsecond,
          "response label second"
        );
      }
      console.log(
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name,
        "DataLabel"
      );
      // setbudget_type(res.data.LabelAndYear[1].Year_List[0].budget_type)
      console.log(
        res.data.LabelAndYear[1].Year_List[0].budget_type,
        "budget type"
      );
      if (
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name == "Revenue" ||
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name ==
          "Admin Expenses" ||
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name ==
          "Tennis Expenses" ||
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name ==
          "Turf Revenue"
      ) {
        setTab(1);
        handleSelect(1);
      } else if (
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name.toUpperCase().trim() ==
        "INVOICE"
      ) {
        setTab(4);
        handleSelect(4);
      } else if (
        res.data.LabelAndYear[0].Labelsecond[0].Label_Name.toUpperCase() == "COST OF GOODS"
      ) {
        setTab(3);
        handleSelect(3);
      } else if (
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name.toUpperCase() == "LABOR"
      ) {
        setTab(4);
        handleSelect(4);
      } else if (
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name.toUpperCase() == "CONTROLLABLE"
      ) {
        setTab(5);
        handleSelect(5);
      } else if (
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name.toUpperCase() ==
        "NON CONTROLLABLE"
      ) {
        setTab(6);
        handleSelect(6);
      } else if (
        res.data.LabelAndYear[0]?.Labelsecond[0]?.Label_Name.toUpperCase() == "PROFIT/LOSS"
      ) {
        setTab(7);
        handleSelect(7);
      }
      let label = Object.entries(res.data.LabelAndYear[0].Labelsecond);

      setLabel(label);
    });
  };

  useEffect(() => {
    getData_1();
    if (
      userDetail.Member_Type == 4 ||
      ((userDetail.Special_user == 0 || userDetail.Special_user == 2) &&
        userDetail.Account_Type == 0)
    ) {
      setShowCourse(true);
    } else {
      setShowCourse(false);
    }
    if (
      (userDetail.Special_user == 0 || userDetail.Special_user == 1) &&
      userDetail.Account_Type != 0
    ) {
      setshowuploadinvoice(true);
    } else {
      setshowuploadinvoice(false);
    }
  }, [d, date_1, Month, bossid,y,selectYear, triggervariable, tabss]);

  const [data, setData] = useState(revenue);

  const [DataInvoice, setDataInvoice] = useState([]);
  const [options, setoptions] = useState([]);
  // console.log(data,"testConsole");
  const handleSelect = async (id) => {
    let getrevenueSalesurl,
      GetCogSalesurl,
      GetLabourSalesurl,
      GetControSalesurl,
      GetNoncontroSalesurl,
      GetProLossDailyurl,
      GetInvoice;

    if ((splUser == 0 || splUser == 1) && Acc_type != 0) {
      console.log(budget_type,'buddaily')

      if (budget_type == 1) {
        setActivePeriodpage(date_1);

        getrevenueSalesurl =
          `/api/DailyPeriod/GetrevenueSales/` + y + `/` + Month + `/` + date_1;
        GetNoncontroSalesurl =
          `/api/DailyPeriod/GetNoncontroSales/` +
          y +
          `/` +
          Month +
          `/` +
          date_1;
        GetCogSalesurl =
          `/api/DailyPeriod/GetCogSales/` + y + `/` + Month + `/` + date_1;
        GetLabourSalesurl =
          `/api/DailyPeriod/GetLabourSales/` + y + `/` + Month + `/` + date_1;
        GetControSalesurl =
          `/api/DailyPeriod/GetControSales/` + y + `/` + Month + `/` + date_1;
        GetProLossDailyurl =
          `/api/DailyPeriod/GetProLossDaily/` + y + `/` + Month + `/` + date_1;
        GetInvoice =
          `/api/DailyPeriod/GetInvoice/` + y + `/` + Month + `/` + date_1;
      } else {
        getrevenueSalesurl =
          `/api/Daily/GetrevenueSales/` + y + `/` + m + `/` + d;
        GetNoncontroSalesurl =
          `/api/Daily/GetNoncontroSales/` + y + `/` + m + `/` + d;
        GetCogSalesurl = `/api/Daily/GetCogSales/` + y + `/` + m + `/` + d;
        GetLabourSalesurl =
          `/api/Daily/GetLabourSales/` + y + `/` + m + `/` + d;
        GetControSalesurl =
          `/api/Daily/GetControSales/` + y + `/` + m + `/` + d;
        GetProLossDailyurl =
          `/api/Daily/GetProLossDaily/` + y + `/` + m + `/` + d;
        GetInvoice = `/api/Daily/GetInvoice/` + y + `/` + m + `/` + d;
      }
    } else {
      if (budget_type == 1) {
        setActivePeriodpage(date_1);
        {
          console.log("hello", Month, date_1);
        }
        getrevenueSalesurl =
          `/api/Boss/GetBossRevenueSales/` +
          y +
          `/` +
          Month +
          `/` +
          date_1 +
          "/" +
          bossid;
        GetCogSalesurl =
          `/api/Boss/GetBossCogSales/` +
          y +
          `/` +
          Month +
          `/` +
          date_1 +
          "/" +
          bossid;
        GetLabourSalesurl =
          `/api/Boss/GetBossLaborSales/` +
          y +
          `/` +
          Month +
          `/` +
          date_1 +
          "/" +
          bossid;
        GetControSalesurl =
          `/api/Boss/GetBossControSales/` +
          y +
          `/` +
          Month +
          `/` +
          date_1 +
          "/" +
          bossid;
        GetNoncontroSalesurl =
          `/api/Boss/GetBossNonControSales/` +
          y +
          `/` +
          Month +
          `/` +
          date_1 +
          "/" +
          bossid;
        GetProLossDailyurl =
          `/api/Boss/GetBossProfitLossDaily/` +
          y +
          `/` +
          Month +
          `/` +
          date_1 +
          "/" +
          bossid;
        GetInvoice =
          `/api/Boss/GetBossInvoice/` +
          y +
          `/` +
          Month +
          `/` +
          date_1 +
          "/" +
          bossid;
      } else {
        getrevenueSalesurl =
          `/api/Boss/GetBossRevenueSales/` +
          y +
          `/` +
          m +
          `/` +
          d +
          "/" +
          bossid;
        GetCogSalesurl =
          `/api/Boss/GetBossCogSales/` + y + `/` + m + `/` + d + "/" + bossid;
        GetLabourSalesurl =
          `/api/Boss/GetBossLaborSales/` + y + `/` + m + `/` + d + "/" + bossid;
        GetControSalesurl =
          `/api/Boss/GetBossControSales/` +
          y +
          `/` +
          m +
          `/` +
          d +
          "/" +
          bossid;
        GetNoncontroSalesurl =
          `/api/Boss/GetBossNonControSales/` +
          y +
          `/` +
          m +
          `/` +
          d +
          "/" +
          bossid;
        GetProLossDailyurl =
          `/api/Boss/GetBossProfitLossDaily/` +
          y +
          `/` +
          m +
          `/` +
          d +
          "/" +
          bossid;
        GetInvoice =
          `/api/Boss/GetBossInvoice/` + y + `/` + m + `/` + d + "/" + bossid;
      }
    }
    if (id == 1) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(getrevenueSalesurl).then((res) => {
        console.log(res.data.Revenue_Dailysales.length, "SwathidAILY");
        res.data.Revenue_Dailysales.map((c)=>{
         c.List.map(y=>{
          y.abs=true;
         })
         
        })
        if (res.data.Revenue_Dailysales.length > 0) {
          setcheckingForNoBudget(true);
        } else {
          setcheckingForNoBudget(false);
        }

        setData({ list: res.data.Revenue_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 2) {
      setTab(id);
      setIsLoading(true);
      await mainAxios.get(GetInvoice).then((res) => {
        setIsLoading(false);
        if (res.data.Invoice_report.length == 0 ) {
          setnodata(true);
        } else {
          setnodata(false);
        }
        console.log(res.data.Invoice_details, "dAILY");
        {
          console.log(res.data.Invoice_details.length, "response of invoice");
        }
        initReportrows(res.data.Invoice_report);
        setTotalInvoice(res.data.Total);
        const dataValue = res.data.Invoice_details;
        let temp1 = [];
        let temp = [];
        dataValue.map((x, index) => {
          temp1 = [
            ...temp1,
            {
              id: "",
              item_amount: x.item_amount,
              item_details: x.item_details,
              item_purveyor: x.item_purveyor,
              item_name: x.item_name,
            },
          ];
        });
        initRow(temp1);
        if (dataValue != undefined || dataValue != "") {
          dataValue[0].List.map((x, index) => {
            temp = [...temp, { id: index, value: x.Cat_id, label: x.Cat_name }];
          });
        }
        setoptions(temp);
        console.log(options, "options");
        console.log(DataInvoice, dataValue, temp1, options, "DataInvoice");
        console.log("temp1", temp);
      });
      setIsLoading(false);
    } else if (id == 3) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(GetCogSalesurl).then((res) => {
        res.data.Cog_Dailysales.map((c)=>{
          c.List.map(y=>{
           y.abs=true;
          })
          
         })

        //console.log(res.data.Cog_Dailysales,"dAILY");
        setData({ list: res.data.Cog_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 4) {
      setTab(id);
      setIsLoading(true);

      await fobeAxios.get(GetLabourSalesurl).then((res) => {

        res.data.Labor_Dailysales.map((c)=>{
          c.List.map(y=>{
           y.abs=true;
          })
          
         })
        //console.log(res.data.Labor_Dailysales,"dAILY");
        if (res.data.Labor_Dailysales.length > 0) {
          setcheckingForNoBudget(true);
        } else {
          setcheckingForNoBudget(false);
        }
        setData({ list: res.data.Labor_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 5) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(GetControSalesurl).then((res) => {
        res.data.Controllable_Dailysales .map((c)=>{
          c.List.map(y=>{
           y.abs=true;
          })
          
         })
           
        //console.log(res.data.Controllable_Dailysales,"dAILY");
        setData({ list: res.data.Controllable_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 6) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(GetNoncontroSalesurl).then((res) => {
        //console.log(res.data.Nonccontrol_Dailysales,"dAILY");

        res.data.Nonccontrol_Dailysales.map((c)=>{
          c.List.map(y=>{
           y.abs=true;
          })
          
         })
        setData({ list: res.data.Nonccontrol_Dailysales });
      });
      setIsLoading(false);
    } else if (id == 7) {
      setTab(id);
      setIsLoading(true);
      await fobeAxios.get(GetProLossDailyurl).then((res) => {
        res.data.Revenue_Dailysales.map((c)=>{
          c.List.map(y=>{
           y.abs=true;
          })
          
         })
        //console.log(res.data.Revenue_Dailysales,"Profit and loss");
        setData({ list: res.data.Revenue_Dailysales });
      });
      setIsLoading(false);
    }
  };

  const [selectDepartment_active, setselectedDepartment_active] = useState(null);
  const handleSelectdrp = (eventKey, event) => {
    setcheckingForNoBudget(false);
    setselectedDepartment_active(eventKey);
    console.log(eventKey, "eeeeeee");
    SetselectDepartment(eventKey.split("`")[1]);
    setbossid(eventKey.split("`")[0]);
  };

  const handledatechange = (eventKey, event) => {
    let new_day;
    if (eventKey.split(" ")[1] < 10) {
      new_day = "0" + eventKey.split(" ")[1];
    } else {
      new_day = eventKey.split(" ")[1];
    }

    setdate1(new_day);
    setActivePeriodpage(new_day);
    SetselectDay(eventKey);
  };

  const handlemonthchange = (eventKey, event) => {
    if (eventKey < 10) {
      eventKey = "0" + eventKey;
    }

    setMonth(eventKey);

    setActiveMonthpage(eventKey);
    SetselectMonth("Period" + " " + eventKey);
  };

  const editDailySales = (id, value, name) => {


// if(value.value==""){


//   value.value=0; 
  
// }
    let type = name == "Daily" ? 0 : 1;

    let temp = data?.list.map((item, index) => {
      if (type == index)
        item.List.map((x) => {
          if (x.Cat_id == id) {
            x.Cat_amt = value.value;
           
          }
        });
      return item;
    });

    setData({ ...data, list: temp });
  };

  const editDailyabs = (id, value, name) => {


    //alert(value.value)

    let type = name == "Daily" ? 0 : 1;

    let temp = data?.list.map((item, index) => {
      if (type == index)
        item.List.map((x) => {
          if (x.Cat_id == id) {
           // x.Cat_amt = value.value;
           x.abs=false;
          }
          else{
            x.abs = true;
            return x;
          }
        }
        
        
        );
       
      return item;
    });

    setData({ ...data, list: temp });
  };


  const editDailyabsblur = (id, value, name) => {


    //alert(value.value)

    let type = name == "Daily" ? 0 : 1;

    let temp = data?.list.map((item, index) => {
      if (type == index)
        item.List.map((x) => {
          if (x.Cat_id == id) {
           // x.Cat_amt = value.value;
           x.abs=true;
          }
          else{
            x.abs = true;
            return x;
          }
        }
        
        
        );
       
      return item;
    });

    setData({ ...data, list: temp });
  };

  const saveDailyData = async (menuId, keyEvent = null) => {
    if (keyEvent && keyEvent.key !== 'Enter') return;

    setIsLoading(true);
    let url, msg;
    if (menuId == 1 || tabs === 1) {
      if (budget_type === 1) {
        url = "Api/DailyPeriod/addRevenueDsr";
        msg = "Revenue Updated Successfully";
      } else {
        url = "Api/Daily/addRevenueDsr";
        msg = "Revenue Updated Successfully";
      }
    }
    // else if (menuId == 3) {
    //   if(budget_type===1){
    //     url = "Api/DailyPeriod/addCogMonthly";
    //     msg = "Cost of Goods Monthly Updated Successfully";}
    //   else{
    //   url = "Api/Daily/addCogMonthly";
    //   msg = "Cost of Goods Monthly Updated Successfully";}
    // }
    // else if (menuId == 3) {
    //   url = 'Api/Budget/addCogMonthly';
    //   msg = 'Cost of Goods Monthly Added SuccessFully';
    // }
    else if (menuId == 4 || tabs === 4) {
      if (budget_type === 1) {
        url = "Api/DailyPeriod/addLaberDsr";
        msg = "Labor Updated Successfully";
      } else {
        url = "Api/Daily/addLaberDsr";
        msg = "Labor Updated Successfully";
      }
    }

    // else if (menuId == 5) {
    //   if(budget_type===1){
    //     url = "Api/DailyPeriod/addControllablerMonthly";
    //     msg = "Controllable Updated Successfully";
    //   }
    //   else{
    //   url = "Api/Daily/addControllablerMonthly";
    //   msg = "Controllable Updated Successfully";}
    // }
    // else if (menuId == 5) {
    //   url = 'Api/Budget/addControllablerMonthly';
    //   msg = 'Controllable Monthly Added SuccessFully';
    // }
    // else if (menuId == 6) {
    //   if(budget_type===1){
    //        url = "Api/DailyPeriod/addNonControllableMonthly";
    //          msg = "Non Controllable Updated Successfully";
    //     }
    //    else{
    //      url = "Api/Daily/addNonControllableMonthly";
    //      msg = "Non Controllable Updated Successfully";}
    //      }

    // else if (menuId == 6) {
    //   url = 'Api/Budget/addNonControllableMonthly';
    //   msg = 'Non Controllable Monthly Added SuccessFully';
    // }

    let budget;
    if (budget_type === 1) {
      budget = {
        Budget: data.list,
        Budget_details: [
          { budget_year: y },
          { budget_month: Month },
          { budget_date: date_1 },
          { Restaurant_id: userDetail.Restaurant_id },
        ],
      };
      
      

    } else {
      budget = {
        Budget: data.list,
        Budget_details: [
          { budget_year: y },
          { budget_month: m },
          { budget_date: d },
          { Restaurant_id: userDetail.Restaurant_id },
        ],
      };
   const updatedList = data.list[0].List.map((item) => {
  const cat_amt = item.Cat_amt == "" ? 0 : item.Cat_amt;
  item.Cat_amt = cat_amt;
  return item;
});
data.list[0].List = updatedList;


      console.log(data.list,"ssss")

    }



    await fobeAxios.post(url, budget)
      .then((resp) => {
        toast.success(msg);
        handleSelect(menuId);
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
    setIsLoading(false);
  };
  const handleKeyDown = (event) => {
    saveDailyData(tabs, event);
  };
  
  // Call saveData directly when not using the keydown event
  const someOtherFunction = (menuId) => {
    saveDailyData(menuId);
  };
  // invoice
  const [rows, initRow] = useState([]);
  const [Reportrows, initReportrows] = useState([]);
  const [TotalInvoice, setTotalInvoice] = useState();
  const [addRow, setAddRow] = useState(false);
  const [Index, setIndex] = useState([]);

  const [editFormData, setEditFormData] = useState(
  // {
  //   Cat_amt: "",
  //   Cat_name: "",
  //   Details: "",
  //   Purveyor: "",
  //   Invoice_id: "",
  //   Cat_id: "",
  // }
  [{ Cat_id: "",
  Invoice_id: "",
  invoice_no: null,
  new_Cat_amt: "",
  new_Details : "",
  new_Purveyor : "",
  new_Cat_name: "",
  old_Details : "",
  old_Purveyor : "",
  old_cat_name: "",
  rest_id: "",
  upload_id: null,
}]
);
  const [editContactId, setEditContactId] = useState(null);
  const [showinvoiceupload, setshowinvoiceupload] = useState(false);
  const invoiceuploadhandleCloses = () => {
    setshowinvoiceupload(false);
    setLogoImage([]);
    setMyFiles([]);
    setFilepath([]);
    setSize(0);
  };
  const invoiceuploadhandleShows = () => setshowinvoiceupload(true);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [fileSize, setFileSize] = useState(0);


  const [showModalReUpload, setShowModalReUpload] = useState(false);
  
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [myFiles]);
  const [image,setimage]=useState({})

  const frmData1 = new FormData();
  const [modelshow,setmodelshow]=useState(true)

  const handleInputChange =async (event) => {


    setprediction()


    const file = event.target.files;
    console.log(file, "file");
    let check;
    for (let i = 0; i < cFile.length; i++) {
      check = false;
      if (file[0].type === cFile[i]) {


        if (
          file[0].size / 1000000 > 1 &&
          file[0].type !== "application/pdf" &&
          file[0].type !== "image/png"
        ) {

     
          compressImage.compress(file[0]).then((data) => {
            setMyFiles([...myFiles, data]);
            setFilepath([...filepath, logoimage]);
            setFileDName([...filedname, data.name]);
            setSize(size + data.size / 1000000);
          });
          i = cFile.length;
          check = true;
        } else {
     
          setMyFiles([...myFiles, file[0]]);
          setFilepath([...filepath, logoimage]);
          setFileDName([...filedname, file[0].name]);
          setSize(size + file[0].size / 1000000);
          i = cFile.length;
          check = true;

          for (let i = 0; i < event.target.files.length; i++) {
            
            setimage(event.target.files[i])
            frmData1.append("image", event.target.files[i]);
            console.log(event.target.files[i], "GIGII");
          
        
          await fobeAxios
            .post("/api/Daily/predict_blur_invoice/", frmData1)
            .then((resp) => {
              console.log(resp.data.prediction, "Check");
              setprediction(resp.data.prediction)
             
            })
          }
        }
      }




    }
    if (!check) {
      toast.warning("Supported files only accepted");
    }
    console.log("file", myFiles, filepath);
    setmodelshow(true)
  };





  const handleInputChangeReupload =async () => {
    console.log(image, "GIGII111");

if(myFiles.length>0){
setprediction(1);

}   
myFiles.pop();

frmData1.append("Local_blurinvoice", image);
          await fobeAxios
            .post("/api/Daily/upload_BlurInvoice/",frmData1)
            .then((resp) => {
              console.log(resp.data.prediction, "Check");
              //myFiles.pop()
             setmodelshow(false)
            })
            console.log(myFiles,myFiles.length,"indexx")
          // setmodelshow(true)
            
          }

         
        
      




    
   
  

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const files = event.dataTransfer.files;
  //   // filter files to only include PDF, PNG, JPG file types
  //   const filteredFiles = Array.from(files).filter(file => file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg');
  //   for (let i = 0; i < files.length; i++) {
  //     filteredFiles.push(files[i]);
  //   }
  //   setSelectedFiles(filteredFiles);
  // };
  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // };

  // const uploadFiles = () => { alert("hkjhkj")
  //   // TODO: implement file upload logic here
  //   console.log(selectedFiles);
  // };
  const uploadFiles = async () => {
    setIsLoading(true);
    setprediction();
    console.log("vvvvvvvvvvvvv", myFiles, filepath);
    const frmData1 = new FormData();
    frmData1.append("uploadimage", "c:/temp/abc.png");
    if (id !== "") {
      frmData1.append("uploadid", id);
    } else {
      frmData1.append("uploadid", id);
    }
    for (let i = 0; i < myFiles.length; i++) {
      frmData1.append("invoicedetails", myFiles[i], myFiles[i].name);

      console.log(myFiles[i],"GIGII")




    }
    console.log("file uploaded data", frmData1);

    await fobeAxios
      .post("/Api/daily/upload_invoiceinfo/", frmData1)
      .then((resp) => {
        setIsLoading(false);
        setMyFiles([]);
        setFilepath([]);
        setSize(0);
        setshowinvoiceupload(false);
        console.log("resp", resp);
        if (resp.data == 1) {
          toast.success("File uploaded successfully");
        } else {
          toast.error("File uploaded failed");
        }
      });
  };
  // removeFile(i,size) {
  //   console.log('asdfj;asdlfk',i,size);
  //   this.logoimage='';
  //   if(this.myFiles.length == 1 ){
  //     this.size = 0 ;
  //   }else{
  //     this.size -= size/1000000;
  //   }
  //   this.myFiles.splice(i,1);
  //   this.filepath.splice(i,1);
  //  }

  const removeFile = (i) => {
    setLogoImage([]);
    if (myFiles.length == 0) {
      setSize(0);
    } else {
      setSize(size - myFiles[i].size / 1000000);
    }
    setMyFiles(myFiles.filter((file, index) => index !== i));
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // setFilepath(filepath.filter((path, index) => index !== i));
    // setFileDName(filedname.filter((name, index) => index !== i));
    // const fileInput = document.querySelector('input[type="file"]');
    // fileInput.value = '';
  };
  // Invoice table
  const addRowTable = () => {
    setAddRow(true);
    const data = {
      id: "",
      item_amount: 0,
      item_details: "",
      item_purveyor: "",
      item_name: "",
    };
    console.log("Add data", data, ...rows);
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
   
    console.log("EditRemove", index);
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
    toast.success("Deleted Row Succesfully")
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  const SaveInvoice = async () => {
    setIsLoading(true);
    let Savedata = [];
    rows.map((x, index) => {
      Savedata = [
        ...Savedata,
        {
          id: "",
          item_amount: x.item_amount,
          item_details: x.item_details,
          item_purveyor: x.item_purveyor,
          item_name: x.item_name.label,
        },
      ];
    });
    {
      console.log("hello 5");
    }
    {
      console.log(Savedata, "Savedata");
    }
    let count = 0;
    let index_1 = [];

    for (let i = 0; i < Savedata.length; i++) {
      if (
        Savedata[i].item_amount !== 0 &&
        typeof Savedata[i].item_amount !== "undefined" &&
        Savedata[i].item_details !== "" &&
        typeof Savedata[i].item_details !== "undefined" &&
        Savedata[i].item_purveyor !== "" &&
        typeof Savedata[i].item_purveyor !== "undefined" &&
        typeof Savedata[i].item_name !== "undefined"
      ) {
        count = 1;
        break;
      }
    }
    for (let i = 0; i < Savedata.length; i++) {
      if (
        (Savedata[i].item_amount !== 0 &&
          typeof Savedata[i].item_amount !== "undefined") ||
        (Savedata[i].item_details !== "" &&
          typeof Savedata[i].item_details !== "undefined") ||
        (Savedata[i].item_purveyor !== "" &&
          typeof Savedata[i].item_purveyor !== "undefined") ||
        typeof Savedata[i].item_name !== "undefined"
      ) {
        if (
          !(
            Savedata[i].item_amount !== 0 &&
            typeof Savedata[i].item_amount !== "undefined" &&
            Savedata[i].item_details !== "" &&
            typeof Savedata[i].item_details !== "undefined" &&
            Savedata[i].item_purveyor !== "" &&
            typeof Savedata[i].item_purveyor !== "undefined" &&
            typeof Savedata[i].item_name !== "undefined"
          )
        ) {
          count = 1;

          index_1.push(i);
        }
      }
    }

    if (count === 0) {
      toast.error("Please fill the data");
      setIsLoading(false);
    } else if (index_1.length !== 0) {
      toast.error("Please fill the entire row");
      setIsLoading(false);
    } else {
      let budget = {
        Budget: Savedata,
        Budget_details: [
          { budget_year: y },
          { budget_month: m },
          { budget_date: d },
        ],
      };
      let url = "";
      if (budget_type == 1) {
        url = "/Api/DailyPeriod/addInvoiceDsr";
      } else {
        url = "/Api/Daily/addInvoiceDsr";
      }
      await fobeAxios
        .post(url, budget)
        .then((resp) => {
          console.log("resp", resp.data);
          if (resp.data == 1) {
            toast.success("Invoice Updated Successfully");
            handleSelect(2);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Failed");
          setIsLoading(false);
        });
    }
  };
  // Invoice report
  const handleDeleteClick = (index) => {
    setShow(true);
    setIndex(index);
    console.log("Delete", index);
  };
  const handleEditClick = (event, row) => {
    event.preventDefault();
    setEditContactId(row.Invoice_id);
    const formValues = {
      // Cat_amt: row.Cat_amt,
      // Cat_name: row.Cat_name,
      // Details: row.Details,
      // Purveyor: row.Purveyor,
      // Invoice_id: row.Invoice_id,
      // Cat_id: row.Cat_id,
    Cat_id : row.Cat_id,
    Invoice_id : row.Invoice_id,
    invoice_no : null,
    new_Cat_amt : row.Cat_amt,
    new_Details : row.Details,  
    new_Purveyor : row.Purveyor,
    new_cat_name : row.Cat_name,
    old_Details : row.Details,
    old_Purveyor : row.Purveyor,
    old_cat_name : row.Cat_name,
    rest_id : row.rest_id,
    upload_id : null,
    };
    setOld_cat_id(row.Cat_id)  
    setOlddata(formValues);
    setEditFormData(formValues);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = async (index) => {
    console.log("Index", index);
    setIsLoading(true);
    setShow(false);
    const dataRow = [...rows];
    let budget = {
      Budget: index,
      Budget_details: [
        { budget_year: y },
        { budget_month: m },
        { budget_date: d },
      ],
    };
    let url = "";
    if (budget_type == 1) {
      url = "/Api/DailyPeriod/deleteInvoice";
    } else {
      url = "/Api/Daily/deleteInvoice";
    }
    await fobeAxios
      .post(url, budget)
      .then((resp) => {
        console.log("resp", resp.data);
        if (resp.data == 1) {
          toast.success("Deleted Successfully");
          dataRow.splice(index, 1);
          handleSelect(2);
          setIsLoading(false);
        } else {
          toast.error("Somethimg wrong");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
    initReportrows(dataRow);
  };
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };
  const handleCancelClick = () => {
    setEditContactId(null);
  };
  const EditFormSave = async (data) => {
    setIsLoading(true);
    let UpdateInvoice = {
      Budget :[
        {
          Cat_id: old_cat_Id,
          Invoice_id: data.Invoice_id,
          invoice_no: data.invoice_no,
          new_cat_name: data.new_cat_name ,
          new_Details: data.new_Details ,
          new_Purveyor: data.new_Purveyor ,
          new_Cat_amt: parseFloat(data.new_Cat_amt) ,
          old_Details: olddata.old_Details,
          old_Purveyor: olddata.old_Purveyor ,
          old_cat_name: olddata.old_cat_name,
          rest_id: items.Restaurant_id ,
          upload_id: data.upload_id ,
        }
      ],
            
      // Budget: data,
            Budget_details: [
              { budget_year: y },
              { budget_month: m },
              { budget_date: d },
            ],
          };
    let url = "";

    if (budget_type == 1) {
      // url = "Api/DailyPeriod/updateInvoice";
      url = "api/Daily/updateInvoice_ai_check"
    } else {
      // url = "Api/Daily/updateInvoice";
      url = "api/Daily/updateInvoice_ai_check"
    }
    await fobeAxios
      .post(url, UpdateInvoice)
      .then((resp) => {
        console.log("resp", resp.data);
        if (resp.data == 1) {
          toast.success("Update Successfully");
          handleSelect(2);
          setIsLoading(false);
        } else {
          toast.error("Somethimg wrong");

          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Failed");
        setIsLoading(false);
      });
    console.log("edit", data);
    handleCancelClick();
  };
  return (
    <>
      <div className="container-fluid pd-0">
        <div
          className="col-md-12"
          style={{ borderBottom: "1px solid rgb(204, 204, 204)" }}
        >
          <div className="row daily-row" style={{ marginBottom: "18px" }}>
            <div className="col-md-4">
              <p
                className="Daily-font"
                style={{
                  fontSize: "30px",
                  color: "#7B7B7B",
                  fontWeight: "normal",
                  textAlign: "left",
                  marginLeft: "-20px",
                }}
              >
                Daily Sales
              </p>
            </div>

            <div className="col-md-8" style={{ padding: "0px 0px 0px 0px" }}>
              <div className="col-md-12 col-sm-8 col-xs-12 daily-sales-con">
                <div
                  className="row formobilemode"
                  style={{
                    float: "right",
                    display: "flex",
                    gap: "15px",
                    marginRight: "-2em",
                  }}
                >
                  {budget_type === 1 ? (
                    <div className="col">
                      <div
                        className="row"
                        style={{ display: "flex", gap: "15px" }}
                      >
                        <div class="col">
                          <p
                            className="course-drp period-drp"
                            style={{
                              marginTop: "0px",
                              float: "right",
                              fontSize: "12px",
                              color: "#7b7b7b",
                            }}
                          >
                            Select Period
                          </p>
                        </div>
                        <div class="col">
                          <Dropdown onSelect={handlemonthchange}>
                            <Dropdown.Toggle
                              id="dropdown-button-dark-example1"
                              className="drop-mld course-dropml"
                              variant="secondary"
                            >
                              {ActiveMonthpage
                                ? ActiveMonthpage < 10
                                  ? "Period" + " " + ActiveMonthpage[1]
                                  : "Period" + " " + ActiveMonthpage
                                : "Period 1"}
                              &nbsp;{" "}
                              <i
                                style={{
                                  color: "#24D153",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                }}
                                class="fa fa-angle-down icon-drop"
                              ></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              variant="dark"
                              className="responsedrop drop-period"
                            >
                              <Dropdown.Item
                                eventKey="1"
                                className={ActiveMonthpage == 1 ? "active" : ""}
                              >
                                Period 1
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                className={ActiveMonthpage == 2 ? "active" : ""}
                              >
                                Period 2
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="3"
                                className={ActiveMonthpage == 3 ? "active" : ""}
                              >
                                Period 3
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="4"
                                className={ActiveMonthpage == 4 ? "active" : ""}
                              >
                                Period 4
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="5"
                                className={ActiveMonthpage == 5 ? "active" : ""}
                              >
                                Period 5
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="6"
                                className={ActiveMonthpage == 6 ? "active" : ""}
                              >
                                Period 6
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="7"
                                className={ActiveMonthpage == 7 ? "active" : ""}
                              >
                                Period 7
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="8"
                                className={ActiveMonthpage == 8 ? "active" : ""}
                              >
                                Period 8
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="9"
                                className={ActiveMonthpage == 9 ? "active" : ""}
                              >
                                Period 9
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="10"
                                className={
                                  ActiveMonthpage == 10 ? "active" : ""
                                }
                              >
                                Period 10
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="11"
                                className={
                                  ActiveMonthpage == 11 ? "active" : ""
                                }
                              >
                                Period 11
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="12"
                                className={
                                  ActiveMonthpage == 12 ? "active" : ""
                                }
                              >
                                Period 12
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="13"
                                className={
                                  ActiveMonthpage == 13 ? "active" : ""
                                }
                              >
                                Period 13
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {budget_type === 1 ? (
                    <div className="col">
                      <div
                        className="row"
                        style={{ display: "flex", gap: "15px" }}
                      >
                        <div class="col">
                          <p
                            className="course-drp day-drp"
                            style={{
                              marginTop: "0px",
                              float: "right",
                              fontSize: "12px",
                              color: "#7b7b7b",
                            }}
                          >
                            Select Day
                          </p>
                        </div>
                        <div class="col">
                          {console.log(ActivePeriodpage, "hello again")}
                          <Dropdown onSelect={handledatechange}>
                            <Dropdown.Toggle
                              id="dropdown-button-dark-example1"
                              className="drop-mld course-dropml"
                              variant="secondary"
                            >
                              {ActivePeriodpage
                                ? ActivePeriodpage < 10
                                  ? "Day" + " " + ActivePeriodpage[1]
                                  : "Day" + " " + ActivePeriodpage
                                : "Day 1"}
                              &nbsp;{" "}
                              <i
                                style={{
                                  color: "#24D153",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                }}
                                class="fa fa-angle-down icon-drop"
                              ></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              variant="dark"
                              className="responsedrop drop-day"
                            >
                              <Dropdown.Item
                                eventKey="Day 1"
                                className={
                                  ActivePeriodpage == 1 ? "active" : ""
                                }
                              >
                                Day 1
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 2"
                                className={
                                  ActivePeriodpage == 2 ? "active" : ""
                                }
                              >
                                Day 2
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 3"
                                className={
                                  ActivePeriodpage == 3 ? "active" : ""
                                }
                              >
                                Day 3
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 4"
                                className={
                                  ActivePeriodpage == 4 ? "active" : ""
                                }
                              >
                                Day 4
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 5"
                                className={
                                  ActivePeriodpage == 5 ? "active" : ""
                                }
                              >
                                Day 5
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 6"
                                className={
                                  ActivePeriodpage == 6 ? "active" : ""
                                }
                              >
                                Day 6
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 7"
                                className={
                                  ActivePeriodpage == 7 ? "active" : ""
                                }
                              >
                                Day 7
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 8"
                                className={
                                  ActivePeriodpage == 8 ? "active" : ""
                                }
                              >
                                Day 8
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 9"
                                className={
                                  ActivePeriodpage == 9 ? "active" : ""
                                }
                              >
                                Day 9
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 10"
                                className={
                                  ActivePeriodpage == 10 ? "active" : ""
                                }
                              >
                                Day 10
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 11"
                                className={
                                  ActivePeriodpage == 11 ? "active" : ""
                                }
                              >
                                Day 11
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 12"
                                className={
                                  ActivePeriodpage == 12 ? "active" : ""
                                }
                              >
                                Day 12
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 13"
                                className={
                                  ActivePeriodpage == 13 ? "active" : ""
                                }
                              >
                                Day 13
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 14"
                                className={
                                  ActivePeriodpage == 14 ? "active" : ""
                                }
                              >
                                Day 14
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 15"
                                className={
                                  ActivePeriodpage == 15 ? "active" : ""
                                }
                              >
                                Day 15
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 16"
                                className={
                                  ActivePeriodpage == 16 ? "active" : ""
                                }
                              >
                                Day 16
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 17"
                                className={
                                  ActivePeriodpage == 17 ? "active" : ""
                                }
                              >
                                Day 17
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 18"
                                className={
                                  ActivePeriodpage == 18 ? "active" : ""
                                }
                              >
                                Day 18
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 19"
                                className={
                                  ActivePeriodpage == 19 ? "active" : ""
                                }
                              >
                                Day 19
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 20"
                                className={
                                  ActivePeriodpage == 20 ? "active" : ""
                                }
                              >
                                Day 20
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 21"
                                className={
                                  ActivePeriodpage == 21 ? "active" : ""
                                }
                              >
                                Day 21
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 22"
                                className={
                                  ActivePeriodpage == 22 ? "active" : ""
                                }
                              >
                                Day 22
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 23"
                                className={
                                  ActivePeriodpage == 23 ? "active" : ""
                                }
                              >
                                Day 23
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 24"
                                className={
                                  ActivePeriodpage == 24 ? "active" : ""
                                }
                              >
                                Day 24
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 25"
                                className={
                                  ActivePeriodpage == 25 ? "active" : ""
                                }
                              >
                                Day 25
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 26"
                                className={
                                  ActivePeriodpage == 26 ? "active" : ""
                                }
                              >
                                Day 26
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 27"
                                className={
                                  ActivePeriodpage == 27 ? "active" : ""
                                }
                              >
                                Day 27
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Day 28"
                                className={
                                  ActivePeriodpage == 28 ? "active" : ""
                                }
                              >
                                Day 28
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* {(budget_type===2) ? 
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="row">
              <div class="col-md-4 col-sm-4 col-xs-4">
                        
                      </div>
                      </div>
              </div> :null}
              {(budget_type===2) ?
              <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="row">
              <div class="col-md-4 col-sm-4 col-xs-4">
                       
                      </div>
                      </div>
                
              </div>:null} */}

              {console.log(budget,"BuSDdget")}
                  {showuploadinvoice && budget!=undefined ? (
                    <button
                      className="btn invoice-button invoice-button1"
                      onClick={invoiceuploadhandleShows}
                      style={{
                        backgroundColor: "#00D95C ",
                        padding: "5px 9px",
                        color: "white",
                        borderColor: "#00D95C",
                        fontSize: "12px",
                        height: "32px",
                        zIndex: "1",
                        marginRight: "-3px",
                        fontWeight: "600",
                      }}
                    >
                      Invoice Upload
                    </button>
                  ):null}
                </div>
              </div>
            </div>
            {/* {showCourse && (
            <div className="col-md-6">
            <div className="forcourdep" style={{display: 'flex', gap: '15px', float: 'right'}}>
             <div className="col" style={{display: 'flex'}}>
              <p style={{marginRight: '25px', color: '#7B7B7B', font: 'normal normal 500 12px/33px Roboto'}}>Course</p>
             <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle
                  id="dropdown-button-dark-example1"
                  className="drop-ml "
                  variant="secondary"
                >                  
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className="responsedrop">
                  
                    <Dropdown.Item
                     
                    >
                     The Honors Course
                    </Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>
             </div>
             <div className="col" style={{display: 'flex'}}>
              <p style={{marginRight: '25px', color: '#7B7B7B', font: 'normal normal 500 12px/33px Roboto'}}>Department</p>
                           <Dropdown onSelect={handleSelectdrp}>
                      <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        className="drop-ml drop-mbl"
                        variant="secondary"
                      >
                        {selectDepartment
                          ? selectDepartment.slice(0, 23)
                          : "All Department"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark" className="responsedrop">
                        <Dropdown.Item
                          eventKey={userDetail.Boss_id + "`" + "All Department"}
                        >
                          All Department
                        </Dropdown.Item>
                        {department?.map((x) => (
                          <Dropdown.Item
                            eventKey={x.Rest_id + "`" + x.Rest_Name}
                            className={
                              selectDepartment_active ===
                              x.Rest_id + "`" + x.Rest_Name
                                ? "active"
                                : ""
                            }
                          >
                            {x.Rest_Name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
              </Dropdown>
             </div>

            </div>
            </div>
          )} */}
          </div>
        </div>
        <div className="col-md-12" style={{ marginTop: "15px" }}>
          <div className="row">
            {budget_type === 1 ? (
              <DatesPaginator
                dt={setdate1}
                dm={setm}
                ds={sety}
                ye={ye}
                budget_type={budget_type}
                date={date_1}
                t={ActivePeriodpage}
              />
            ) : (
              <DatesPaginator dt={setd} dm={setm} ds={sety} ye={ye}/>
            )}
          </div>
        </div>

        {console.log(checkingForNoBudget,"snjnjn")}

        {budget!=undefined && Budgetlength != 0 ? (
          <>
            <div className="DailySales-tabsections"   onKeyDown={handleKeyDown} tabIndex={0}>
              <Tabs
                defaultActiveKey={tabs}
                activeKey={tabs}
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={handleSelect}
              >
                {label_second?.map((x) => {
                  let i;
                  if (
                    x[1].Label_Name.toUpperCase() === "REVENUE" ||
                    x[1].Label_Name.toUpperCase() === "ADMIN EXPENSES" ||
                    x[1].Label_Name.toUpperCase() === "Bldg Mnt Expenses" ||
                    x[1].Label_Name.toUpperCase() === "TENNIS EXPENSES" ||
                    x[1].Label_Name.toUpperCase() === "Turf Revenue"
                  ) {
                    return (
                      <Tab
                        eventKey={1}
                        title={x[1].Label_Name}
                        style={{ marginTop: "35px" }}
                      >
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={1}
                          data={data ? data : null}
                          title="REVENUE"
                          header={header}
                          isBudget={false}
                          editDailyabs={editDailyabs}
                          editDailyabsblur={editDailyabsblur}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {data.list
                            ?.find((item) => item.Name === "Daily")
                            .List?.filter((x) => x.Editable_Status == "1")
                            .length != 0 && items.Account_Type!==0 && (
                            <button
                              style={{ marginRight: "10px" }}
                              className="btn btn-success save-response"
                              variant="contained"
                              onClick={() => saveDailyData(1)}
                            >
                              Save
                            </button>
                          )}


                          
                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name.toUpperCase() === "REVENUE" ||
                              x[1].Label_Name.toUpperCase() ===
                                "ADMIN EXPENSES" ||
                              x[1].Label_Name.toUpperCase() ===
                                "Bldg Mnt Expenses" ||
                              x[1].Label_Name.toUpperCase() ===
                                "TENNIS EXPENSES" ||
                              x[1].Label_Name.toUpperCase() ===
                                "Turf Revenue"
                            ) {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                i = parseInt(x[0]) + 1;
                                // {console.log(i,"i value")
                                // console.log(label_second[i])}
                                if (
                                  label_second[i][1].Label_Name.toUpperCase() === "INVOICE") 
                                  
                                  {


                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      Invoice{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "COST OF GOODS"
                                ) {
                                  return (
                                    <Button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      Cost Of Goods{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </Button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "LABOR"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      Labor{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "CONTROLLABLE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      Controllable{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (label_second[i][1].Label_Name.toUpperCase() === "NON CONTROLLABLE") {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      Non Controllable{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <Button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                        {/* <hr /> */}
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name.toUpperCase() === "INVOICE") {
                    return (
                      <Tab eventKey={2} title="Invoice">
                        { items.Account_Type == 0  ? (
                            <>
                                <TableInvoice
                                          menuId={2}
                                          rows={rows}
                                          options={options}
                                          addRow={addRow}
                                          addRowTable={addRowTable}
                                          onValUpdate={onValUpdate}
                                          tableRowRemove={tableRowRemove}
                                          title="Invoice"
                                          nodata={nodata}
                                        /> 
                             <TableInvoice_report
                                    menuId={2}
                                    rows={Reportrows}
                                    editContactId={editContactId}
                                    handleCancelClick={handleCancelClick}
                                    show={show}
                                    handleDeleteClick={handleDeleteClick}
                                    Total={TotalInvoice}
                                    handleEditClick={handleEditClick}
                                    EditFormSave={EditFormSave}
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    title="Invoice"
                                  />
                             
                              <div class="row">
                                <div
                                  class="col-md-6 col-xs-6"
                                  style={{ float: "left", marginBottom: "2em" }}
                                >
                                  {label_second.map((x) => {
                                    if (
                                      x[1].Label_Name.toUpperCase() ===
                                      "INVOICE"
                                    ) {
                                      if (parseInt(x[0]) > 0) {
                                        i = parseInt(x[0]) - 1;
                                        if (
                                          label_second[i][1].Label_Name ===
                                            "Revenue" ||
                                          label_second[i][1].Label_Name ===
                                            "Admin Expenses" ||
                                          label_second[i][1].Label_Name ===
                                            "Bldg Mnt Expenses" ||
                                          label_second[i][1].Label_Name ===
                                            "Tennis Expenses" ||
                                          label_second[i][1].Label_Name ===
                                            "Turf Revenue"
                                        ) {
                                          return (
                                            <button
                                              className="btn btn-success fl-lf nxt-response"
                                              variant="contained"
                                              onClick={() => {
                                                setTab(1);
                                                handleSelect(1);
                                              }}
                                            >
                                              Revenue
                                            </button>
                                          );
                                        }
                                      }
                                    }
                                  })}
                                </div>
                                <div className="col-md-6 col-xs-6 rig-alg dily-s">
                                
                                  {label_second.map((x) => {
                                    if (
                                      x[1].Label_Name.toUpperCase() ===
                                      "INVOICE"
                                    ) {
                                      if (
                                        (parseInt(x[0]) === 0 ||
                                          parseInt(x[0]) > 0) &&
                                        parseInt(x[0]) + 1 < label_second.length
                                      ) {
                                        i = parseInt(x[0]) + 1;

                                        if (
                                          label_second[
                                            i
                                          ][1].Label_Name.toUpperCase() ===
                                          "COST OF GOODS"
                                        ) {
                                          return (
                                            <button
                                              className="btn btn-success nxt-response"
                                              variant="contained"
                                              onClick={() => {
                                                setTab(3);
                                                handleSelect(3);
                                              }}
                                            >
                                              Cost Of Goods{" "}
                                            </button>
                                          );
                                        } else if (
                                          label_second[
                                            i
                                          ][1].Label_Name.toUpperCase() ===
                                          "LABOR"
                                        ) {
                                          return (
                                            <button
                                              className="btn btn-success nxt-response"
                                              variant="contained"
                                              onClick={() => {
                                                setTab(4);
                                                handleSelect(4);
                                              }}
                                            >
                                              Labor{" "}
                                            </button>
                                          );
                                        } else if (
                                          label_second[
                                            i
                                          ][1].Label_Name.toUpperCase() ===
                                          "CONTROLLABLE"
                                        ) {
                                          return (
                                            <button
                                              className="btn btn-success nxt-response"
                                              variant="contained"
                                              onClick={() => {
                                                setTab(5);
                                                handleSelect(5);
                                              }}
                                            >
                                              Controllable{" "}
                                            </button>
                                          );
                                        } else if (
                                          label_second[
                                            i
                                          ][1].Label_Name.toUpperCase() ===
                                          "NON CONTROLLABLE"
                                        ) {
                                          return (
                                            <button
                                              className="btn btn-success nxt-response"
                                              variant="contained"
                                              onClick={() => {
                                                setTab(6);
                                                handleSelect(6);
                                              }}
                                            >
                                              Non Controllable{" "}
                                            </button>
                                          );
                                        } else if (
                                          label_second[
                                            i
                                          ][1].Label_Name.toUpperCase() ===
                                          "PROFIT/LOSS"
                                        ) {
                                          return (
                                            <Button
                                              className="btn btn-success nxt-response"
                                              variant="contained"
                                              onClick={() => {
                                                setTab(7);
                                                handleSelect(7);
                                              }}
                                            >
                                              Profit/Loss{" "}
                                            </Button>
                                          );
                                        }
                                      }
                                    }
                                  })}
                                </div>
                              </div>{" "}
                            </>
                          ):
                          (items.Special_user == 1 || items.Special_user == 0) && items.Account_Type != 0  && (
                                      <>
          
                                        <TableInvoice
                                          menuId={2}
                                          rows={rows}
                                          options={options}
                                          addRow={addRow}
                                          addRowTable={addRowTable}
                                          onValUpdate={onValUpdate}
                                          tableRowRemove={tableRowRemove}
                                          title="Invoice"
                                          nodata={nodata}
                                        /> 
                                        <div class="row">
                                          <div
                                            class="col-md-12 col-xs-12 rig-alg dily-s"
                                            style={{
                                              marginBottom: "2em",
                                              marginTop: "-0.5em",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            <span
                                              className="invoice-span"
                                              onClick={addRowTable}
                                              style={{
                                                cursor: "pointer",
                                                color: "#24D153",
                                                fontSize: "14px",
                                                display:'flex',
                                                float:'right'
                                              }}
                                            >
                                              <i
                                                class="fa fa-plus-circle"
                                                aria-hidden="true"
                                                style={{
                                                  marginTop:'5px',
                                                  marginRight: "0.5em",
                                                  color: "#24D153",
                                                  fontSize: "1.5em",
                                                }}
                                              ></i>
                                              Add new Category
                                            </span>
                                          </div>
                                          </div>
                                          {/* <TableInvoice_report
                                           menuId={2}
                                           rows={Reportrows}
                                           editContactId={editContactId}
                                           handleCancelClick={handleCancelClick}
                                           show={show}
                                           handleDeleteClick={handleDeleteClick}
                                           Total={TotalInvoice}
                                           handleEditClick={handleEditClick}
                                           EditFormSave={EditFormSave}
                                           editFormData={editFormData}
                                           handleEditFormChange={handleEditFormChange}
                                           title="Invoice"
                                          /> */}
                                          
                                        <div class="row">
                                          <div
                                            class="col-md-6 col-xs-6"
                                            style={{ float: "left", marginBottom: "2em" }}
                                          >
                                            {label_second.map((x) => {
                                              if (
                                                x[1].Label_Name.toUpperCase() ===
                                                "INVOICE"
                                              ) {
                                                if (parseInt(x[0]) > 0) {
                                                  i = parseInt(x[0]) - 1;
                                                  if (
                                                    label_second[i][1].Label_Name ===
                                                      "Revenue" ||
                                                    label_second[i][1].Label_Name ===
                                                      "Admin Expenses" ||
                                                    label_second[i][1].Label_Name ===
                                                      "Bldg Mnt Expenses" ||
                                                    label_second[i][1].Label_Name ===
                                                      "Tennis Expenses" ||
                                                    label_second[i][1].Label_Name ===
                                                      "Turf Revenue"
                                                  ) {
                                                    return (
                                                      <button
                                                        className="btn btn-success fl-lf nxt-response"
                                                        variant="contained"
                                                        onClick={() => {
                                                          setTab(1);
                                                          handleSelect(1);
                                                        }}
                                                      >
                                                        Revenue
                                                      </button>
                                                    );
                                                  }
                                                }
                                              }
                                            })}
                                          </div>
                                          <div className="col-md-6 col-xs-6 rig-alg dily-s">
                                            <button
                                              className="btn btn-success save-response save-hand"
                                              style={{ marginRight: "1em" }}
                                              onClick={() => SaveInvoice()}
                                            >
                                              Save{" "}
                                            </button>
          
                                            {label_second.map((x) => {
                                              if (
                                                x[1].Label_Name.toUpperCase() ===
                                                "INVOICE"
                                              ) {
                                                if (
                                                  (parseInt(x[0]) === 0 ||
                                                    parseInt(x[0]) > 0) &&
                                                  parseInt(x[0]) + 1 < label_second.length
                                                ) {
                                                  i = parseInt(x[0]) + 1;
          
                                                  if (
                                                    label_second[
                                                      i
                                                    ][1].Label_Name.toUpperCase() ===
                                                    "COST OF GOODS"
                                                  ) {
                                                    return (
                                                      <button
                                                        className="btn btn-success nxt-response"
                                                        variant="contained"
                                                        onClick={() => {
                                                          setTab(3);
                                                          handleSelect(3);
                                                        }}
                                                      >
                                                        Cost Of Goods{" "}
                                                      </button>
                                                    );
                                                  } else if (
                                                    label_second[
                                                      i
                                                    ][1].Label_Name.toUpperCase() ===
                                                    "LABOR"
                                                  ) {
                                                    return (
                                                      <button
                                                        className="btn btn-success nxt-response"
                                                        variant="contained"
                                                        onClick={() => {
                                                          setTab(4);
                                                          handleSelect(4);
                                                        }}
                                                      >
                                                        Labor{" "}
                                                      </button>
                                                    );
                                                  } else if (
                                                    label_second[
                                                      i
                                                    ][1].Label_Name.toUpperCase() ===
                                                    "CONTROLLABLE"
                                                  ) {
                                                    return (
                                                      <button
                                                        className="btn btn-success nxt-response"
                                                        variant="contained"
                                                        onClick={() => {
                                                          setTab(5);
                                                          handleSelect(5);
                                                        }}
                                                      >
                                                        Controllable{" "}
                                                      </button>
                                                    );
                                                  } else if (
                                                    label_second[
                                                      i
                                                    ][1].Label_Name.toUpperCase() ===
                                                    "NON CONTROLLABLE"
                                                  ) {
                                                    return (
                                                      <button
                                                        className="btn btn-success nxt-response"
                                                        variant="contained"
                                                        onClick={() => {
                                                          setTab(6);
                                                          handleSelect(6);
                                                        }}
                                                      >
                                                        Non Controllable{" "}
                                                      </button>
                                                    );
                                                  } else if (
                                                    label_second[
                                                      i
                                                    ][1].Label_Name.toUpperCase() ===
                                                    "PROFIT/LOSS"
                                                  ) {
                                                    return (
                                                      <Button
                                                        className="btn btn-success nxt-response"
                                                        variant="contained"
                                                        onClick={() => {
                                                          setTab(7);
                                                          handleSelect(7);
                                                        }}
                                                      >
                                                        Profit/Loss{" "}
                                                      </Button>
                                                    );
                                                  }
                                                }
                                              }
                                            })}
                                          </div>
                                        </div>{" "}
                                    <TableInvoice_report
                                    menuId={2}
                                    rows={Reportrows}
                                    editContactId={editContactId}
                                    handleCancelClick={handleCancelClick}
                                    show={show}
                                    handleDeleteClick={handleDeleteClick}
                                    Total={TotalInvoice}
                                    handleEditClick={handleEditClick}
                                    EditFormSave={EditFormSave}
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    title="Invoice"
                                    options = {options}
                                    setEditFormData = {setEditFormData}
                                  />
                                      </>
                                    )}
                                  {/* <TableInvoice_report
                                    menuId={2}
                                    rows={Reportrows}
                                    editContactId={editContactId}
                                    handleCancelClick={handleCancelClick}
                                    show={show}
                                    handleDeleteClick={handleDeleteClick}
                                    Total={TotalInvoice}
                                    handleEditClick={handleEditClick}
                                    EditFormSave={EditFormSave}
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    title="Invoice"
                                  /> */}

                                

                      </Tab>
                    );
                  }

                  


                  if (x[1].Label_Name.toUpperCase() === "COST OF GOODS") {
                    return (
                      <Tab eventKey={3} title="Cost Of Goods">
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={3}
                          data={data}
                          title="Cost Of Goods"
                          header={header}
                          isBudget={false}
                          editDailyabs={editDailyabs}
                          editDailyabsblur={editDailyabsblur}
                        />

                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name.toUpperCase() === "COST OF GOODS"
                            ) {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;
                                if (
                                  label_second[i][1].Label_Name.toUpperCase() === "REVENUE" ||  label_second[i][1].Label_Name.toUpperCase() ==="ADMIN EXPENSES" ||label_second[i][1].Label_Name.toUpperCase() ===
                                    "Bldg Mnt Expenses" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "TENNIS EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Turf Revenue"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Revenue
                                    </button>
                                  );
                                }
                                if (label_second[i][1].Label_Name.toUpperCase() === "INVOICE") 
                                
                                {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Invoice
                                    </button>
                                  );
                                }
                              }
                            }
                          })}

                          {data.list
                            .find((item) => item.Name === "Daily")
                            .List.filter((x) => x.Editable_Status == "1")
                            .length != 0 && (
                            <button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                            >
                              Save
                            </button>
                          )}
                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name.toUpperCase() === "COST OF GOODS"
                            ) {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                i = parseInt(x[0]) + 1;
                                if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "LABOR"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      Labor{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "CONTROLLABLE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      Controllable{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "NON CONTROLLABLE"
                                ) {
                                  return (
                                    <Button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      Non Controllable{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </Button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <Button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name.toUpperCase() === "LABOR") {
                    return (
                      <Tab eventKey={4} title="Labor">
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={4}
                          data={data}
                          title="Labor"
                          header={header}
                          isBudget={false}
                          editDailyabs={editDailyabs}
                          editDailyabsblur={editDailyabsblur}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (x[1].Label_Name.toUpperCase() === "LABOR") {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;

                                if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "REVENUE" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "ADMIN EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Bldg Mnt Expenses" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "TENNIS EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Turf Revenue"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Revenue
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "INVOICE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Invoice
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "COST OF GOODS"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Cost Of Goods
                                    </button>
                                  );
                                }
                              }
                            }
                          })}

                          {data.list
                            .find((item) => item.Name === "Daily")
                            .List.filter((x) => x.Editable_Status == "1")
                            .length != 0 && items.Account_Type !==0 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                              onClick={() => saveDailyData(4)}
                            >
                              Save
                            </Button>
                          )}

                          {label_second.map((x) => {
                            if (x[1].Label_Name.toUpperCase() === "LABOR") {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                {
                                  console.log(x[0], "i value before increment");
                                }
                                i = parseInt(x[0]) + 1;
                                {
                                  console.log(
                                    label_second[i][1].Label_Name,
                                    " label name"
                                  );
                                }
                                if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "CONTROLLABLE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      Controllable{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "NON CONTROLLABLE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      Non Controllable{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <Button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </Button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name.toUpperCase() === "CONTROLLABLE") {
                    return (
                      <Tab eventKey={5} title="Controllable">
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={5}
                          data={data}
                          title="Controllable"
                          header={header}
                          isBudget={false}
                          editDailyabs={editDailyabs}
                          editDailyabsblur={editDailyabsblur}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name.toUpperCase() === "CONTROLLABLE"
                            ) {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;
                                if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "REVENUE" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "ADMIN EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Bldg Mnt Expenses" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "TENNIS EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Turf Revenue"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Revenue
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "INVOICE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Invoice
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "COST OF GOODS"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Cost Of Goods
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "LABOR"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Labor
                                    </button>
                                  );
                                }
                              }
                            }
                          })}

                          {data.list
                            .find((item) => item.Name === "Daily")
                            .List.filter((x) => x.Editable_Status == "1")
                            .length != 0 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                            >
                              Save
                            </Button>
                          )}

                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name.toUpperCase() === "CONTROLLABLE"
                            ) {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                i = parseInt(x[0]) + 1;
                                if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "NON CONTROLLABLE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      Non Controllable{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name.toUpperCase() === "NON CONTROLLABLE") {
                    return (
                      <Tab eventKey={6} title="Non Controllable">
                        <Tablespanrow
                          editDailySales={editDailySales}
                          menuId={6}
                          data={data}
                          title="Non Controllable"
                          header={header}
                          isBudget={false}
                          editDailyabs={editDailyabs}
                          editDailyabsblur={editDailyabsblur}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name.toUpperCase() ===
                              "NON CONTROLLABLE"
                            ) {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;

                                if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "REVENUE" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "ADMIN EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Bldg Mnt Expenses" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "TENNIS EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Turf Revenue"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Revenue
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "INVOICE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Invoice
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "COST OF GOODS"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Cost Of Goods
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "LABOR"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Labor
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "CONTROLLABLE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Controllable
                                    </button>
                                  );
                                }
                              }
                            }
                          })}

                          {data.list
                            .find((item) => item.Name === "Daily")
                            .List.filter((x) => x.Editable_Status == "1")
                            .length != 0 && (
                            <Button
                              className="mat-btn mat-save save-response"
                              variant="contained"
                            >
                              Save
                            </Button>
                          )}
                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name.toUpperCase() ===
                              "NON CONTROLLABLE"
                            ) {
                              if (
                                (parseInt(x[0]) === 0 || parseInt(x[0]) > 0) &&
                                parseInt(x[0]) + 1 < label_second.length
                              ) {
                                i = parseInt(x[0]) + 1;
                                if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "PROFIT/LOSS"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(7);
                                        handleSelect(7);
                                      }}
                                    >
                                      Profit/Loss{" "}
                                      {/* <i
                                        class="fa fa-arrow-right"
                                        style={{ marginLeft: "1em" }}
                                        aria-hidden="true"
                                      ></i> */}
                                    </button>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </Tab>
                    );
                  }

                  if (x[1].Label_Name.toUpperCase() === "PROFIT/LOSS") {
                    return (
                      <Tab eventKey={7} title="Profit/Loss">
                        <Tablespanrow
                          menuId={7}
                          data={data}
                          title="PROFIT/LOSS"
                          header={header}
                          isBudget={false}
                          editDailyabs={editDailyabs}
                          editDailyabsblur={editDailyabsblur}
                        />
                        <div className="col-md-12 pd-0 rig-alg dily-s">
                          {label_second.map((x) => {
                            if (
                              x[1].Label_Name.toUpperCase() === "PROFIT/LOSS"
                            ) {
                              if (parseInt(x[0]) > 0) {
                                i = parseInt(x[0]) - 1;
                                if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "REVENUE" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "ADMIN EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Bldg Mnt Expenses" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "TENNIS EXPENSES" ||
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                    "Turf Revenue"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(1);
                                        handleSelect(1);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Revenue
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "INVOICE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(2);
                                        handleSelect(2);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Invoice
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "COST OF GOODS"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(3);
                                        handleSelect(3);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Cost Of Goods
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() === "LABOR"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(4);
                                        handleSelect(4);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Labor
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "CONTROLLABLE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(5);
                                        handleSelect(5);
                                      }}
                                    >
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Controllable
                                    </button>
                                  );
                                } else if (
                                  label_second[
                                    i
                                  ][1].Label_Name.toUpperCase() ===
                                  "NON CONTROLLABLE"
                                ) {
                                  return (
                                    <button
                                      className="btn btn-success fl-lf nxt-response"
                                      variant="contained"
                                      onClick={() => {
                                        setTab(6);
                                        handleSelect(6);
                                      }}
                                    >
                                      {" "}
                                      {/* <i
                                        class="fa fa-arrow-left"
                                        style={{ marginRight: "1em" }}
                                        aria-hidden="true"
                                      ></i>{" "} */}
                                      Non Controllable
                                    </button>
                                  );
                                }
                              }
                            }
                          })}

                          {/* <Button className='mat-btn mat-save' variant="contained">Save</Button> */}
                        </div>
                      </Tab>
                    );
                  }
                })}
              </Tabs>
              {/* <hr  className="daily-hr"/> */}
              {isLoading ? <Spinner /> : <></>}
            </div>
          </>
        ) : (
          <>
            <div className="col-xs-12 text-center">
              <div className="col-xs-12 ">
                <figure className="info-img">
                  <img
                    src="https://d2a7u30t6wbkim.cloudfront.net/assets/videos/golf-gif.gif"
                    alt="Budget Image"
                    style={{ width: "5%" }}
                  />
                </figure>
              </div>
              <div className="col-xs-12  info-title">
                <h5 className="" style={{ fontWeight: "900" }}>
                  No Budget available..
                </h5>
                <div className="col-xs-12">
                  <p className="awesome">
                    <span
                      className="info-span1"
                      style={{
                        fontSize: "13px",
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "#24d153",
                      }}
                    >
                      "Create a budget for a successful golf course"
                    </span>
                  </p>
                </div>
              </div>
              {( items.Account_Type!=0 && items.Account_id==5 &&
              <p
                className="res-tag"
                style={{
                  background: "#d0d8d8",
                  padding: "7px 12px",
                  borderRadius: "7px",
                  color: "#000",
                  letterSpacing: "1px",
                  fontSize: "12px",
                  display: "inline-block",
                }}
              >
                <a
                  href="https://newapp.fobegolf.com/businessjournal"
                  onClick={() => {}}
                  style={{ color: "#2196f3" }}
                >
                  Click
                </a>{" "}
                here to budget!
              </p>
                )}
            </div>
          </>
        )}
      </div>
      {/* warning popup */}



      <Modal size="sm" show={show} onHide={handleClose} className="delete-line">
        <Modal.Header
          style={{
            border: "none",
            borderRadius: "5px 5px 0px 0px",
            background: "#FFBC00",
            padding: "25px 10px 20px 10px",
          }}
        >
          <Modal.Title
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ color: "#fff", fontSize: "24px" }}>
              {" "}
              <i
                class="bi bi-exclamation-triangle"
                style={{ color: "#fff", fontSize: "25px" }}
              ></i>{" "}
              Warning
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body

          style={{
            textAlign: "center",
            fontSize: "1.01em",
            padding: "10px 30px",
          }}
        >
          {" "}
          <h1
            style={{
              fontSize: "16px",
              fontweight: "500",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            Do you want to delete this line item?
          </h1>
        </Modal.Body>
        
        <Modal.Footer
          style={{
            textAlign: "center",
            marginRight: "0em",
            border: "none",
            padding: "0px 15px 15px 15px",
          }}
        >
          <button
            className="btn btn-danger"
            onClick={handleClose}
            style={{
              marginRight: "0.5em",
              backgroundColor: "#FF0C00",
              borderColor: "#FF0C00",
            }}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            className="btn btn-success"
            onClick={() => handleShow(Index)}
            style={{ backgroundColor: "#24D153", borderColor: "#24D153" }}
          >
            {" "}
            Confirm{" "}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="modalforupload"
        show={showinvoiceupload}
        //onHide={invoiceuploadhandleCloses}
      >
        <Modal.Header
          style={{
            border: "none",
            padding: "15px 30px 10px 30px",
            background: "#fff",
            borderRadius: "6px",
          }}
        >
          <Modal.Title
            style={{
              fontSize: "22px",
              textAlign: "left",
              color: "#000",
              background: "#fff",
              padding: "0px 0px 8px",
              borderBottom: "2px solid #BCBCBC",
            }}
          >
            Upload Invoice{" "}
            <i
              class="bi bi-x-circle-fill"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#24D153",
                fontSize: "23px",
                float: "right",
                marginTop: "-3px",
              }}
              onClick={invoiceuploadhandleCloses}
            ></i>
          </Modal.Title>
        </Modal.Header>

        {/* <Modal.Header style={{backgroundColor:"#19A73F",color:"white"}} >
      <Modal.Title>Upload Invoice      <i
      class="fa fa-times"
      aria-hidden="true"
      style={{
        cursor: "pointer",
        color: "white",
        fontSize: "1em",
        float: "right",
        marginTop: "3px",
      }}
      onClick={invoiceuploadhandleCloses}
      ></i></Modal.Title>
      </Modal.Header> */}

        <Modal.Body >


      

          {/* style={{height:'190px',overflowX:'hidden',overflowY:'scroll'}} */}
          <div>
            <div className="upload-btn-wrapperr">
              <button class="b-tnn" style={{cursor:( myFiles.length==0 || prediction ==1 )? "pointer":'not-allowed'}} >
                Select file from your device
                <input style={{padding:'10px 0px',cursor:( myFiles.length==0 || prediction ==1 )? "pointer":'not-allowed'}}
                  type="file"
                  value={logoimage}
                  multiple
             
                  disabled={( myFiles.length==0 || prediction ==1 ) ? false : true}
                  onChange={(e) => {
                    {
                      handleInputChange(e);
                    }
                  }}
                />
              </button>
            </div>
          </div>
          <div>
            <div className="disclaimer_text">Supports: .PDF, .PNG, .JPG</div>
          </div>
          
          <ul
            style={{
              maxHeight: "330px",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
       <div className="scrollable-content" ref={containerRef}>
            {myFiles.map((file, index) => (
              <div
                className="upld-sec"
                style={{ marginLeft: "-30px", marginTop: "0px" }}
              >
                <div className="col-md-12 all-sec-up">
                  <div className="row">
                    <div
                      className="col-md-8 col-sm-8"
                      style={{ display: "flex" }}
                    >
                      <div className="col">
                        <div style={{ paddingTop: "3px" }}>
                          <span>
                            <i
                              className="fa fa-file-text-o fil-icn-upld"
                              key={index}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div
                        className="col file-col"
                        style={{ textAlign: "left" }}
                      >
                        <span> {file.name}</span>
                        {/* - {file.type} */}
                      </div>
                    </div>
                    <div
                      className="col-md-4 col-sm-4"
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        gap: "10px",
                      }}
                    >
                      <div className="col mb-col">
                        <span
                          style={{
                            fontSize: "14px",
                            border: "1px solid #ccc",
                            borderRadius: "3px",
                            padding: "6px 10px",
                          }}
                        >
                          {numeral(file.size / 1000000).format("0.00")}MB
                        </span>
                      </div>
                      <div className="col  progrss-cls">
                        {/* <span>
                          <div className="progress blue">
                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value">
                              <i
                                className="bi bi-check2"
                                style={{
                                  paddingRight: "0px",
                                  fontSize: "19px",
                                }}
                              ></i>
                            </div>
                          </div>
                        </span> */}
                      </div>
                      <div className="col">
                        <span
                          id=""
                          style={{
                            paddingTop: "3px",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{ color: "#898989", fontSize: "23px" }}
                            className="bi bi-x"
                            onClick={() => removeFile(index, file.size)}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
      
            <div ref={scrollRef}></div>
            </div>
          </ul>

      
          <div className="file-size text-center">
            {myFiles != "" ? <p>File Size:{size.toFixed(2)}MB</p> : null}
          </div>
       
        </Modal.Body>



        <Modal.Footer
          style={{
            textAlign: "center",
            marginRight: "0em",
            border: "none",
            padding: "10px 0px 35px 0px",
          }}
        >
          <div className="d-flex justify-content-end w-100">
            <Button
              className="close-bt"
              type="button"
              style={{
                textTransform: "capitalize",
                backgroundColor: "#f0f0f0",
                fontSize: "12px",
                fontWeight: "500",
                boxShadow: "none",
                border: "1px solid #85828221",
                padding: "6px 12px",
                color: "black",
                marginRight: "10px",
              }}
              variant="secondary"
              onClick={invoiceuploadhandleCloses}
            >
              Close
            </Button>
<>
       {(myFiles.length==0 || prediction ==1 )?(

              <Button
              className="upload-bt"
              style={{
                textTransform: "capitalize",
                backgroundColor: "#24D153",
                fontSize: "12px",
                fontWeight: "500",
                boxShadow: "none",
                border: "1px solid #24D153",
                padding: "6px 12px",
                color: "white",
              }}
              onClick={uploadFiles}
              disabled={(myFiles.length==0 || prediction !=1 ) ? true : false}
            >
              Upload
            </Button>
            ):(

             <Buttons  
                           className="upload-bt"
                           style={{
                             textTransform: "capitalize",
                             backgroundColor: "#24D153",
                             fontSize: "12px",
                             fontWeight: "500",
                             boxShadow: "none",
                             border: "1px solid #24D153",
                             padding: "6px 12px",
                             color: "white",
                           }}
              loading={true}>Uploading...</Buttons>
            )}
</>    
          </div>
        </Modal.Footer>
      </Modal>


{/* Re upload */}
{console.log(prediction,modelshow,"iii")}
{ prediction==0 &&
      <Modal
        className="modalforreupload"
        show={modelshow}
      >
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2 col-sm-2">
                <i class="bi bi-exclamation-circle-fill reupload-icon"></i>
              </div>
              <div className="col-md-10 col-sm-10">
                <p className="reupload-para">
                  The invoice uploaded is blurry and cannot be scanned. Please reupload the invoice with improved clarity.
                </p>

              </div>
            </div>
          </div>
          <div className="col-md-12">
            <button className="reupload-button" onClick={handleInputChangeReupload}>Re Upload</button>
          </div>
        </Modal.Body>
      </Modal>
}
    </>
  );
};
export default DailySales;
