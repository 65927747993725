import Table from "react-bootstrap/Table";
import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../Datepagenator.css";
import "./TableComponent.css";
import "../../index.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { fobeAxios, mainAxios } from "../../middleware/interceptor";
import Pagination from "@mui/material/Pagination";
import { ToastContainer, toast } from "react-toastify";
import { PaginationItem } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { Container, Row, Col } from "react-bootstrap";
import numeral from "numeral";
import image from "../../folder.png";
import { WrapText } from "@material-ui/icons";
const TableComponent1 = ({
  user_up,
  set_user_up,
  header,
  data,
  nestedColumn,
  searchFunction,
  Search,
  pagination,
  getPage,
  isFooter,
  editUser,
  loader,
  DeleteUser,
  searchstate,
  fromdate,
  todate,
  handleDateChange,
  getData,
  getDatas,
  setdropdown,
  dropdown,
  handleDropdownChange,
  searchText,
  period1,
  setperiod1,
  periodvalue,
  setbossid,
  bossid,
  handleclick,
  selectDepartment,
  selectedDepartment,
}) => {
 

  const [notespopup, setNotesPopup] = useState(false);
  const renderTooltip = (props) => <Tooltip {...props}>Edit</Tooltip>;
  const renderTooltip1 = (props) => <Tooltip {...props}>Delete</Tooltip>;
  const [show, setShow] = useState(false);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [payload, setpayload] = useState();
  const [users, setUser] = useState(false);
  const [deleteitem, setitem] = useState();
  const [selectedUploadIds, setSelectedUploadIds] = useState([]);
  const [notes, setNotes] = useState([]);

  const items = JSON.parse(localStorage.getItem("UserClaims"));
  const currentDate = new Date();
  const nextMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );
  const lastDayOfMonth = new Date(nextMonth - 1);

  const [department, setdepartment] = useState([]);

  //const [selectDepartment, SetselectDepartment] = useState("All");

  const [alldepartments, setalldepartments] = useState(items.Restaurant_id);

  let [length, setlength] = useState(recordPerPage);

  const [isLoadings, setIsLoadings] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.list.length / recordPerPage);

  // useEffect(() => {
  //   setUser(data.list.slice(0, recordPerPage));
  //   console.log("data:", users);
  //   setShow(true);
  // }, [data, length, dropdown]);

  // useEffect(() => {
  //   setUser(
  //     data.list.slice(0 * recordPerPage, 0 * recordPerPage + recordPerPage)
  //   );
  // }, [recordPerPage, length]);
  useEffect(() => {
    const startIndex = (currentPage - 1) * recordPerPage;
    const endIndex = startIndex + recordPerPage;
    setUser(data.list.slice(startIndex, endIndex));
  }, [recordPerPage, currentPage, data]);

  useEffect(() => {
    if (data.list.length > 0) {
      setUser(data.list.slice(0, recordPerPage));
      setShow(true);
    }
  }, [data, recordPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchstate]);

  const viewfile = (item) => {
    window.open(item);
  };
  // const handlePageChange = (event, value) => {
  //   value = value - 1;
  //   setUser(
  //     data.list.slice(
  //       value * recordPerPage,
  //       value * recordPerPage + recordPerPage
  //     )
  //   );
  // };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlechange = (e) => {
    editUser(e);
  };

  const handleRowSelect = (uploadId, restId) => {
 setdownloads(false)
    if (selectedUploadIds.includes(uploadId)) {
      // If the row was already selected, remove it from the array
      setSelectedUploadIds(selectedUploadIds.filter((id) => id !== uploadId));
    } else {
      // If the row was not selected, add it to the array
      setSelectedUploadIds([...selectedUploadIds, uploadId]);
    }
    // Create a new array of objects with the selectedUploadIds
    const uploadIdsArray = selectedUploadIds.map((id) => ({ upload_id: id }));

    // Create the payload object
    //    const payloadObj = { Rest_id: restId, upload_id: uploadIdsArray };

    // Update the payload state
    setpayload(restId);

    console.log(selectedUploadIds, "selectedUploadIds");
    console.log(payload, "payload");
  };

  const [downloads,setdownloads]=useState(false);

 
  
  const download =async () => {  
    
     const arrays=[];
     
     
  const filteredDepartments = selectedUploadIds.map((dept) => {
  const matchingDepartment = data.list.filter((item) => item.Upload_id == dept);

if(matchingDepartment.length>0){


arrays.push(matchingDepartment[0]);

}});

    try {


      let  response=""
      if(downloads==true){
       response = await mainAxios.post("api/Hampton/downloadAsync",data.list, {
   
      });

    }else{

      response = await mainAxios.post("api/Hampton/downloadAsync",arrays, {

      })


    }


      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement("a");
      const fileName = "Invoices.pdf";
  
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    
    catch (error) {
      console.error('Error downloading PDF:', error);

    }

  //   const uploadIdsArray = selectedUploadIds.map((id) => ({ upload_id: id }));
  //   const payloads = { Rest_id: payload, upload_id: uploadIdsArray };
  //   if (selectedUploadIds.length > 1) {
  //     await fobeAxios.post("api/Budget/download_multiple_pdf", payloads)
  //       .then((res) => {
  //         if (res) {
  //           console.log(res.data, "res.data");
  //           setSelectedUploadIds([]);
  //           window.open(res.data);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     toast.error("Please select atleast one file");
  //   }
   };



   
  const getnotes = async (record) => {
    // setNoterecord(record);
    await fobeAxios(
      "api/Daily/getNotes/" + record.Rest_id + "/" + record.Upload_id
      // "api/Daily/getNotes/" + 5843 + "/" + 2457
    )
      .then((d) => {
        console.log(d.data.LatestNotes, "d.data.LatestNotes");

        setNotes(d.data.LatestNotes);
        setNotesPopup(true);
      })
      .catch(() => {
        alert("Went Wrong");
      });
  };
  useEffect(() => {
    console.log(selectedUploadIds, "selectedUploadIds");
  }, [selectedUploadIds, payload]);

  const [shows, setShows] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(false);
  const [id, setid] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  let filter = "";
  const [data1, setsata1] = useState({});

  const [showinvoiceupload, setshowinvoiceupload] = useState(false);

  const handleClick = (item) => {
    filter = item;

    setsata1(item);
    console.log(filter.Upload_id, "ID");
    //uploadFiles(item)
    console.log(item, "Filedata");
    if (item.invoice_status == "3") {
      setshowinvoiceupload(true);
    }
  };

  const invoiceuploadhandleCloses = () => {
    console.log(data1.Upload_id, "ID");
    setshowinvoiceupload(false);
  };
  const [logoimage, setLogoImage] = useState([]);
  const compressImage = null;
  const [myFiles, setMyFiles] = useState([]);
  const [filepath, setFilepath] = useState([]);
  const [filedname, setFileDName] = useState([]);
  const [size, setSize] = useState(0);
  const cFile = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
  const handleInputChange = (event) => {
    const file = event.target.files;
    console.log(file, "file");
    let check;
    for (let i = 0; i < cFile.length; i++) {
      check = false;
      if (file[0].type === cFile[i]) {
        if (
          file[0].size / 1000000 > 1 &&
          file[0].type !== "application/pdf" &&
          file[0].type !== "image/png"
        ) {
          compressImage.compress(file[0]).then((data) => {
            setMyFiles([...myFiles, data]);
            setFilepath([...filepath, logoimage]);
            setFileDName([...filedname, data.name]);
            setSize(size + data.size / 1000000);
          });
          i = cFile.length;
          check = true;
        } else {
          setMyFiles([...myFiles, file[0]]);
          setFilepath([...filepath, logoimage]);
          setFileDName([...filedname, file[0].name]);
          setSize(size + file[0].size / 1000000);
          i = cFile.length;
          check = true;
        }
      }
    }
    if (!check) {
      toast.warning("Supported files only accepted");
    }
    console.log("file", myFiles, filepath);
  };

  const uploadFiles = async () => {
    //alert('hello')

    console.log(filter, "ID");
    setIsLoading(true);
    console.log("vassssss", myFiles, filepath);
    const frmData1 = new FormData();
    frmData1.append("uploadimage", "c:/temp/abc.png");
    if (id !== "") {
      console.log(filter.Upload_id, "ID");
      console.log("ID");
      frmData1.append("uploadid", data1.Upload_id);
    } else {
      frmData1.append("uploadid", data1.Upload_id);
    }
    for (let i = 0; i < myFiles.length; i++) {
      frmData1.append("invoicedetails", myFiles[i], myFiles[i].name);
    }
    console.log("file uploaded data", frmData1);
    await mainAxios
      .post("/Api/daily/upload_invoiceinfo/", frmData1)
      .then((resp) => {
        setIsLoading(false);
        setMyFiles([]);
        setFilepath([]);
        setSize(0);
        setshowinvoiceupload(false);
        console.log("resp", resp);
        if (resp.data == 1) {
          toast.success("File uploaded successfully");
          getData();
        } else {
          toast.error("File uploaded failed");
        }
      });
  };

  const removeFile = (i) => {
    setLogoImage([]);
    if (myFiles.length == 0) {
      setSize(0);
    } else {
      setSize(size - myFiles[i].size / 1000000);
    }
    setMyFiles(myFiles.filter((file, index) => index !== i));
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // let newPaths = this.state.filepath.filter((path, index) => index !== i);
    // setFilepath(filepath.filter((path, index) => index !== i));
    // setFileDName(filedname.filter((name, index) => index !== i));
    // const fileInput = document.querySelector('input[type="file"]');
    // fileInput.value = '';
  };
  const [Courseid, setCourseid] = useState(items.Restaurant_id);

  useEffect(() => {
    const getdatasss = async () => {
      await mainAxios
        .get("api/Common/getRestaurantList/" + Courseid + "/" + 2023)
        .then((res) => {
          console.log(res.data.Restaurants, "heybyy");
          setdepartment(res.data.Restaurants);

          const restaurants = res.data.Restaurants;

          // Use the map method to create a new array with the desired values
          const restaurantData = restaurants.map((restaurant) => {
            return {
              Rest_id: restaurant.Rest_id,
              Rest_Name: restaurant.Rest_Name,
            };
          });

          console.log(data.list, "TESRR");
        });
    };

    getdatasss();
  }, []);

  console.log(department.length, "Cluster");

 // useEffect(() => {
    if (items.Account_Type == 0 && data.list.length > 0) 
    {
      const filteredDepartments = data.list.map((dept) => {
        const matchingDepartments = department.filter(
          (item) => item.Rest_id === dept.Rest_id
        );
        const Rest_Name =
          matchingDepartments.length > 0
            ? matchingDepartments[0].Rest_Name
            : "";
        console.log(matchingDepartments, "Rest_Name");
        return Rest_Name;
      });

      data.list.forEach((dept, index) => {
        if (index < filteredDepartments.length && filteredDepartments[index]) {
          const parts = filteredDepartments[index].split("-");
          if (parts.length > 1) {
            dept.Rest_Name = parts[1].trim();
          }
        }
      });
      console.log(filteredDepartments, "fineee");
    }
  //});

  const [bossalldepart, setbossalldepart] = useState(1);
  const [checkingForNoBudget, setcheckingForNoBudget] = useState(false);
  const [showtable, setshowtable] = useState(false);

  //const [bossid, setbossid] = useState(items.Boss_id);

  //  const handleSelectdrp = (eventKey, event) => {

  //   SetselectDepartment(eventKey.split("`")[1]);

  //   //console.log(eventKey.split("`")[0],"EventKey")
  //   if (eventKey.split("`")[1] === "All ") {
  //   } else {

  // let course = eventKey.split("`")[0];

  //     setbossid(course)

  //     console.log(bossid,"EventKey")

  //   }

  //   getdata();
  // }, []);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = () => {
    setdownloads(true)
    setIsCheckAll(!isCheckAll);
    setSelectedUploadIds(data.list.map((li) => li.Upload_id));

    if (isCheckAll) {
      setSelectedUploadIds([]);
    }
    console.log(data.list, "Text");
  };

  return (
    <>
      <Modal
        className="modalforupload"
        show={showinvoiceupload}
        onHide={invoiceuploadhandleCloses}
      >
        <Modal.Header
          style={{
            border: "none",
            padding: "15px 30px 10px 30px",
            background: "#fff",
            borderRadius: "6px",
          }}
        >
          <Modal.Title
            style={{
              fontSize: "22px",
              textAlign: "left",
              color: "#000",
              background: "#fff",
              padding: "0px 0px 8px",
              borderBottom: "2px solid #BCBCBC",
            }}
          >
            Upload Invoice{" "}
            <i
              class="bi bi-x-circle-fill"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#24D153",
                fontSize: "23px",
                float: "right",
                marginTop: "-3px",
              }}
              onClick={invoiceuploadhandleCloses}
            ></i>
          </Modal.Title>
        </Modal.Header>

        {/* <Modal.Header style={{backgroundColor:"#19A73F",color:"white"}} >
      <Modal.Title>Upload Invoice      <i
      class="fa fa-times"
      aria-hidden="true"
      style={{
        cursor: "pointer",
        color: "white",
        fontSize: "1em",
        float: "right",
        marginTop: "3px",
      }}
      onClick={invoiceuploadhandleCloses}
      ></i></Modal.Title>
      </Modal.Header> */}

        <Modal.Body style={{ padding: "15px 15px 0px 15px" }}>
          {/* style={{height:'190px',overflowX:'hidden',overflowY:'scroll'}} */}
          <div>
            <div className="upload-btn-wrapperr">
              <button class="b-tnn">
                Select file from your device
                <input
                  type="file"
                  value={logoimage}
                  multiple
                  onChange={(e) => {
                    {
                      handleInputChange(e);
                    }
                  }}
                />
              </button>
            </div>
          </div>
          <div>
            <div className="disclaimer_text">Supports: .PDF, .PNG, .JPG</div>
          </div>
          <ul
            style={{
              maxHeight: "200px",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            {myFiles.map((file, index) => (
              <div
                className="upld-sec"
                style={{ marginLeft: "-30px", marginTop: "0px" }}
              >
                <div className="col-md-12 all-sec-up">
                  <div className="row">
                    <div
                      className="col-md-8 col-sm-8"
                      style={{ display: "flex" }}
                    >
                      <div className="col">
                        <div style={{ paddingTop: "3px" }}>
                          <span>
                            <i
                              className="fa fa-file-text-o fil-icn-upld"
                              key={index}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div
                        className="col file-col"
                        style={{ textAlign: "left" }}
                      >
                        <span> {file.name}</span>
                        {/* - {file.type} */}
                      </div>
                    </div>
                    <div
                      className="col-md-4 col-sm-4"
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        gap: "10px",
                      }}
                    >
                      <div className="col mb-col">
                        <span
                          style={{
                            fontSize: "14px",
                            border: "1px solid #ccc",
                            borderRadius: "3px",
                            padding: "5px",
                          }}
                        >
                          {numeral(file.size / 1000000).format("0.00")}MB
                        </span>
                      </div>
                      <div className="col progrss-cls">
                        <span>
                          <div className="progress blue">
                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value">
                              <i
                                className="bi bi-check2"
                                style={{
                                  paddingRight: "0px",
                                  fontSize: "19px",
                                }}
                              ></i>
                            </div>
                          </div>
                        </span>
                      </div>
                      <div className="col">
                        <span
                          id=""
                          style={{
                            paddingTop: "3px",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{ color: "#898989", fontSize: "23px" }}
                            className="bi bi-x"
                            onClick={() => removeFile(index, file.size)}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </ul>

          <div className="file-size text-center">
            {myFiles != "" ? <p>File Size:{size.toFixed(2)}MB</p> : null}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            textAlign: "center",
            marginRight: "0em",
            border: "none",
            padding: "0px 0px 32px 0px",
          }}
        >
          <div className="d-flex justify-content-end w-100">
            <Button
              className="close-bt close-bt1"
              type="button"
              style={{
                textTransform: "capitalize",
                backgroundColor: "#f0f0f0",
                fontSize: "12px",
                fontWeight: "500",
                boxShadow: "none",
                border: "1px solid #85828221",
                padding: "8px 16px",
                color: "black",
                marginRight: "10px",
              }}
              variant="secondary"
              onClick={invoiceuploadhandleCloses}
            >
              Close
            </Button>
            <Button
              className="upload-bt upload-bt1"
              style={{
                marginRight: "0em",
                textTransform: "capitalize",
                backgroundColor: "#24D153",
                fontSize: "12px",
                fontWeight: "500",
                boxShadow: "none",
                border: "1px solid #24D153",
                padding: "8px 16px",
                color: "white",
              }}
              onClick={uploadFiles}
              disabled={myFiles.length == 0 ? true : false}
            >
              Upload
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <div
        className="container-fluid"
        style={{
          paddingRight: "40px",
          paddingLeft: "40px",
          marginTop: "20px",
          marginTop: "97px",
        }}
      >
        <div className="col-md-12">
          <div
            class="row"
            style={{
              marginBottom: "30px",
              paddingBottom: "18px",
              paddingTop: "18px",
              borderBottom: "1px solid #ccc",
            }}
          >
            <div
              className="col-lg-3 col-md-3 col-sm-12"
              style={{ paddingLeft: "0px" }}
            >
              <div className="h2-title Daily-font" style={{ marginTop: "5px" }}>
                Invoice Dashboard
              </div>
            </div>

            <div
              className="col-lg-9 col-md-9 col-sm-12"
              style={{ paddingRight: "0px", paddingTop: "4px" }}
            >
              <div class="col-lg-4 col-md-5 col-sm-8 col-xs-6">
                {/* <Dropdown
                          className="dr_op inv-dr_op"
                          style={{
                            border: "1px solid #ddd",
                            background: "#fff",
                            padding: "4px 10px",
                            borderRadius: "3px",
                            marginTop: "0px",
                          }}
                        
                          onSelect={handleDropdownChange}
                        >
                          <span
                            className="drop-header-text drop-header-text1"
                            style={{
                              paddingRight: "5px",
                              color: "#666666",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "36px",
                            }}
                          >
                          {periodvalue}

                          </span>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            className="dr-ml header-btn"
                            variant="dark"
                            style={{ float: "right", padding: "8px 0px" }}
                          >
                            <i
                              class="bi bi-caret-down-fill"
                              style={{ color: "#19a73f", fontSize: "11px" }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            variant="dark"
                            className="pageheaddrop inv-drop"
                          >
                            <Dropdown.Item
                            eventKey={"All"}
                            onClick={() => setperiod1("All")} className={period1 === "All" ? "active" : ""} >All</Dropdown.Item>
                            <>

                              <Dropdown.Item  eventKey={"Processing"}
                              className={period1 === "Processing" ? "active" : ""}
                            onClick={() => setperiod1("Processing")}>Processing</Dropdown.Item>
                              <Dropdown.Item  
                              className={period1 === "Completed" ? "active" : ""}
                            eventKey={"Completed"}
                            onClick={() => setperiod1("Completed")}>Completed</Dropdown.Item>
                            </>
                            <Dropdown.Item 
                            eventKey={"Re-Scan"}
                            className={period1 === "Re-Scan" ? "active" : ""}
                            onClick={() => setperiod1("Re-Scan")} > Re-Scan</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
              </div>
              <div
                className="inv-dash-row"
                style={{ display: "flex", gap: "20px", float: "right" }}
              >
                {items.Account_Type == 0 && items.Account_id == 5 && (
                  <>
                    <div className="col">
                      <p
                        className="depart-drp depart-drp-inv"
                        style={{
                          color: "#7B7B7B",
                          font: "normal normal 500 12px/33px Roboto",
                        }}
                      >
                        Department
                      </p>
                    </div>
                    <div className="col">
                      <Dropdown onSelect={handleclick}>
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          className="depar-dropml inv-dep"
                          variant="secondary"
                        >
                          <span>
                            {" "}
                            {selectDepartment
                              ? selectDepartment.slice(0, 10)
                              : "All Department"}
                            &nbsp;{" "}
                            <i
                              style={{
                                color: "#24D153",
                                fontWeight: "600",
                                fontSize: "15px",
                              }}
                              class="fa fa-angle-down icon-drop"
                            ></i>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="dark" className="forAll">
                          <Dropdown.Item
                            eventKey={alldepartments + "`" + "All "}
                          >
                            All
                          </Dropdown.Item>
                          {console.log("")}
                          {department?.map((x) => (
                            <Dropdown.Item
                              eventKey={x.Rest_id + "`" + x.Rest_Name}
                              className={
                                selectedDepartment ===
                                x.Rest_id + "`" + x.Rest_Name
                                  ? "active"
                                  : ""
                              }
                            >
                              {x.Rest_Name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>{" "}
                  </>
                )}
                <div className="col">
                  <label className="label-ipad" style={{ color: "#7b7b7b" }}>
                    From
                  </label>
                  &nbsp;&nbsp;
                  <input
                    className="date-pck-inc date-pck-inv"
                    type="date"
                    value={fromdate}
                    max={todate}
                    name="fromdate"
                    onChange={handleDateChange}
                    style={{ outline: "none" }}
                    //max={todate}
                  />
                </div>
                <div className="col">
                  <label className="label-ipad" style={{ color: "#7b7b7b" }}>
                    To
                  </label>
                  &nbsp;&nbsp;
                  <input
                    className="date-pck-inc date-pck-inv"
                    type="date"
                    value={todate}
                    // max={updatedate}
                    name="todate"
                    onChange={handleDateChange}
                    style={{ outline: "none" }}
                    min={fromdate}
                  />
                </div>
                <div className="col">
                  <button
                    type="button"
                    class="src-invc mt-4 src-ipad"
                    style={{
                      background: "rgb(25 167 63)",
                      color: "white",
                      borderRadius: "3px",
                    }}
                    onClick={getDatas}
                  >
                    {" "}
                    <i class="fa fa-search"></i>&nbsp; Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {show && (
          <div className="panel" style={{ margin: "-20px -22px -20px -22px" }}>
            <div className="col-md-12">
              <div
                className="forborder"
                style={{ paddingTop: "10px", paddingBottom: "0px" }}
              >
                <div class="row" style={{ marginBottom: "30px" }}>
                  <div className="col-lg-6 col-md-6 col-sm-5">
                    <div className="" style={{ display: "flex", gap: "10px" }}>
                      <div class="">
                        <p
                          className="drop-text label-ipad"
                          style={{
                            color: "#7b7b7b",
                            marginTop: "1px",
                            marginRight: "-25px",
                          }}
                        >
                          Filter By
                        </p>
                      </div>
                      <div class="col-lg-4 col-md-5 col-sm-8 col-xs-6">
                        <Dropdown
                          className="dr_op inv-dr_op"
                          style={{
                            border: "1px solid #ddd",
                            background: "#fff",
                            padding: "4px 10px",
                            borderRadius: "3px",
                            marginTop: "0px",
                          }}
                          onSelect={handleDropdownChange}
                        >
                          <span
                            className="drop-header-text drop-header-text1"
                            style={{
                              paddingRight: "5px",
                              color: "#666666",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "36px",
                            }}
                          >
                            {periodvalue}
                          </span>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            className="dr-ml header-btn"
                            variant="dark"
                            style={{ float: "right", padding: "8px 0px" }}
                          >
                            <i
                              class="bi bi-caret-down-fill"
                              style={{ color: "#19a73f", fontSize: "11px" }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            variant="dark"
                            className="pageheaddrop inv-drop"
                          >
                            <Dropdown.Item
                              eventKey={"All"}
                              onClick={() => setperiod1("All")}
                              className={period1 === "All" ? "active" : ""}
                            >
                              All
                            </Dropdown.Item>
                            <>
                              <Dropdown.Item
                                eventKey={"Processing"}
                                className={
                                  period1 === "Processing" ? "active" : ""
                                }
                                onClick={() => setperiod1("Processing")}
                              >
                                Processing
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={
                                  period1 === "Completed" ? "active" : ""
                                }
                                eventKey={"Completed"}
                                onClick={() => setperiod1("Completed")}
                              >
                                Completed
                              </Dropdown.Item>
                            </>
                            <Dropdown.Item
                              eventKey={"Re-Scan"}
                              className={period1 === "Re-Scan" ? "active" : ""}
                              onClick={() => setperiod1("Re-Scan")}
                            >
                              {" "}
                              Re-Scan
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div>
                        {selectedUploadIds.length > 1 && (
                          <button
                            className="flowcheck"
                            onClick={() => {
                              download();
                            }}
                          >
                            Download
                          </button>
                        )}
                      </div>

                      {/* <div className="col-md-7">
<label style={{ fontSize: "14px", color: "#666666", float: "right", marginTop: "7px", fontWeight: '600' }}>Filter By :</label>
</div> */}
                      {/* <div className="col-md-5">
                <select       value={dropdown}
              
            onChange={handleDropdownChange}  style={{width:"15em",height:"2.5em",borderRadius:"3px", border: "1px solid #ddd", outline: "none"}}>
                <option value='0'>All</option>
                <option value='5'>Processing</option>
                <option value='2'>Completed</option>
                <option value='3'>Re-Scan</option>   
                    </select>
</div> */}
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-7"
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "right",
                    }}
                  >
                    <div class="col" style={{ float: "left" }}>
                      <span className="label-ipad" style={{ color: "#7b7b7b" }}>
                        {" "}
                        Show Rows
                      </span>
                      <Form.Select
                        className="dr-sel"
                        style={{ outline: "none", height: "37px" }}
                        aria-label="Default select example"
                        onChange={(e) => setRecordPerPage(+e.target.value)}
                      >
                        <option value="5">5</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </Form.Select>
                      {/* <span className="label-ipad" style={{color:'#7b7b7b'}}>
                    Entries
                  </span> */}
                      {/* {selectedUploadIds.length>1 && (
                <button  className="flowcheck"   onClick={() => {
                  download();
                  }}>Download</button> )} */}
                    </div>

                    <div class="col">
                      {Search && (
                        <div class="">
                          <div>
                            <button
                              style={{
                                display: "flex",
                                background: "transparent",
                                border: "1px solid #ddd",
                                borderRadius: "3px",
                              }}
                            >
                              <input
                                id="search-box"
                                class="sear sear-inv"
                                placeholder="Search"
                                value={searchstate}
                                onChange={(e) => searchFunction(e.target.value)}
                              />

                              <i
                                class="bi bi-search search-icon"
                                style={{
                                  marginLeft: "0em",
                                  float: "right",
                                  marginRight: "5px",
                                  fontSize: "15px",
                                  marginTop: "5px",
                                  color: "#686464",
                                }}
                              />
                            </button>
                          </div>
                          {/* <div class="col-sm-6">
                        <span style={{float: "right", color: "#686464", marginTop: '7px'}}
                        >search: </span>
                      </div>
                      <div class="col-sm-6">
                        <input
                          id="search-box"
                          value={searchstate}
                          class="sear"
                         onChange={(e) => searchFunction(e.target.value)}
                        />
                      </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {users.length > 0 ? (
                  <div
                    class="table-scroll table-responsive inv-table table-bordered"
                    style={{ borderTop: "1px solid #ccc" }}
                  >
                    <Table striped bordered hover className="Inv-Dash-Table">
                      <thead>
                        <tr>
                          <th
                            className="inv-th"
                            style={{ zIndex: 1, display: "flex" }}
                          >
                            <span style={{ marginTop: "5px" }}>
                              <input
                                style={{ cursor: "pointer" }}
                                type="checkbox"
                                onClick={handleSelectAll}
                                checked={isCheckAll}
                              />
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            <div
                              className="tab-card-new admin-budget-th inv-table-th inv-table-th1"
                              style={{
                                textAlign: "center",
                                padding: "6px 6px",
                                width: "100%",
                              }}
                            >
                              INVOICE DATE{" "}
                            </div>
                          </th>
                          <th className="inv-th" style={{ zIndex: 1 }}>
                            <div
                              className="tab-card-new admin-budget-th inv-table-th"
                              style={{
                                textAlign: "center",
                                padding: "6px 6px",
                              }}
                            >
                              INVOICE NUMBER
                            </div>
                          </th>
                          <th className="inv-th" style={{ zIndex: 1 }}>
                            <div
                              className="tab-card-new admin-budget-th inv-table-th"
                              style={{
                                textAlign: "center",
                                padding: "6px 6px",
                              }}
                            >
                              PURVEYOR{" "}
                            </div>
                          </th>
                          <th className="inv-th" style={{ zIndex: 1 }}>
                            <div
                              className="tab-card-new admin-budget-th inv-table-th"
                              style={{
                                textAlign: "center",
                                padding: "6px 6px",
                              }}
                            >
                              AMOUNT
                            </div>
                          </th>
                          <th className="inv-th" style={{ zIndex: 1 }}>
                            <div
                              className="tab-card-new admin-budget-th inv-table-th"
                              style={{
                                textAlign: "center",
                                padding: "6px 6px",
                              }}
                            >
                              STATUS
                            </div>
                          </th>
                          <th className="inv-th" style={{ zIndex: 1 }}>
                            <div
                              className="tab-card-new admin-budget-th inv-table-th"
                              style={{
                                textAlign: "center",
                                padding: "6px 6px",
                              }}
                            >
                              ACTION
                            </div>
                          </th>
                        
                          {items.Account_Type == 0 && ( 
                            <th className="inv-th" style={{ zIndex: 1 }}>
                              <div
                                className="tab-card-new admin-budget-th inv-table-th"
                                style={{
                                  textAlign: "center",
                                  padding: "6px 6px",
                                }}
                              >
                                DEPARTMENT
                              </div>
                            </th> 
                          )}
                          
                        </tr>
                      </thead>
                      {users?.map((item) => (
                        <tbody>
                          <tr key={item.id}>
                            <td className="f1 inv-td">
                              <span style={{ float: "left" }}>
                                <input
                                  style={{ cursor: "pointer" }}
                                  type="checkbox"
                                  onChange={() =>
                                    handleRowSelect(
                                      item.Upload_id,
                                      item.Rest_id
                                    )
                                  }
                                  checked={selectedUploadIds.includes(
                                    item.Upload_id
                                  )}
                                />
                              </span>
                              &nbsp;&nbsp;&nbsp;
                              <span
                                className="ipad-date"
                                style={{ marginLeft: "10px" }}
                              >
                                {new Date(item.CreatedDate).toLocaleString()}
                              </span>
                            </td>
                            <td
                              className="f1 inv-td"
                              style={{ textAlign: "center" }}
                            >
                              {item.Inv_num ? item.Inv_num : "-"}
                            </td>
                            <td
                              className="f1 inv-td"
                              style={{ textAlign: "center" }}
                            >
                              {item.Purveyor_name ? item.Purveyor_name : "-"}
                            </td>
                            <td
                              className="f1 inv-td"
                              style={{ textAlign: "center" }}
                            >
                              {"$ " + item.Amount.toLocaleString()}
                            </td>
                            <td
                              className="f1 inv-td"
                              style={{ textAlign: "center" }}
                            >
                              <div className="rescan-div">
                              {console.log(item.invoice_status,"Stata")}
                                <button 
                                  disabled={
                                    items.Account_Type == 0 &&
                                    items.Account_id == 5
                                      ? true
                                      : ""
                                  }
                                  onClick={() => handleClick(item)}
                                  style={{
                                    width: item.invoice_status == "7"? '110px':'',
                                    borderRadius: "3px",
                                    backgroundColor:
                                      item.invoice_status == "5" ||
                                      item.invoice_status == "1" ||
                                      item.invoice_status == "6"||
                                      item.invoice_status == "7"
                                        ? "#fff"
                                        : item.invoice_status == "2"
                                        ? "#158927"
                                        : "#ff9800",
                                    color:
                                      item.invoice_status == "5" ||
                                      item.invoice_status == "1" ||
                                      item.invoice_status == "6" ||
                                      item.invoice_status == "7"
                                        ? "#00c344"
                                        : item.invoice_status == "2"
                                        ? "#fff"
                                        : "#fff",
                                    border:
                                      item.invoice_status == "5" ||
                                      item.invoice_status == "1" ||
                                      item.invoice_status == "7"||
                                      item.invoice_status == "6"
                                        ? "2px solid #00c344"
                                        : item.invoice_status == "2"
                                        ? "2px solid #158927"
                                        : "2px solid #ff9800",
                                    padding:
                                      item.invoice_status == "5" ||
                                      item.invoice_status == "1" ||
                                      item.invoice_status == "6" ||
                                      item.invoice_status == "7"
                                        ? "0px 18px 0px 16px"
                                        : item.invoice_status == "2"
                                        ? "0px 20px 0px 20px"
                                        : "0px 29px 0px 29px",
                                  }}
                                >
                                  {item.invoice_status == "5" || item.invoice_status == "1" ||item.invoice_status == "6"? "Processing"  : item.invoice_status == "2"? "completed":item.invoice_status=="7" ? "Duplicate":"Re-scan"}
                                </button>
                                {item.invoice_status == "5" ||item.invoice_status == "1" || item.invoice_status =="6"|| item.invoice_status =="7" ? null : item.invoice_status =="2" ? null : (  
                                  
                                  <i
                                    onClick={() => {
                                      getnotes(item);
                                    }}
                                    className="fa fa-edit ng-star-inserted edit-inv"
                                    data-target="#theModal"
                                    data-toggle="modal"
                                    style={{
                                      fontSize: "20px",
                                      color: "#00adff",
                                      paddingTop: "10px",
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                      position: "absolute",
                                    }}
                                  ></i>
                                )}
                              </div>
                            </td>
                            <td
                              className="f1 inv-td"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                className="badgev"
                                onClick={() => {
                                  viewfile(item.filename);
                                }}
                              >
                                view invoice
                              </button>
                            </td>
                            {items.Account_Type == 0 && 
                            (
                              <td
                                className="f1 inv-td1"
                                style={{ textAlign: "center" }}
                              >
                                {item.Rest_Name ? item.Rest_Name : ""}
                              </td>
                            )}
                          </tr>
                        </tbody>
                      ))}
                    </Table>
                  </div>
                ) : (
                  <div>
                    <div class="table-scroll" style={{ overflow: "hidden" }}>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th style={{ zIndex: 1 }}>
                              <div className="tab-card-new admin-budget-th">
                                INVOICE DATE{" "}
                              </div>
                            </th>
                            <th style={{ zIndex: 1 }}>
                              <div className="tab-card-new admin-budget-th">
                                INVOICE NUMBER
                              </div>
                            </th>
                            <th style={{ zIndex: 1 }}>
                              <div className="tab-card-new admin-budget-th">
                                PURVEYOR{" "}
                              </div>
                            </th>
                            <th style={{ zIndex: 1 }}>
                              <div className="tab-card-new admin-budget-th">
                                AMOUNT
                              </div>
                            </th>
                            <th style={{ zIndex: 1 }}>
                              <div className="tab-card-new admin-budget-th">
                                STATUS
                              </div>
                            </th>
                            <th style={{ zIndex: 1 }}>
                              <div className="tab-card-new admin-budget-th">
                                ACTION
                              </div>
                            </th>
                            {items.Account_Type == 0 && ( 
                            <th className="inv-th" style={{ zIndex: 1 }}>
                              <div
                                className="tab-card-new admin-budget-th inv-table-th"
                                style={{
                                  textAlign: "center",
                                  padding: "6px 6px",
                                }}
                              >
                                DEPARTMENT
                              </div>
                            </th> 
                          )}
                          </tr>
                        </thead>
                      </Table>

                      <img
                        src={image}
                        className="img"
                        style={{ margin: "0 auto", display: "block" }}
                      />

                      <div style={{ textAlign: "center" }}>
                        <span
                          style={{
                            fontWeight: "bolder",
                            marginLeft: "0 auto",
                            fontSize: "15px",
                            opacity: "0.6",
                            fontFamily: "FontAwesome",
                          }}
                        >
                          No Data Found
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ marginBottom: "17px" }}>
                 {pagination && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
                )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Add Notes Popup */}
      <Modal
        show={notespopup}
        onHide={() => {
          setNotesPopup(false);
        }}
        className="priceing"
      >
        <Modal.Header
          style={{
            border: "none",
            padding: "45px 45px 20px 45px",
            background: "#fff",
            borderRadius: "6px",
          }}
        >
          <Modal.Title
            style={{
              fontSize: "24px",
              textAlign: "left",
              color: "#000",
              background: "#fff",
              padding: "0px 0px 25px",
              borderBottom: "2px solid #BCBCBC",
            }}
          >
            Notes{" "}
            <i
              class="bi bi-x-circle-fill"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#24D153",
                fontSize: "23px",
                float: "right",
                marginTop: "-5px",
              }}
              onClick={() => {
                setNotesPopup(false);
              }}
            ></i>
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Header  style={{    background: "#19a73f", padding: "10px 15px"}}>
         <Modal.Title  style={{fontSize: "20px", color: "#fff", textAlign: "center"}}>Notes</Modal.Title>
         <button type="button" className="close" onClick={() => {
           setNotesPopup(false);
         }}><span aria-hidden="true" style={{fontWeight: "normal"}}>&times;</span></button>
          
       </Modal.Header> */}
        <Modal.Body>
          <div class="row" style={{ padding: "0px 17px" }}>
            <div class="row scrol-pop">
              {notes.length > 0 ? (
                <div class="history">
                  {/* <div class="col-md-2 pd-0">
                 <h5 class="h5-sz" style={{ marginLeft: "-10px" }}>
                   Notes:
                 </h5>
               </div> */}
                  <div class="col-md-12 pd-0" style={{ padding: "25px 0px" }}>
                    <div
                      class="hiscont"
                      style={{ height: "275px", overflow: "hidden scroll" }}
                    >
                      {notes.map((x) => {
                        return (
                          <ul class="timeline">
                            <li class="timeline-inverted">
                              <div
                                class="timeline-badge"
                                style={{ wordWrap: "break-word" }}
                              >
                                {x.date.split(" ")[0] +
                                  " " +
                                  x.date.split(" ")[1]}
                                <br />
                                {x.date.split(" ")[2]}
                              </div>
                              <div class="timeline-panel">
                                <div class="row">
                                  <div class="col-md-6 post_u">
                                    <p class="by_post"></p>
                                  </div>
                                  <div class="col-md-12 g_date">
                                    <p
                                      class="glyph_u"
                                      style={{ float: "right" }}
                                    >
                                      <i
                                        className="glyphicon glyphicon-time"
                                        style={{ marginRight: "5px" }}
                                      ></i>
                                      {x.date.split(" ")[3] +
                                        " " +
                                        x.date.split(" ")[4]}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div class="flex-container flex-around">
                                  <div class="box arrow-left timeline-heading">
                                    <textarea
                                      style={{
                                        resize: "none",
                                        border: "none",
                                        background: "transparent",
                                        outline: "none",
                                        marginTop: "-10px",
                                        lineHeight: "25px",
                                      }}
                                      readonly
                                    >
                                      {x.notes}
                                    </textarea>
                                    <p class="com"></p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-12 pd-0">
                  <p
                    className="emptynotes"
                    style={{
                      textAlign: "center",
                      color: "#666",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStyle: "italic",
                    }}
                  >
                    The notes is empty
                  </p>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*<Modal size="sm" show={shows} onHide={handleClose}>
        <Modal.Body className='p-2 text-center' style={{ fontSize: "1.2em" }}> Are you sure you want to delete account?</Modal.Body>
        <Modal.Footer style={{ border: "none", textAlign: "center" }}>
          <Button variant="mute" style={{ marginRight: "1em", background: "red", color: "white", padding: "0.2em 1em" }} onClick={(e) => {
            handleClose(e);
            setShows(false);
            setShow(true);
          }}>
            No
          </Button>
          <Button variant="mute" style={{ marginRight: "1em", background: "green", color: "white", padding: "0.2em 1em" }} onClick={(e) => {handledelete(deleteitem); handleClose(e);   setShows(false); searchFunction(""); }} 
          >
            Yes
          </Button>
        </Modal.Footer>
        </Modal>*/}
    </>
  );
};

export default TableComponent1;
