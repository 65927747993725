import 'bootstrap/dist/css/bootstrap.min.css'
import './Weather.css'
import moment from 'moment';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { mainAxios } from '../../middleware/interceptor';
const sample1 = [{

}, {}, {}, {}, {}]
function Weather() {
   const items=JSON.parse(localStorage.getItem("UserClaims"))

   const [sample, setsample] = useState(sample1)
   const [loader, setloader] = useState(false)

   // eslint-disable-next-line react-hooks/rules-of-hooks
   useEffect(() => {
      try {


         

         mainAxios.get('Api/Hampton/weather/'+items.Company).then(resp => {
            console.log(resp.data,"datafromWeather")


            setloader(true);

            setsample(resp.data.forecast)
         })
      } catch {
        
         setloader(false);
      }
   }, [])



   return (


      <>


         {loader &&
            <div class="container ">
               <div class="werow">
                  <div class="col-xs-12 col-lg-10  newweather" >

                     <ul class="">
                        <li class="">
                           <div class="block1 ">
                              <div class="block1deg"><span class="deg1"> {sample[0]?.temp.max}  <sup >°</sup></span><span class="deg2">{sample[0]?.temp.min} <sup >°</sup> - {sample[0]?.temp.max} <sup >°</sup></span></div>
                              <div class="block1image"><img src="https://cdn.aerisapi.com/wxblox/icons/fair.png" alt="Mostly Sunny" /><br /><span class="block1imagecon text-uppercase">{sample[0]?.description}</span></div>
                              <div class="block1list">
                                 <p class="list listbor"><span class="listname">wind</span><span class="listitem">NW {sample[0]?.wind} m/s</span></p>
                                 <p class="list listbor"><span class="listname">humidity</span><span class="listitem">{sample[0]?.humidity} %</span></p>
                                 <p class="list "><span class="listname">rain</span><span class="listitem">{sample[0]?.rain} %</span></p>
                              </div>
                           </div>
                           <div class="we-sec weatherreport-location ng-star-inserted">
                              <span class="loca-img"><span class="loca1"><i aria-hidden="true" class="fa fa-map-marker"></i> {sample[0]?.city} </span>
                              <span class="weatherreport-date"> at {moment.unix(sample[0]?.date).format('LL')} </span></span></div>
                           <div class="curve">
                              <svg preserveAspectRatio="none" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
                                 <path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                              c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                              c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
                              </svg>
                           </div>
                        </li>
                        <li class="ng-star-inserted">
                           <div class="block2 ng-star-inserted">
                              <p ><span class="dayname ">Wed</span><img src={sample[0]?.icon} alt="Mostly Sunny" /><span class="daydeg">{sample[0]?.temp?.min} <sup >°</sup>/{sample[0]?.temp?.max} <sup >°</sup></span><span class="listitem"><i aria-hidden="true" class="fa fa-tint"></i> {sample[0]?.humidity} %</span></p>
                           </div>
                           <div class="curve">
                              <svg preserveAspectRatio="none" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
                                 <path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                              c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                              c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
                              </svg>
                           </div>
                        </li>
                        <li class="ng-star-inserted">
                           <div class="block2 ng-star-inserted">
                              <p ><span class="dayname ">Thu</span><img src={sample[1]?.icon} alt="Mostly Sunny" /><span class="daydeg">{sample[1]?.temp?.min} <sup >°</sup>/{sample[1]?.temp?.max} <sup >°</sup></span><span class="listitem"><i aria-hidden="true" class="fa fa-tint"></i> {sample[1]?.humidity} %</span></p>
                           </div>
                           <div class="curve">
                              <svg preserveAspectRatio="none" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
                                 <path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                              c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                              c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
                              </svg>
                           </div>
                        </li>
                        <li class="ng-star-inserted">
                           <div class="block2 ng-star-inserted">
                              <p ><span class="dayname ">Fri</span><img src={sample[2]?.icon} alt="Mostly Sunny" /><span class="daydeg">{sample[2]?.temp?.min} <sup >°</sup>/{sample[2]?.temp?.max} <sup >°</sup></span><span class="listitem"><i aria-hidden="true" class="fa fa-tint"></i> {sample[2]?.humidity} %</span></p>
                           </div>
                           <div class="curve">
                              <svg preserveAspectRatio="none" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
                                 <path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                              c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                              c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
                              </svg>
                           </div>
                        </li>
                        <li class="ng-star-inserted">
                           <div class="block2 ng-star-inserted">
                              <p ><span class="dayname ">Sat</span><img src={sample[3]?.icon} alt="Mostly Sunny" /><span class="daydeg">{sample[3]?.temp?.min} <sup >°</sup>/{sample[3]?.temp?.max} <sup >°</sup></span><span class="listitem"><i aria-hidden="true" class="fa fa-tint"></i> {sample[3]?.humidity} %</span></p>
                           </div>
                           <div class="curve">
                              <svg preserveAspectRatio="none" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
                                 <path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                              c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                              c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
                              </svg>
                           </div>
                        </li>
                        <li class="ng-star-inserted">
                           <div class="block2 ng-star-inserted">
                              <p ><span class="dayname ">Sun</span><img src={sample[4]?.icon} alt="Mostly Sunny" /><span class="daydeg">{sample[4]?.temp?.min} <sup >°</sup>/{sample[4]?.temp?.max} <sup >°</sup></span><span class="listitem"><i aria-hidden="true" class="fa fa-tint"></i> {sample[4]?.humidity} %</span></p>
                           </div>
                           <div class="curve">
                              <svg preserveAspectRatio="none" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
                                 <path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                              c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                              c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
                              </svg>
                           </div>
                        </li>
                        <li class="ng-star-inserted">
                           <div class="block2 ng-star-inserted">
                              <p ><span class="dayname ">Mon</span><img src={sample[5]?.icon} alt="Partly Cloudy" /><span class="daydeg">{sample[5]?.temp?.min} <sup >°</sup>/{sample[5]?.temp?.max} <sup >°</sup></span><span class="listitem"><i aria-hidden="true" class="fa fa-tint"></i> {sample[5]?.humidity} %</span></p>
                           </div>
                           <div class="curve">
                              <svg preserveAspectRatio="none" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
                                 <path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                              c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                              c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
                              </svg>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>

            </div>}
      </>


   );
}
export default Weather;
