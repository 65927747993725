import '../Footer/footer.css'


const  Footer = () => {
    return ( 
        <div className='container-fluid footer-sec'>
            <div className=' ft_rw normal-footer' style={{display:'block'}}>
        <div className='col-md-12'>
    <div className='row' >

<div className="col-md-4 col-sm-5 col-xs-12 ftr-left">

<address className='addressfooter'>
                    <p className="numb"><i className="fa fa-volume-control-phone"></i><span><a href="tel:904-538-0780">904-538-0780</a></span></p>
                    <p className="icon"><i className="fa fa-envelope-o"></i><span className="support">SUPPORT@FOBEGOLF.COM</span></p>
                    {/* <div className="icons">
                        <a ><i className="fa fa-facebook" title="facebook"></i></a>
                        <a><i className="fa fa-youtube-play" title="youtube"></i> </a>
                        <a><i className="fa fa-linkedin" title="linkedin"></i></a>
                        <a><i className="fa fa-instagram" title="instagram"></i></a>
                    </div> */}
                    <p>©2022 – FobeGolf, Inc. - <span key="reserved"> All Rights Reserved</span></p>
                    <div className="btn-group btn-toggle buttontoggle ">
                        <a type="button" id="en" onclick="" className=" btn-md en">English</a>
                        <a type="button" id="es" onclick="" className=" btn-md active es">Español</a>
                    </div>
                </address>
</div>

<div className="col-md-4 col-sm-3 col-xs-12 ftr-brand">
                <a href={"https://dev.fobegolf.com/"} style={{ Cursor:'pointer'}} className="navbar-brand">
                    <img className="footer-logo" src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/footer-logo.png"/>
                </a>
            </div>

            
            <div className="col-md-4 col-sm-4 col-xs-12 footer-links" >
                <div className="">
                    <ul>
                       <li><a href='https://dev.fobegolf.com/services.html'>Services</a></li>
                        <li><a href='https://dev.fobegolf.com/Blog'>Blog</a></li>
                        <li><a href='https://dev.fobegolf.com/about-us.html' key="aus">About Us</a></li>
                        <li><a href="https://newapp.fobegolf.com/" key="lgn">Login</a></li>
                    </ul>
                    <div className="buttondiv">
                  <a href="https://newapp.fobegolf.com/Signup" className="btn button" key="sign">Sign Up Now</a>
                    &nbsp;
                        <a href='https://dev.fobegolf.com/contact-us.html' className="btn button" key="sch">Schedule Demo</a>
                        {/* &nbsp;
                        <a className="btn button" key="requestinfo3">Request Info</a> */}
                    </div>
                </div>
                <br/>
            </div>

<br/>
</div>
</div>
</div>


           <div className=' ft_rw mbl-footer' style={{display:'none'}}>
        <div className='col-md-12'>
    <div className='row' >

<div className="col-md-4 col-sm-5 col-xs-12 ftr-left">
           <div className="col-md-4 col-sm-4 col-xs-12 footer-links" >
                <div className="">
                    <ul>
                        <li><a href='https://dev.fobegolf.com/services.html'>Services</a></li>
                        <li><a href='https://dev.fobegolf.com/Blog'>Blog</a></li>
                        <li><a href='https://dev.fobegolf.com/about-us.html' key="aus">About Us</a></li>
                        <li><a href="https://newapp.fobegolf.com/" key="lgn">Login</a></li>
                    </ul>
                    <div className="buttondiv">
                    <a href="https://newapp.fobegolf.com/Signup" className="btn button" key="sign">Sign Up Now</a>
                    &nbsp;
                         <a href='https://dev.fobegolf.com/contact-us.html' className="btn button" key="sch">Schedule Demo</a>
                        {/* &nbsp;
                        <a className="btn button" key="requestinfo3">Request Info</a> */}
                    </div>
                </div>
                <br/>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-12 ftr-brand">
                <a href={"https://dev.fobegolf.com/"} style={{ Cursor:'pointer'}} className="navbar-brand">
                    <img className="footer-logo" src="https://d2a7u30t6wbkim.cloudfront.net/assets/fobegolf/image/footer-logo.png"/>
                </a>
            </div>
<address className='addressfooter'>
                    <p className="numb"><i className="fa fa-volume-control-phone"></i><span><a href="tel:904-538-0780">904-538-0780</a></span></p>
                    <p className="icon"><i className="fa fa-envelope-o"></i><span className="support">SUPPORT@FOBEGOLF.COM</span></p>
                    {/* <div className="icons">
                        <a ><i className="fa fa-facebook" title="facebook"></i></a>
                        <a><i className="fa fa-youtube-play" title="youtube"></i> </a>
                        <a><i className="fa fa-linkedin" title="linkedin"></i></a>
                        <a><i className="fa fa-instagram" title="instagram"></i></a>
                    </div> */}
                    <p>©2022 – FobeGolf, Inc. - <span key="reserved"> All Rights Reserved</span></p>
                    <div className="btn-group btn-toggle buttontoggle ">
                        <a type="button" id="en" onclick="" className=" btn-md en">English</a>
                        <a type="button" id="es" onclick="" className=" btn-md active es">Español</a>
                    </div>
                </address>
</div>



            
 

<br/>
</div>
</div>
</div>
</div>




    );
};

export default Footer;