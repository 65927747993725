import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../loader";
import { useState } from "react";
import { useEffect } from "react";
import { mainAxios, FobeAxios } from "../middleware/interceptor";
import SnapshotMonthlyViewTable from "../Component/Tables/SnapshotMonthlyViewTable";
import { Spinner } from "../Component/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tab } from "@testing-library/user-event/dist/tab";
import { Await } from "react-router-dom";

const revMonth = [
  {
    month: "January",
    category: "Food",
    list: [
      { amount: 100, percentage: 98 },
      { amount: 200, percentage: 98 },
    ],
    total: 4000,
    per: 87,
  },
  {
    month: "FEBRUARY",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },
  {
    month: "march",
    category: "Food",
    list: [
      { amount: 100, percentage: 98 },
      { amount: 200, percentage: 98 },
    ],
    total: 4000,
    per: 87,
  },
  {
    month: "APril",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },
  {
    month: "May",
    category: "Food",
    list: [
      { amount: 100, percentage: 98 },
      { amount: 200, percentage: 98 },
    ],
    total: 4000,
    per: 87,
  },
  {
    month: "June",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },

  {
    month: "JULY",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },
  {
    month: "AUGUST",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },
  {
    month: "SEPTEMBER",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },
  {
    month: "OCTOBER",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },
  {
    month: "NOVEMBER",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },
  {
    month: "DECEMBER",
    category: "Wine",
    list: [
      { amount: 500, percentage: 98 },
      { amount: 1000, percentage: 98 },
    ],
    total: 5000,
    per: 87,
  },
];

const sampleTotalPlan_List = [
  {
    Fore_Month: 1,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 2,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 3,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 4,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 5,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 6,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 7,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 8,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 9,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 10,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 11,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
  {
    Fore_Month: 12,
    Fore_Rounds: 0,
    Fore_Per: 0,
    Editable_Status: 1,
  },
];

const sampleDay_List = [
  [
    {
      Fore_Month: 1,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 2,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 3,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 4,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 5,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 6,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 7,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 8,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 9,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 10,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 11,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 12,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
  ],
  [
    {
      Fore_Month: 1,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 2,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 3,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 4,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 5,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 6,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 7,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 8,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 9,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 10,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 11,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 12,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
  ],
  [
    {
      Fore_Month: 1,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 2,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 3,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 4,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 5,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 6,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 7,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 8,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 9,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 10,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 11,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 12,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
  ],
  [
    {
      Fore_Month: 1,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 2,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 3,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 4,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 5,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 6,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 7,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 8,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 9,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 10,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 11,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 12,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
  ],
  [
    {
      Fore_Month: 1,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 2,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 3,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 4,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 5,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 6,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 7,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 8,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 9,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 10,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 11,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 12,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
  ],
  [
    {
      Fore_Month: 1,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 2,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 3,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 4,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 5,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 6,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 7,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 8,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 9,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 10,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 11,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 12,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
  ],
  [
    {
      Fore_Month: 1,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 2,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 3,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 4,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 5,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 6,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 7,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 8,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 9,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 10,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 11,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
    {
      Fore_Month: 12,
      Fore_Rounds: 0,
      Fore_Per: 0,
      Editable_Status: 2,
    },
  ],
];
const monthd = [
  { key: "1", Month: "January" },
  { key: "2", Month: "February" },
  { key: "3", Month: "March" },
  { key: "4", Month: "April" },
  { key: "5", Month: "May" },
  { key: "6", Month: "June" },
  { key: "7", Month: "July" },
  { key: "8", Month: "August" },
  { key: "9", Month: "September" },
  { key: "10", Month: "October" },
  { key: "11", Month: "November" },
  { key: "12", Month: "December" },
];
function SnapshotBudget({ dropdowndata, tabs }) {
  const [TotalPlan_List, setTotalPlan_List] = useState([]);
  const [Day_List, setDay_List] = useState([]);
  const [perFlag, setPerFlag] = useState(false);
  const [perFlagMonth, setPerFlagMonth] = useState(false);
  const [issueText, setissueText] = useState("");

  const [loading, setLoading] = useState(true);
  const userDetail = JSON.parse(localStorage.getItem("UserClaims"));
  const[true1,settrue1]=useState(false)
  const[true2,settrue2]=useState(false)
const[show,setshow]=useState(true)


  const encodedString = btoa(dropdowndata);

  useEffect(() => {
    getBudget();
  }, [dropdowndata, tabs]);

  const getBudget = async () => {
    setLoading(true);
    setPerFlag(false);
    await mainAxios
      .get(
        "Api/Hampton/GetForecastPlan/" +
          new Date().getUTCFullYear() +
          "/" +
          encodedString+"/"
      )
      .then((resp) => {
        console.log(resp.data, "ddddddd");
        // sample=resp.data
        setshow(true)
        if (resp.data["TotalPlan_List"].length) {
          console.log("budget", resp.data);
          setTotalPlan_List(resp.data["TotalPlan_List"]);
          setDay_List(resp.data["Day_List"]);
          setLoading(false);
        } else {
          setTotalPlan_List(sampleTotalPlan_List);
          setDay_List(sampleDay_List);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const changeData = (Fore_Month, value, index) => {


    {console.log(value,"change value")}

    if(typeof(value)==="undefined"){
      value=0;
    }


    let totalRounds = TotalPlan_List.find(
      (x) => x.Fore_Month == Fore_Month
    ).Fore_Rounds;
    let totalPercentage = TotalPlan_List.find(
      (x) => x.Fore_Month == Fore_Month
    ).Fore_Per;

    let totalPer = 0;

    const daylist = [...Day_List];

    daylist[index].map((list) => {
      if (list.Fore_Month == Fore_Month) {
        list.Fore_Rounds = (parseFloat(value) / 100) * totalRounds;
        list.Fore_Per = value;
        // totalPer =  totalPer + parseFloat(list.Fore_Per);
      }
    });

    for (let i = 0; i < daylist.length; i++) {
      daylist[i].map((list) => {
        if (list.Fore_Month == Fore_Month) {
          totalPer = totalPer + parseFloat(list.Fore_Per);
        }
      });
    }

    setDay_List(daylist);

    setTotalPlan_List((TotalPlan_List) => {
      return TotalPlan_List.map((list) => {
        if (list.Fore_Month == Fore_Month) {
          list.Fore_Per = totalPer;
        }
        return list;
      });
    });

    totalRounds = TotalPlan_List.find(
      (x) => x.Fore_Month == Fore_Month
    ).Fore_Rounds;
    
    if (
      (totalRounds != 0 && totalPer != 100) ||
      (totalRounds == 0 && totalPer !== 100) 
    )
      setPerFlag(true);
    else setPerFlag(false);
  };

  const handleTotalRound = (Fore_Month, value) => {
   
    setTotalPlan_List((TotalPlan_List) => {
      return TotalPlan_List.map((list) => {
        if (list.Fore_Month == Fore_Month) {
          list.Fore_Rounds = value.floatValue;
          // list.Fore_Per = 100;
        }
        return list;
      });
    });

    let totalPercentage = TotalPlan_List.find(
      (x) => x.Fore_Month == Fore_Month
    ).Fore_Per;
    let totalRounds = TotalPlan_List.find(
      (x) => x.Fore_Month == Fore_Month
    ).Fore_Rounds;
    if ((totalRounds > 0 && totalPercentage != 100))
      setPerFlag(true);
    else setPerFlag(false);

    const daylist = [...Day_List];

    daylist.map((x) => {
      x.map((list) => {
        if (list.Fore_Month == Fore_Month) {
          list.Fore_Rounds = list.Fore_Per
            ? (list.Fore_Per / 100) * value.floatValue
            : 0;
        }
      });
      return x;
    });
    setDay_List(daylist);
  };

  const saveRoundBudget = async () => {
    
    settrue2(false)
    setLoading(true)
    setPerFlagMonth(false);
    setPerFlag(false)
    if (!perFlag) {
      let ForecastPlan = {
        ForecastPlan: [
          { Year_List: [{ Year: new Date().getUTCFullYear(), course: dropdowndata }] },
          [{ TotalPlan_List: TotalPlan_List }],
          [{ Day_List: Day_List }],
        ],
      };
      console.log(Day_List, "Day_List");
      console.log(TotalPlan_List, "TotalPlan_List");
      const rr = await TotalPlan_List.map((y) => {
        console.log(isNaN(y.Fore_Rounds))
        if (y.Fore_Rounds !== "" && y.Fore_Rounds !== 0 && !(isNaN(y.Fore_Rounds))) {
         
          if (y.Fore_Per != 100) {
            setLoading(false)
            let name=monthd.filter(z=>z.key==y.Fore_Month)
            
            toast.error("You Should Complete "+name[0].Month+" Rounds");
            return false;
          } else {
            return true;
          }
        } 
       
        else if(y.Fore_Rounds===0 || isNaN(y.Fore_Rounds)){
          console.log(y.Fore_Rounds,"fromout")
          console.log(y.Fore_Per,"fromPer")
          if(y.Fore_Per!=0){
            console.log(y.Fore_Rounds,"fromIn")
            let name=monthd.filter(z=>z.key==y.Fore_Month)
            setLoading(false)
            toast.error("You Should Complete "+name[0].Month+" Rounds");
            return false;
          }
          else{
            return true;
          }

        }
       
      });
   

      if (!(String(rr).includes('false'))) {
            await mainAxios
              .post("Api/Hampton/SaveForecast", ForecastPlan)
              .then((resp) => {
                setshow(true)
                setLoading(false)
                toast.success("Budget Updated Successfully");
              })
              .catch((err) => {
                setLoading(false)
                toast.error("Budget Update Failed");
              });
          }
        
      // setTimeout(async()=>{
      //   alert(perFlagMonth)
      //   if (!perFlagMonth) {
      //     await mainAxios
      //       .post("Api/Hampton/SaveForecast", ForecastPlan)
      //       .then((resp) => {
      //         toast.success("Budget Updated SucessFully");
      //       })
      //       .catch((err) => {
      //         toast.error("Budget Update Failed");
      //       });
      //   }
      // },1000)
    }
  };

  // const [monthData, setMonthData] = useState({ data: revMonth, label: 'Revenue Monthly View', title: 'Day', sample: budgetdata });

  return (
    <div className="container-fluid">
      {loading && (
        <Spinner />
      ) } 
      {(show )&&(
        <div className="row monthlytable">
          <SnapshotMonthlyViewTable
            perFlag={perFlag}
            handleTotalRound={handleTotalRound}
            changeData={changeData}
            Day_List={Day_List}
            title="Day"
            isBudget={false}
            TotalPlan_List={TotalPlan_List}
          />

          {perFlag && (
            <p
              style={{
                color: "red",
                marginBottom: "2.5rem",
                textAlign: "center",
              }}
            >
              *Total Percentage Should be 100 %
            </p>
          )}
          
        </div>
      )}
        {console.log( true2,perFlag,"hhhh" )}
      <div class="col-sm-12 bt-pr">
       {(!loading) && <button
          class="btn btn-success cxt-ctr"
          disabled={true2 || perFlag }
          onClick={() => saveRoundBudget()}
        >
          <span class="up-pr">Submit</span>
        </button>}
      </div>
    </div>
  );
}
export default SnapshotBudget;
