import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
//import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import React from "react";
import Tooltip from "react-simple-tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../Datepagenator.css";
import "./TableComponent.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import Pagination from "@mui/material/Pagination";
import image from "../../folder.png";

import "./Uploadexceltable.css";

const Uploadexceltable = ({
  header,
  data,
  nestedColumn,
  searchFunction,
  Search,
  pagination,
  getPage,
  isFooter,
  editUser,
  searchText
}) => {
  const renderTooltip = (props) => <Tooltip {...props}>Edit</Tooltip>;
  const renderTooltip1 = (props) => <Tooltip {...props}>Delete</Tooltip>;

  const [show, setShow] = useState(false);
  const [errordata, seterrordata] = useState([]);
  const [showmodal, setShowmodal] = useState(false);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [users, setUser] = useState(false);
  let [length, setlength] = useState(recordPerPage)
  useEffect(() => {
    if (data.list.length > 0) {
      setUser(data.list.slice(0, recordPerPage));
      // console.log("dataaa:", users)
      setShow(true);
    }
    else{
      setShow(false);
    }
    console.log("varahi", data);
  }, [data, length]);

  useEffect(() => {
    setUser(
      data.list.slice(0 * recordPerPage, 0 * recordPerPage + recordPerPage)
    );
  }, [recordPerPage, length]);

  const nextPage = (offset) => {
    setUser(
      data.list.slice(
        offset * recordPerPage,
        offset * recordPerPage + recordPerPage
      )
    );
  };

  const firstPage = () => {
    setUser(data.list.slice(0, recordPerPage));
  };

  const lastPage = () => {
    setUser(
      data.list.slice(data.list.length - recordPerPage, data.list.length)
    );
  };

  const func = (e) => {
    setlength(Math.ceil(data.list.length / recordPerPage));
  };
  const popup = (e) => {
    console.log("e", e);
    seterrordata(e);
    e.map((item) => {
      setShowmodal(true);
    });
    console.log(errordata, "errordata");
  };
  console.log("dataaa:", users);
  const handleClose = () => {

  //alert('ojkok')
    setShowmodal(false);
  };
  const handleShow = () => {
    setShowmodal(true);
  };
  const handlePageChange = (event, value) => {
    value = value - 1;
    setUser(
      data.list.slice(
        value * recordPerPage,
        value * recordPerPage + recordPerPage
      )
    );
  };
  return (
    <>
     {/* // {show && ( */}
       {/* // <div> */}
          {/* <div style={{ float: "right" }}>
            <button
              style={{
                display: "flex",
                background: "transparent",
                border: "1px solid #80808078",
                borderRadius: "3px",
              }}
            >
              <input id="search-box" class="sear" placeholder="Search"  onChange={(e) => searchFunction(e.target.value)}/>
              <i
                class="bi bi-search search-icon"
                style={{
                  marginLeft: "0em",
                  float: "right",
                  marginRight: "5px",
                  fontSize: "15px",
                  marginTop: "5px",
                  color: "#686464",
                }}
              />
       
          </div> */}
          <div className="col-md-12">
            <div className="row" style={{marginBottom:'30px'}}>
             <div>
            <div
              className="colforrow"
              style={{ display: "flex", gap: "24px", float: "right" }}
            >
              <div className="col" style={{ display: "flex" }}>
                <span
                  className="show"
                  style={{
                    color: "#7B7B7B",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  Show Rows
                </span>
                <Form.Select
                  className="dr-sel view-dr"
                  aria-label="Default select example"
                  onChange={(e) => setRecordPerPage(+e.target.value)}
                >
                  <option value="5">5</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Form.Select>
              </div>
              <div class="col">
                <button
                  style={{
                    display: "flex",
                    background: "transparent",
                    border: "1px solid #80808078",
                    borderRadius: "3px",
                  }}
                >
                  <input
                    id="search-box"
                    class="sear"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => searchFunction(e.target.value)}
                  />

                  <i
                    class="bi bi-search search-icon"
                    style={{
                      marginLeft: "0em",
                      float: "right",
                      marginRight: "5px",
                      fontSize: "15px",
                      marginTop: "5px",
                      color: "#686464",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>

            </div>

          </div>


          {   
      show ? (
        <div>

          <div class="table-scroll">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ padding: "8px 8px 8px 0px" }}>
                    <div className="tab-card-new admin-budget-th">
                      First Name
                    </div>
                  </th>
                  <th>
                    <div className="tab-card-new admin-budget-th">
                      Last Name
                    </div>
                  </th>
                  <th>
                    <div className="tab-card-new admin-budget-th">Email</div>
                  </th>
                  <th>
                    <div className="tab-card-new admin-budget-th">Password</div>
                  </th>
                  <th>
                    <div className="tab-card-new admin-budget-th">Role</div>
                  </th>
                  <th>
                    <div className="tab-card-new admin-budget-th">Course</div>
                  </th>
                  <th>
                    <div className="tab-card-new admin-budget-th">
                      Department
                    </div>
                  </th>
                  <th>
                    <div className="tab-card-new admin-budget-th">Phone</div>
                  </th>
                  <th style={{ padding: "8px 0px 8px 8px" }}>
                    <div className="tab-card-new admin-budget-th">Status</div>
                  </th>
                </tr>
              </thead>
              <tbody class="tab-gf">
                {users?.map((user, index) => (
                  <tr>
                    <td>{user.First_Name}</td>
                    <td>{user.Last_Name}</td>
                    <td>{user.Email}</td>
                    <td>{user.Password}</td>
                    <td>{user.Role}</td>
                    <td>{user.Course}</td>
                    <td>{user.Department}</td>
                    <td>{user.Phone}</td>

                    {/* {user.Errormessage.length > 0 && <td className='admin-budget-td' style={{textAlign:"center"}}>   <Tooltip content={user.Errormessage.length + " Error!"}><FaTimes  style={{color:"red"}} onClick={(e) => popup(user.Errormessage)}/></Tooltip>
</td>}
{user.Errormessage.length == 0 && <td className='admin-budget-td' style={{textAlign:"center"}}>   <Tooltip content="0Error!"><FaCheck style={{color:"green"}} onClick={(e) => popup(user.Errormessage)}/></Tooltip></td>} */}
                    {user.Errormessage.length > 0 && (
                      <td
                        className="admin-budget-td"
                        style={{ textAlign: "center" }}
                      >
                        {" "}
                        <Tooltip content={user.Errormessage.length + " Error!"}>
                          <i
                            class="bi bi-exclamation-circle-fill"
                            style={{ color: "red" ,cursor:'pointer'}}
                            onClick={(e) => popup(user.Errormessage)}
                          ></i>
                        </Tooltip>
                      </td>
                    )}
                    {user.Errormessage.length == 0 && (
                      <td
                        className="admin-budget-td"
                        style={{ textAlign: "center" }}
                      >
                        {" "}
                        <Tooltip content="0 Error!">
                          <i
                            class="bi bi-check-circle-fill"
                            style={{ color: "green" }}
                            onClick={(e) => popup(user.Errormessage)}
                          ></i>{" "}
                        </Tooltip>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>

              {isFooter && (
                <tfoot>
                  <tr>
                    <td>TOTAL</td>
                    <td>
                      <span>$</span> <p className="fl-r">{data.total}</p>
                    </td>
                    <td>
                      <p className="fl-r">
                        {data.percentage}
                        <span>%</span>
                      </p>
                    </td>
                  </tr>
                </tfoot>
              )}
            </Table>
          </div>
          <div style={{marginLeft:'50px'}}>
                {pagination && (
                  <Pagination
                    count={Math.ceil(data.list.length / recordPerPage)}
                    className={"paginate"}
                    //variant="outlined"
                    //shape="rounded"
                    onChange={handlePageChange}
                  ></Pagination>
                )}
              </div>
        </div>
      ) :
      (
       <div>
       
           <div class="table-scroll" style={{ overflow: "hidden" }}>
             <Table striped bordered hover>
               <thead>
                 <tr>
                   {!nestedColumn &&
                     header.map((head) => (
                       <th>
                         <div className="tab-card-new admin-budget-th">{head.column}</div>
                       </th>
                     ))}
                 </tr>
               </thead>
             </Table>

             <img src={image} className="img" style={{margin:'0 auto'}}/>

             <div>
               <span
                 style={{
                   fontWeight: "bolder",
                   margin: "0 auto",
                   fontSize: "15px",
                   opacity: "0.6",
                   fontFamily: "FontAwesome",
                 }}
               >
                 No Data Found
               </span>
             </div>
           </div>
         
       </div>
     )}

      <Modal
        className="upload"
        show={showmodal}
        onHide={handleClose}
        size="xs"
        backdrop="static"
        keyboard={false}
        style={{}}
      >
        <Modal.Header
          style={{
            border: "none",
            padding: "20px 20px 40px",
            background: "#fff",
            borderRadius: "6px",
          }}
        >
          <Modal.Title
            style={{
              fontSize: "22px",
              textAlign: "left",
              color: "#000",
              background: "#fff",
              padding: "0px 0px 20px",
              borderBottom: "2px solid #BCBCBC",
            }}
          >
            Warning{" "}
            <i
              class="bi bi-x-circle-fill"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#24D153",
                fontSize: "23px",
                float: "right",
                marginTop: "-7px",
              }}
              onClick={handleClose}
            ></i>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            color: "#9d9d9d",
            textAlign: "center",
            fontSize: "18px",
            padding: "0px 30px",
            height: "300px",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {errordata?.map((item) => {
            console.log(item,"dddddd");
            return (
              //  <div style={{marginTop:"1em", backgroundColor: "#ffecb3",color: "#7f6003", height:"56px" ,padding: '10px' ,border: '1px solid #ffc107',borderWidth: "0 0 0 6px",borderRadius:"4px"}} ><FaInfoCircle style={{color:"#7f6003" }} />&nbsp;&nbsp;{item.msg}</div>

              //   <div style={{marginTop:"1em", backgroundColor: "#ffecb3",color: "#7f6003", height:"56px" ,padding: '10px' ,border: '1px solid #ffc107',borderWidth: "0 0 0 6px",borderRadius:"4px"}} ><FaInfoCircle style={{color:"#7f6003" }} />&nbsp;&nbsp;{item.msg}</div>

              <div className="col-md-12">
                <div
                  className="row"
                  style={{
                    marginTop: "1em",
                    backgroundColor: "#ffecb3",
                    color: "#7f6003",
                    padding: "10px",
                    border: "1px solid #ffc107",
                    borderWidth: "0 0 0 6px",
                    borderRadius: "4px",
                  }}
                >
                  {console.log(item.msg,"item.msg")}
                  <div className="col-md-2 col-sm-2">
                    <i
                      class="bi bi-info-circle-fill info-icon"
                      style={{ color: "#7f6003" }}
                    ></i>
                    {/* <FaInfoCircle style={{color:"#7f6003" }} /> */}
                  </div>
                  <div
                    className="col-md-10 col-sm-10 info-text"
                    style={{ textAlign: "left" }}
                  >
                    {item.msg}
                  </div>
                </div>
              </div>
            );
          })}
        </Modal.Body>

        <Modal.Footer
          style={{
            textAlign: "center",
            padding: "5px 30px 25px",
            border: "none",
          }}
        >
          {/* <div style={{textAlign:'center'}}>
              <button className='btn' type='button' variant="primary" onClick={handleClose} style={{position:"relative",marginRight:'0px',marginTop:'15px',backgroundColor:'#24D153',fontWeight:'bold',
    border:'1px solid #24D153',
    padding: '8px 28px',
    color:' #fff',
    borderRadius:'3px'}} >
                Ok
              </button>
                </div> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Uploadexceltable;
