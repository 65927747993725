import "bootstrap/dist/css/bootstrap.min.css";
import "../Tab_manageuser/Tab_user.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Pagination from "react-bootstrap/Pagination";
import TableComponent from "../Component/Tables/TableComponent";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { mainAxios } from "../middleware/interceptor";
import { Spinner } from "../Component/Spinner";

const sample = [
  {
    firstName: "Nagaraj",
    lastName: "Vanaraj",
    email: "nagaraj@jetzerp.com",
    password: "river",
    course: "cse",
    department: "it",
    Role: "Team Member",
    Status: "1",
    FobesoftStatus: "Not Applicable",
  },
];
const column = [
  { column: "First Name" },
  { column: "Last Name" },
  { column: "Email" },
  { column: "Password" },
  { column: "Course" },
  { column: "Department" },
  { column: "Role" },
  { column: "Status" },
  { column: "Action" },
];

function ViewTeamMembers({ tab, setTab, setEditUser, setDeleteUser }) {
  const [data, setData] = useState(sample);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userupdated, setUserUpdated] = useState(false);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [users, setUser] = useState(false);
  let [length, setlength] = useState(recordPerPage);
  const [show, setShow] = useState(false);

  const getData = async () => {
    let result = [];
    const { data } = await mainAxios.get(`Api/Hampton/GetUsersListnew`);
    console.log("UserList", data.getResultList);

    let Role = "";
    data.getResultList.map((x) => {
      if (x.Posid == "0") {
        Role = "General Manager";
      }
      if (x.Posid == "2") {
        Role = "Team member";
      }
      if (x.Posid == "1") {
        Role = "Department Head";
      }
      if (x.Posid == "4") {
        Role = "Super User";
      }
      result.push({
        Userid: x.UserId,
        firstName: x.Fname,
        lastName: x.Lname,
        email: x.Username,
        password: x.Password,
        course: x.Course,
        department: x.DeptName,
        Courseid: x.Course,
        Deptid: x.Deptid,
        selectRole: x.Posid,
        Role: Role,
        PMail:x.Email,
        status_d: x.Business_Status,
        Status: x.status == 1 ? true : false,
        FobesoftStatus: "Not Appliacable",
        Phone: x.Phone,
        Posid: x.Posid,
      });
    });
    setData(result);
    setSearch(result);
    setIsLoading(false);
    // console.log(data.getResultList);
  };

  useEffect(() => {
    setSearchText("");
    getData();
  }, [tab]);
  useEffect(() => {
    getData();
    if (searchText !== "") {
      searchFunction(searchText);
    }
  }, [userupdated]);

  const searchFunction = (searchText) => {
    setSearchText(searchText);
    console.log(searchText);
    let user = data.filter((item) => {
      return (
        item.Role.toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.email.toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.course.trim().toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.firstName.toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.lastName.toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.department.toLowerCase().startsWith(searchText.toLowerCase())

      );
    });
    console.log(user, "SearchingDetails");
    if (user.length > 0) {
      setSearch(user);
      console.log(user, "Users");
    } else {
      setSearch([]);
    }
  };

  const getPage = () => {};

  const editUser = (user) => {
    console.log("user:", user);
    setEditUser(user);
    setTab("Add");
  };

  return (
    <div
      className="container-fluid"
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
    >
      <div className="col-md-12">
        <div
          className="row"
          style={{ marginRight: "-30px", marginLeft: "-30px" ,marginTop:'-12px'}}
        >
          <div className="col-md-6 col-sm-5">
            {/* <div className={"mergebut"}>
              <a
                style={{
                  fontSize: "5px",
                  color: "rgb(243, 244, 255)",
                  textDecoration: "none",
                  marginRight: "30px",
                }}
              >
                <button
                  className="btn recurringbtns team-btn"
                  type="button"
                  style={{ padding: "6px 20px" }}
                  onClick={() => {
                    setTab("view");
                  }}
                >
                  View Team Member
                </button>
              </a>
              <a
                style={{
                  fontSize: "5px",
                  color: "rgb(243, 244, 255)",
                  textDecoration: "none",
                }}
              >
                <button
                  className="btn recurringbtns user-btn"
                  // class="btn btn-success"

                  style={{ padding: "6px 20px" }}
                  type="button"
                  onClick={() => {
                    setTab("Add");
                  }}
                >
                  {editUser ? "Update User" : "Add User"}
                </button>
              </a>
            </div> */}
             <div
          className=""          
        >
          <p
            className="Daily-font"
            style={{
              textAlign: "left",
              fontSize: "30px",
              letterSpacing: "0px",
              color: "#7B7B7B",
              marginBottom: "30px",
            }}
          >
            View Team Members
          </p>
        </div>
          </div>
          <div className="col-md-6 col-sm-7">
            <div
              className="colforrow"
              style={{ display: "flex", gap: "24px", float: "right" }}
            >
              <div className="col" style={{ display: "flex" }}>
                <span
                  className="show"
                  style={{
                    color: "#7B7B7B",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  Show Rows
                </span>
                <Form.Select
                  className="dr-sel view-dr"
                  aria-label="Default select example"
                  onChange={(e) => setRecordPerPage(+e.target.value)}
                >
                  <option value="5">5</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Form.Select>
              </div>
              <div class="col">
                <button
                  style={{
                    display: "flex",
                    background: "transparent",
                    border: "1px solid #80808078",
                    borderRadius: "3px",
                  }}
                >
                  <input
                    id="search-box"
                    class="sear"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => searchFunction(e.target.value)}
                  />

                  <i
                    class="bi bi-search search-icon"
                    style={{
                      marginLeft: "0em",
                      float: "right",
                      marginRight: "5px",
                      fontSize: "15px",
                      marginTop: "5px",
                      color: "#686464",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="row"
          style={{
            borderBottom: "1px solid rgb(197, 196, 196)",
            marginBottom: "25px",
          }}
        >
          <p
            className="Daily-font"
            style={{
              textAlign: "left",
              fontSize: "30px",
              letterSpacing: "0px",
              color: "#7B7B7B",
              marginTop: "25px",
              marginBottom: "20px",
            }}
          >
            View Team Members
          </p>
        </div> */}
      </div>
      <div class="table-sections">
        {isLoading ? (
          <Spinner />
        ) : (
          <TableComponent
            header={column}
            data={{ list: search }}
            nestedColumn={false}
            editUser={editUser}
            Search={true}
            searchFunction={searchFunction}
            searchstate={searchText}
            pagination={true}
            user_up={userupdated}
            loader={setIsLoading}
            set_user_up={setUserUpdated}
            getPage={getPage}
            users={users}
            setUser={setUser}
            recordPerPage={recordPerPage}
            setRecordPerPage={setRecordPerPage}
            //  const [recordPerPage, setRecordPerPage] = useState(5);
          />
        )}
      </div>
    </div>
  );
}
export default ViewTeamMembers;
