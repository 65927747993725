import "../Manageteam/manageteam.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Tab_user from "../Tab_manageuser/AddTeamMember";
import ViewTeamMember from "../Tab_manageuser/ViewTeamMembers";
import { useState, useEffect } from "react";
import { mainAxios } from "../middleware/interceptor";
import { ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";

function Manageteam() {
  const [tab, setTab] = useState("Add");
  const [editUser, setEditUser] = useState(null);
  const [showDept, setShowDept] = useState(false);




const Tabchange=()=>{

  console.log(editUser,"editUser")
setTab('Add')


if(editUser!=null){

  editUser.firstName="";
  editUser.lastName="";
  editUser.email="";
 editUser.selectRole=""
 editUser.password=""
 editUser.course=""
editUser.department=""
editUser.Courseid="";
editUser.Deptid="";
editUser.Phone=""
editUser.Posid=""
editUser.Userid=null
editUser.FobesoftStatus=""
editUser.Status=false
editUser.status_d=null
editUser.Role=""

}
setShowDept(false)
setEditUser(null);

 }

  return (
    <div
      className="container-fluid"
      style={{ paddingRight: "40px", paddingLeft: "40px", marginTop:"97px" }}
    >
      <ToastContainer autoClose={3000} />
      <div className="tab-sections manageteam">
        <div className="row manageteam2">
          <div className="col-sm-5 col-md-6 col-lg-6">
            <h2
              className="Daily-font"
              style={{
                fontSize: "30px",
                marginTop: "0px",

                float: "left",
                color: "rgb(135,135,135)",
              }}
            >
              Manage Team
              <span className="h2-title" style={{ color: "#7b7b7b" }}></span>
            </h2>
          </div>

          <div className={"col-sm-7 col-md-6"} style={{paddingLeft: "0px"}}>
            <div className={"mergebut"} style={{ float: "right",marginTop:'-6px' }}>
              <a
                style={{
                  fontSize: "5px",
                  color: "rgb(243, 244, 255)",
                  textDecoration: "none",
                  marginRight: "25px",
                }}
              >
                <button
                  className={ tab==='view'? "btn recurringbtn1":"btn newbutss"}
                  type="button"
                  onClick={() => {
                    setTab("view");
                  }}
                >
                  View Team Member
                </button>
              </a>
              <a
                style={{
                  fontSize: "5px",
                  color: "rgb(243, 244, 255)",
                  textDecoration: "none",
                }}
              >
                <button
                  className={ tab === 'Add'? "btn recurringbtn1":"btn newbutss1"}
                  // class="btn btn-success"

                  type="button"
                  onClick={Tabchange}
                >
                  {"Add User"}
                </button>
              </a>
            </div>
          </div>
        </div>{" "}
      </div>
      <div></div> <div></div>
      <br></br>
      <br></br>
      {tab == "Add" ? (
        <Tab_user
          setTab={setTab}
          editUser={editUser}
          setEditUser={setEditUser}
          showDept={showDept}
          setShowDept={setShowDept}

        />
      ) : (
        <ViewTeamMember tab={tab} setTab={setTab} setEditUser={setEditUser} />
      )}
    </div>
  );
}

export default Manageteam;
